import { createContext } from 'react';

export type CardContextType = {
    expanded: boolean;
}

const CardContext = createContext<CardContextType>({ 
    expanded: false,
});

export default CardContext;