import { Icon } from "@iconify/react";
import { CustomIconButtonType } from "./CustomIconButtonType";
import { useState } from "react";
const CustomIconButton = ({
  variant = "primary",
  disabled = false,
  className = "",
  tooltip = "",
  borderShadow = true,
  onClick,
  icon,
}: CustomIconButtonType) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const getButtonClasses = () => {
    return `
      ${className} h-11 w-11 rounded-3xl flex justify-center items-center duration-300 
      ${variant === "primary" ? "bg-neutralGrey100" : "bg-white"} 
      ${onClick && !disabled ? "hover:bg-neutralGrey300 cursor-pointer active:bg-blueGrey200" : ""}
      ${disabled ? "opacity-50 cursor-not-allowed" : ""}
      ${borderShadow ? 'border shadow-button border-white' : ''}
      relative
    `;
  };
  const getIconClasses = () => {
    return `text-buttonIconGrey text-2xl ${
      disabled ? "cursor-not-allowed" : ""
    } ${onClick && !disabled ? "group-active:text-white" : ""}`;
  };

  return (
    <div 
      className="relative inline-block"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <button
        disabled={disabled}
        onClick={onClick && !disabled ? onClick : undefined}
        className={getButtonClasses()}
      >
        <Icon icon={icon} className={getIconClasses()} />
      </button>
      {tooltip && showTooltip && (
        <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-100 transition-opacity duration-300 -bottom-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
          {tooltip}
          <div className="absolute w-2.5 h-2.5 -top-1 left-1/2 transform -translate-x-1/2 rotate-45 bg-gray-900"></div>
        </div>
      )}
    </div>
  );
};

export default CustomIconButton;
