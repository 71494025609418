import React from "react";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomText from "@/components/atoms/CustomText";
import { t } from "i18next";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import { DeviceStatus, ServiceStatus } from "./d";
import { ServiceData } from "@/pages/Device/Service/d";
import { ServiceItemType } from "./ServiceItemType";
const ServiceItem = ({
  service,
  deviceInfo,
  enabled,
  index,
  isRequiredService,
  isModbusService,
  isAnyDeviceInProgress,
  uninstallService,
  updateService,
}: ServiceItemType) => {
  const uninstallServiceOptions = [
    "device-rccmd",
    "device-snmp",
    "device-modbus-tcp",
  ];

  function findServiceToUninstallOptions(valor: any) {
    return uninstallServiceOptions.includes(valor);
  }
  const getStatusText = (service: ServiceData | undefined): string => {
    if (!service) return "N/A";
    const { status, currentVersion } = service;
    const statusTextMap: { [key: string]: string } = {
      OK: currentVersion || "",
      ERROR: t("services.status.error"),
      IN_PROGRESS: t("services.status.inProgress"),
      UNINSTALLED: t("services.status.unInstalled"),
      NOT_INSTALLED: t("services.status.notInstalled"),
      DISABLED: t("services.status.disabled") + (currentVersion || ""),
      ENABLED: t("services.status.enabled") + (currentVersion || ""),
    };
    return statusTextMap[status] || "N/A";
  };
  const getStatusClass = (service: ServiceData | undefined): string => {
    if (service) {
      const statusClassMap: { [key: string]: string } = {
        OK: "bg-green-400 text-white",
        ERROR: "bg-red-600 text-white",
        IN_PROGRESS: "bg-orange-300 text-white",
        UNINSTALLED: "bg-gray-300 text-white",
        NOT_INSTALLED: "bg-gray-300 text-white",
        DISABLED: "bg-gray-300 text-white",
        ENABLED: "bg-green-400 text-white",
      };
      return statusClassMap[service.status] || "bg-gray-300 text-white";
    } else {
      return "bg-gray-300 text-white";
    }
  };

  return (
    <div
      key={index}
      className="grid grid-cols-12 items-center justify-between mt-6"
    >
      <CustomText weight="regular" className="col-span-5 w-full">
        {service.name}
      </CustomText>
      <div
        className={`flex items-center justify-center rounded-lg w-full col-span-3 ${getStatusClass(
          service
        )}`}
      >
        <div className="flex  item-center justify-center gap-2">
          <CustomText size="sm" weight="regular">
            {getStatusText(service)}
          </CustomText>
          {service?.additionalInfo && (
            <CustomIcon
              icon="la:info-circle"
              tooltip={service?.additionalInfo}
            />
          )}
        </div>
      </div>

      <div className="flex justify-end items-center col-span-4 w-full">
        {!isRequiredService(service.name) &&
          (findServiceToUninstallOptions(service.name)||
            service?.status === ServiceStatus.UNINSTALLED ||
            service?.status === ServiceStatus.ENABLED ||
            service?.status === ServiceStatus.NOT_INSTALLED) && (
            <CustomIconButton
            className={!isModbusService(service.name) ? "" : "text-red-500"}
            disabled={
                isAnyDeviceInProgress() ||
                service?.status === ServiceStatus.DISABLED ||
                service?.status === ServiceStatus.NOT_INSTALLED ||
                deviceInfo?.status === DeviceStatus.offline
              }
              onClick={() => uninstallService(service.name)}
              tooltip={
                isModbusService(service.name)
                  ? t("services.remove")
                  : t("services.uninstall")
              }
              icon={
                !isModbusService(service.name)
                  ? "la:trash"
                  : "la:stop-circle-solid"
              }
            />
          )}

        <CustomIconButton
          className="btn btn-success ml-2"
          disabled={
            isAnyDeviceInProgress() ||
            service?.status === ServiceStatus.ENABLED ||
            deviceInfo?.status === DeviceStatus.offline
          }
          onClick={() => updateService(service.name)}
          tooltip={
            isModbusService(service.name)
              ? t("services.add")
              : t("services.updateService")
          }
          icon={
            !isModbusService(service.name)
              ? "la:arrow-down"
              : "la:play-circle-solid"
          }
        />
      </div>
    </div>
  );
};

export default ServiceItem;
