import { authService } from "./auth.service";
import { Device } from "@/utils/models";
import { ModelKey, ModelValue, modelsMapDevices } from "@/utils/constants";
import { apiService } from "./api.service";

export const deviceService = {
  getDeviceSearchParams: async (searchParams?: string) => {
    if (!searchParams) {
      searchParams = "";
    }
    const url = `${process.env.REACT_APP_BASE_URL}/device/search`;
    let response = await apiService.get(
      url,
      authService.getToken(),
      searchParams
    );
    return response;
  },

  create: async (device: Device) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device`;
    let newDevice: any = device;
    newDevice.latitude = device.latitude?.toString();
    newDevice.longitude = device.longitude?.toString();

    let response = await apiService.post(
      url,
      newDevice,
      authService.getToken()
    );
    return response;
  },

  validateDeviceCode: async (deviceCode: string, hashCode: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/qr-verification/${deviceCode}/${hashCode}`;
    let response = await apiService.get(url, authService.getToken());
    return response;
  },

  update: async (device: Device, deviceId?: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device/${deviceId}`;

    device.mac = device.mac ? device.mac.toString() : "";
    device.release = device.release ? device.release.toString() : "";
    device.UPSrelease = device.UPSrelease ? device.UPSrelease.toString() : "";

    let response = await apiService.put(url, device, authService.getToken());
    return response;
  },

  save: async (device: Device, is_new: boolean, deviceId?: string) => {
    return is_new
      ? deviceService.create(device)
      : deviceService.update(device, deviceId);
  },
  delete: async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device/${id}`;
    let response = await apiService.delete(url, authService.getToken());
    return response;
  },
  getModels(): Array<string> {
    return Object.keys(modelsMapDevices);
  },
  getModelByKeyDevice(key: ModelKey): ModelValue | null {
    return modelsMapDevices[key] || null;
  },

  getKeyByModelDevice(model: string): ModelKey | null {
    const key = (Object.keys(modelsMapDevices) as ModelKey[]).find(
      (k) => modelsMapDevices[k] === model
    );
    return key || null;
  },

  getDeviceByUser: async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device/user/${id}`;
    let response = await apiService.get(url, authService.getToken());
    return response;
  },
  getDeviceById: async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device/${id}`;
    let response = await apiService.get(url, authService.getToken());
    return response;
  },
  getDevicesByUserId: async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/device/user/${id}`;
    let response = await apiService.get(url, authService.getToken());
    return response;
  },
  getLastMeasure: async (id: string, showLoader: boolean) => {
    const url = `${process.env.REACT_APP_BASE_URL}/measures/${id}/last`;
    let response = await apiService.get(
      url,
      authService.getToken(),
      showLoader
    );
    return response;
  },
  getGraphicMeasures: async (
    id: string,
    startTime: number,
    endTime: number,
    filters: any
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}/measures/${id}/graphic/${startTime}/${endTime}`;
    let response = await apiService.post(url, filters, authService.getToken());
    return response;
  },
};

export default deviceService;
