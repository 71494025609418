import React from "react";
import CustomInfoBox from "../CustomInfoBox";
import { t } from "i18next";
import { ServiceListType } from "./ServiceListType";
import ServiceItem from "../ServiceItem";
const ServiceList = ({
  services,
  deviceInfo,
  enabled,
  isRequiredService,
  isModbusService,
  isAnyDeviceInProgress,
  uninstallService,
  updateService,
}: ServiceListType) => {

   let requiredServices: Array<string> = [
    "device-logger",
    "device-mapper",
    "device-modbus",
    "device-serial",
    "device-panel",
    "device-mqtt",
    "device-smtp",
    "device-modbus-tcp",
    "device-rccmd",
    "device-snmp",
    "device-modbus-api-rest"
  ];
  const combinedServices = (
    requiredServices.length === 0
      ? services || [] 
      : [
          ...requiredServices.map((deviceName: any) => {
            const service = services?.find((service) => service.name === deviceName);
            return service || {
              name: deviceName,
              status: "NOT_INSTALLED", 
              currentVersion: "N/A",
            };
          }),
          ...services?.filter((service) => 
            !requiredServices.includes(service.name)
          ) || [],
        ]
  );

  return (
    <>
      {services ? (
        combinedServices.map((service: any, index: number) => (
          <ServiceItem
            key={"service" + index}
            service={service}
            deviceInfo={deviceInfo}
            enabled={enabled}
            isRequiredService={isRequiredService}
            isModbusService={isModbusService}
            isAnyDeviceInProgress={isAnyDeviceInProgress}
            uninstallService={uninstallService}
            updateService={updateService}
            index={index}
          />
        ))
      ) : (
        <CustomInfoBox
          icon="la:info-circle"
          className="flex items-center "
          type="danger"
        >
          {t("services.status.disabled")}
        </CustomInfoBox>
      )}
    </>
  );
};

export default ServiceList;
