import CustomIcon from "@/components/atoms/CustomIcon";
import { CustomCheckboxType } from "./CustomCheckboxType";
import { ChangeEvent, useEffect, useRef, useState } from "react";

const CustomCheckbox = ({
    onChange = (e: ChangeEvent<HTMLInputElement>) => { },
    disabled = false,
    checked = false,
    className = '',
    label,
    name,
}: CustomCheckboxType) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const changeInputStateOnClick = () => {
        if (!disabled && inputRef.current) {
            const newCheckState = !isChecked;
            inputRef.current.checked = newCheckState;
            onChange({ target: inputRef.current } as React.ChangeEvent<HTMLInputElement>);
            setIsChecked(newCheckState);
        }
    };

    return (
        <div className={`flex items-center w-fit ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${className}`} onClick={changeInputStateOnClick}>
            <div
                className={`w-8 h-8 shrink-0 rounded-lg border border-black flex justify-center items-center ${disabled ? 'opacity-50' : ''}`}>
                {isChecked &&
                    <CustomIcon icon="la:check" size="2xl" />
                }
                <input
                    defaultChecked={isChecked}
                    className="hidden"
                    type="checkbox"
                    ref={inputRef}
                    name={name}
                />
            </div>
            <label className={`text-sm text-black ml-4 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>{label}</label>
        </div>
    );
}

export default CustomCheckbox;