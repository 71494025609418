import clsx from "clsx";
import { CustomTabLinesType } from "./CustomTabLinesType";

const CustomTabLines = ({
    tabs,
    currentTab,
    onChange,
}: CustomTabLinesType) => {
    const tabArray = Array.from({ length: tabs }, (_, index) => index);

  return (
    <div className="w-full flex justify-center items-center space-x-2">
    {tabArray.map((tabIndex) => (
      <div
        key={tabIndex}
        className={clsx(
          "rounded-full h-2 cursor-pointer transition-all duration-300",
          tabIndex === currentTab && "bg-salicru w-8",
          tabIndex !== currentTab && "bg-gray-300 w-4 hover:bg-red-200 transition-transform hover:scale-110"
        )}
        onClick={() => onChange(tabIndex)}
      ></div>
    ))}
  </div>
  );
};

export default CustomTabLines;
