import React from "react";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import CustomText from "@/components/atoms/CustomText";
import { t } from "i18next";
import { Link, useLocation } from "react-router-dom";
import { DetailNavigationRoutes } from "@/pages/Device";
import { useDeviceContext } from "@/utils/context/deviceContext";
import { CustomListMenuType } from "./CustomListMenuType";

const CustomListMenu = ({
  subtitle = "",
  title = "",
  icon = "",
  index,
  href,
}: CustomListMenuType) => {
  const location = useLocation();
  const { deviceId } = useDeviceContext();
  const isActive = (path: string) => {
    return location.pathname.includes(path);
  };

  const isFirstChild = index === 0;
  const isLastChild = index === DetailNavigationRoutes.length - 1;
  const activeStyle = isActive(`/device/${href}`)
    ? "bg-blueGrey200 border-blueGrey200"
    : "bg-neutralGrey100 border-neutralGrey300 hover:bg-neutralGrey200 cursor-pointer";
  const inactiveStyle = isActive(`/device/${href}`) ? "text-white" : "";

  return (
    <div>
      <Link to={`/device/${deviceId}/${href}`} className="cursor-pointer">
        <div
          className={`flex pt-2 pb-3.5 px-5 border ${activeStyle} ${isFirstChild ? "rounded-t-xl" : ""
            } ${isLastChild ? "rounded-b-xl" : ""}`}
        >
          <div className="bg-white rounded-3xl w-10 h-10 flex justify-center items-center mt-2">
            <CustomIconButton
              disabled={true}
              icon={icon}
              className="text-2xl text-blueGrey100"
            />
          </div>
          <div className={`ml-3 mt-1.5 ${inactiveStyle}`}>
            <CustomText size="lg" weight="bold">
              {t(title)}
            </CustomText>
            <CustomText weight="regular" size="xs">
              {t(subtitle)}
            </CustomText>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CustomListMenu;
