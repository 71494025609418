
export const devicesAlarms: any = {
    "ADAPTX": {
      "83": {
        "0": "alarm.batt_disconnected"
      },
      "84": {
        "0": "alarm.maint_cb_open"
      },
      "85": {
        "0": "alarm.epo"
      },
      "88": {
        "0": "alarm.input_fault"
      },
      "89": {
        "0": "alarm.byp_sequence"
      },
      "90": {
        "0": "alarm.byp_v_fail"
      },
      "91": {
        "0": "alarm.byp_fail"
      },
      "92": {
        "0": "alarm.byp_overload"
      },
      "93": {
        "0": "alarm.byp_overload_timeout"
      },
      "94": {
        "0": "alarm.byp_desync"
      },
      "95": {
        "0": "alarm.tx_timeout"
      },
      "96": {
        "0": "alarm.output_short"
      },
      "97": {
        "0": "alarm.batt_eod"
      },
      "101": {
        "0": "alarm.mant_fault"
      },
      "105": {
        "0": "alarm.inv_deshab_inhab"
      },
      "107": {
        "0": "alarm.volt_batt_low"
      },
      "108": {
        "0": "alarm.batt_pol_inv"
      },
      "110": {
        "0": "alarm.input_neutral_fault"
      },
      "111": {
        "0": "alarm.byp_fan_fault"
      },
      "112": {
        "0": "alarm.redund_lost"
      },
      "113": {
        "0": "alarm.sys_eod"
      },
      "114": {
          "0": "alarm.weld_reversed"
      },
      "115": {
          "0": "alarm.electrolyte_leakage"
      },
      "116": {
          "0": "alarm.batt_sensor_disconnected",
          "1": "alarm.env_sensor_disconnected"
      },
      "118": {
        "0": "alarm.general_alarm",
        "1": "alarm.general_fault"
      },
      "3071": {
        "0": "alarm.rect_fault_mod#1",
        "1": "alarm.inv_failure_mod#1",
        "2": "alarm.temp_rect_high_mod#1",
        "3": "alarm.fan_fault_mod#1",
        "4": "alarm.ups_overload_mod#1",
        "5": "alarm.overload_timeuot_mod#1",
        "6": "alarm.temp_inv_high_mod#1",
        "7": "alarm.inv_cb_mod#1",
        "8": "alarm.manual_shutdown_mod#1",
        "9": "alarm.batt_chgr_fail_mod#1",
        "10": "alarm.power_dist_mod#1",
        "11": "alarm.sync_pulse_mod#1",
        "12": "alarm.input_v_detection_mod#1",
        "13": "alarm.batt_v_detect_mod#1",
        "14": "alarm.output_v_detection_fail_mod#1",
        "15": "alarm.byp_v_detection_fail_mod#1"
      },
      "3072": {
        "0": "alarm.inv_bridge_fail_mod#1",
        "1": "alarm.temp_outlet_error_mod#1",
        "2": "alarm.input_c_unbalanced_mod#1",
        "3": "alarm.busDC_v_high_mod#1",
        "4": "alarm.rect_ss_fault_mod#1",
        "5": "alarm.relay_conn_err_mod#1",
        "6": "alarm.relay_shortc_mod#1",
        "7": "alarm.PWM_sync_mod#1",
        "8": "alarm.intel_sleep_mod#1",
        "9": "alarm.input_c_high_timeout_mod#1",
        "10": "alarm.no_outlet_sens_mod#1",
        "11": "alarm.no_inlet_sens_mod#1",
        "12": "alarm.capacitator_expired_mod#1",
        "13": "alarm.fan_expired_mod#1",
        "14": "alarm.mod_connector_mod#1",
        "15": "alarm.firmware_error_mod#1"
      },
      "3075": {
        "0": "alarm.rect_fault_mod#2",
        "1": "alarm.inv_failure_mod#2",
        "2": "alarm.temp_rect_high_mod#2",
        "3": "alarm.fan_fault_mod#2",
        "4": "alarm.ups_overload_mod#2",
        "5": "alarm.overload_timeuot_mod#2",
        "6": "alarm.temp_inv_high_mod#2",
        "7": "alarm.inv_cb_mod#2",
        "8": "alarm.manual_shutdown_mod#2",
        "9": "alarm.batt_chgr_fail_mod#2",
        "10": "alarm.power_dist_mod#2",
        "11": "alarm.sync_pulse_mod#2",
        "12": "alarm.input_v_detection_mod#2",
        "13": "alarm.batt_v_detect_mod#2",
        "14": "alarm.output_v_detection_fail_mod#2",
        "15": "alarm.byp_v_detection_fail_mod#2"
      },
      "3076": {
        "0": "alarm.inv_bridge_fail_mod#2",
        "1": "alarm.temp_outlet_error_mod#2",
        "2": "alarm.input_c_unbalanced_mod#2",
        "3": "alarm.busDC_v_high_mod#2",
        "4": "alarm.rect_ss_fault_mod#2",
        "5": "alarm.relay_conn_err_mod#2",
        "6": "alarm.relay_shortc_mod#2",
        "7": "alarm.PWM_sync_mod#2",
        "8": "alarm.intel_sleep_mod#2",
        "9": "alarm.input_c_high_timeout_mod#2",
        "10": "alarm.no_outlet_sens_mod#2",
        "11": "alarm.no_inlet_sens_mod#2",
        "12": "alarm.capacitator_expired_mod#2",
        "13": "alarm.fan_expired_mod#2",
        "14": "alarm.mod_connector_mod#2",
        "15": "alarm.firmware_error_mod#2"
      },
      "3079": {
        "0": "alarm.rect_fault_mod#3",
        "1": "alarm.inv_failure_mod#3",
        "2": "alarm.temp_rect_high_mod#3",
        "3": "alarm.fan_fault_mod#3",
        "4": "alarm.ups_overload_mod#3",
        "5": "alarm.overload_timeuot_mod#3",
        "6": "alarm.temp_inv_high_mod#3",
        "7": "alarm.inv_cb_mod#3",
        "8": "alarm.manual_shutdown_mod#3",
        "9": "alarm.batt_chgr_fail_mod#3",
        "10": "alarm.power_dist_mod#3",
        "11": "alarm.sync_pulse_mod#3",
        "12": "alarm.input_v_detection_mod#3",
        "13": "alarm.batt_v_detect_mod#3",
        "14": "alarm.output_v_detection_fail_mod#3",
        "15": "alarm.byp_v_detection_fail_mod#3"
      },
      "3080": {
        "0": "alarm.inv_bridge_fail_mod#3",
        "1": "alarm.temp_outlet_error_mod#3",
        "2": "alarm.input_c_unbalanced_mod#3",
        "3": "alarm.busDC_v_high_mod#3",
        "4": "alarm.rect_ss_fault_mod#3",
        "5": "alarm.relay_conn_err_mod#3",
        "6": "alarm.relay_shortc_mod#3",
        "7": "alarm.PWM_sync_mod#3",
        "8": "alarm.intel_sleep_mod#3",
        "9": "alarm.input_c_high_timeout_mod#3",
        "10": "alarm.no_outlet_sens_mod#3",
        "11": "alarm.no_inlet_sens_mod#3",
        "12": "alarm.capacitator_expired_mod#3",
        "13": "alarm.fan_expired_mod#3",
        "14": "alarm.mod_connector_mod#3",
        "15": "alarm.firmware_error_mod#3"
      },
      "3083": {
        "0": "alarm.rect_fault_mod#4",
        "1": "alarm.inv_failure_mod#4",
        "2": "alarm.temp_rect_high_mod#4",
        "3": "alarm.fan_fault_mod#4",
        "4": "alarm.ups_overload_mod#4",
        "5": "alarm.overload_timeuot_mod#4",
        "6": "alarm.temp_inv_high_mod#4",
        "7": "alarm.inv_cb_mod#4",
        "8": "alarm.manual_shutdown_mod#4",
        "9": "alarm.batt_chgr_fail_mod#4",
        "10": "alarm.power_dist_mod#4",
        "11": "alarm.sync_pulse_mod#4",
        "12": "alarm.input_v_detection_mod#4",
        "13": "alarm.batt_v_detect_mod#4",
        "14": "alarm.output_v_detection_fail_mod#4",
        "15": "alarm.byp_v_detection_fail_mod#4"
      },
      "3084": {
        "0": "alarm.inv_bridge_fail_mod#4",
        "1": "alarm.temp_outlet_error_mod#4",
        "2": "alarm.input_c_unbalanced_mod#4",
        "3": "alarm.busDC_v_high_mod#4",
        "4": "alarm.rect_ss_fault_mod#4",
        "5": "alarm.relay_conn_err_mod#4",
        "6": "alarm.relay_shortc_mod#4",
        "7": "alarm.PWM_sync_mod#4",
        "8": "alarm.intel_sleep_mod#4",
        "9": "alarm.input_c_high_timeout_mod#4",
        "10": "alarm.no_outlet_sens_mod#4",
        "11": "alarm.no_inlet_sens_mod#4",
        "12": "alarm.capacitator_expired_mod#4",
        "13": "alarm.fan_expired_mod#4",
        "14": "alarm.mod_connector_mod#4",
        "15": "alarm.firmware_error_mod#4"
      },
      "3087": {
        "0": "alarm.rect_fault_mod#5",
        "1": "alarm.inv_failure_mod#5",
        "2": "alarm.temp_rect_high_mod#5",
        "3": "alarm.fan_fault_mod#5",
        "4": "alarm.ups_overload_mod#5",
        "5": "alarm.overload_timeuot_mod#5",
        "6": "alarm.temp_inv_high_mod#5",
        "7": "alarm.inv_cb_mod#5",
        "8": "alarm.manual_shutdown_mod#5",
        "9": "alarm.batt_chgr_fail_mod#5",
        "10": "alarm.power_dist_mod#5",
        "11": "alarm.sync_pulse_mod#5",
        "12": "alarm.input_v_detection_mod#5",
        "13": "alarm.batt_v_detect_mod#5",
        "14": "alarm.output_v_detection_fail_mod#5",
        "15": "alarm.byp_v_detection_fail_mod#5"
      },
      "3088": {
        "0": "alarm.inv_bridge_fail_mod#5",
        "1": "alarm.temp_outlet_error_mod#5",
        "2": "alarm.input_c_unbalanced_mod#5",
        "3": "alarm.busDC_v_high_mod#5",
        "4": "alarm.rect_ss_fault_mod#5",
        "5": "alarm.relay_conn_err_mod#5",
        "6": "alarm.relay_shortc_mod#5",
        "7": "alarm.PWM_sync_mod#5",
        "8": "alarm.intel_sleep_mod#5",
        "9": "alarm.input_c_high_timeout_mod#5",
        "10": "alarm.no_outlet_sens_mod#5",
        "11": "alarm.no_inlet_sens_mod#5",
        "12": "alarm.capacitator_expired_mod#5",
        "13": "alarm.fan_expired_mod#5",
        "14": "alarm.mod_connector_mod#5",
        "15": "alarm.firmware_error_mod#5"
      },
      "3091": {
        "0": "alarm.rect_fault_mod#6",
        "1": "alarm.inv_failure_mod#6",
        "2": "alarm.temp_rect_high_mod#6",
        "3": "alarm.fan_fault_mod#6",
        "4": "alarm.ups_overload_mod#6",
        "5": "alarm.overload_timeuot_mod#6",
        "6": "alarm.temp_inv_high_mod#6",
        "7": "alarm.inv_cb_mod#6",
        "8": "alarm.manual_shutdown_mod#6",
        "9": "alarm.batt_chgr_fail_mod#6",
        "10": "alarm.power_dist_mod#6",
        "11": "alarm.sync_pulse_mod#6",
        "12": "alarm.input_v_detection_mod#6",
        "13": "alarm.batt_v_detect_mod#6",
        "14": "alarm.output_v_detection_fail_mod#6",
        "15": "alarm.byp_v_detection_fail_mod#6"
      },
      "3092": {
        "0": "alarm.inv_bridge_fail_mod#6",
        "1": "alarm.temp_outlet_error_mod#6",
        "2": "alarm.input_c_unbalanced_mod#6",
        "3": "alarm.busDC_v_high_mod#6",
        "4": "alarm.rect_ss_fault_mod#6",
        "5": "alarm.relay_conn_err_mod#6",
        "6": "alarm.relay_shortc_mod#6",
        "7": "alarm.PWM_sync_mod#6",
        "8": "alarm.intel_sleep_mod#6",
        "9": "alarm.input_c_high_timeout_mod#6",
        "10": "alarm.no_outlet_sens_mod#6",
        "11": "alarm.no_inlet_sens_mod#6",
        "12": "alarm.capacitator_expired_mod#6",
        "13": "alarm.fan_expired_mod#6",
        "14": "alarm.mod_connector_mod#6",
        "15": "alarm.firmware_error_mod#6"
      },
      "3095": {
        "0": "alarm.rect_fault_mod#7",
        "1": "alarm.inv_failure_mod#7",
        "2": "alarm.temp_rect_high_mod#7",
        "3": "alarm.fan_fault_mod#7",
        "4": "alarm.ups_overload_mod#7",
        "5": "alarm.overload_timeuot_mod#7",
        "6": "alarm.temp_inv_high_mod#7",
        "7": "alarm.inv_cb_mod#7",
        "8": "alarm.manual_shutdown_mod#7",
        "9": "alarm.batt_chgr_fail_mod#7",
        "10": "alarm.power_dist_mod#7",
        "11": "alarm.sync_pulse_mod#7",
        "12": "alarm.input_v_detection_mod#7",
        "13": "alarm.batt_v_detect_mod#7",
        "14": "alarm.output_v_detection_fail_mod#7",
        "15": "alarm.byp_v_detection_fail_mod#7"
      },
      "3096": {
        "0": "alarm.inv_bridge_fail_mod#7",
        "1": "alarm.temp_outlet_error_mod#7",
        "2": "alarm.input_c_unbalanced_mod#7",
        "3": "alarm.busDC_v_high_mod#7",
        "4": "alarm.rect_ss_fault_mod#7",
        "5": "alarm.relay_conn_err_mod#7",
        "6": "alarm.relay_shortc_mod#7",
        "7": "alarm.PWM_sync_mod#7",
        "8": "alarm.intel_sleep_mod#7",
        "9": "alarm.input_c_high_timeout_mod#7",
        "10": "alarm.no_outlet_sens_mod#7",
        "11": "alarm.no_inlet_sens_mod#7",
        "12": "alarm.capacitator_expired_mod#7",
        "13": "alarm.fan_expired_mod#7",
        "14": "alarm.mod_connector_mod#7",
        "15": "alarm.firmware_error_mod#7"
      },
      "3099": {
        "0": "alarm.rect_fault_mod#8",
        "1": "alarm.inv_failure_mod#8",
        "2": "alarm.temp_rect_high_mod#8",
        "3": "alarm.fan_fault_mod#8",
        "4": "alarm.ups_overload_mod#8",
        "5": "alarm.overload_timeuot_mod#8",
        "6": "alarm.temp_inv_high_mod#8",
        "7": "alarm.inv_cb_mod#8",
        "8": "alarm.manual_shutdown_mod#8",
        "9": "alarm.batt_chgr_fail_mod#8",
        "10": "alarm.power_dist_mod#8",
        "11": "alarm.sync_pulse_mod#8",
        "12": "alarm.input_v_detection_mod#8",
        "13": "alarm.batt_v_detect_mod#8",
        "14": "alarm.output_v_detection_fail_mod#8",
        "15": "alarm.byp_v_detection_fail_mod#8"
      },
      "3100": {
        "0": "alarm.inv_bridge_fail_mod#8",
        "1": "alarm.temp_outlet_error_mod#8",
        "2": "alarm.input_c_unbalanced_mod#8",
        "3": "alarm.busDC_v_high_mod#8",
        "4": "alarm.rect_ss_fault_mod#8",
        "5": "alarm.relay_conn_err_mod#8",
        "6": "alarm.relay_shortc_mod#8",
        "7": "alarm.PWM_sync_mod#8",
        "8": "alarm.intel_sleep_mod#8",
        "9": "alarm.input_c_high_timeout_mod#8",
        "10": "alarm.no_outlet_sens_mod#8",
        "11": "alarm.no_inlet_sens_mod#8",
        "12": "alarm.capacitator_expired_mod#8",
        "13": "alarm.fan_expired_mod#8",
        "14": "alarm.mod_connector_mod#8",
        "15": "alarm.firmware_error_mod#8"
      },
      "3103": {
        "0": "alarm.rect_fault_mod#9",
        "1": "alarm.inv_failure_mod#9",
        "2": "alarm.temp_rect_high_mod#9",
        "3": "alarm.fan_fault_mod#9",
        "4": "alarm.ups_overload_mod#9",
        "5": "alarm.overload_timeuot_mod#9",
        "6": "alarm.temp_inv_high_mod#9",
        "7": "alarm.inv_cb_mod#9",
        "8": "alarm.manual_shutdown_mod#9",
        "9": "alarm.batt_chgr_fail_mod#9",
        "10": "alarm.power_dist_mod#9",
        "11": "alarm.sync_pulse_mod#9",
        "12": "alarm.input_v_detection_mod#9",
        "13": "alarm.batt_v_detect_mod#9",
        "14": "alarm.output_v_detection_fail_mod#9",
        "15": "alarm.byp_v_detection_fail_mod#9"
      },
      "3104": {
        "0": "alarm.inv_bridge_fail_mod#9",
        "1": "alarm.temp_outlet_error_mod#9",
        "2": "alarm.input_c_unbalanced_mod#9",
        "3": "alarm.busDC_v_high_mod#9",
        "4": "alarm.rect_ss_fault_mod#9",
        "5": "alarm.relay_conn_err_mod#9",
        "6": "alarm.relay_shortc_mod#9",
        "7": "alarm.PWM_sync_mod#9",
        "8": "alarm.intel_sleep_mod#9",
        "9": "alarm.input_c_high_timeout_mod#9",
        "10": "alarm.no_outlet_sens_mod#9",
        "11": "alarm.no_inlet_sens_mod#9",
        "12": "alarm.capacitator_expired_mod#9",
        "13": "alarm.fan_expired_mod#9",
        "14": "alarm.mod_connector_mod#9",
        "15": "alarm.firmware_error_mod#9"
      },
      "3107": {
        "0": "alarm.rect_fault_mod#10",
        "1": "alarm.inv_failure_mod#10",
        "2": "alarm.temp_rect_high_mod#10",
        "3": "alarm.fan_fault_mod#10",
        "4": "alarm.ups_overload_mod#10",
        "5": "alarm.overload_timeuot_mod#10",
        "6": "alarm.temp_inv_high_mod#10",
        "7": "alarm.inv_cb_mod#10",
        "8": "alarm.manual_shutdown_mod#10",
        "9": "alarm.batt_chgr_fail_mod#10",
        "10": "alarm.power_dist_mod#10",
        "11": "alarm.sync_pulse_mod#10",
        "12": "alarm.input_v_detection_mod#10",
        "13": "alarm.batt_v_detect_mod#10",
        "14": "alarm.output_v_detection_fail_mod#10",
        "15": "alarm.byp_v_detection_fail_mod#10"
      },
      "3108": {
        "0": "alarm.inv_bridge_fail_mod#10",
        "1": "alarm.temp_outlet_error_mod#10",
        "2": "alarm.input_c_unbalanced_mod#10",
        "3": "alarm.busDC_v_high_mod#10",
        "4": "alarm.rect_ss_fault_mod#10",
        "5": "alarm.relay_conn_err_mod#10",
        "6": "alarm.relay_shortc_mod#10",
        "7": "alarm.PWM_sync_mod#10",
        "8": "alarm.intel_sleep_mod#10",
        "9": "alarm.input_c_high_timeout_mod#10",
        "10": "alarm.no_outlet_sens_mod#10",
        "11": "alarm.no_inlet_sens_mod#10",
        "12": "alarm.capacitator_expired_mod#10",
        "13": "alarm.fan_expired_mod#10",
        "14": "alarm.mod_connector_mod#10",
        "15": "alarm.firmware_error_mod#10"
      }
    },
    "CUBE3": {
      "0": {
        "0": "alarm.rect_overload",
        "1": "alarm.inve_overload",
        "2": "alarm.low_battery",
        "3": "alarm.inv_v_out_margins",
        "4": "alarm.dc_output",
        "5": "alarm.byp_mant",
        "6": "alarm.drain_Batt",
        "7": "alarm.overtemp_reduce_load",
        "8": "alarm.switch_batt_open",
        "9": "alarm.byp_fail",
        "10": "alarm.byp_on",
        "11": "alarm.block_byp_mant",
        "12": "alarm.bus1_fail_comms",
        "13": "alarm.bus2_fail_comms",
        "14": "alarm.end_batt_life",
        "15": "alarm.overtemp_batt"
      },
      "1": {
        "0": "alarm.test_batt_fail",
        "1": "alarm.desc_batt_OnOff",
        "2": "alarm.phase_rot_main",
        "3": "alarm.phase_rot_byp",
        "4": "alarm.input_v_incorrect",
        "5": "alarm.desat_rect",
        "6": "alarm.DSP_internal_err",
        "7": "alarm.phase_rot_input",
        "8": "alarm.desat_inv",
        "9": "alarm.inv_overload",
        "10": "alarm.inv_stop",
        "11": "alarm.byp_mant",
        "12": "alarm.paral_desc",
        "13": "alarm.output_overload",
        "14": "alarm.overtemp",
        "15": "alarm.rect_overload"
      },
      "2": {
        "0": "alarm.dsp_internal_err",
        "1": "alarm.output_short",
        "2": "alarm.phase_rot_byp",
        "3": "alarm.dsp_internal_err",
        "4": "alarm.low_battery",
        "5": "alarm.epo",
        "6": "alarm.output_short",
        "7": "alarm.dsp_internal_err",
        "8": "alarm.busDc_v_err",
        "9": "alarm.ups_blk",
        "10": "alarm.desat_rect",
        "11": "alarm.charge_ramp_v_err",
        "12": "alarm.DSP_rect_exec",
        "13": "alarm.DSP_internal_err",
        "14": "alarm.test_contactor_fail",
        "15": "alarm.discharge_ramp_v_err"
      },
      "3": {
        "0": "alarm.dc_output",
        "1": "alarm.inv_blk",
        "2": "alarm.desat_inv",
        "3": "alarm.DSP_rect_exec",
        "4": "alarm.inv_internal_err",
        "5": "alarm.ups_blk",
        "6": "alarm.internal_init_err",
        "7": "alarm.internal_exec_err",
        "8": "alarm.ups_blk",
        "9": "alarm.busDC_v_disch_err",
        "10": "alarm.busDC_v_disch_err",
        "11": "alarm.overtemp_ups",
        "12": "alarm.rect_overload",
        "13": "alarm.desat_inv",
        "14": "alarm.dsp_internal_err",
        "15": "alarm.pfc_inv_blk"
      },
      "4": {
        "0": "alarm.inve_overload",
        "1": "alarm.inv_failure",
        "2": "alarm.discharge_ramp_v_err",
        "3": "alarm.busDc_v_err",
        "4": "alarm.paral_rect_stop",
        "5": "alarm.paral_inv_stop",
        "6": "alarm.pfc_inv_ups_shutdown",
        "7": "alarm.paral_stop",
        "8": "alarm.master_paral_sys_comms_err",
        "9": "alarm.paral_redundancy_lost",
        "10": "alarm.test_contactor_fail",
        "11": "alarm.comms_paral_ups_blk",
        "12": "alarm.eeprom_fail",
        "13": "alarm.overtemp",
        "14": "alarm.output_freq_detection",
        "15": "alarm.test_batt_ok"
      }
    },
    "DCL": {
      "399": {
        "0": "alarm.batt_leak_current",
        "1": "alarm.electolite_low",
        "2": "alarm.output_v_high",
        "3": "alarm.output_v_low",
        "4": "alarm.volt_batt_high",
        "5": "alarm.volt_batt_low",
        "6": "alarm.end_batt_autonomy",
        "7": "alarm.busDC_c_high",
        "8": "alarm.batt_charge_current_high",
        "9": "alarm.batt_discharge_current_high",
        "10": "alarm.high_in_v_RS",
        "11": "alarm.high_in_v_ST",
        "12": "alarm.high_in_v_TR",
        "13": "alarm.low_in_v_RS",
        "14": "alarm.low_in_v_ST",
        "15": "alarm.low_in_v_TR"
      },
      "400": {
        "0": "alarm.high_in_c_R",
        "1": "alarm.high_in_c_S",
        "2": "alarm.high_in_c_T",
        "3": "alarm.charger_failure",
        "4": "alarm.batt_fuse",
        "5": "alarm.rect_fuse",
        "6": "alarm.in_sw_open",
        "7": "alarm.ac_fail",
        "8": "alarm.out_sw_open",
        "9": "alarm.overtemp_heatsink",
        "10": "alarm.overtemp_batt",
        "11": "alarm.filesys_error",
        "12": "alarm.phase_rot_input",
        "13": "alarm.rect_overload",
        "14": "alarm.high_in_v",
        "15": "alarm.low_in_v"
      },
      "401": {
        "0": "alarm.max_use_exceded",
        "1": "alarm.reducer_high_v",
        "2": "alarm.batt_leak_neg_current",
        "3": "alarm.batt_chg_interrupt",
        "4": "alarm.charge_end",
        "5": "alarm.manual_operation",
        "6": "alarm.min_load"
      }
    },
    "DCS": {
      "19999": {
        "0": "alarm.drain_Batt",
        "1": "alarm.low_battery",
        "2": "alarm.end_batt_autonomy",
        "3": "alarm.sys_overload",
        "4": "alarm.overtemp_system",
        "5": "alarm.overload_safe",
        "6": "alarm.overload_use",
        "8": "alarm.volt_batt_high",
        "10": "alarm.input_v_low_mains",
        "11": "alarm.digital_input_1",
        "12": "alarm.urg_mod_alarm",
        "13": "alarm.noUrg_mod_alarm",
        "15": "alarm.batt_charge_current_high"
      },
      "20000": {
        "0": "alarm.input_v_high",
        "3": "alarm.output_v_low",
        "4": "alarm.output_v_high",
        "5": "alarm.digital_input_2",
        "6": "alarm.non_prior_discon",
        "7": "alarm.isol_pos_fault",
        "8": "alarm.mod_dcs_lost",
        "10": "alarm.electolite_low",
        "11": "alarm.digital_input_3",
        "12": "alarm.digital_input_4",
        "13": "alarm.isol_neg_fault",
        "14": "alarm.incorrect_password"
      },
      "20001": {
        "0": "alarm.high_out_v_n1",
        "1": "alarm.high_out_v_n2",
        "2": "alarm.low_out_v_n1",
        "3": "alarm.low_out_v_n2"
      },
      "22999": {
        "0": "alarm.mod_discharge#1",
        "1": "alarm.mod_discharge#2",
        "2": "alarm.mod_discharge#3",
        "3": "alarm.mod_discharge#4",
        "4": "alarm.mod_discharge#5",
        "5": "alarm.mod_discharge#6",
        "6": "alarm.mod_discharge#7",
        "7": "alarm.mod_discharge#8",
        "8": "alarm.mod_discharge#9",
        "9": "alarm.mod_discharge#10",
        "10": "alarm.mod_discharge#11",
        "11": "alarm.mod_discharge#12",
        "12": "alarm.mod_discharge#13",
        "13": "alarm.mod_discharge#14",
        "14": "alarm.mod_discharge#15",
        "15": "alarm.mod_discharge#16"
      },
      "23000": {
        "0": "alarm.mod_discharge#17",
        "1": "alarm.mod_discharge#18",
        "2": "alarm.mod_discharge#19",
        "3": "alarm.mod_discharge#20",
        "4": "alarm.mod_discharge#21",
        "5": "alarm.mod_discharge#22",
        "6": "alarm.mod_discharge#23",
        "7": "alarm.mod_discharge#24",
        "8": "alarm.mod_discharge#25",
        "9": "alarm.mod_discharge#26",
        "10": "alarm.mod_discharge#27",
        "11": "alarm.mod_discharge#28",
        "12": "alarm.mod_discharge#29",
        "13": "alarm.mod_discharge#30"
      },
      "23003": {
        "0": "alarm.mod_low_batt#1",
        "1": "alarm.mod_low_batt#2",
        "2": "alarm.mod_low_batt#3",
        "3": "alarm.mod_low_batt#4",
        "4": "alarm.mod_low_batt#5",
        "5": "alarm.mod_low_batt#6",
        "6": "alarm.mod_low_batt#7",
        "7": "alarm.mod_low_batt#8",
        "8": "alarm.mod_low_batt#9",
        "9": "alarm.mod_low_batt#10",
        "10": "alarm.mod_low_batt#11",
        "11": "alarm.mod_low_batt#12",
        "12": "alarm.mod_low_batt#13",
        "13": "alarm.mod_low_batt#14",
        "14": "alarm.mod_low_batt#15",
        "15": "alarm.mod_low_batt#16"
      },
      "23004": {
        "0": "alarm.mod_low_batt#17",
        "1": "alarm.mod_low_batt#18",
        "2": "alarm.mod_low_batt#19",
        "3": "alarm.mod_low_batt#20",
        "4": "alarm.mod_low_batt#21",
        "5": "alarm.mod_low_batt#22",
        "6": "alarm.mod_low_batt#23",
        "7": "alarm.mod_low_batt#24",
        "8": "alarm.mod_low_batt#25",
        "9": "alarm.mod_low_batt#26",
        "10": "alarm.mod_low_batt#27",
        "11": "alarm.mod_low_batt#28",
        "12": "alarm.mod_low_batt#29",
        "13": "alarm.mod_low_batt#30"
      },
      "23007": {
        "0": "alarm.mod_autonomy#1",
        "1": "alarm.mod_autonomy#2",
        "2": "alarm.mod_autonomy#3",
        "3": "alarm.mod_autonomy#4",
        "4": "alarm.mod_autonomy#5",
        "5": "alarm.mod_autonomy#6",
        "6": "alarm.mod_autonomy#7",
        "7": "alarm.mod_autonomy#8",
        "8": "alarm.mod_autonomy#9",
        "9": "alarm.mod_autonomy#10",
        "10": "alarm.mod_autonomy#11",
        "11": "alarm.mod_autonomy#12",
        "12": "alarm.mod_autonomy#13",
        "13": "alarm.mod_autonomy#14",
        "14": "alarm.mod_autonomy#15",
        "15": "alarm.mod_autonomy#16"
      },
      "23008": {
        "0": "alarm.mod_autonomy#17",
        "1": "alarm.mod_autonomy#18",
        "2": "alarm.mod_autonomy#19",
        "3": "alarm.mod_autonomy#20",
        "4": "alarm.mod_autonomy#21",
        "5": "alarm.mod_autonomy#22",
        "6": "alarm.mod_autonomy#23",
        "7": "alarm.mod_autonomy#24",
        "8": "alarm.mod_autonomy#25",
        "9": "alarm.mod_autonomy#26",
        "10": "alarm.mod_autonomy#27",
        "11": "alarm.mod_autonomy#28",
        "12": "alarm.mod_autonomy#29",
        "13": "alarm.mod_autonomy#30"
      },
      "23011": {
        "0": "alarm.mod_overload#1",
        "1": "alarm.mod_overload#2",
        "2": "alarm.mod_overload#3",
        "3": "alarm.mod_overload#4",
        "4": "alarm.mod_overload#5",
        "5": "alarm.mod_overload#6",
        "6": "alarm.mod_overload#7",
        "7": "alarm.mod_overload#8",
        "8": "alarm.mod_overload#9",
        "9": "alarm.mod_overload#10",
        "10": "alarm.mod_overload#11",
        "11": "alarm.mod_overload#12",
        "12": "alarm.mod_overload#13",
        "13": "alarm.mod_overload#14",
        "14": "alarm.mod_overload#15",
        "15": "alarm.mod_overload#16"
      },
      "23012": {
        "0": "alarm.mod_overload#17",
        "1": "alarm.mod_overload#18",
        "2": "alarm.mod_overload#19",
        "3": "alarm.mod_overload#20",
        "4": "alarm.mod_overload#21",
        "5": "alarm.mod_overload#22",
        "6": "alarm.mod_overload#23",
        "7": "alarm.mod_overload#24",
        "8": "alarm.mod_overload#25",
        "9": "alarm.mod_overload#26",
        "10": "alarm.mod_overload#27",
        "11": "alarm.mod_overload#28",
        "12": "alarm.mod_overload#29",
        "13": "alarm.mod_overload#30"
      },
      "23015": {
        "0": "alarm.mod_out_mcb#1",
        "1": "alarm.mod_out_mcb#2",
        "2": "alarm.mod_out_mcb#3",
        "3": "alarm.mod_out_mcb#4",
        "4": "alarm.mod_out_mcb#5",
        "5": "alarm.mod_out_mcb#6",
        "6": "alarm.mod_out_mcb#7",
        "7": "alarm.mod_out_mcb#8",
        "8": "alarm.mod_out_mcb#9",
        "9": "alarm.mod_out_mcb#10",
        "10": "alarm.mod_out_mcb#11",
        "11": "alarm.mod_out_mcb#12",
        "12": "alarm.mod_out_mcb#13",
        "13": "alarm.mod_out_mcb#14",
        "14": "alarm.mod_out_mcb#15",
        "15": "alarm.mod_out_mcb#16"
      },
      "23016": {
        "0": "alarm.mod_out_mcb#17",
        "1": "alarm.mod_out_mcb#18",
        "2": "alarm.mod_out_mcb#19",
        "3": "alarm.mod_out_mcb#20",
        "4": "alarm.mod_out_mcb#21",
        "5": "alarm.mod_out_mcb#22",
        "6": "alarm.mod_out_mcb#23",
        "7": "alarm.mod_out_mcb#24",
        "8": "alarm.mod_out_mcb#25",
        "9": "alarm.mod_out_mcb#26",
        "10": "alarm.mod_out_mcb#27",
        "11": "alarm.mod_out_mcb#28",
        "12": "alarm.mod_out_mcb#29",
        "13": "alarm.mod_out_mcb#30"
      },
      "23019": {
        "0": "alarm.mod_heatsink_temp#1",
        "1": "alarm.mod_heatsink_temp#2",
        "2": "alarm.mod_heatsink_temp#3",
        "3": "alarm.mod_heatsink_temp#4",
        "4": "alarm.mod_heatsink_temp#5",
        "5": "alarm.mod_heatsink_temp#6",
        "6": "alarm.mod_heatsink_temp#7",
        "7": "alarm.mod_heatsink_temp#8",
        "8": "alarm.mod_heatsink_temp#9",
        "9": "alarm.mod_heatsink_temp#10",
        "10": "alarm.mod_heatsink_temp#11",
        "11": "alarm.mod_heatsink_temp#12",
        "12": "alarm.mod_heatsink_temp#13",
        "13": "alarm.mod_heatsink_temp#14",
        "14": "alarm.mod_heatsink_temp#15",
        "15": "alarm.mod_heatsink_temp#16"
      },
      "23020": {
        "0": "alarm.mod_heatsink_temp#17",
        "1": "alarm.mod_heatsink_temp#18",
        "2": "alarm.mod_heatsink_temp#19",
        "3": "alarm.mod_heatsink_temp#20",
        "4": "alarm.mod_heatsink_temp#21",
        "5": "alarm.mod_heatsink_temp#22",
        "6": "alarm.mod_heatsink_temp#23",
        "7": "alarm.mod_heatsink_temp#24",
        "8": "alarm.mod_heatsink_temp#25",
        "9": "alarm.mod_heatsink_temp#26",
        "10": "alarm.mod_heatsink_temp#27",
        "11": "alarm.mod_heatsink_temp#28",
        "12": "alarm.mod_heatsink_temp#29",
        "13": "alarm.mod_heatsink_temp#30"
      },
      "23023": {
        "0": "alarm.mod_rect_fail#1",
        "1": "alarm.mod_rect_fail#2",
        "2": "alarm.mod_rect_fail#3",
        "3": "alarm.mod_rect_fail#4",
        "4": "alarm.mod_rect_fail#5",
        "5": "alarm.mod_rect_fail#6",
        "6": "alarm.mod_rect_fail#7",
        "7": "alarm.mod_rect_fail#8",
        "8": "alarm.mod_rect_fail#9",
        "9": "alarm.mod_rect_fail#10",
        "10": "alarm.mod_rect_fail#11",
        "11": "alarm.mod_rect_fail#12",
        "12": "alarm.mod_rect_fail#13",
        "13": "alarm.mod_rect_fail#14",
        "14": "alarm.mod_rect_fail#15",
        "15": "alarm.mod_rect_fail#16"
      },
      "23024": {
        "0": "alarm.mod_rect_fail#17",
        "1": "alarm.mod_rect_fail#18",
        "2": "alarm.mod_rect_fail#19",
        "3": "alarm.mod_rect_fail#20",
        "4": "alarm.mod_rect_fail#21",
        "5": "alarm.mod_rect_fail#22",
        "6": "alarm.mod_rect_fail#23",
        "7": "alarm.mod_rect_fail#24",
        "8": "alarm.mod_rect_fail#25",
        "9": "alarm.mod_rect_fail#26",
        "10": "alarm.mod_rect_fail#27",
        "11": "alarm.mod_rect_fail#28",
        "12": "alarm.mod_rect_fail#29",
        "13": "alarm.mod_rect_fail#30"
      },
      "23027": {
        "0": "alarm.mod_remote_stop#1",
        "1": "alarm.mod_remote_stop#2",
        "2": "alarm.mod_remote_stop#3",
        "3": "alarm.mod_remote_stop#4",
        "4": "alarm.mod_remote_stop#5",
        "5": "alarm.mod_remote_stop#6",
        "6": "alarm.mod_remote_stop#7",
        "7": "alarm.mod_remote_stop#8",
        "8": "alarm.mod_remote_stop#9",
        "9": "alarm.mod_remote_stop#10",
        "10": "alarm.mod_remote_stop#11",
        "11": "alarm.mod_remote_stop#12",
        "12": "alarm.mod_remote_stop#13",
        "13": "alarm.mod_remote_stop#14",
        "14": "alarm.mod_remote_stop#15",
        "15": "alarm.mod_remote_stop#16"
      },
      "23028": {
        "0": "alarm.mod_remote_stop#17",
        "1": "alarm.mod_remote_stop#18",
        "2": "alarm.mod_remote_stop#19",
        "3": "alarm.mod_remote_stop#20",
        "4": "alarm.mod_remote_stop#21",
        "5": "alarm.mod_remote_stop#22",
        "6": "alarm.mod_remote_stop#23",
        "7": "alarm.mod_remote_stop#24",
        "8": "alarm.mod_remote_stop#25",
        "9": "alarm.mod_remote_stop#26",
        "10": "alarm.mod_remote_stop#27",
        "11": "alarm.mod_remote_stop#28",
        "12": "alarm.mod_remote_stop#29",
        "13": "alarm.mod_remote_stop#30"
      },
      "23031": {
        "0": "alarm.mod_out_overtesion#1",
        "1": "alarm.mod_out_overtesion#2",
        "2": "alarm.mod_out_overtesion#3",
        "3": "alarm.mod_out_overtesion#4",
        "4": "alarm.mod_out_overtesion#5",
        "5": "alarm.mod_out_overtesion#6",
        "6": "alarm.mod_out_overtesion#7",
        "7": "alarm.mod_out_overtesion#8",
        "8": "alarm.mod_out_overtesion#8",
        "9": "alarm.mod_out_overtesion#9",
        "10": "alarm.mod_out_overtesion#11",
        "11": "alarm.mod_out_overtesion#12",
        "12": "alarm.mod_out_overtesion#13",
        "13": "alarm.mod_out_overtesion#14",
        "14": "alarm.mod_out_overtesion#15",
        "15": "alarm.mod_out_overtesion#16"
      },
      "23032": {
        "0": "alarm.mod_out_overtesion#17",
        "1": "alarm.mod_out_overtesion#18",
        "2": "alarm.mod_out_overtesion#19",
        "3": "alarm.mod_out_overtesion#20",
        "4": "alarm.mod_out_overtesion#21",
        "5": "alarm.mod_out_overtesion#22",
        "6": "alarm.mod_out_overtesion#23",
        "7": "alarm.mod_out_overtesion#24",
        "8": "alarm.mod_out_overtesion#25",
        "9": "alarm.mod_out_overtesion#26",
        "10": "alarm.mod_out_overtesion#27",
        "11": "alarm.mod_out_overtesion#28",
        "12": "alarm.mod_out_overtesion#29",
        "13": "alarm.mod_out_overtesion#30"
      },
      "23035": {
        "0": "alarm.mod_pb_fail#1",
        "1": "alarm.mod_pb_fail#2",
        "2": "alarm.mod_pb_fail#3",
        "3": "alarm.mod_pb_fail#4",
        "4": "alarm.mod_pb_fail#5",
        "5": "alarm.mod_pb_fail#6",
        "6": "alarm.mod_pb_fail#7",
        "7": "alarm.mod_pb_fail#8",
        "8": "alarm.mod_pb_fail#8",
        "9": "alarm.mod_pb_fail#9",
        "10": "alarm.mod_pb_fail#11",
        "11": "alarm.mod_pb_fail#12",
        "12": "alarm.mod_pb_fail#13",
        "13": "alarm.mod_pb_fail#14",
        "14": "alarm.mod_pb_fail#15",
        "15": "alarm.mod_pb_fail#16"
      },
      "23036": {
        "0": "alarm.mod_pb_fail#17",
        "1": "alarm.mod_pb_fail#18",
        "2": "alarm.mod_pb_fail#19",
        "3": "alarm.mod_pb_fail#20",
        "4": "alarm.mod_pb_fail#21",
        "5": "alarm.mod_pb_fail#22",
        "6": "alarm.mod_pb_fail#23",
        "7": "alarm.mod_pb_fail#24",
        "8": "alarm.mod_pb_fail#25",
        "9": "alarm.mod_pb_fail#26",
        "10": "alarm.mod_pb_fail#27",
        "11": "alarm.mod_pb_fail#28",
        "12": "alarm.mod_pb_fail#29",
        "13": "alarm.mod_pb_fail#30"
      },
      "23039": {
        "0": "alarm.mod_mains_fail#1",
        "1": "alarm.mod_mains_fail#2",
        "2": "alarm.mod_mains_fail#3",
        "3": "alarm.mod_mains_fail#4",
        "4": "alarm.mod_mains_fail#5",
        "5": "alarm.mod_mains_fail#6",
        "6": "alarm.mod_mains_fail#7",
        "7": "alarm.mod_mains_fail#8",
        "8": "alarm.mod_mains_fail#9",
        "9": "alarm.mod_mains_fail#10",
        "10": "alarm.mod_mains_fail#11",
        "11": "alarm.mod_mains_fail#12",
        "12": "alarm.mod_mains_fail#13",
        "13": "alarm.mod_mains_fail#14",
        "14": "alarm.mod_mains_fail#15",
        "15": "alarm.mod_mains_fail#16"
      },
      "23040": {
        "0": "alarm.mod_mains_fail#17",
        "1": "alarm.mod_mains_fail#18",
        "2": "alarm.mod_mains_fail#19",
        "3": "alarm.mod_mains_fail#20",
        "4": "alarm.mod_mains_fail#21",
        "5": "alarm.mod_mains_fail#22",
        "6": "alarm.mod_mains_fail#23",
        "7": "alarm.mod_mains_fail#24",
        "8": "alarm.mod_mains_fail#25",
        "9": "alarm.mod_mains_fail#26",
        "10": "alarm.mod_mains_fail#27",
        "11": "alarm.mod_mains_fail#28",
        "12": "alarm.mod_mains_fail#29",
        "13": "alarm.mod_mains_fail#30"
      },
      "23043": {
        "0": "alarm.mod_fan_fail#1",
        "1": "alarm.mod_fan_fail#2",
        "2": "alarm.mod_fan_fail#3",
        "3": "alarm.mod_fan_fail#4",
        "4": "alarm.mod_fan_fail#5",
        "5": "alarm.mod_fan_fail#6",
        "6": "alarm.mod_fan_fail#7",
        "7": "alarm.mod_fan_fail#8",
        "8": "alarm.mod_fan_fail#9",
        "9": "alarm.mod_fan_fail#10",
        "10": "alarm.mod_fan_fail#11",
        "11": "alarm.mod_fan_fail#12",
        "12": "alarm.mod_fan_fail#13",
        "13": "alarm.mod_fan_fail#14",
        "14": "alarm.mod_fan_fail#15",
        "15": "alarm.mod_fan_fail#16"
      },
      "23044": {
        "0": "alarm.mod_fan_fail#17",
        "1": "alarm.mod_fan_fail#18",
        "2": "alarm.mod_fan_fail#19",
        "3": "alarm.mod_fan_fail#20",
        "4": "alarm.mod_fan_fail#21",
        "5": "alarm.mod_fan_fail#22",
        "6": "alarm.mod_fan_fail#23",
        "7": "alarm.mod_fan_fail#24",
        "8": "alarm.mod_fan_fail#25",
        "9": "alarm.mod_fan_fail#26",
        "10": "alarm.mod_fan_fail#27",
        "11": "alarm.mod_fan_fail#28",
        "12": "alarm.mod_fan_fail#29",
        "13": "alarm.mod_fan_fail#30"
      }
    },
    "EMI3": {
      "399": {
        "0": "alarm.high_in_v_R",
        "1": "alarm.high_in_v_S",
        "2": "alarm.high_in_v_T",
        "3": "alarm.high_in_v_mono",
        "4": "alarm.low_in_v_R",
        "5": "alarm.low_in_v_S",
        "6": "alarm.low_in_v_T",
        "7": "alarm.low_in_v_mono",
        "8": "alarm.r_overload",
        "9": "alarm.s_overload",
        "10": "alarm.t_overload",
        "11": "alarm.mono_phase_overload",
        "12": "alarm.high_out_v_R",
        "13": "alarm.high_out_v_S",
        "14": "alarm.high_out_v_T",
        "15": "alarm.high_out_v_mono"
      },
      "400": {
        "0": "alarm.low_out_v_R",
        "1": "alarm.low_out_v_S",
        "2": "alarm.low_out_v_T",
        "3": "alarm.low_out_v_mono",
        "4": "alarm.motor_R",
        "5": "alarm.motor_S",
        "6": "alarm.motor_T",
        "7": "alarm.motor_mono",
        "8": "alarm.filesys_error",
        "9": "alarm.mant_fault",
        "10": "alarm.relay_overlaod",
        "11": "alarm.max_min_detect",
        "12": "alarm.digital_input",
        "13": "alarm.non_prior_discon"
      }
    },
    "XPERT": {
      "0": {
        "0": "alarm.mains_failure",
        "1": "alarm.input_seq_wrong",
        "2": "alarm.booster_stop",
        "3": "alarm.booster_fault",
        "4": "alarm.dc_v_fault",
        "5": "alarm.batt_on_test",
        "6": "alarm.BCB_open",
        "7": "alarm.drain_Batt",
        "8": "alarm.end_batt_autonomy",
        "9": "alarm.batt_fault",
        "10": "alarm.shortcircuit",
        "11": "alarm.shortcircuit_timeout",
        "12": "alarm.inv_out_tolerance",
        "13": "alarm.byp_sequence",
        "14": "alarm.byp_fail",
        "15": "alarm.byp_feed_load"
      },
      "1": {
        "0": "alarm.retrans_block",
        "1": "alarm.MBYP_closed",
        "2": "alarm.OCB_open",
        "3": "alarm.ups_overload",
        "4": "alarm.thermal_img",
        "5": "alarm.byp_switch",
        "6": "alarm.EPO_closed",
        "7": "alarm.overtemp",
        "8": "alarm.inv_off",
        "9": "alarm.comms_error",
        "10": "alarm.eeprom_fail",
        "11": "alarm.critical_fault",
        "12": "alarm.mant_req",
        "13": "alarm.general_alarm",
        "14": "alarm.MBCB_closed",
        "15": "alarm.EPO_closed"
      },
      "2": {
        "0": "alarm.asym_load",
        "1": "alarm.serv_req",
        "2": "alarm.diesel_mode",
        "3": "alarm.dcFast_shutdown",
        "5": "alarm.inv_feed_err",
        "6": "alarm.inv_loop_err",
        "7": "alarm.ssi_fault",
        "8": "alarm.boost_loop_err",
        "12": "alarm.overtemp_ssw",
        "13": "alarm.redund_lost",
        "14": "alarm.send_params_error",
        "15": "alarm.reci_params_error"
      },
      "3": {
        "0": "alarm.test_mode",
        "2": "alarm.batt_temp_out_tol",
        "3": "alarm.inv_blk",
        "4": "alarm.firmware_error",
        "5": "alarm.can_error",
        "6": "alarm.paral_cable_discon",
        "7": "alarm.terna_unbal",
        "8": "alarm.input_c_unbalanced",
        "9": "alarm.inv_c_unbal",
        "10": "alarm.rel_backfeed_on",
        "14": "alarm.init_seq_block"
      }
    },
    "XTRA": {
      "0": {
        "0": "alarm.mains_failure",
        "1": "alarm.input_seq_wrong",
        "2": "alarm.booster_stop",
        "3": "alarm.booster_fault",
        "4": "alarm.dc_v_fault",
        "5": "alarm.batt_on_test",
        "6": "alarm.BCB_open",
        "7": "alarm.drain_Batt",
        "8": "alarm.end_batt_autonomy",
        "9": "alarm.batt_fault",
        "10": "alarm.shortcircuit",
        "11": "alarm.shortcircuit_timeout",
        "12": "alarm.inv_out_tolerance",
        "13": "alarm.byp_sequence",
        "14": "alarm.byp_fail",
        "15": "alarm.byp_feed_load"
      },
      "1": {
        "0": "alarm.retrans_block",
        "1": "alarm.MBYP_closed",
        "2": "alarm.OCB_open",
        "3": "alarm.ups_overload",
        "4": "alarm.thermal_img",
        "5": "alarm.byp_switch",
        "6": "alarm.EPO_closed",
        "7": "alarm.overtemp",
        "8": "alarm.inv_off",
        "9": "alarm.comms_error",
        "10": "alarm.eeprom_fail",
        "11": "alarm.critical_fault",
        "12": "alarm.mant_req",
        "13": "alarm.general_alarm",
        "14": "alarm.MBCB_closed",
        "15": "alarm.EPO_closed"
      },
      "2": {
        "0": "alarm.asym_load",
        "1": "alarm.serv_req",
        "2": "alarm.diesel_mode",
        "3": "alarm.dcFast_shutdown",
        "4": "alarm.high_temp_rect",
        "5": "alarm.inv_feed_err",
        "6": "alarm.inv_loop_err",
        "7": "alarm.ssi_fault",
        "8": "alarm.boost_loop_err",
        "9": "alarm.inp_fuse_blow", 
        "10": "alarm.curr_err_loop", 
        "11": "alarm.desat_igbt_inv",
        "12": "alarm.overtemp_ssw",
        "13": "alarm.redund_lost",
        "14": "alarm.send_params_error",
        "15": "alarm.reci_params_error"
      },
      "3": {
        "0": "alarm.test_mode",
        "1": "alarm.ssw_blocked",
        "2": "alarm.batt_temp_out_tol",
        "3": "alarm.dc_compens_err",
        "4": "alarm.firmware_error",
        "5": "alarm.can_error",
        "6": "alarm.paral_cable_discon",
        "14": "alarm.init_seq_block"
      }
    },
    "CUBE43080": {
      "399": {
        "0": "alarm.byp_on",
        "1": "alarm.drain_Batt",
        "2": "alarm.paral_sys_fault",
        "3": "alarm.batt_replace",
        "4": "alarm.test_batt_fail",
        "5": "alarm.dc_cap_replace",
        "6": "alarm.ac_cap_replace",
        "7": "alarm.fan_replace",
        "8": "alarm.clk_batt_replace",
        "9": "alarm.fan_fault",
        "10": "alarm.aux_pwr_supply",
        "11": "alarm.cold_start",
        "12": "alarm.diesel_mode",
        "13": "alarm.serv_req",
        "14": "alarm.dst_igbt_r",
        "15": "alarm.dst_igbt_s"
      },
      "400": {
        "0": "alarm.dst_igbt_t",
        "1": "alarm.rec_busDC_v_high",
        "2": "alarm.rect_overload",
        "3": "alarm.rect_v_margin",
        "4": "alarm.rect_r_none",
        "5": "alarm.rect_s_none",
        "6": "alarm.rect_t_none",
        "7": "alarm.rec_phase_rot_input",
        "8": "alarm.rec_in_sync",
        "9": "alarm.rec_low_battery",
        "10": "alarm.rec_batt_out_margins",
        "11": "alarm.batt_temp_out_tol",
        "12": "alarm.overtemp_r_high",
        "13": "alarm.overtemp_s_high",
        "14": "alarm.overtemp_t_high",
        "15": "alarm.chg_desat"
      },
      "401": {
        "0": "alarm.rt_deadline",
        "1": "alarm.chg_busDC_v_high",
        "2": "alarm.batt_chg_v_high",
        "3": "alarm.overtemp_charger",
        "4": "alarm.inve_overload",
        "5": "alarm.inv_v_high",
        "6": "alarm.inve_dc_output",
        "7": "alarm.byp_v_out_margin",
        "8": "alarm.int_comm_fail",
        "9": "alarm.phase_rot_byp",
        "10": "alarm.ssb_r_open",
        "11": "alarm.ssb_s_open",
        "12": "alarm.ssb_t_open",
        "13": "alarm.r_v_fault",
        "14": "alarm.s_v_fault",
        "15": "alarm.t_v_fault"
      },
      "402": {
        "0": "alarm.byp_mant",
        "1": "alarm.high_temp_ups_stop",
        "2": "alarm.rect_no_v_in",
        "3": "alarm.dst_igbt_r_ups_stop",
        "4": "alarm.dst_igbt_s_ups_stop",
        "5": "alarm.dst_igbt_t_ups_stop",
        "6": "alarm.busDC_v_high_ups_stop",
        "7": "alarm.end_batt_life",
        "8": "alarm.rec_phase_rot_input_ups_stop",
        "9": "alarm.rec_stop_thermal_sw",
        "10": "alarm.remote_stop",
        "11": "alarm.paral_comms_ups_stop",
        "12": "alarm.rect_overload_ups_stop",
        "13": "alarm.main_pwr_supply",
        "14": "alarm.dst_igbt_r_rect_stop",
        "15": "alarm.dst_igbt_s_rect_stop"
      },
      "403": {
        "0": "alarm.dst_igbt_t_rect_stop",
        "1": "alarm.rect_stop_ac_fail",
        "2": "alarm.rect_stop_bat_fail",
        "3": "alarm.rect_stop_soft_timeout",
        "4": "alarm.rect_stop_soft_in-rush",
        "5": "alarm.rect_stop_pll_fail",
        "6": "alarm.chg_stop_desat",
        "7": "alarm.chg_stop_timeout",
        "8": "alarm.chg_stop_no_battery",
        "9": "alarm.inv_stop_ovl",
        "10": "alarm.inv_stop_desat_igbt_r",
        "11": "alarm.inv_stop_desat_igbt_s",
        "12": "alarm.inv_stop_desat_igbt_t",
        "13": "alarm.inv_stop_maint_byp",
        "14": "alarm.inv_stop_by_byp_rot",
        "15": "alarm.inv_stop_thermal_sw"
      },
      "404": {
        "0": "alarm.inv_stop_byp_short-circuit",
        "1": "alarm.inv_stop_v_ramp_error",
        "2": "alarm.inv_stop_byp_tx_par_sys",
        "3": "alarm.inv_stop_timeout",
        "4": "alarm.inv_stop_volt_r_magins",
        "5": "alarm.inv_stop_volt_s_magins",
        "6": "alarm.inv_stop_volt_t_magins",
        "7": "alarm.block_rec_desat_rtrs",
        "8": "alarm.block_file_sys_fail",
        "9": "alarm.block_intpro_loc_com",
        "10": "alarm.block_intpro_rem_com",
        "11": "alarm.block_network_fail",
        "12": "alarm.block_dc_bus_hi_rtrs",
        "13": "alarm.block_start",
        "14": "alarm.block_rect_start",
        "15": "alarm.block_rect_test"
      },
      "405": {
        "0": "alarm.block_inv_test",
        "1": "alarm.block_set_file_error",
        "2": "alarm.block_license_expiry",
        "3": "alarm.rect_block_dc_bus_max",
        "4": "alarm.block_deadline_r",
        "5": "alarm.block_deadline_s",
        "6": "alarm.block_deadline_t",
        "7": "alarm.chg_block_dc_bus_max",
        "8": "alarm.chg_block_deadline",
        "9": "alarm.chg_block_desat",
        "10": "alarm.inv_block_desat_retries",
        "11": "alarm.inv_block_dc_v_out",
        "12": "alarm.inv_block_byp_off_short",
        "13": "alarm.inv_block_dc_bus_high",
        "14": "alarm.inv_block_deadline",
        "15": "alarm.inv_block_inv_start"
      },
      "406": {
        "0": "alarm.v_out_fault_inv_stop",
        "1": "alarm.inv_ssb_r_short",
        "2": "alarm.inv_ssb_s_short",
        "3": "alarm.inv_ssb_t_short",
        "4": "alarm.inv_ssi_r_short",
        "5": "alarm.inv_ssi_s_short",
        "6": "alarm.inv_ssi_t_short",
        "7": "alarm.inv_ssi_r_open",
        "8": "alarm.inv_ssi_s_open",
        "9": "alarm.inv_ssi_t_open",
        "10": "alarm.inv_v_out_margins",
        "11": "alarm.sys_new_params",
        "12": "alarm.epo"
      }
    },
    "CUBE40720": {
      "399": {
        "0": "alarm.epo_active"
      },
      "400": {
        "0": "desc.ups_failed"
      },
      "401": {
        "0": "alarm.bat_low"
      },
      "402": {
        "0": "desc.utility_fail"
      },
      "403": {
        "0": "warning.bat_open",
        "1": "warning.ip_n_loss",
        "2": "warning.line_ph_error",
        "3": "warning.byp_ph_error",
        "4": "warning.batt_over_charge",
        "5": "warning.bat_low",
        "6": "warning.ovload_wrng",
        "7": "warning.fan_lock_wrng",
        "8": "warning.epo_active",
        "9": "warning.ovtemp",
        "10": "warning.CHG_fail",
        "11": "warning.line_conn_diff",
        "12": "warning.byp_conn_diff",
        "13": "warning.parallel_load_diff",
        "14": "warning.lock_byp_ov_3_times",
        "15": "warning.ac_in_current_un"
      },
      "414": {
        "0": "warning.inv_current_un",
        "1": "warning.bat_replace",
        "2": "warning.cover_main_sw_open",
        "3": "warning.utility_extreme_un",
        "4": "warning.byp_unstable",
        "5": "warning.batt_volt_high",
        "6": "warning.batt_v_unbalance",
        "7": "warning.CHG_fail",
        "8": "warning.byp_loss",
        "9": "warning.iso_over_temp",
        "10": "warning.ext_out_sw_open",
        "11": "warning.ext_batt_sw_open",
        "12": "warning.ext_byp_sw_open",
        "13": "warning.ext_in_br_open"
      },
      "425": {
        "001": "alarm.bus_start_failed",
        "002": "alarm.bus_ov_voltage",
        "003": "alarm.bus_un_voltage",
        "004": "alarm.bus_volt_unbalanced",
        "006": "alarm.pfc_ov_current",
        "017": "alarm.inv_start_fail",
        "018": "alarm.inv_voltage_high",
        "019": "alarm.inv_voltage_low",
        "020": "alarm.r_inv_out_short",
        "021": "alarm.s_inv_out_short",
        "022": "alarm.t_inv_out_short",
        "023": "alarm.rs_inv_out_short",
        "024": "alarm.st_inv_out_short",
        "025": "alarm.tr_inv_out_short",
        "026": "alarm.r_inv_neg_pw_fault",
        "027": "alarm.s_inv_neg_pw_fault",
        "028": "alarm.t_inv_neg_pw_fault",
        "035": "alarm.inverter_relay_open",
        "037": "alarm.wiring_fail",
        "049": "alarm.can_comm_fault",
        "065": "alarm.overtemperature",
        "066": "alarm.dsp_comm_fault",
        "067": "alarm.overload_fault",
        "069": "alarm.charger_fail",
        "070": "alarm.model_fault_fail",
        "071": "alarm.dsp_mcu_comm_fault",
        "073": "alarm.in_out_ph_error",
        "097": "alarm.byp_scr_short",
        "098": "alarm.bypass_scr_open",
        "099": "alarm.r_inv_wave_abn",
        "100": "alarm.s_inv_wave_abn",
        "101": "alarm.t_inv_wave_abn",
        "103": "alarm.byp_out_short",
        "104": "alarm.byp_out_line_short",
        "105": "alarm.inv_relay_short",
        "108": "alarm.bus_v_vary_fast",
        "109": "alarm.curr_det_error",
        "110": "alarm.sps_pw_fault",
        "111": "alarm.batt_conn_reverse",
        "113": "alarm.r_pfc_igbt_ov_curr_fault",
        "114": "alarm.s_pfc_igbt_ov_curr_fault",
        "115": "alarm.t_pfc_igbt_ov_curr_fault",
        "116": "alarm.r_inv_igbt_ov_curr_fault",
        "117": "alarm.s_inv_igbt_ov_curr_fault",
        "118": "alarm.t_inv_igbt_ov_curr_fault",
        "119": "alarm.iso_transf_ovtemp",
        "120": "alarm.lcd_mcu_comm_failure"
      }
    },
    "ADAPT2": {
      "399": {
        "0": "alarm.epo",
        "1": "alarm.byp_sequence",
        "2": "alarm.byp_SCR_fail_open",
        "3": "alarm.byp_SCR_fail_shorted",
        "4": "alarm.byp_overload_timeout",
        "5": "alarm.output_short",
        "6": "alarm.batt_eod",
        "7": "alarm.rect_fault",
        "8": "alarm.inv_failure",
        "9": "alarm.rect_overtemp",
        "10": "alarm.fan_fault",
        "11": "alarm.output_overload",
        "12": "alarm.inve_overload_tout",
        "13": "alarm.inve_overtemp",
        "14": "alarm.on_UPS_inh",
        "15": "alarm.batt_pol_inv"
      },
      "400": {
        "0": "alarm.inv_cb",
        "1": "alarm.input_neutral_fault",
        "2": "alarm.byp_fan_fault",
        "3": "alarm.paral_cable_discon",
        "4": "alarm.rect_can_fault",
        "5": "alarm.inv_io_can_fail",
        "6": "alarm.inv_data_can_fail",
        "7": "alarm.sync_pulse",
        "8": "alarm.batt_v_detect",
        "9": "alarm.output_v_fail_R",
        "10": "alarm.output_v_fail_S",
        "11": "alarm.output_v_fail_T",
        "12": "alarm.inv_bridge_fail",
        "13": "alarm.input_c_unbalanced",
        "14": "alarm.rect_ss_fault",
        "15": "alarm.relay_conn_err"
      },
      "401": {
        "0": "alarm.relay_shortc",
        "1": "alarm.PWM_sync",
        "2": "alarm.input_c_high_timeout",
        "3": "alarm.no_inlet_sens",
        "4": "alarm.no_outlet_sens",
        "5": "alarm.byp_can_fault",
        "6": "alarm.firmware_error",
        "7": "alarm.sys_settings_fail",
        "8": "alarm.mod_id_duplicate",
        "9": "alarm.inv_igbt_ovcurrent",
        "10": "alarm.redund_ovl_tout",
        "11": "alarm.no_output",
        "12": "alarm.byp_mant",
        "13": "alarm.bat_low",
        "14": "alarm.filesys_error",
        "15": "alarm.inv_igbt_driver_fault" 
      },
      "402": {
        "0": "alarm.bat_ov_voltage",
        "1": "alarm.rec_phase_seq_fault",
        "2": "alarm.neg_bus_undervoltage",
        "3": "alarm.pos_bus_undervoltage",
        "4": "alarm.aux_power_lost",
        "5": "alarm.inv_src_overtemp"
      },
      "412": {
        "0": "alarm.batt_not_connect",
        "1": "alarm.mod_on_less",
        "2": "alarm.utility_abnormal",
        "3": "alarm.byp_v_abn_R",
        "4": "alarm.byp_v_abn_S",
        "5": "alarm.byp_v_abn_T",
        "6": "alarm.byp_mod_ovload_R",
        "7": "alarm.byp_mod_ovload_S",
        "8": "alarm.byp_mod_ovload_T",
        "9": "alarm.byp_freq_ovtrack",
        "10": "alarm.exceed_tx_times_lmt",
        "11": "alarm.batt_v_low",
        "12": "alarm.lost_redundant",
        "13": "alarm.batt_test_fail",
        "14": "alarm.batt_man_fail",
        "15": "alarm.ambient_ovtemp"
      },
      "413": {
        "0": "alarm.input_v_detection",
        "1": "alarm.temp_outlet_error",
        "2": "alarm.dc_bus_ovvolt",
        "3": "alarm.inlet_ovtemp",
        "4": "alarm.overtemp_batt",
        "5": "alarm.byp_fan_expired",
        "6": "alarm.capacitor_expired",
        "7": "alarm.fan_expired",
        "8": "alarm.outlet_delta_temp",
        "9": "alarm.batt_expired",
        "10": "alarm.dust_filter_expired",
        "11": "alarm.overtemp_byp",
        "12": "alarm.rtc_batt_low",
        "13": "alarm.genset_input",
        "14": "alarm.shutdown_input",
        "15": "alarm.bcb_open"
      },
      "414": {
        "0": "alarm.external_dig_1",
        "1": "alarm.external_dig_2",
        "2": "alarm.external_dig_3",
        "3": "alarm.external_dig_4",
        "4": "alarm.external_dig_5",
        "5": "alarm.external_dig_6",
        "6": "alarm.synchro_fault",
        "7": "alarm.in_overcurrent",
        "8": "alarm.pwm_pulse_fault",
        "9": "alarm.in_backfeed"
      },
      "415": {
        "0": "alarm.module_fault"
      },
      "2199": {
        "0": "pm.inv_failure#1",
        "1": "pm.rect_fail#1",
        "2": "pm.syn_fail#1",
        "3": "pm.inv_v_phase_R_fault#1",
        "4": "pm.high_in_c_R#1",
        "5": "pm.high_in_c_S#1",
        "6": "pm.high_in_c_T#1",
        "7": "pm.inv_v_phT_fault#1",
        "8": "pm.inv_v_phS_fault#1",
        "9": "pm.input_c_unbalanced#1",
        "10": "pm.pwm_pulse#1",
        "11": "pm.sync_pulse#1",
        "12": "pm.rect_ss_fault#1",
        "13": "pm.input_phases_seq#1",
        "14": "pm.pos_bus_ov_v_fault#1",
        "15": "pm.rect_ovtemp_fault#1"
      },
      "2200": {
        "0": "pm.input_back_feed#1",
        "1": "pm.fan_fail#1",
        "2": "pm.neg_bus_ov_v_fault#1",
        "3": "pm.postoneg_batt_diff#1",
        "4": "pm.batt_pol_inv#1",
        "5": "pm.neg_bus_under_v_fault#1",
        "6": "pm.pos_bus_under_v_fault#1",
        "7": "pm.neg_charg_batt_ov#1",
        "8": "pm.pos_charg_batt_ov#1",
        "9": "pm.neg_batt_charg_fault#1",
        "10": "pm.pos_batt_charg_fault#1",
        "11": "pm.neutral_lost#1",
        "12": "pm.overload_tout#1",
        "13": "pm.inv_pw_back_feed#1",
        "14": "pm.inv_prot_fail#1",
        "15": "pm.inv_temp#1"
      },
      "2201": {
        "0": "pm.inv_scr_status#1",
        "1": "pm.inv_overload#1",
        "2": "pm.inv_igbt_current#1",
        "3": "pm.paral_current_shar#1",
        "4": "pm.rect_inv_com#1",
        "5": "pm.output_short#1",
        "6": "pm.inv_relay_short#1",
        "7": "pm.inv_relay_open#1",
        "8": "pm.inv_bridge_open#1",
        "9": "pm.no_outlet_sens#1",
        "10": "pm.no_inlet_sens#1",
        "11": "pm.input_c_high_timeout#1",
        "12": "pm.inv_id_ovlap#1",
        "13": "pm.fan_fail_ovtemp#1",
        "14": "pm.sys_rate_sett#1"
      },
      "2204": {
        "0": "pm.input_pw_fail#1",
        "1": "pm.byp_fault#1",
        "2": "pm.input_fq_fail#1",
        "3": "pm.input_v_fail#1",
        "4": "pm.neg_batt_eod#1",
        "5": "pm.pos_batt_eod#1",
        "6": "pm.neg_batt_v_low#1",
        "7": "pm.pos_batt_v_low#1",
        "8": "pm.byp_scr_fault#1",
        "9": "pm.byp_v#1",
        "10": "pm.byp_phases_seq#1",
        "11": "pm.byp_fq_ovtrack#1",
        "12": "pm.aux_pw_supp_lost#1",
        "13": "pm.transf_tm_lm_onehr#1",
        "14": "pm.man_sdown#1",
        "15": "pm.inv_batt_man#1"
      },
      "2205": {
        "0": "pm.inv_batt_test#1",
        "1": "pm.outlet_delta_temp#1",
        "2": "pm.inv_scr_ovtemp#1",
        "3": "pm.no_input_scr_sensor#1",
        "4": "pm.man_on#1",
        "5": "pm.fan_expired#1",
        "6": "pm.capacitor_expired#1",
        "7": "pm.inlet_ovtemp#1",
        "8": "pm.outlet_ovtemp#1"
      },
      "2699": {
        "0": "pm.inv_failure#2",
        "1": "pm.rect_fail#2",
        "2": "pm.syn_fail#2",
        "3": "pm.inv_v_phase_R_fault#2",
        "4": "pm.high_in_c_R#2",
        "5": "pm.high_in_c_S#2",
        "6": "pm.high_in_c_T#2",
        "7": "pm.inv_v_phT_fault#2",
        "8": "pm.inv_v_phS_fault#2",
        "9": "pm.input_c_unbalanced#2",
        "10": "pm.pwm_pulse#2",
        "11": "pm.sync_pulse#2",
        "12": "pm.rect_ss_fault#2",
        "13": "pm.input_phases_seq#2",
        "14": "pm.pos_bus_ov_v_fault#2",
        "15": "pm.rect_ovtemp_fault#2"
      },
      "2700": {
        "0": "pm.input_back_feed#2",
        "1": "pm.fan_fail#2",
        "2": "pm.neg_bus_ov_v_fault#2",
        "3": "pm.postoneg_batt_diff#2",
        "4": "pm.batt_pol_inv#2",
        "5": "pm.neg_bus_under_v_fault#2",
        "6": "pm.pos_bus_under_v_fault#2",
        "7": "pm.neg_charg_batt_ov#2",
        "8": "pm.pos_charg_batt_ov#2",
        "9": "pm.neg_batt_charg_fault#2",
        "10": "pm.pos_batt_charg_fault#2",
        "11": "pm.neutral_lost#2",
        "12": "pm.overload_tout#2",
        "13": "pm.inv_pw_back_feed#2",
        "14": "pm.inv_prot_fail#2",
        "15": "pm.inv_temp#2"
      },
      "2701": {
        "0": "pm.inv_scr_status#2",
        "1": "pm.inv_overload#2",
        "2": "pm.inv_igbt_current#2",
        "3": "pm.paral_current_shar#2",
        "4": "pm.rect_inv_com#2",
        "5": "pm.output_short#2",
        "6": "pm.inv_relay_short#2",
        "7": "pm.inv_relay_open#2",
        "8": "pm.inv_bridge_open#2",
        "9": "pm.no_outlet_sens#2",
        "10": "pm.no_inlet_sens#2",
        "11": "pm.input_c_high_timeout#2",
        "12": "pm.inv_id_ovlap#2",
        "13": "pm.fan_fail_ovtemp#2",
        "14": "pm.sys_rate_sett#2"
      },
      "2704": {
        "0": "pm.input_pw_fail#2",
        "1": "pm.byp_fault#2",
        "2": "pm.input_fq_fail#2",
        "3": "pm.input_v_fail#2",
        "4": "pm.neg_batt_eod#2",
        "5": "pm.pos_batt_eod#2",
        "6": "pm.neg_batt_v_low#2",
        "7": "pm.pos_batt_v_low#2",
        "8": "pm.byp_scr_fault#2",
        "9": "pm.byp_v#2",
        "10": "pm.byp_phases_seq#2",
        "11": "pm.byp_fq_ovtrack#2",
        "12": "pm.aux_pw_supp_lost#2",
        "13": "pm.transf_tm_lm_onehr#2",
        "14": "pm.man_sdown#2",
        "15": "pm.inv_batt_man#2"
      },
      "2705": {
        "0": "pm.inv_batt_test#2",
        "1": "pm.outlet_delta_temp#2",
        "2": "pm.inv_scr_ovtemp#2",
        "3": "pm.no_input_scr_sensor#2",
        "4": "pm.man_on#2",
        "5": "pm.fan_expired#2",
        "6": "pm.capacitor_expired#2",
        "7": "pm.inlet_ovtemp#2",
        "8": "pm.outlet_ovtemp#2"
      },
      "3199": {
        "0": "pm.inv_failure#3",
        "1": "pm.rect_fail#3",
        "2": "pm.syn_fail#3",
        "3": "pm.inv_v_phase_R_fault#3",
        "4": "pm.high_in_c_R#3",
        "5": "pm.high_in_c_S#3",
        "6": "pm.high_in_c_T#3",
        "7": "pm.inv_v_phT_fault#3",
        "8": "pm.inv_v_phS_fault#3",
        "9": "pm.input_c_unbalanced#3",
        "10": "pm.pwm_pulse#3",
        "11": "pm.sync_pulse#3",
        "12": "pm.rect_ss_fault#3",
        "13": "pm.input_phases_seq#3",
        "14": "pm.pos_bus_ov_v_fault#3",
        "15": "pm.rect_ovtemp_fault#3"
      },
      "3200": {
        "0": "pm.input_back_feed#3",
        "1": "pm.fan_fail#3",
        "2": "pm.neg_bus_ov_v_fault#3",
        "3": "pm.postoneg_batt_diff#3",
        "4": "pm.batt_pol_inv#3",
        "5": "pm.neg_bus_under_v_fault#3",
        "6": "pm.pos_bus_under_v_fault#3",
        "7": "pm.neg_charg_batt_ov#3",
        "8": "pm.pos_charg_batt_ov#3",
        "9": "pm.neg_batt_charg_fault#3",
        "10": "pm.pos_batt_charg_fault#3",
        "11": "pm.neutral_lost#3",
        "12": "pm.overload_tout#3",
        "13": "pm.inv_pw_back_feed#3",
        "14": "pm.inv_prot_fail#3",
        "15": "pm.inv_temp#3"
      },
      "3201": {
        "0": "pm.inv_scr_status#3",
        "1": "pm.inv_overload#3",
        "2": "pm.inv_igbt_current#3",
        "3": "pm.paral_current_shar#3",
        "4": "pm.rect_inv_com#3",
        "5": "pm.output_short#3",
        "6": "pm.inv_relay_short#3",
        "7": "pm.inv_relay_open#3",
        "8": "pm.inv_bridge_open#3",
        "9": "pm.no_outlet_sens#3",
        "10": "pm.no_inlet_sens#3",
        "11": "pm.input_c_high_timeout#3",
        "12": "pm.inv_id_ovlap#3",
        "13": "pm.fan_fail_ovtemp#3",
        "14": "pm.sys_rate_sett#3"
      },
      "3204": {
        "0": "pm.input_pw_fail#3",
        "1": "pm.byp_fault#3",
        "2": "pm.input_fq_fail#3",
        "3": "pm.input_v_fail#3",
        "4": "pm.neg_batt_eod#3",
        "5": "pm.pos_batt_eod#3",
        "6": "pm.neg_batt_v_low#3",
        "7": "pm.pos_batt_v_low#3",
        "8": "pm.byp_scr_fault#3",
        "9": "pm.byp_v#3",
        "10": "pm.byp_phases_seq#3",
        "11": "pm.byp_fq_ovtrack#3",
        "12": "pm.aux_pw_supp_lost#3",
        "13": "pm.transf_tm_lm_onehr#3",
        "14": "pm.man_sdown#3",
        "15": "pm.inv_batt_man#3"
      },
      "3205": {
        "0": "pm.inv_batt_test#3",
        "1": "pm.outlet_delta_temp#3",
        "2": "pm.inv_scr_ovtemp#3",
        "3": "pm.no_input_scr_sensor#3",
        "4": "pm.man_on#3",
        "5": "pm.fan_expired#3",
        "6": "pm.capacitor_expired#3",
        "7": "pm.inlet_ovtemp#3",
        "8": "pm.outlet_ovtemp#3"
      },
      "3699": {
        "0": "pm.inv_failure#4",
        "1": "pm.rect_fail#4",
        "2": "pm.syn_fail#4",
        "3": "pm.inv_v_phase_R_fault#4",
        "4": "pm.high_in_c_R#4",
        "5": "pm.high_in_c_S#4",
        "6": "pm.high_in_c_T#4",
        "7": "pm.inv_v_phT_fault#4",
        "8": "pm.inv_v_phS_fault#4",
        "9": "pm.input_c_unbalanced#4",
        "10": "pm.pwm_pulse#4",
        "11": "pm.sync_pulse#4",
        "12": "pm.rect_ss_fault#4",
        "13": "pm.input_phases_seq#4",
        "14": "pm.pos_bus_ov_v_fault#4",
        "15": "pm.rect_ovtemp_fault#4"
      },
      "3700": {
        "0": "pm.input_back_feed#4",
        "1": "pm.fan_fail#4",
        "2": "pm.neg_bus_ov_v_fault#4",
        "3": "pm.postoneg_batt_diff#4",
        "4": "pm.batt_pol_inv#4",
        "5": "pm.neg_bus_under_v_fault#4",
        "6": "pm.pos_bus_under_v_fault#4",
        "7": "pm.neg_charg_batt_ov#4",
        "8": "pm.pos_charg_batt_ov#4",
        "9": "pm.neg_batt_charg_fault#4",
        "10": "pm.pos_batt_charg_fault#4",
        "11": "pm.neutral_lost#4",
        "12": "pm.overload_tout#4",
        "13": "pm.inv_pw_back_feed#4",
        "14": "pm.inv_prot_fail#4",
        "15": "pm.inv_temp#4"
      },
      "3701": {
        "0": "pm.inv_scr_status#4",
        "1": "pm.inv_overload#4",
        "2": "pm.inv_igbt_current#4",
        "3": "pm.paral_current_shar#4",
        "4": "pm.rect_inv_com#4",
        "5": "pm.output_short#4",
        "6": "pm.inv_relay_short#4",
        "7": "pm.inv_relay_open#4",
        "8": "pm.inv_bridge_open#4",
        "9": "pm.no_outlet_sens#4",
        "10": "pm.no_inlet_sens#4",
        "11": "pm.input_c_high_timeout#4",
        "12": "pm.inv_id_ovlap#4",
        "13": "pm.fan_fail_ovtemp#4",
        "14": "pm.sys_rate_sett#4"
      },
      "3704": {
        "0": "pm.input_pw_fail#4",
        "1": "pm.byp_fault#4",
        "2": "pm.input_fq_fail#4",
        "3": "pm.input_v_fail#4",
        "4": "pm.neg_batt_eod#4",
        "5": "pm.pos_batt_eod#4",
        "6": "pm.neg_batt_v_low#4",
        "7": "pm.pos_batt_v_low#4",
        "8": "pm.byp_scr_fault#4",
        "9": "pm.byp_v#4",
        "10": "pm.byp_phases_seq#4",
        "11": "pm.byp_fq_ovtrack#4",
        "12": "pm.aux_pw_supp_lost#4",
        "13": "pm.transf_tm_lm_onehr#4",
        "14": "pm.man_sdown#4",
        "15": "pm.inv_batt_man#4"
      },
      "3705": {
        "0": "pm.inv_batt_test#4",
        "1": "pm.outlet_delta_temp#4",
        "2": "pm.inv_scr_ovtemp#4",
        "3": "pm.no_input_scr_sensor#4",
        "4": "pm.man_on#4",
        "5": "pm.fan_expired#4",
        "6": "pm.capacitor_expired#4",
        "7": "pm.inlet_ovtemp#4",
        "8": "pm.outlet_ovtemp#4"
      },
      "4199": {
        "0": "pm.inv_failure#5",
        "1": "pm.rect_fail#5",
        "2": "pm.syn_fail#5",
        "3": "pm.inv_v_phase_R_fault#5",
        "4": "pm.high_in_c_R#5",
        "5": "pm.high_in_c_S#5",
        "6": "pm.high_in_c_T#5",
        "7": "pm.inv_v_phT_fault#5",
        "8": "pm.inv_v_phS_fault#5",
        "9": "pm.input_c_unbalanced#5",
        "10": "pm.pwm_pulse#5",
        "11": "pm.sync_pulse#5",
        "12": "pm.rect_ss_fault#5",
        "13": "pm.input_phases_seq#5",
        "14": "pm.pos_bus_ov_v_fault#5",
        "15": "pm.rect_ovtemp_fault#5"
      },
      "4200": {
        "0": "pm.input_back_feed#5",
        "1": "pm.fan_fail#5",
        "2": "pm.neg_bus_ov_v_fault#5",
        "3": "pm.postoneg_batt_diff#5",
        "4": "pm.batt_pol_inv#5",
        "5": "pm.neg_bus_under_v_fault#5",
        "6": "pm.pos_bus_under_v_fault#5",
        "7": "pm.neg_charg_batt_ov#5",
        "8": "pm.pos_charg_batt_ov#5",
        "9": "pm.neg_batt_charg_fault#5",
        "10": "pm.pos_batt_charg_fault#5",
        "11": "pm.neutral_lost#5",
        "12": "pm.overload_tout#5",
        "13": "pm.inv_pw_back_feed#5",
        "14": "pm.inv_prot_fail#5",
        "15": "pm.inv_temp#5"
      },
      "4201": {
        "0": "pm.inv_scr_status#5",
        "1": "pm.inv_overload#5",
        "2": "pm.inv_igbt_current#5",
        "3": "pm.paral_current_shar#5",
        "4": "pm.rect_inv_com#5",
        "5": "pm.output_short#5",
        "6": "pm.inv_relay_short#5",
        "7": "pm.inv_relay_open#5",
        "8": "pm.inv_bridge_open#5",
        "9": "pm.no_outlet_sens#5",
        "10": "pm.no_inlet_sens#5",
        "11": "pm.input_c_high_timeout#5",
        "12": "pm.inv_id_ovlap#5",
        "13": "pm.fan_fail_ovtemp#5",
        "14": "pm.sys_rate_sett#5"
      },
      "4204": {
        "0": "pm.input_pw_fail#5",
        "1": "pm.byp_fault#5",
        "2": "pm.input_fq_fail#5",
        "3": "pm.input_v_fail#5",
        "4": "pm.neg_batt_eod#5",
        "5": "pm.pos_batt_eod#5",
        "6": "pm.neg_batt_v_low#5",
        "7": "pm.pos_batt_v_low#5",
        "8": "pm.byp_scr_fault#5",
        "9": "pm.byp_v#5",
        "10": "pm.byp_phases_seq#5",
        "11": "pm.byp_fq_ovtrack#5",
        "12": "pm.aux_pw_supp_lost#5",
        "13": "pm.transf_tm_lm_onehr#5",
        "14": "pm.man_sdown#5",
        "15": "pm.inv_batt_man#5"
      },
      "4205": {
        "0": "pm.inv_batt_test#5",
        "1": "pm.outlet_delta_temp#5",
        "2": "pm.inv_scr_ovtemp#5",
        "3": "pm.no_input_scr_sensor#5",
        "4": "pm.man_on#5",
        "5": "pm.fan_expired#5",
        "6": "pm.capacitor_expired#5",
        "7": "pm.inlet_ovtemp#5",
        "8": "pm.outlet_ovtemp#5"
      },
      "4699": {
        "0": "pm.inv_failure#6",
        "1": "pm.rect_fail#6",
        "2": "pm.syn_fail#6",
        "3": "pm.inv_v_phase_R_fault#6",
        "4": "pm.high_in_c_R#6",
        "5": "pm.high_in_c_S#6",
        "6": "pm.high_in_c_T#6",
        "7": "pm.inv_v_phT_fault#6",
        "8": "pm.inv_v_phS_fault#6",
        "9": "pm.input_c_unbalanced#6",
        "10": "pm.pwm_pulse#6",
        "11": "pm.sync_pulse#6",
        "12": "pm.rect_ss_fault#6",
        "13": "pm.input_phases_seq#6",
        "14": "pm.pos_bus_ov_v_fault#6",
        "15": "pm.rect_ovtemp_fault#6"
      },
      "4700": {
        "0": "pm.input_back_feed#6",
        "1": "pm.fan_fail#6",
        "2": "pm.neg_bus_ov_v_fault#6",
        "3": "pm.postoneg_batt_diff#6",
        "4": "pm.batt_pol_inv#6",
        "5": "pm.neg_bus_under_v_fault#6",
        "6": "pm.pos_bus_under_v_fault#6",
        "7": "pm.neg_charg_batt_ov#6",
        "8": "pm.pos_charg_batt_ov#6",
        "9": "pm.neg_batt_charg_fault#6",
        "10": "pm.pos_batt_charg_fault#6",
        "11": "pm.neutral_lost#6",
        "12": "pm.overload_tout#6",
        "13": "pm.inv_pw_back_feed#6",
        "14": "pm.inv_prot_fail#6",
        "15": "pm.inv_temp#6"
      },
      "4701": {
        "0": "pm.inv_scr_status#6",
        "1": "pm.inv_overload#6",
        "2": "pm.inv_igbt_current#6",
        "3": "pm.paral_current_shar#6",
        "4": "pm.rect_inv_com#6",
        "5": "pm.output_short#6",
        "6": "pm.inv_relay_short#6",
        "7": "pm.inv_relay_open#6",
        "8": "pm.inv_bridge_open#6",
        "9": "pm.no_outlet_sens#6",
        "10": "pm.no_inlet_sens#6",
        "11": "pm.input_c_high_timeout#6",
        "12": "pm.inv_id_ovlap#6",
        "13": "pm.fan_fail_ovtemp#6",
        "14": "pm.sys_rate_sett#6"
      },
      "4704": {
        "0": "pm.input_pw_fail#6",
        "1": "pm.byp_fault#6",
        "2": "pm.input_fq_fail#6",
        "3": "pm.input_v_fail#6",
        "4": "pm.neg_batt_eod#6",
        "5": "pm.pos_batt_eod#6",
        "6": "pm.neg_batt_v_low#6",
        "7": "pm.pos_batt_v_low#6",
        "8": "pm.byp_scr_fault#6",
        "9": "pm.byp_v#6",
        "10": "pm.byp_phases_seq#6",
        "11": "pm.byp_fq_ovtrack#6",
        "12": "pm.aux_pw_supp_lost#6",
        "13": "pm.transf_tm_lm_onehr#6",
        "14": "pm.man_sdown#6",
        "15": "pm.inv_batt_man#6"
      },
      "4705": {
        "0": "pm.inv_batt_test#6",
        "1": "pm.outlet_delta_temp#6",
        "2": "pm.inv_scr_ovtemp#6",
        "3": "pm.no_input_scr_sensor#6",
        "4": "pm.man_on#6",
        "5": "pm.fan_expired#6",
        "6": "pm.capacitor_expired#6",
        "7": "pm.inlet_ovtemp#6",
        "8": "pm.outlet_ovtemp#6"
      },
      "5199": {
        "0": "pm.inv_failure#7",
        "1": "pm.rect_fail#7",
        "2": "pm.syn_fail#7",
        "3": "pm.inv_v_phase_R_fault#7",
        "4": "pm.high_in_c_R#7",
        "5": "pm.high_in_c_S#7",
        "6": "pm.high_in_c_T#7",
        "7": "pm.inv_v_phT_fault#7",
        "8": "pm.inv_v_phS_fault#7",
        "9": "pm.input_c_unbalanced#7",
        "10": "pm.pwm_pulse#7",
        "11": "pm.sync_pulse#7",
        "12": "pm.rect_ss_fault#7",
        "13": "pm.input_phases_seq#7",
        "14": "pm.pos_bus_ov_v_fault#7",
        "15": "pm.rect_ovtemp_fault#7"
      },
      "5200": {
        "0": "pm.input_back_feed#7",
        "1": "pm.fan_fail#7",
        "2": "pm.neg_bus_ov_v_fault#7",
        "3": "pm.postoneg_batt_diff#7",
        "4": "pm.batt_pol_inv#7",
        "5": "pm.neg_bus_under_v_fault#7",
        "6": "pm.pos_bus_under_v_fault#7",
        "7": "pm.neg_charg_batt_ov#7",
        "8": "pm.pos_charg_batt_ov#7",
        "9": "pm.neg_batt_charg_fault#7",
        "10": "pm.pos_batt_charg_fault#7",
        "11": "pm.neutral_lost#7",
        "12": "pm.overload_tout#7",
        "13": "pm.inv_pw_back_feed#7",
        "14": "pm.inv_prot_fail#7",
        "15": "pm.inv_temp#7"
      },
      "5201": {
        "0": "pm.inv_scr_status#7",
        "1": "pm.inv_overload#7",
        "2": "pm.inv_igbt_current#7",
        "3": "pm.paral_current_shar#7",
        "4": "pm.rect_inv_com#7",
        "5": "pm.output_short#7",
        "6": "pm.inv_relay_short#7",
        "7": "pm.inv_relay_open#7",
        "8": "pm.inv_bridge_open#7",
        "9": "pm.no_outlet_sens#7",
        "10": "pm.no_inlet_sens#7",
        "11": "pm.input_c_high_timeout#7",
        "12": "pm.inv_id_ovlap#7",
        "13": "pm.fan_fail_ovtemp#7",
        "14": "pm.sys_rate_sett#7"
      },
      "5204": {
        "0": "pm.input_pw_fail#7",
        "1": "pm.byp_fault#7",
        "2": "pm.input_fq_fail#7",
        "3": "pm.input_v_fail#7",
        "4": "pm.neg_batt_eod#7",
        "5": "pm.pos_batt_eod#7",
        "6": "pm.neg_batt_v_low#7",
        "7": "pm.pos_batt_v_low#7",
        "8": "pm.byp_scr_fault#7",
        "9": "pm.byp_v#7",
        "10": "pm.byp_phases_seq#7",
        "11": "pm.byp_fq_ovtrack#7",
        "12": "pm.aux_pw_supp_lost#7",
        "13": "pm.transf_tm_lm_onehr#7",
        "14": "pm.man_sdown#7",
        "15": "pm.inv_batt_man#7"
      },
      "5205": {
        "0": "pm.inv_batt_test#7",
        "1": "pm.outlet_delta_temp#7",
        "2": "pm.inv_scr_ovtemp#7",
        "3": "pm.no_input_scr_sensor#7",
        "4": "pm.man_on#7",
        "5": "pm.fan_expired#7",
        "6": "pm.capacitor_expired#7",
        "7": "pm.inlet_ovtemp#7",
        "8": "pm.outlet_ovtemp#7"
      },
      "5699": {
        "0": "pm.inv_failure#8",
        "1": "pm.rect_fail#8",
        "2": "pm.syn_fail#8",
        "3": "pm.inv_v_phase_R_fault#8",
        "4": "pm.high_in_c_R#8",
        "5": "pm.high_in_c_S#8",
        "6": "pm.high_in_c_T#8",
        "7": "pm.inv_v_phT_fault#8",
        "8": "pm.inv_v_phS_fault#8",
        "9": "pm.input_c_unbalanced#8",
        "10": "pm.pwm_pulse#8",
        "11": "pm.sync_pulse#8",
        "12": "pm.rect_ss_fault#8",
        "13": "pm.input_phases_seq#8",
        "14": "pm.pos_bus_ov_v_fault#8",
        "15": "pm.rect_ovtemp_fault#8"
      },
      "5700": {
        "0": "pm.input_back_feed#8",
        "1": "pm.fan_fail#8",
        "2": "pm.neg_bus_ov_v_fault#8",
        "3": "pm.postoneg_batt_diff#8",
        "4": "pm.batt_pol_inv#8",
        "5": "pm.neg_bus_under_v_fault#8",
        "6": "pm.pos_bus_under_v_fault#8",
        "7": "pm.neg_charg_batt_ov#8",
        "8": "pm.pos_charg_batt_ov#8",
        "9": "pm.neg_batt_charg_fault#8",
        "10": "pm.pos_batt_charg_fault#8",
        "11": "pm.neutral_lost#8",
        "12": "pm.overload_tout#8",
        "13": "pm.inv_pw_back_feed#8",
        "14": "pm.inv_prot_fail#8",
        "15": "pm.inv_temp#8"
      },
      "5701": {
        "0": "pm.inv_scr_status#8",
        "1": "pm.inv_overload#8",
        "2": "pm.inv_igbt_current#8",
        "3": "pm.paral_current_shar#8",
        "4": "pm.rect_inv_com#8",
        "5": "pm.output_short#8",
        "6": "pm.inv_relay_short#8",
        "7": "pm.inv_relay_open#8",
        "8": "pm.inv_bridge_open#8",
        "9": "pm.no_outlet_sens#8",
        "10": "pm.no_inlet_sens#8",
        "11": "pm.input_c_high_timeout#8",
        "12": "pm.inv_id_ovlap#8",
        "13": "pm.fan_fail_ovtemp#8",
        "14": "pm.sys_rate_sett#8"
      },
      "5704": {
        "0": "pm.input_pw_fail#8",
        "1": "pm.byp_fault#8",
        "2": "pm.input_fq_fail#8",
        "3": "pm.input_v_fail#8",
        "4": "pm.neg_batt_eod#8",
        "5": "pm.pos_batt_eod#8",
        "6": "pm.neg_batt_v_low#8",
        "7": "pm.pos_batt_v_low#8",
        "8": "pm.byp_scr_fault#8",
        "9": "pm.byp_v#8",
        "10": "pm.byp_phases_seq#8",
        "11": "pm.byp_fq_ovtrack#8",
        "12": "pm.aux_pw_supp_lost#8",
        "13": "pm.transf_tm_lm_onehr#8",
        "14": "pm.man_sdown#8",
        "15": "pm.inv_batt_man#8"
      },
      "5705": {
        "0": "pm.inv_batt_test#8",
        "1": "pm.outlet_delta_temp#8",
        "2": "pm.inv_scr_ovtemp#8",
        "3": "pm.no_input_scr_sensor#8",
        "4": "pm.man_on#8",
        "5": "pm.fan_expired#8",
        "6": "pm.capacitor_expired#8",
        "7": "pm.inlet_ovtemp#8",
        "8": "pm.outlet_ovtemp#8"
      },
      "6199": {
        "0": "pm.inv_failure#9",
        "1": "pm.rect_fail#9",
        "2": "pm.syn_fail#9",
        "3": "pm.inv_v_phase_R_fault#9",
        "4": "pm.high_in_c_R#9",
        "5": "pm.high_in_c_S#9",
        "6": "pm.high_in_c_T#9",
        "7": "pm.inv_v_phT_fault#9",
        "8": "pm.inv_v_phS_fault#9",
        "9": "pm.input_c_unbalanced#9",
        "10": "pm.pwm_pulse#9",
        "11": "pm.sync_pulse#9",
        "12": "pm.rect_ss_fault#9",
        "13": "pm.input_phases_seq#9",
        "14": "pm.pos_bus_ov_v_fault#9",
        "15": "pm.rect_ovtemp_fault#9"
      },
      "6200": {
        "0": "pm.input_back_feed#9",
        "1": "pm.fan_fail#9",
        "2": "pm.neg_bus_ov_v_fault#9",
        "3": "pm.postoneg_batt_diff#9",
        "4": "pm.batt_pol_inv#9",
        "5": "pm.neg_bus_under_v_fault#9",
        "6": "pm.pos_bus_under_v_fault#9",
        "7": "pm.neg_charg_batt_ov#9",
        "8": "pm.pos_charg_batt_ov#9",
        "9": "pm.neg_batt_charg_fault#9",
        "10": "pm.pos_batt_charg_fault#9",
        "11": "pm.neutral_lost#9",
        "12": "pm.overload_tout#9",
        "13": "pm.inv_pw_back_feed#9",
        "14": "pm.inv_prot_fail#9",
        "15": "pm.inv_temp#9"
      },
      "6201": {
        "0": "pm.inv_scr_status#9",
        "1": "pm.inv_overload#9",
        "2": "pm.inv_igbt_current#9",
        "3": "pm.paral_current_shar#9",
        "4": "pm.rect_inv_com#9",
        "5": "pm.output_short#9",
        "6": "pm.inv_relay_short#9",
        "7": "pm.inv_relay_open#9",
        "8": "pm.inv_bridge_open#9",
        "9": "pm.no_outlet_sens#9",
        "10": "pm.no_inlet_sens#9",
        "11": "pm.input_c_high_timeout#9",
        "12": "pm.inv_id_ovlap#9",
        "13": "pm.fan_fail_ovtemp#9",
        "14": "pm.sys_rate_sett#9"
      },
      "6204": {
        "0": "pm.input_pw_fail#9",
        "1": "pm.byp_fault#9",
        "2": "pm.input_fq_fail#9",
        "3": "pm.input_v_fail#9",
        "4": "pm.neg_batt_eod#9",
        "5": "pm.pos_batt_eod#9",
        "6": "pm.neg_batt_v_low#9",
        "7": "pm.pos_batt_v_low#9",
        "8": "pm.byp_scr_fault#9",
        "9": "pm.byp_v#9",
        "10": "pm.byp_phases_seq#9",
        "11": "pm.byp_fq_ovtrack#9",
        "12": "pm.aux_pw_supp_lost#9",
        "13": "pm.transf_tm_lm_onehr#9",
        "14": "pm.man_sdown#9",
        "15": "pm.inv_batt_man#9"
      },
      "6205": {
        "0": "pm.inv_batt_test#9",
        "1": "pm.outlet_delta_temp#9",
        "2": "pm.inv_scr_ovtemp#9",
        "3": "pm.no_input_scr_sensor#9",
        "4": "pm.man_on#9",
        "5": "pm.fan_expired#9",
        "6": "pm.capacitor_expired#9",
        "7": "pm.inlet_ovtemp#9",
        "8": "pm.outlet_ovtemp#9"
      },
      "6699": {
        "0": "pm.inv_failure#10",
        "1": "pm.rect_fail#10",
        "2": "pm.syn_fail#10",
        "3": "pm.inv_v_phase_R_fault#10",
        "4": "pm.high_in_c_R#10",
        "5": "pm.high_in_c_S#10",
        "6": "pm.high_in_c_T#10",
        "7": "pm.inv_v_phT_fault#10",
        "8": "pm.inv_v_phS_fault#10",
        "9": "pm.input_c_unbalanced#10",
        "10": "pm.pwm_pulse#10",
        "11": "pm.sync_pulse#10",
        "12": "pm.rect_ss_fault#10",
        "13": "pm.input_phases_seq#10",
        "14": "pm.pos_bus_ov_v_fault#10",
        "15": "pm.rect_ovtemp_fault#10"
      },
      "6700": {
        "0": "pm.input_back_feed#10",
        "1": "pm.fan_fail#10",
        "2": "pm.neg_bus_ov_v_fault#10",
        "3": "pm.postoneg_batt_diff#10",
        "4": "pm.batt_pol_inv#10",
        "5": "pm.neg_bus_under_v_fault#10",
        "6": "pm.pos_bus_under_v_fault#10",
        "7": "pm.neg_charg_batt_ov#10",
        "8": "pm.pos_charg_batt_ov#10",
        "9": "pm.neg_batt_charg_fault#10",
        "10": "pm.pos_batt_charg_fault#10",
        "11": "pm.neutral_lost#10",
        "12": "pm.overload_tout#10",
        "13": "pm.inv_pw_back_feed#10",
        "14": "pm.inv_prot_fail#10",
        "15": "pm.inv_temp#10"
      },
      "6701": {
        "0": "pm.inv_scr_status#10",
        "1": "pm.inv_overload#10",
        "2": "pm.inv_igbt_current#10",
        "3": "pm.paral_current_shar#10",
        "4": "pm.rect_inv_com#10",
        "5": "pm.output_short#10",
        "6": "pm.inv_relay_short#10",
        "7": "pm.inv_relay_open#10",
        "8": "pm.inv_bridge_open#10",
        "9": "pm.no_outlet_sens#10",
        "10": "pm.no_inlet_sens#10",
        "11": "pm.input_c_high_timeout#10",
        "12": "pm.inv_id_ovlap#10",
        "13": "pm.fan_fail_ovtemp#10",
        "14": "pm.sys_rate_sett#10"
      },
      "6704": {
        "0": "pm.input_pw_fail#10",
        "1": "pm.byp_fault#10",
        "2": "pm.input_fq_fail#10",
        "3": "pm.input_v_fail#10",
        "4": "pm.neg_batt_eod#10",
        "5": "pm.pos_batt_eod#10",
        "6": "pm.neg_batt_v_low#10",
        "7": "pm.pos_batt_v_low#10",
        "8": "pm.byp_scr_fault#10",
        "9": "pm.byp_v#10",
        "10": "pm.byp_phases_seq#10",
        "11": "pm.byp_fq_ovtrack#10",
        "12": "pm.aux_pw_supp_lost#10",
        "13": "pm.transf_tm_lm_onehr#10",
        "14": "pm.man_sdown#10",
        "15": "pm.inv_batt_man#10"
      },
      "6705": {
        "0": "pm.inv_batt_test#10",
        "1": "pm.outlet_delta_temp#10",
        "2": "pm.inv_scr_ovtemp#10",
        "3": "pm.no_input_scr_sensor#10",
        "4": "pm.man_on#10",
        "5": "pm.fan_expired#10",
        "6": "pm.capacitor_expired#10",
        "7": "pm.inlet_ovtemp#10",
        "8": "pm.outlet_ovtemp#10"
      }
    },
    "TWINRT20410": {
      "25": "desc.ups_work_mode",
      "399": {
        "0": "status.network_fail",
        "1": "status.batt_low",
        "2": "status.ups_in_bypass",
        "3": "status.ups_fail",
        "4": "status.epo_active",
        "5": "status.test_in_progress",
        "6": "status.shut_active",
        "7": "status.buffer_active"
      },
      "409": {
        "01": "alarm.bus_dc_soft_start_fail",
        "02": "alarm.bus_dc_high_volt_fail",
        "03": "alarm.bus_dc_low_volt_fail",
        "04": "alarm.bus_dc_unstable_volt",
        "05": "alarm.bus_dc_short_volt",
        "11": "alarm.inv_soft_start_fail",
        "12": "alarm.inv_high_volt_fail",
        "13": "alarm.inv_low_volt_fail",
        "14": "alarm.inv_short_volt",
        "1A": "alarm.inv_neg_power_fail",
        "21": "alarm.scr_batt_fail",
        "24": "alarm.inv_relay_short",
        "35": "alarm.lost_paralel_comm",
        "36": "alarm.curr_out_fail",
        "41": "alarm.ovtemp",
        "42": "alarm.comm_cpu_fail",
        "43": "alarm.ovload"
      },
      "410": {
        "0": "status.conv_dc_on",
        "1": "status.pfc_on",
        "2": "status.inv_on",
        "4": "status.in_relay_on",
        "5": "status.out_relay_on"
      },
      "432": {
        "0": "warning.bat_open",
        "1": "warning.ovload_batt",
        "2": "warning.bat_low",
        "3": "warning.ovload",
        "4": "warning.fan_lock_wrng",
        "5": "warning.epo_active",
        "6": "warning.ovtemp",
        "7": "warning.CHG_fail",
        "8": "warning.in_fuse",
        "9": "warning.cover_main_byp_open"
      }
    },
    "TWINPRO20410": {
      "25": "desc.ups_work_mode",
      "399": {
        "0": "status.network_fail",
        "1": "status.batt_low",
        "2": "status.ups_in_bypass",
        "3": "status.ups_fail",
        "4": "status.epo_active",
        "5": "status.test_in_progress",
        "6": "status.shut_active",
        "7": "status.buffer_active"
      },
      "404": {
          "01": "alarm.bus_dc_soft_start_fail",
          "02": "alarm.bus_dc_high_volt_fail",
          "03": "alarm.bus_dc_low_volt_fail",
          "04": "alarm.bus_dc_unstable_volt",
          "05": "alarm.bus_dc_short_volt",
          "11": "alarm.inv_soft_start_fail",
          "12": "alarm.inv_high_volt_fail",
          "13": "alarm.inv_low_volt_fail",
          "14": "alarm.inv_short_volt",
          "1A": "alarm.inv_neg_power_fail",
          "21": "alarm.scr_batt_fail",
          "24": "alarm.inv_relay_short",
          "35": "alarm.lost_paralel_comm",
          "36": "alarm.curr_out_fail",
          "41": "alarm.ovtemp",
          "42": "alarm.comm_cpu_fail",
          "43": "alarm.ovload"
      },
      "413": {
        "0": "status.conv_dc_on",
        "1": "status.pfc_on",
        "2": "status.inv_on",
        "4": "status.in_relay_on",
        "5": "status.out_relay_on"
      },
      "417": {
          "0": "warning.bat_open",
          "1": "warning.ovload_batt",
          "2": "warning.bat_low",
          "3": "warning.ovload",
          "4": "warning.fan_lock_wrng",
          "5": "warning.epo_active",
          "6": "warning.ovtemp",
          "7": "warning.CHG_fail",
          "8": "warning.in_fuse",
          "9": "warning.cover_main_byp_open"
      }
    },
    "TWINRT20003": {
      "25": "desc.ups_work_mode",
      "399": {
        "0": "status.network_fail",
        "1": "status.batt_low",
        "2": "status.ups_in_bypass",
        "3": "status.ups_fail",
        "4": "status.epo_active",
        "5": "status.test_in_progress",
        "6": "status.shut_active",
        "7": "status.buffer_active"
      },
      "409": {
          "01": "alarm.bus_dc_soft_start_fail",
          "02": "alarm.bus_dc_high_volt_fail",
          "03": "alarm.bus_dc_low_volt_fail",
          "11": "alarm.inv_soft_start_fail",
          "12": "alarm.inv_high_volt_fail",
          "13": "alarm.inv_low_volt_fail",
          "14": "alarm.inv_short_volt",
          "27": "alarm.batt_too_high",
          "28": "alarm.batt_too_low",
          "2A": "alarm.chg_out_short",
          "41": "alarm.ovtemp",
          "43": "alarm.ovload",
          "45": "alarm.charger_failure",
          "49": "alarm.ov_in_fault"
      },
      "410": {
        "0": "status.conv_dc_on",
        "1": "status.pfc_on",
        "2": "status.inv_on",
        "4": "status.in_relay_on",
        "5": "status.out_relay_on"
      },
      "432": {
          "0": "warning.bat_open",
          "1": "alarm.wiring_fail",
          "2": "warning.byp_unstable",
          "3": "warning.batt_over_charge",
          "4": "warning.bat_low",
          "5": "warning.ovload",
          "6": "warning.epo_active",
          "7": "warning.ovtemp",
          "8": "warning.CHG_fail",
          "9": "warning.r_in_ovcurr",
          "10": "warning.r_cutt_off",
          "11": "warning.bat_replace",
          "12": "warning.eeprom_err"
      }
    },
    "TWINPRO20003": {
      "25": "desc.ups_work_mode",
      "399": {
        "0": "status.utility_fail",
        "1": "status.batt_low",
        "2": "status.byp_boost_active",
        "3": "status.ups_fail",
        "4": "desc.test_inProgress",
        "5": "status.shut_active",
        "6": "flag.site_fault_det",
        "7": "alarm.eeprom_fail",
        "8": "status.test_batt_ok",
        "9": "status.test_batt_fail",
        "10": "status.bat_test_open",
        "11": "status.bat_test_unknown",
        "12": "status.ups_normal_mode",
        "13": "alarm.ups_overload",
        "14": "alarm.bat_open"
      },
      "403": {
        "21": "alarm.bus_ov_voltage",
        "22": "alarm.bus_un_voltage",
        "24": "alarm.bus_dc_short_volt",
        "25": "alarm.bus_soft_start_fail",
        "31": "alarm.chg_out_short",
        "32": "alarm.inv_voltage_high",
        "33": "alarm.inv_voltage_low",
        "34": "alarm.inv_ss_fault",
        "35": "alarm.inv_cap_open",
        "42": "alarm.inv_overload",
        "43": "alarm.byp_overload",
        "71": "alarm.epo_active",
        "81": "alarm.overtemp_heatsink",
        "87": "alarm.inv_heatsink_ntc_open",
        "1F": "alarm.batt_conn_reverse"
      },
      "404": {
        "2": "status.out_relay_on",
        "3": "status.in_relay_on",
        "5": "status.inv_on",
        "6": "status.pfc_on",
        "7": "status.dc_to_dc_on"
      }
    },
    "TWINPRO20820": {
      "25": "desc.ups_work_mode",
      "399": {
        "0": "status.network_fail",
        "1": "status.batt_low",
        "2": "status.ups_in_bypass",
        "3": "status.ups_fail",
        "4": "status.epo_active",
        "5": "status.test_in_progress",
        "6": "status.shut_active",
        "7": "status.buffer_active"
      },
      "404": {
          "01": "alarm.bus_dc_soft_start_fail",
          "02": "alarm.bus_dc_high_volt_fail",
          "03": "alarm.bus_dc_low_volt_fail",
          "04": "alarm.bus_dc_unstable_volt",
          "06": "alarm.bus_dc_short_volt",
          "11": "alarm.inv_soft_start_fail",
          "12": "alarm.inv_high_volt_fail",
          "13": "alarm.inv_low_volt_fail",
          "14": "alarm.inv_short_volt",
          "1A": "alarm.inv_neg_power_fail",
          "21": "alarm.scr_batt_fail",
          "24": "alarm.inv_relay_short",
          "29": "alarm.batt_fuse",
          "35": "alarm.lost_paralel_comm",
          "36": "alarm.curr_out_fail",
          "41": "alarm.ovtemp",
          "43": "alarm.ovload",
          "46": "alarm.model_fail"
      },
      "405": {
        "0": "status.conv_dc_on",
        "1": "status.pfc_on",
        "2": "status.inv_on",
        "4": "status.in_relay_on",
        "5": "status.out_relay_on"
      },
      "419": {
          "0": "warning.bat_open",
          "1": "warning.neutral_loss",
          "2": "alarm.line_ph_error",
          "3": "warning.ovload_batt",
          "4": "warning.bat_low",
          "5": "warning.ovload",
          "6": "warning.fan_lock_wrng",
          "7": "warning.epo_active",
          "8": "warning.ovtemp",
          "9": "warning.CHG_fail",
          "10": "warning.in_fuse",
          "11": "warning.diff_in_connection",
          "12": "warning.diff_byp_connection",
          "13": "warning.ovload_ups_blocked",
          "14": "alarm.input_c_unbalanced",
          "15": "alarm.batt_fuse"
      },
      "427": {
          "0": "alarm.inv_c_unbal",
          "1": "warning.cover_main_byp_open",
          "2": "warning.automatic_phase_fail",
          "3": "alarm.terna_unbal",
          "4": "warning.byp_unstable"
      }
    },
    "TWINPRO3": {
      "62": "desc.ups_work_mode",
      "1": {
        "0": "alarm.no_batt",
        "3": "alarm.charger_fault",
        "11": "alarm.end_batt_autonomy"
      },
      "2": {
        "5": "alarm.wiring_fail",
        "9": "alarm.in_freq_out_range",
        "13": "alarm.in_volt_out_range"
      },
      "3": {
        "7": "alarm.byp_freq_out_range",
        "9": "alarm.byp_device_fault", 
        "10": "alarm.byp_overload"
      },
      "4": {
        "0": "alarm.ov_prealarm", 
        "1": "alarm.ov_fault",
        "10": "alarm.output_short"
      },
      "5": {
        "0": "alarm.bat_low",
        "1": "alarm.epo",
        "2": "alarm.fan_fault",
        "3": "alarm.load_not_powered",
        "4": "alarm.ups_failed",
        "5": "alarm.ups_overload",
        "6": "alarm.overtemp_ups",
        "8": "alarm.batt_replace",
        "12": "alarm.group1_off",
        "13": "alarm.group2_off",
        "14": "alarm.normal_ac_loss",
        "15": "alarm.curr_fault"
      },
      "6": {
        "2": "alarm.chrg_v_high",
        "3": "alarm.chrg_v_low", 
        "4": "alarm.charger_fault",
        "9": "alarm.he_freq_loss", 
        "12": "alarm.inv_igbt_ovcurrent"
      },
      "7": {
        "0": "alarm.he_v_loss", 
        "3": "alarm.inv_failure",
        "6": "alarm.inv_v_out_margins_h",
        "7": "alarm.inv_v_out_margins_l", 
        "8": "alarm.busDc_v_err",
        "11": "alarm.rect_fault"
      }
    },
    "TWINRT3": {
      "62": "desc.ups_work_mode",
      "1": {
        "0": "alarm.no_batt",
        "3": "alarm.charger_fault",
        "11": "alarm.end_batt_autonomy"
      },
      "2": {
        "5": "alarm.wiring_fail",
        "9": "alarm.in_freq_out_range",
        "13": "alarm.in_volt_out_range"
      },
      "3": {
        "7": "alarm.byp_freq_out_range",
        "9": "alarm.byp_device_fault", 
        "10": "alarm.byp_overload"
      },
      "4": {
        "0": "alarm.ov_prealarm", 
        "1": "alarm.ov_fault",
        "10": "alarm.output_short"
      },
      "5": {
        "0": "alarm.bat_low",
        "1": "alarm.epo",
        "2": "alarm.fan_fault",
        "3": "alarm.load_not_powered",
        "4": "alarm.ups_failed",
        "5": "alarm.ups_overload",
        "6": "alarm.overtemp_ups",
        "8": "alarm.batt_replace",
        "12": "alarm.group1_off",
        "13": "alarm.group2_off",
        "14": "alarm.normal_ac_loss",
        "15": "alarm.curr_fault"
      },
      "6": {
        "2": "alarm.chrg_v_high",
        "3": "alarm.chrg_v_low", 
        "4": "alarm.charger_fault",
        "9": "alarm.he_freq_loss", 
        "12": "alarm.inv_igbt_ovcurrent"
      },
      "7": {
        "0": "alarm.he_v_loss", 
        "3": "alarm.inv_failure",
        "6": "alarm.inv_v_out_margins_h",
        "7": "alarm.inv_v_out_margins_l", 
        "8": "alarm.busDc_v_err",
        "11": "alarm.rect_fault"
      }
    }
  }

