import { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { FloatingLabel } from "flowbite-react";
import { CustomGoogleInputType } from "./CustomGoogleInputType";
import CustomIcon from "@/components/atoms/CustomIcon";
import { getFloatingLabelPositionTheme } from "@/utils/constants";


const libraries = ["places"];
const CustomGoogleInput = ({
  onChange = () => { },
  onPlaceChanged = () => { },
  defaultValue = "",
  disabled = false,
  required = false,
  readOnly = false,
  showText = false,
  helperText = "",
  className = "",
  type = "text",
  label = "",
  icon = "",
  danger,
  name,
}: CustomGoogleInputType) => {
  const [view, setView] = useState(false);
  const theme = getFloatingLabelPositionTheme(label);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    libraries: libraries as any,
  });

  const showPassword = () => {
    setView(!view);
  };

  return (
    <>
      {!isLoaded ? (
        <></>
      ) : (
        <>
          <div className={`relative w-full ${className}`}>
            <Autocomplete
              onLoad={(auto: google.maps.places.Autocomplete) =>
                setAutocomplete(auto)
              }
              onPlaceChanged={() => {
                if (autocomplete) {
                  const place = autocomplete.getPlace();
                  onPlaceChanged(place!);
                }
              }}
            >
              <FloatingLabel
                type={
                  type !== "password" && !showText
                    ? type
                    : view
                      ? "text"
                      : "password"
                }
                autoComplete={autocomplete ? "new-password" : ""}
                helperText={helperText ? helperText : undefined}
                label={required ? label + " *" : label}
                color={danger ? "error" : undefined}
                value={defaultValue}
                className={`${className}`}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                variant="standard"
                theme={theme}
                name={name}
              />
            </Autocomplete>
            {!showText ? (
              icon && (
                <CustomIcon
                  className="absolute right-1 top-2 text-xl text-black"
                  icon={icon}
                />
              )
            ) : (
              <div className="cursor-pointer" onClick={showPassword}>
                <CustomIcon
                  className="absolute right-1 top-2 text-xl text-black"
                  icon={view ? "la:eye-slash" : "la:eye"}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CustomGoogleInput;