import React from "react";
import LabeledText from "@/components/molecules/LabeledText";
import { t } from "i18next";
import { UserCollapsedType } from "./UserCollapsedType";
import { useNavigate } from "react-router-dom";

const UserCollapsed = ({ UserInfo }: UserCollapsedType) => {
  const navigate = useNavigate();
  const redirectToUserDetails = () => {
    navigate(`/users/${UserInfo.id}/userDetails`);
  };

  return (
    <div onClick={redirectToUserDetails} className="grid grid-cols-2 gap-2 lg:grid-cols-5 w-full pl-6">
      <LabeledText
        label={t("user.nameAndSurname")}
        text={UserInfo.name + ' ' + UserInfo.surname}
        className={"col-span-1"}
        ellipsis
      />
      <LabeledText
        label={t("user.email")}
        text={UserInfo.email}
        className={"col-span-2"}
      />
      <LabeledText
        label={t("user.rol")}
        text={UserInfo.roles}
        className={"col-span-1"}
      />
      <LabeledText
        label={t("user.companyName")}
        text={UserInfo.companyName}
        className={"col-span-1"}
        ellipsis
      />
    </div>
  );
};

export default UserCollapsed;