import React, { useContext, useRef, useState, ReactNode } from "react";
import CustomButton from "@/components/atoms/CustomButton";
import CustomText from "@/components/atoms/CustomText";
import CustomModal from "@/components/organisms/CustomModal";
import { ModalBody, ModalFooter } from "flowbite-react";
import { t } from "i18next";

type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

type ModalContextType = {
  showConfirmation: (
    title: string,
    message: string | JSX.Element
  ) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType | undefined>(undefined);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = ({ children }) => {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = useState<{
    title: string;
    message: string | JSX.Element;
  } | null>(null);
  const resolver = useRef<Function>();

  const handleShow = (
    title: string,
    message: string | JSX.Element
  ): Promise<boolean> => {
    setContent({
      title,
      message,
    });
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    if (resolver.current) {
      resolver.current(true);
    }
    onHide(); 
  };

  const handleCancel = () => {
    if (resolver.current) {
      resolver.current(false);
    }
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {children}

      {content && (
        <CustomModal
          isOpen={show} 
          onClose={onHide}
          title={content.title}
          body={
            <>
              <ModalBody className="px-10">
                <div className="grid grid-cols-1 gap-6 mb-4">
                  <CustomText>{content.message}</CustomText>
                </div>
              </ModalBody>
              <ModalFooter className="px-10">
                <div className="flex w-full justify-between">
                  <CustomButton
                    variant="secondary"
                    leftIconName="la:times"
                    onClick={handleCancel}
                  >
                    {t("general.cancel")}
                  </CustomButton>
                  <CustomButton
                    variant="primary"
                    onClick={handleOk}
                  >
                    {t("general.accept")}
                  </CustomButton>
                </div>
              </ModalFooter>
            </>
          }
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType => {
  const context = useContext(ConfirmationModalContext);
  if (context === undefined) {
    throw new Error("useConfirmationModalContext must be used within a ConfirmationModalContextProvider");
  }
  return context;
};

export { useModalShow, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
