import { t } from "i18next";
import { useEffect, useRef } from "react";

export const mqtt: string = "mqtt";
export let requiredServices: Array<string> = [
  "device-modbus",
  "device-serial",
  "device-mapper",
  "device-panel",
  "device-mqtt",
  "device-logger",
  "device-rccmd",
  "device-smtp",
];
export let serialDevices: Array<string> = [
  "CUBE40720",
  "TWINRT20003",
  "TWINRT20410",
  "TWINPRO20003",
  "TWINPRO20410",
  "TWINPRO20820",
];

export interface LogsData {
  logsDate: string;
  logsContent: string;
}

export interface ServiceData {
  id: string;
  deviceIotId: string;
  name: string;
  status: string;
  currentVersion: string;
  additionalInfo: string;
  firstRemoteUpdate: any;
  lastRemoteUpdate: any;
}

export const LogDisplay = ({ log }: { log: LogsData | undefined | null }) => {
  const logEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (logEndRef.current) {
      logEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [log]); 

  if (log && log.logsContent) {
    const formattedContent = log.logsContent
      .split("\n")
      .map((line, index) => <div key={index}>{line}</div>);

    return (
      <div className="flex flex-col-reverse font-mono bg-gray-800 text-white p-4 rounded max-h-[48rem] overflow-y-auto">
        {formattedContent}
        <div ref={logEndRef} />
      </div>
    );
  } else {
    return <div>{t('services.noLogsAvailable')}</div>;
  }
};