import { Link } from "react-router-dom";
import { GoBackHeaderType } from "./GoBackHeaderType";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import { t } from "i18next";
import CustomText from "@/components/atoms/CustomText";
import CustomLayout from "@/components/atoms/CustomLayout";

const GoBackHeader = ({
  goBackText = "",
  href = "",
  children,
  className = ''
}: GoBackHeaderType) => {
  return (
    <>
      <div className={`border-b-2 border-neutralGrey200 bg-neutralGrey100 flex ${className}}`}>
        <CustomLayout className="!py-6 flex justify-between items-center">
          <Link className="flex w-fit" to={href}>
            <div>
              <CustomIconButton variant="primary" icon="la:arrow-left" />
            </div>
            <div className="pt-3 pl-5">
              <CustomText
                className="text-blueGrey200"
                variant="secondary"
                size="sm"
              >
                {goBackText ? goBackText : t("general.back")}
              </CustomText>
            </div>
          </Link>
          {children}
        </CustomLayout>
      </div>
    </>
  );
};

export default GoBackHeader;