import { useState } from "react";
import CustomButton from "@/components/atoms/CustomButton";
import { ModalBody, ModalFooter } from "flowbite-react";
import { t } from "i18next";
import CustomInput from "@/components/molecules/CustomInput";
import CustomText from "@/components/atoms/CustomText";
import { DeleteModalType } from "./DeleteModaType";
import { isSaveDisabled } from "./d";

const DeleteModal = ({ onClose, title, button, deleteFunction }: DeleteModalType) => {
  const [formData, setFormData] = useState<any>({ password: "" });
  const [errorField, setErrorField] = useState(false);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData({ password: value });
  };

  const validationDelete = (formData: any) => {
    const confirmWord = formData.password.toLowerCase();
    if (confirmWord === t("user.formUser.deleteModal.confirmation")) {
      deleteFunction()
    } else {
      setErrorField(true);
    }
  }

  return (
    <>
      <ModalBody className="px-10">
        <div className="grid grid-cols-1 gap-6 mb-4">
          <CustomText variant="secondary" size="base">
            {title}
          </CustomText>
          <CustomInput
            label={t("device.deleteModal.confirmationTextLabel")}
            name={"password"}
            onChange={handlePasswordChange}
            helperText={errorField ? t("device.deleteModal.error.fieldNotMatch") : ''}
            danger={errorField}
            className="col-span-1"
            required
          />
        </div>
      </ModalBody>
      <ModalFooter className="px-10">
        <div className="flex w-full justify-between">
          <CustomButton
            variant="secondary"
            leftIconName="la:times"
            onClick={() => onClose()}
            children={t("general.cancel")}
          />
          <CustomButton
            variant="primary"
            leftIconName="la:save"
            onClick={() => validationDelete(formData)}
            children={button}
            disabled={isSaveDisabled(formData)}
          />
        </div>
      </ModalFooter>
    </>
  );
};

export default DeleteModal;