import { ApexChartSeries, unitMeasureSensorArray } from "@/pages/Device/Measures/d";
import { getChartColors} from "@/utils/constants";
import useDeviceType from "@/utils/hooks/useDevicResponsiveType";
import { ApexOptions } from "apexcharts";
import { t } from "i18next";
import { useEffect, useState } from "react";


export const PowerCurvedChartFormComponent = ({
  measureData,
  collapsed,
  expanded,
  loading,
}: {
  measureData: ApexChartSeries[];
  collapsed: any;
  expanded: any;
  loading: any;
}) => {
  const [chartSeries, setChartSeries] = useState<ApexChartSeries[]>([]);
  const [chartNameSeries, setChartNameSeries] = useState<any[]>([]);
  const [heightScreen, setHeightScreen] = useState<number | string>("200px");
  const { isLargeDesktop, isTablet } = useDeviceType();

   const getUnitByDescription = (descriptionSearch: string , params:boolean): string => {
    for (const series in unitMeasureSensorArray) {
      const seriesData = unitMeasureSensorArray[series];
      for (const sensor of seriesData) {
        const descriptions = sensor.description.split(', ');
        if (descriptions.includes(descriptionSearch)) {
          return params ? t(sensor.type)+" ("+sensor.unit+")":sensor.unit;
        }
      }
    }
    return 'V';
  };

  useEffect(() => {
    setUpChart(measureData);
    calculateHeight();
  }, [measureData, expanded]);

  const setUpChart = (measures: any[]) => {
    const newSeries = measures.map((item: any, index: number) => ({
      name: t(`alarm.${item.name}`),
      color:
        index === 0
          ? getChartColors().darkYellow
          : index === 1
          ? getChartColors().darkOrange
          : index === 2
          ? getChartColors().orange
          : getChartColors().darkGreen,
      data: item.data.map((d: { x: number; y: number }) => ({
        x: new Date(d.x).getTime(),
        y: d.y,
      })),
    }));

    const newNameSeries = measures.map((item: any, index: number) => ({
      name: t(`alarm.${item.name}`),
      unit: getUnitByDescription(item.name,false),
    }));

    setChartNameSeries(newNameSeries);
    setChartSeries(newSeries);
  };

  const calculateHeight = () => {
    if (expanded) {
      setHeightScreen(isLargeDesktop || isTablet ? "100%" : "80%");
    } else {
      setHeightScreen("200px");
    }
  };

  const generateTimeIntervals = (star: any, end: Number, step: any) => {
    let intervals = [];
    for (let time = star; time <= end; time += step) {
      intervals.push(time);
    }
    return intervals;
  };

  const startTimestamp = Math.min(
    ...chartSeries.flatMap((item) =>
      item.data.map((d) => new Date(d.x).getTime())
    )
  );
  const endTimestamp = Math.max(
    ...chartSeries.flatMap((item) =>
      item.data.map((d) => new Date(d.x).getTime())
    )
  );
  const step = 30 * 60 * 1000;
  const timeIntervals = generateTimeIntervals(
    startTimestamp,
    endTimestamp,
    step
  );

  const chartOptions: ApexOptions = {
    fill: {
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
   
    chart: {
      height: heightScreen,
      width: "90%",
      type: "line",

      toolbar: {
        show: !collapsed,
      },
      zoom: {
        enabled: true,
            type: 'x',  
            autoScaleYaxis: true, 
      },
      events: {
        mounted: (chart: ApexCharts) => {
          chart.updateOptions({
            yaxis: {
              title: {
                text: getUnitByDescription(measureData[0].name, true),
              },
            },
          });
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      axisTicks: {
        show: !collapsed,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        show: !collapsed,
        formatter: function (val: string | number | Date) {
          const date = new Date(val);
          return `${date.getDate().toString().padStart(2, "0")}/${(
            date.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}/${date.getFullYear()} ${date
            .getHours()
            .toString()
            .padStart(2, "0")}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
        },
      },
      min: startTimestamp,
      max: endTimestamp,
    },
    yaxis: [
      {
        title: {
          text: getUnitByDescription(measureData[0].name, true),
         
        },
        labels: {
          formatter: (value: number) => value.toFixed(1),
        },
        show: !collapsed,
      },
    ],
    tooltip: {
      custom: ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: any[];
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) => {
        let seriesToShow = [];
        for (let s of w.config.series) {
          if (s.data.length > 0) {
            seriesToShow.push(s.name);
          }
        }

        let tooltipHtml = "";

        for (let i of seriesToShow) {
          let currentIndex = -1;
          let index = 0;
          for (let s of w.globals.initialSeries) {
            if (s.name === i) {
              currentIndex = index;
              break;
            }
            index++;
          }

          if (currentIndex === -1) continue;

          let info = w.globals.initialSeries[currentIndex];
          let dataValue =
            w.globals.initialSeries[currentIndex].data[dataPointIndex];

          let valueToShow = "N/A";

          if (dataValue && dataValue.y !== undefined) {
            valueToShow = dataValue.y.toString();
          }

          let formattedDate = "";
          if (dataValue && dataValue.x) {
            const date = new Date(dataValue.x);
            formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
              date.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}/${date.getFullYear()} ${date
              .getHours()
              .toString()
              .padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${date.getSeconds()}`;
          }

          if (i === seriesToShow[0]) {
            tooltipHtml =
              '<div style="border-radius: 5px; display: inline-block; border: 1px solid #E4E4E4;">' +
              '<div style="background: #D7D7D7; border-top-left-radius: 5px; border-top-right-radius: 5px;">' +
              '<p style="margin-bottom: 0px; padding: 3px 3px 3px 10px; font-size: 12px; font-weight: 400; font-family: ManropeMedium, Helvetica, Arial, sans-serif;">' +
              formattedDate +
              " h" +
              "</p>" +
              "</div>";
          }

          tooltipHtml +=
            '<div style="margin: 10px 0px">' +
              '<span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="margin-left: 10px; background:' +
              info.color +
              "; color:" +
              info.color +
              '; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 2px;"></span>' +
              '<span class="apexcharts-legend-text" rel="1" i="0" data:default-text="serie1" data:collapsed="false" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; margin: 0px 10px 0px 5px; color: rgb(55, 61, 63); font-size: 12px; font-weight: 400; font-family: ManropeMedium, Helvetica, Arial, sans-serif;">' +
              info.name +
              ": <b>" +
              valueToShow +
              "</b> " +
              chartNameSeries[seriesIndex]?.unit || "" + "</span>" + "</div>";

          if (i == seriesToShow[seriesToShow.length - 1]) {
            tooltipHtml += "</div>";
          }
        }

        return tooltipHtml;
      },
    },
    legend: {
      fontFamily: "ManropeRegular",
      fontSize: "14px",
      itemMargin: {
        horizontal: 40,
      },
      showForSingleSeries: true,
    },
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: !collapsed,
        },
      },
      yaxis: {
        lines: {
          show: !collapsed,
        },
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 1000,
    //     options: {
    //       plotOptions: {
    //         line: {
    //           horizontal: false,
    //         },
    //       },
    //       legend: {
    //         position: "bottom",
    //       },
    //     },
    //   },
    // ],
  };

  return {
    chartSeries,
    chartOptions,
    heightScreen,
  };
};
