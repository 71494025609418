import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { Icon } from "@iconify/react";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDateRounded.scss";
import { CustomDateRoundedType, DateTimeFormatOptions, } from "./CustomDateRoundedType";

const CustomDateRounded = ({
  maxDayRange = 0,
  onChange,
  minDate,
  maxDate,
  className = "",
  range,
  name,
}: CustomDateRoundedType) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const actualDate = new Date();
  const lastDate = new Date();
  const unixTime = lastDate.setDate(lastDate.getDate() - 1);
  const FirstDate = new Date(unixTime);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [minimumDate, setMinimumData] = useState(minDate);
  const [maximumDate, setMaximumDate] = useState(maxDate);
  const [startDate, setStartDate] = useState<Date>(FirstDate);
  const [endDate, setEndDate] = useState<Date>(actualDate);

  const minMaxDateSetter = (start: Date) => {
    const end = new Date(start);
    end.setMonth(end.getMonth() + maxDayRange);
    setMaximumDate(end);
  };

  const onRangeDayChange = (e: any) => {
    const [start, end]: [Date, Date] = e;
    maxDayRange > 0 && minMaxDateSetter(start);
    setStartDate(start);
    setEndDate(end);
    triggerChangeEventFormated(start, end);
  };

  const onDayChange = (e: any) => {
    const start = e;
    const end = new Date(e);
    maxDayRange > 0 && minMaxDateSetter(start);
    setStartDate(start);
    triggerChangeEventFormated(start, end);
  };

  const triggerChangeEventFormated = (start: Date, end: Date) => {
    if (end && end !== null && end !== undefined) {
      const firstDay = new Date(start);
      const lastDay = new Date(end);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      } as DateTimeFormatOptions;

      firstDay.setHours(0, 0, 0, 0);
      lastDay.setHours(23, 59, 59, 999);

      onChange({
        start: firstDay.toLocaleDateString("en-US", options),
        end: lastDay.toLocaleDateString("en-US", options),
      });
    }
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  const onFocus = (e: any) => {
    e.target.setAttribute("readOnly", true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPickerOpen]);

  return (
    <div
      className={`day-input-rounded z-10 relative bg-transparent ${className}`}
    >
      <Icon
        className="day-input-rounded__icon absolute z-30 right-0 top-0 mt-3 mr-4 hover:drop-shadow text-gray-600"
        icon={"octicon:calendar-24"}
        fontSize="1.5rem"
      />
      {range ? (
        <DatePicker
          className="font-manropeMedium w-fit text-gray-600 border-2 border-gray-300 bg-white h-12 px-5 pr-14 rounded-full text-sm focus:outline-none focus:ring-salicru focus:border-salicru focus:shadow-md blur-none !autofill:bg-transparent"
          onChange={onRangeDayChange}
          dateFormat="dd/MM/yyyy"
          startDate={startDate}
          onKeyDown={onKeyDown}
          minDate={minimumDate}
          maxDate={maximumDate}
          selected={startDate}
          onFocus={onFocus}
          endDate={endDate}
          selectsRange
          name={name}
        />
      ) : (
        <DatePicker
          className="font-manropeMedium text-gray-600 border-2 border-gray-300 bg-white h-12 px-5 pr-14 ml-32 rounded-full text-sm focus:outline-none focus:ring-salicru focus:border-salicru focus:shadow-md blur-none !autofill:bg-transparent"
          onChange={onDayChange}
          dateFormat="dd/MM/yyyy"
          startDate={startDate}
          onKeyDown={onKeyDown}
          minDate={minimumDate}
          maxDate={maximumDate}
          selected={startDate}
          onFocus={onFocus}
          endDate={endDate}
          name={name}
        />
      )}
    </div>
  );
};

export default CustomDateRounded;