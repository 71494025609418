import { useEffect, useState } from "react";
import { t } from "i18next";
import { ModalBody, ModalFooter } from "flowbite-react";
import CustomButton from "@/components/atoms/CustomButton";
import { UserFormComponent } from "./d";
import { CreateUser } from "@/utils/models";
import {
  accountRole,
  getFindSelectModel,
  handleFormChange,
  setCountryCodeMap,
} from "@/utils/constants";
import CustomInput from "@/components/molecules/CustomInput";
import CustomSelect from "@/components/molecules/CustomSelect";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import { ModalUserFormType } from "./ModaUserFormType";

const ModalUserForm = ({ onClose, isEdit, user }: ModalUserFormType) => {
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [professional, setProfessional] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateUser>({
    id: "",
    name: "",
    surname: "",
    password: "",
    confirmPassword: "",
    email: "",
    nationalPhone: "",
    countryCode: "",
    roles: "",
    professional: false,
    companyName: "",
    country: "",
    commercialOk: false,
  });
  const {
    errors,
    initializeEditForm,
    setAccountUseOption,
    isSaveDisabled,
    setAccountRoleOption,
    doUpdate,
    doSave,
  } = UserFormComponent({ isEditing: isEdit, user });

  useEffect(() => {
    if (isEdit && user) {
      const editFormData = initializeEditForm(user, isEdit);
      setFormData(editFormData);
    } else {
      setFormData(formData);
    }
  }, [isEdit, user]);

  useEffect(() => {
    setSaveDisabled(isSaveDisabled(formData));
  }, [formData]);

  return (
    <>
      <ModalBody className="px-10">
        <form>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10 py-2">
            <CustomInput
              label={t("user.formUser.name")}
              name={"name"}
              onChange={(e) => handleFormChange(e, setFormData, formData)}
              value={formData?.name}
              helperText={errors.name ? errors.name : ""}
              danger={errors.name ? true : false}
              className="col-span-1"
              required
            />
            <CustomInput
              label={t("user.formUser.surname")}
              name={"surname"}
              onChange={(e) => handleFormChange(e, setFormData, formData)}
              value={formData?.surname}
              helperText={errors.surname ? errors.surname : ""}
              danger={errors.surname ? true : false}
              className="col-span-1"
              required
            />
            <div className="grid grid-cols-4 gap-2 col-span-2 my-2">
              <CustomSelect
                hasSearch={true}
                label={t("user.formUser.countryCode")}
                name={"countryCode"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                options={setCountryCodeMap()}
                defaultValue={getFindSelectModel(
                  setCountryCodeMap(),
                  formData.countryCode || ""
                )}
                helperText={errors.countryCode ? errors.countryCode : ""}
                danger={errors.countryCode ? true : false}
                className="col-span-1"
                required
              />
              <CustomInput
                label={t("user.formUser.phone")}
                name={"nationalPhone"}
                type="number"
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                danger={errors.nationalPhone ? true : false}
                value={formData?.nationalPhone}
                helperText={errors.nationalPhone ? errors.nationalPhone : ""}
                className="col-span-3"
                required
              />
            </div>
            <CustomInput
              label={t("user.formUser.email")}
              name={"email"}
              onChange={(e) => handleFormChange(e, setFormData, formData)}
              value={formData?.email}
              helperText={errors.email ? errors.email : ""}
              danger={errors.email ? true : false}
              disabled={isEdit ? true : false}
              className="col-span-1"
              required
            />
            <CustomSelect
              label={t("user.formUser.accountUsage.field")}
              name={"professional"}
              onChange={(e) => {
                handleFormChange(e, setFormData, formData)
                setProfessional(!professional);
              }}
              options={setAccountUseOption()}
              defaultValue={getFindSelectModel(
                setAccountUseOption(),
                formData.professional ? "professional" : "personal"
              )}
              helperText={errors.professional ? errors.professional : ""}
              danger={errors.professional ? true : false}
              className="col-span-1"
              required
            />

            {/* Password and ConfirmPassword */}
            {!isEdit && (
              <>
                <div className="flex gap-2  col-span-2 my-2">
                  <CustomInput
                    label={t("user.formUser.password")}
                    type="password"
                    name={"password"}
                    onChange={(e) => handleFormChange(e, setFormData, formData)}
                    value={formData?.password}
                    helperText={errors.password ? errors.password : ""}
                    danger={errors.password ? true : false}
                    className="col-span-1 mb-4"
                    showText
                    required
                  />
                  <CustomInput
                    label={t("user.formUser.confirmPassword")}
                    type="password"
                    name={"confirmPassword"}
                    onChange={(e) => handleFormChange(e, setFormData, formData)}
                    value={formData?.confirmPassword}
                    helperText={
                      errors.confirmPassword ? errors.confirmPassword : ""
                    }
                    danger={errors.confirmPassword ? true : false}
                    className="col-span-1"
                    showText
                    required
                  />
                </div>
              </>
            )}

            <div className="flex justify-between gap-7 col-span-2 my-2">
              {professional && (
                <CustomInput
                  label={t("user.formUser.company")}
                  name={"companyName"}
                  onChange={(e) => handleFormChange(e, setFormData, formData)}
                  value={formData?.companyName}
                  danger={errors.companyName ? true : false}
                  className="col-span-1"
                  disabled={formData.professional ? false : true}
                  required
                />
              )}

              <CustomCheckbox
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    commercialOk: e.target.checked,
                  });
                }}
                label={t("user.formUser.sendCommercialInformation")}
                name={"commercialOk"}
                checked={formData.commercialOk}
                className="col-span-1"
              />
            </div>

            <div className="col-span-1 my-2">
              <CustomSelect
                label={t("user.formUser.role.role")}
                name={"roles"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                options={setAccountRoleOption()}
                defaultValue={isEdit ?
                  getFindSelectModel(
                  setAccountRoleOption(),
                  formData.roles)
                  :
                  getFindSelectModel(
                  setAccountRoleOption(),
                  formData.roles === "-" ? "-" : accountRole.User
                )}
                helperText={errors.roles ? errors.roles : ""}
                danger={errors.roles ? true : false}
                className="col-span-1"
                required
              />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="px-10">
        <div className="flex w-full justify-between">
          <CustomButton
            variant="secondary"
            leftIconName="la:times"
            onClick={() => onClose()}
            children={t("device.form.buttonCancel")}
          />
          <CustomButton
            variant="primary"
            leftIconName="la:save"
            onClick={() =>
              isEdit
                ? doUpdate(formData, onClose)
                : doSave(formData, onClose, false)
            }
            children={isEdit
              ? t("user.formUser.editUser")
              : t("user.formUser.saveUser")}
            disabled={!isEdit && saveDisabled}
          />
        </div>
      </ModalFooter>
    </>
  );
};

export default ModalUserForm;