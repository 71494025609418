import React from "react";
import CustomLeftPanel from "@/components/organisms/CustomLeftPanel";
import CustomLeftMenu from "@/components/organisms/CustomLeftMenu";
import GoBackHeader from "@/components/molecules/CustomGoBackHeader";
import { DeviceProvider } from "@/utils/context/deviceContext";
import { useParams } from "react-router-dom";
import ConfirmationModalContextProvider from "@/utils/context/ConfirmationModalContextProvider";
import useDeviceType from "@/utils/hooks/useDevicResponsiveType";
import { t } from "i18next";

const Device: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  const { id } = useParams<{ id: string }>();
  const { isDesktop } = useDeviceType();

  return (
    <DeviceProvider deviceId={id ? id : ""}>
      <ConfirmationModalContextProvider>
        <div className={`${!isDesktop ? "flex flex-col w-full h-full" :"flex h-full w-full"}`}>
          <CustomLeftPanel
            title={t("device.detail.deviceDetail")}
            className={`${!isDesktop ? "hidden" : ""}}`}
            isMobile={isDesktop ? false : true}
          >
            <CustomLeftMenu isMobile={isDesktop ? false : true} />
          </CustomLeftPanel>
          <div className="w-full bg-neutralGrey100">
            {isDesktop && <GoBackHeader goBackText={t("device.detail.backToList")} href="/" />}
            <div>
              <>{element}</>
            </div>
          </div>
        </div>
      </ConfirmationModalContextProvider>
    </DeviceProvider>
  );
};

export default Device;
