import React, { useEffect, useRef, useState } from "react";
import CustomImage from "@/components/atoms/CustomImage";
import Cookies from "js-cookie";
import CA from "@/assets/images/CA.svg";
import ES from "@/assets/images/ES.svg";
import EN from "@/assets/images/EN.svg";
import useAuth from "@/utils/hooks/useAuth";
import i18n from "@/i18n";

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { setTokenLanguage, getTokenLanguage } = useAuth();

  const language = getTokenLanguage();

  const setLanguage = (lang: string) => {
    setTokenLanguage(lang);
    i18n.changeLanguage(lang);
    Cookies.set("SAL-nimbus-language", lang); // Set the language cookie
  };
  const getImage = (lang: string) => {
    return lang === "es" ? ES : lang === "ca" ? CA : EN;
  };

  const langList = [
    {
      lang: "es",
      path: ES,
    },
    {
      lang: "ca",
      path: CA,
    },
    {
      lang: "en",
      path: EN,
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="language-selector mt-auto w-[56px] flex justify-center items-center"
      ref={ref}
    >
      <button
        className="transform transition duration-300 hover:scale-110 opacity-100"
        onClick={() => setIsOpen(!isOpen)}
      >
        <CustomImage src={getImage(language)} alt={language} />
      </button>
      {isOpen && (
        <div className="flex absolute z-10 gap-3 justify-between bg-gray-300 py-2.5 px-3.5 rounded-2xl left-0.5 bottom-9">
          {langList.map(
            (el, i) =>
              el.lang !== language && (
                <button
                  className="transform transition duration-300 hover:scale-110 w-[25px] opacity-75 hover:opacity-100"
                  onClick={() => setLanguage(el.lang)}
                  key={i}
                >
                  <CustomImage src={el.path} alt={el.lang} />
                </button>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
