import CustomLayout from "@/components/atoms/CustomLayout";
import LabeledText from "@/components/molecules/LabeledText/LabeledText";
import CustomCard from "@/components/organisms/CustomCard/CustomCard";
import CustomModal from "@/components/organisms/CustomModal/CustomModal";
import DeviceHeader from "@/components/organisms/DeviceHeader/DeviceHeader";
import ModalCreateDevices from "@/components/organisms/DeviceModalForm/DeviceModalForm";
import { userService } from "@/services/user.service";
import { unixTimeToDate } from "@/utils/constants/consts";
import { useDeviceContext } from "@/utils/context/deviceContext";
import { IUser } from "@/utils/models";
import { t } from "i18next";
import { useEffect, useState } from "react";

interface UserAssociate {
  id?: String;
  email?: String;
}

const DeviceInfo: React.FC = () => {
  const [searchExpanded, setSearchExpanded] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [userList, setUserList] = useState<UserAssociate[] | null>(null);
  const [loading, setLoading] = useState(true);

  const { deviceInfo } = useDeviceContext();

  const [showCreateModal, setShowCreateModal] = useState(false);

  function onHeaderSearch(searchText: string) {
    setSearchExpanded(false);
    onSearch(searchText);
  }

  function onSearch(searchText: string) {
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText !== keyword) {
      setKeyword(trimmedSearchText);
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (deviceInfo?.users) {
          const userPromises = deviceInfo.users.map((user: any) =>
            userService.getUserById(user)
          );
          const responses = await Promise.all(userPromises);
          const newUserList = responses.map((response: IUser) => ({
            id: response.id,
            email: response.email,
          }));
          setUserList(newUserList);
        } else {
          setUserList([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [deviceInfo?.users]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (deviceInfo?.users) {
          const userPromises = deviceInfo.users.map((user: any) =>
            userService.getUserById(user)
          );
          const responses = await Promise.all(userPromises);
          const newUserList = responses.map((response: IUser) => ({
            id: response.id,
            email: response.email,
          }));
          setUserList(newUserList);
        } else {
          setUserList([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [deviceInfo?.users]);

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  return (
    <>
      <CustomLayout className="relative">
        <DeviceHeader
          title={t("device.detail.deviceInfo")}
          onClickButton={() => setShowCreateModal(true)}
          buttonIcon="la:edit"
          buttonTitle="general.edit"
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <CustomCard
            padding="lg"
            expandable={false}
            title={t("device.generalData.generalData")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                <LabeledText
                  label={t("device.generalData.serialNumber")}
                  text={deviceInfo?.serialNumber || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.model")}
                  text={deviceInfo?.type || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.maintenanceContract")}
                  text={t(`device.detail.contract.${deviceInfo?.maintenanceContract?.toLowerCase()}`) || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.company")}
                  text={deviceInfo?.company || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.description")}
                  text={deviceInfo?.description || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.lastMeasurementDate")}
                  text={unixTimeToDate(deviceInfo?.lastPing) || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.lastUpdateDate")}
                  text={unixTimeToDate(deviceInfo?.updatedAt) || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.upsFirmware")}
                  text={deviceInfo?.UPSrelease || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.iotDeviceFirmware")}
                  text={deviceInfo?.release || "-"}
                  className={"col-span-2"}
                />
              </div>
            </>
          </CustomCard>
          <CustomCard
            padding="lg"
            expandable={false}
            title={t("device.generalData.location.location")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
                <LabeledText
                  label={t("device.form.locationAddress")}
                  text={deviceInfo?.address || "-"}
                  className={"col-span-2"}
                />
                <LabeledText
                  label={t("device.generalData.location.localIP")}
                  text={deviceInfo?.localIp || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.location.remoteIP")}
                  text={deviceInfo?.remoteIp || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.location.macAddress")}
                  text={deviceInfo?.mac || "-"}
                  className={"col-span-2"}
                />
              </div>
            </>
          </CustomCard>
        </div>
        <div className="my-5">
          <CustomCard
            padding="lg"
            expandable={false}
            title={t("device.generalData.location.associatedUsers")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-1 gap-6 mt-6 text-base font-manropeBold">
                {userList &&
                  userList.length > 0 &&
                  userList.map((user: UserAssociate, index: any) => (
                    <div key={index + user.id}>{user.email || "-"}</div>
                  ))}
              </div>
            </>
          </CustomCard>
        </div>
        <CustomModal
          isOpen={showCreateModal}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={onCloseCreateModal}
          title={t("device.form.titleEdit")}
          body={
            <ModalCreateDevices
              onClose={onCloseCreateModal}
              isEdit={true}
              device={deviceInfo}
            />
          }
        />
      </CustomLayout>
    </>
  );
};
export default DeviceInfo;
