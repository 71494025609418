import deviceService from "@/services/device.service";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { Device } from "../models";
import { DeviceContextType } from "@/pages/Device/Measures/d";

const DeviceContext = createContext<DeviceContextType>({ deviceId: undefined });
export const useDeviceContext = () => useContext(DeviceContext);

export const DeviceProvider: React.FC<{
  children: ReactNode;
  deviceId: string;
}> = ({ children, deviceId }) => {
  const navigate = useNavigate();
  const [deviceInfo, setDeviceInfo] = useState<Device | null>(null);
  const [autoRefreshInterval] = useState<number | null>(300000);
  const fetchDeviceInfo = useCallback(async () => {
    if (deviceId) {
      deviceService
        .getDeviceById(deviceId)
        .then((res: Device) => {
          setDeviceInfo(res);
        })
        .catch((err) => {
          console.error("Error obtaining data:", err);
        });
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceId) {
      fetchDeviceInfo();
      let intervalId: NodeJS.Timeout;
      if (autoRefreshInterval) {
        intervalId = setInterval(() => {
          fetchDeviceInfo();
        }, autoRefreshInterval);
      }
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    } else {
      navigate("/");
    }
  }, [deviceId, fetchDeviceInfo, autoRefreshInterval, navigate]);

  return (
    <DeviceContext.Provider
      value={{ deviceId, deviceInfo, updateDeviceInfo: fetchDeviceInfo }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
