import { removeLocalStorage } from "@/utils/constants";
import { ApiFetchDataType, ApiRequestOptions } from "@/utils/models/Auth";
import { redirect } from "react-router-dom";

export const apiService = {
  fetchData: async function ({ url, options }: ApiFetchDataType): Promise<any> {
    const { method, body, token, returnError } = options;

    const headers: HeadersInit = {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    try {
      const response = await fetch(url, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      if (!response.ok) {
        const errorData = await this.parseErrorResponse(response);
        if (!returnError && response.status === 401) {
          removeLocalStorage();
          return;
        } else {
          throw errorData;
        }
      }

      if (response.status === 204) {
        return { message: "success" };
      }

      return await response.json();
    } catch (error) {
      this.handleFetchError(error);
      throw error;
    }
  },

  parseErrorResponse: async function (response: Response): Promise<any> {
    try {
      return await response.json();
    } catch (e) {
      return {
        status: response.status,
        statusText: response.statusText,
        message: `Error ${response.status}: ${response.statusText}`,
      };
    }
  },

  handleFetchError: function (error: any): void {
    if (error.status === 401) {
      removeLocalStorage();
      redirect("/login");
    } else if (error.status === 403) {
      throw error;
    } else {
      throw error;
    }
  },

  objToQueryString: function (obj: any): string {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
      );
    }
    return keyValuePairs.join("&");
  },

  get: async function (url: string, token?: string, body?: any) {
    if (body) {
      url += "?filter=" + body;
    }

    const options: ApiRequestOptions = {
      method: "GET",
      token: token,
    };
    return await this.fetchData({ url, options });
  },

  post: async function (
    url: string,
    body: any,
    token?: string,
    returnError?: boolean
  ) {
    const options: ApiRequestOptions = {
      method: "POST",
      token: token,
      body: body,
      returnError,
    };
    return await this.fetchData({ url, options });
  },

  put: async function (url: string, body: any, token?: string) {
    const options: ApiRequestOptions = {
      method: "PUT",
      token: token,
      body: body,
    };
    return await this.fetchData({ url, options });
  },

  patch: async function (url: string, body: any, token: string) {
    const options: ApiRequestOptions = {
      method: "PATCH",
      token: token,
      body: body,
    };
    return await this.fetchData({ url, options });
  },

  delete: async function (url: string, token: string) {
    const options: ApiRequestOptions = {
      method: "DELETE",
      token: token,
    };
    return await this.fetchData({ url, options });
  },
};
