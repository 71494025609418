import { IListCard } from "@/utils/models/interface/IListCard";

export function useRender<T>(
  items: T[],
  toggleItem: (itemId: number) => void,
  itemState: (itemId: number) => boolean,
  cardGenerator: (props: IListCard<T>) => React.ReactElement,
  identifier: keyof T,
  additionalProps?: Record<string, any>
): React.ReactElement[] {
  const rendererCardItems = items.map((item: any, index: number) => {
    return cardGenerator({
      key: index,
      model: item,
      isExpanded: itemState(item[identifier]),
      toggleExpansion: () => toggleItem(item[identifier]),
      updateList: () => { },
      ...(additionalProps && { additionalProps: additionalProps }),
      dropDown: [],
      collapsedChildren: item,
      expandedChildren: item
    })
  });

  return rendererCardItems;
}