import { Dispatch, SetStateAction } from "react";
import { userService } from "@/services/user.service";
import {
  accountUse,
  countryCodes,
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "@/utils/constants";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import { t } from "i18next";
import { EditUser } from "@/utils/models";
import { CustomOptionType } from "@/components/molecules/CustomSelect";
import { UserFormComponent } from "@/components/organisms/ModaUserForm/d";
import { format } from "path";

export const langOptions = [
  {
    value: "es",
    label: "Español",
    selected: false,
  },
  {
    value: "ca",
    label: "Català",
    selected: false,
  },
  {
    value: "en",
    label: "English",
    selected: false,
  },
];

export const changePasswordForm = [
  {
    name: "oldPassword",
    label: "profile.pwd.current",
  },
  {
    name: "newPassword",
    label: "profile.pwd.new",
  },
  {
    name: "confirmPassword",
    label: "profile.pwd.confirm",
  },
];

export const generalInfo = [
  {
    className: "py-4",
    text: "account.name",
    name: "name",
    type: "text",
  },
  {
    className: "py-4",
    text: "account.surname",
    name: "surname",
    type: "text",
  },
  {
    className: "py-4 md:col-span-2",
    text: "account.email",
    name: "email",
    type: "text",
  },
  {
    className: "py-4",
    text: "account.country",
    name: "countryCode",
    type: "text",
  },
  {
    className: "py-4",
    text: "account.phone",
    name: "nationalPhone",
    type: "text",
  },
  {
    className: "py-4",
    text: "account.professional",
    name: "professional",
    type: "checkbox",
  },
  {
    className: "py-4",
    text: "account.commercial",
    name: "commercialOk",
    type: "checkbox",
  },
  {
    className: "py-4",
    text: "account.company_name",
    name: "companyName",
    type: "text",
  },
];

export const getLanguageName = (lang: string) => {
  return lang === "en" ? "English" : lang === "es" ? "Español" : "Català";
};

export function setLangOptionsMap(
  valueToCompare?: string
): CustomOptionType | CustomOptionType[] {
  if (valueToCompare) {
    const option = langOptions.find((el) => el.value === valueToCompare);
    if (option) {
      return {
        value: option.value,
        label: `${option.label}`,
      };
    }
    return { value: "", label: "" };
  } else {
    return langOptions.map((el) => ({
      value: el.value,
      label: `${el.label}`,
    }));
  }
}
export const changePasswordHandler = (
  d: any,
  handleSaveProfile: () => void
) => {
  const changePassword = {
    oldPassword: d.oldPassword,
    newPassword: d.newPassword,
    confirmPassword: d.confirmPassword,
  };

  if (changePassword.newPassword === changePassword.confirmPassword) {
    if (!changePassword.oldPassword || !changePassword.newPassword) {
      toast.error(t("profile.toastMessage.empty"));
      return;
    }
    delete changePassword.confirmPassword;

    userService
      .changeUserPassword(changePassword)
      .then((res) => {
        toast.success(t("profile.toastMessage.successPassword"));
        handleSaveProfile();
      })
      .catch((err) => {
        const errorResponse = err?.error || {};
        const { statusCode, name, message } = errorResponse;
        if (statusCode === 403 || message === "error.invalid.oldPassword") {
          toast.error(t("profile.toastMessage.oldPassword"));
        } else {
          toast.error(t("profile.toastMessage.currentIncorrect"));
        }
      });
  } else {
    toast.error(t("profile.pwd.error.different"));
  }
};
function getCountryCodeFromDialCode(dialCode: string) {
  const countryCode = countryCodes.find(
    (countryCode) => countryCode.dialCode === dialCode
  );
  return countryCode ? countryCode.countryCode : "";
}

export const validateFormData = (
  formData: any,
  updateUser?: boolean
): Record<string, string> => {
  const newErrors: Record<string, string> = {};
  const validationName = /^[a-zA-ZÀ-ÿ\s]+$/;
  // Validate name
  if (!formData.name) {
    newErrors.name = t("user.formUser.formValidationError.nameMandatory");
  } else if (!validationName.test(formData.name)) {
    newErrors.name = t("user.formUser.formValidationError.nameFormat");
  }

  // Validate surname
  if (!formData.surname) {
    newErrors.surname = t("user.formUser.formValidationError.surnameMandatory");
  } else if (!validationName.test(formData.surname)) {
    newErrors.surname = t("user.formUser.formValidationError.surnameFormat");
  }

  // Validate phone number
  if (!formData.nationalPhone) {
    newErrors.nationalPhone = t(
      "user.formUser.formValidationError.nationalPhoneEmpty"
    );
  } else if (!isValidPhone(formData.countryCode, formData.nationalPhone)) {
    newErrors.nationalPhone = t(
      "user.formUser.formValidationError.nationalPhoneFormat"
    );
  }

  // Validate country code
  if (!formData.countryCode) {
    newErrors.countryCode = t(
      "user.formUser.formValidationError.countryCodeMandatory"
    );
  }

  // Validate roles
  if (!formData.roles) {
    newErrors.roles = t("user.formUser.formValidationError.roleMandatory");
  }

  return newErrors;
};

export const saveEditUserProfile = (
  formData: any,
  UserId: string,
  handleSaveProfile: () => void
) => {
  const editUserData: EditUser = {
    name: formData.name || "",
    surname: formData.surname || "",
    email: formData.email || "",
    nationalPhone: formData.nationalPhone || "",
    countryCode: formData.countryCode || "",
    roles: formData.roles || "",
    professional: formData.professional || false,
    commercialOk: formData.commercialOk || false,
    pinnedDevices: formData.pinnedDevices || [],
    languageSettings: formData.languageSettings,
    companyName: formData.companyName || "",
    country: getCountryCodeFromDialCode(formData.dialCode) || "",
  };

  userService
    .update(editUserData, UserId)
    .then(() => {
      
      handleSaveProfile();
    })
    .catch((error) => {
      toast.error(t("user.formUser.toastMessage.userError") + error);
    });
};
