import { Icon } from "@iconify/react";
import { Modal, ModalHeader } from "flowbite-react";
import { CustomModalType } from "./CustomModalType";

const CustomModal = ({
  onClose = () => { },
  dismissible = true,
  isOpen = false,
  size = '3xl',
  icon = '',
  body = '',
  title,
}: CustomModalType) => {

  function handleModalClose() {
    onClose();
  }

  return (
    <Modal
      onClose={handleModalClose}
      dismissible={dismissible}
      className="relative"
      position="center"
      show={isOpen}
      size={size}
    >
      <ModalHeader className="border-0 pl-10 pr-10 pt-10">
        <div className="flex items-center space-x-4 mb-2">
          <Icon icon={icon} className="text-2xl" />
          <p className="font-manropeBold bold">{title}</p>
        </div>
      </ModalHeader>

      {body}

    </Modal>
  );
}

export default CustomModal;