import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import CustomInput from "@/components/molecules/CustomInput";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import CustomButton from "@/components/atoms/CustomButton";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomTitle from "@/components/atoms/CustomTitle";
import CustomText from "@/components/atoms/CustomText";

import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

import { ReactComponent as LogoImage } from "@/assets/images/nimbus-logo.svg";
import BackImage from "@/assets/images/login-background.png";
import { authService } from "@/services/auth.service";
import toast from "react-hot-toast";
import { AuthError } from "@/utils/constants";
import { ResetPasswordData, resetPasswordFormType } from "./d";


const ResetPassword: React.FC = () => {
  const { token, code } = useParams<{ token?: string; code?: string }>();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const hashedTokenRef = useRef<string | null>(null);
  const isValidTokenRef = useRef<boolean | null>(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<resetPasswordFormType>({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { errors, validateStep1, validateStep2 } = ResetPasswordData();

  useEffect(() => {
    if (token && code) {
      setFormData((prevData) => ({ ...prevData, token }));

      setLoading(true);
      setStep(2);

      authService
        .validateResetPasswordToken(code)
        .then((result) => {
          if (result.isValid) {
            isValidTokenRef.current = result?.isValid;
            hashedTokenRef.current = result?.data;
          } else {
            isValidTokenRef.current = false;
          }
          setLoading(false);
        })
        .catch((error: AuthError) => {
          console.log(error.error.message);
          setLoading(false);
        });
    }
  }, [token, code]);

  const customStyles: React.CSSProperties = {
    backgroundImage: `url('${BackImage}')`,
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (step === 1) {
        if (validateStep1(formData)) {
          await authService.resetPasswordRequest(formData.email);
          toast.success(
           t('user.resetPassword.toastEmail')
          );
          setTimeout(() => {
            setLoading(false);
            navigate("/login");
          }, 2000);
        }
      } else if (step === 2) {
        if (validateStep2(formData) && code && hashedTokenRef.current) {
          authService
            .resetPassword(
              code,
              hashedTokenRef.current,
              formData.password,
              formData.confirmPassword
            )
            .then(() => {
              toast.success(t('user.resetPassword.toastPassword'));
              setTimeout(() => {
                navigate("/login");
              }, 2000);
            })
            .catch((error: AuthError) => {
              if(error.error.message === 'error.password.same.old'){ 
                setMessage(t('user.resetPassword.passwordSameOld'));
               
              }else{
                setMessage(t('user.resetPassword.tokenNotFound'));
              }
              console.log(error.error.message);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.currentTarget ? e.currentTarget : e.target;
    if (el) {
      setFormData({
        ...formData,
        [el.name]: el.value,
      });
    }
  };

  return (
    <>
      <CustomLoader visible={loading}></CustomLoader>
      <div className="flex flex-col-reverse md:flex-row w-full h-screen md:h-[25vh] mb-[5rem]">
        <div className="py-8 px-8 w-full flex flex-col md:justify-center items-center h-3/5 md:h-screen md:w-1/2 rounded-t-3xl md:rounded-r-3xl bg-white relative md:mt-0 -mt-5 md:-mr-4 ">
          <div className="mb-8 w-full max-w-md">
            <div className="mb-4">
              <div className="flex gap-3 mb-5">
                <Link to="/login" className="flex">
                  <div>
                    <CustomIconButton icon={"la:arrow-left"}></CustomIconButton>
                  </div>
                  <div className="pt-3 pl-5">
                    <CustomText variant="secondary">
                      {t("general.back")}
                    </CustomText>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              <CustomTitle heading="h1">{t("reset.title")}</CustomTitle>
              <CustomText variant="secondary" weight="semi">
                {step === 1 && (
                  <>
                  {t("reset.subtitle.case1")}
                  </>
                )}
                {step === 2 && (
                  <>
                  {t("reset.subtitle.case2")}
                  </>
                )}
              </CustomText>
              {message && (
                <CustomText className="text-red-600" weight="bold">
                  {message}
                </CustomText>
              )}
            </div>
          </div>
          <form
            data-test-id="login-form"
            onSubmit={onSubmit}
            className="w-full max-w-md bg-white rounded-lg"
          >
            {step === 1 && (
              <div className="mb-12">
                <CustomInput
                  onChange={handleFormChange}
                  label={t("account.email")}
                  icon="la:envelope-open"
                  autocomplete={false}
                  name={"email"}
                  helperText={errors.email ? errors.email : ""}
                  danger={errors.email ? true : false}
                  required
                />
              </div>
            )}
            {step === 2 && (
              <>
                <div className="mb-12">
                  <CustomInput
                    label={t("account.newPassword")}
                    type="password"
                    name={"password"}
                    onChange={handleFormChange}
                    helperText={errors.password ? errors.password : ""}
                    danger={errors.password ? true : false}
                    showText
                    required
                  />
                </div>
                <div className="mb-12">
                  <CustomInput
                    label={t("account.confirm")}
                    type="password"
                    name={"confirmPassword"}
                    onChange={handleFormChange}
                    helperText={
                      errors.confirmPassword ? errors.confirmPassword : ""
                    }
                    danger={errors.confirmPassword ? true : false}
                    showText
                    required
                  />
                </div>
              </>
            )}
            <div className="mb-6">
              <CustomButton className="mt-6 w-full" type="submit">
              {step === 1 && (
                  <>
                  {t("reset.button.case1")}
                  </>
                )}
                {step === 2 && (
                  <>
                  {t("reset.button.case2")}
                  </>
                )}
              </CustomButton>
            </div>
          </form>
        </div>

        <div
          className="flex h-3/5 md:h-screen md:w-4/5 items-center justify-center bg-cover bg-no-repeat"
          style={customStyles}
        >
          <LogoImage className="h-[13rem] md:h-[25rem] w-80 self-center mx-auto flex-shrink-0 flex-grow-0" />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
