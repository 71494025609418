import { authService } from "./auth.service";
import { apiService } from "./api.service";
import { INotification } from "@/utils/models/entities/Notification";

const NOTIFY_CONNECTIVITY: boolean = false;

export const notificationService = {
    getNotificationByUserId: async (userId: string, filter?: string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/notifications/${userId}`; 
        let response = await apiService.get(url, authService.getToken(),filter);
        return response; 
    },
    
    saveNotifications :async (notification: INotification) => {
        const url = `${process.env.REACT_APP_BASE_URL}/notifications`; 
        let response = await apiService.post(url, notification,authService.getToken());
        return response
    },
    
    getNotifications:async (id:string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/notification/${id}`; 
        let response = await apiService.get(url, authService.getToken());
        return response 
    },
    delete:async (id:string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/notifications/${id}`; 
        let response = await apiService.delete(url, authService.getToken());
        return response;
    },
    isGroupEnabled(group: string): boolean {
        return (group !== 'alarm.device_disconnection' || NOTIFY_CONNECTIVITY);
    },
};


