import { ChangeEvent, useEffect, useState } from "react";
import { CreateNotificationType } from "./ModalNotificationType";
import { t } from "i18next";
import { ModalBody, ModalFooter } from "flowbite-react";
import CustomButton from "@/components/atoms/CustomButton";
import CustomText from "@/components/atoms/CustomText";
import CustomSelect, {CustomOptionType,} from "@/components/molecules/CustomSelect";
import DynamicFieldMail from "@/components/organisms/DynamicFieldMail";
import {FieldData,FieldValue,NotificationFormComponent,CustomDynamicFieldEnumMode,} from "./d";
import CustomLoader from "@/components/atoms/CustomLoader";
import useNotificationList from "@/utils/hooks/useNotificationList";
import useAuth from "@/utils/hooks/useAuth";
import { INotification } from "@/utils/models/entities/Notification";
import { langOptions } from "@/utils/constants";
import DynamicFieldPhone from "../DynamicFieldPhone";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import CustomInput from "@/components/molecules/CustomInput";
import { IUser } from "@/utils/models";

const CreateNotification = ({
  onClose,
  isEdit,
  notification,
}: CreateNotificationType) => {
  const [formData, setFormData] = useState<INotification>({
    id: "",
    userId: "",
    sms: false,
    mail: false,
    enabledAdditionalPhone: false,
    enabledAdditionalMail: false,
    additionalPhone: [],
    additionalMail: [],
    locale: "",
    iotId: "",
  });
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [showFieldsMail, setShowFieldsMail] = useState(false);
  const [showFieldsSms, setShowFieldsSms] = useState(false); 

  const { getCurrentUserHook } = useAuth();
  const currentUser: IUser = getCurrentUserHook();

  const toggleMailFields = (e: boolean) =>
    setFormData((prevData) => ({ ...prevData, enabledAdditionalMail: e })); 

  const toggleSmsFields = (e: boolean) =>
    setFormData((prevData) => ({ ...prevData, enabledAdditionalPhone: e }));

  const { initializeEditForm, isSaveDisabled, doSave, doUpdate } =
    NotificationFormComponent({
      isEditing: isEdit,
      CurrentUser: getCurrentUserHook(),
    });

  const { deviceOptions, loading: deviceLoading } = useNotificationList();

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit && notification) {
        const editFormData = initializeEditForm(notification, isEdit);
        setFormData(editFormData);
        editFormData.mail && setShowFieldsMail(editFormData.mail);
        editFormData.sms &&  setShowFieldsSms(editFormData.sms);
      }
    };
    fetchData();
  }, [isEdit, notification]);

  useEffect(() => {
    setSaveDisabled(isSaveDisabled(formData));
  }, [formData, isSaveDisabled]);

  const formChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const el = e.currentTarget ? e.currentTarget : e.target;
    if (el) {
      setFormData({
        ...formData,
        [el.name]: el.value,
      });
    }
  };

  const handleFieldValuesChangeOutside = (
    newFieldValues: FieldValue[],
    name: string,
    mode: string
  ) => {
    if (!newFieldValues) return;
    if (mode === CustomDynamicFieldEnumMode.DynamicFieldPhone) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newFieldValues.map((el: any) => ({
          countryCode: el.countryCode || "",
          number: el.mobilePhone || "",
        })),
      }));
    } else {
      const data: FieldData[] = newFieldValues
        .filter((el) => el.name && el.value)
        .map((el) => {
          return {
            name: el.name || "",
            value: el.value || "",
          };
        });

      setFormData((prevState) => ({
        ...prevState,
        [name]: data,
      }));
    }
  };

  const stringToCustomOption = (formatData: string): CustomOptionType => ({
    value: formatData,
    label: formatData,
    disabled: false,
    selected: false,
  });

  function setLangOptionsMap(valueToCompare?: string): CustomOptionType[] {
    let optionsArrayOptions: CustomOptionType[] = [];
    if (valueToCompare) {
      optionsArrayOptions = langOptions
        .filter((el) => el.value === valueToCompare)
        .map((el) => ({
          value: el.value,
          label: `${el.label}`,
        }));
    } else {
      optionsArrayOptions = langOptions.map((el) => ({
        value: el.value,
        label: `${el.label}`,
      }));
    }
    return optionsArrayOptions;
  }

  return (
    <>
      {deviceLoading ? (
        <CustomLoader visible={deviceLoading} />
      ) : (
        <>
          <ModalBody className="px-10">
            <CustomText
              size="lg"
              weight="bold"
              className="mb-5"
              children={t("notificationDetail.detail.titleBreadcrumb")}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-10">
              <CustomSelect
                emptyValue={true}
                onChange={formChange}
                label={t("menu.device")}
                options={deviceOptions}
                defaultValue={stringToCustomOption(formData.iotId)}
                className="mt-6"
                disabled={isEdit ? true : false}
                name="iotId"
                required
              />
              <CustomSelect
                emptyValue={true}
                onChange={formChange}
                label={t("profile.language")}
                options={setLangOptionsMap()}
                defaultValue={
                  isEdit ? setLangOptionsMap(formData.locale)[0] : undefined
                }
                className="mt-6"
                name="locale"
                required
              />
            </div>
            <CustomText
              size="lg"
              weight="bold"
              className="mb-5"
              children={t("notificationDetail.form.notifications")}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10">
              <div className="col-span-1 flex flex-col mx-2 my-4">
                <CustomCheckbox
                  label={t("notificationDetail.enableEmailNotificationsMail")}
                  name={"mail"}
                  className="col-span-1"
                  checked={formData.mail}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mail: e.target.checked,
                    });
                    setShowFieldsMail(e.target.checked);
                  }}
                ></CustomCheckbox>
                {showFieldsMail ? (
                  <CustomInput
                    label={''}
                    disabled={true}
                    value={currentUser.email}
                    name={"textEmail"}
                    className="mt-2"
                  />
                ) : null}
              </div>

              <div className="col-span-1 flex flex-col mx-2 my-4">
                <CustomCheckbox
                  label={t("notificationDetail.enabledEmailNotificationsSMS")}
                  name={"sms"}
                  className="col-span-"
                  checked={formData.sms}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      sms: e.target.checked,
                    });
                    setShowFieldsSms(e.target.checked); 
                  }}
                ></CustomCheckbox>

                {showFieldsSms ? (
                  <CustomInput
                    label={''}
                    disabled={true}
                    value={currentUser.countryCode + currentUser.nationalPhone}
                    name={"textPhone"}
                    className="mt-2"
                  />
                ) : null}
              </div>

              <DynamicFieldMail
                maxFieldsCount={6}
                statusCheck={toggleMailFields}
                additionalMail={formData.additionalMail}
                isEdit={isEdit}
                onChange={(e: any) =>
                  handleFieldValuesChangeOutside(
                    e,
                    "additionalMail",
                    CustomDynamicFieldEnumMode.DynamicFieldEmail
                  )
                }
                name={t("notificationDetail.detail.additionalMail")}
              />
              <DynamicFieldPhone
                maxFieldsCount={6}
                statusCheck={toggleSmsFields}
                additionalPhone={formData.additionalPhone}
                isEdit={isEdit}
                onChange={(e: any) =>
                  handleFieldValuesChangeOutside(
                    e,
                    "additionalPhone",
                    CustomDynamicFieldEnumMode.DynamicFieldPhone
                  )
                }
                name={t("notificationDetail.detail.additionalPhone")}
              />
            </div>
          </ModalBody>
          <ModalFooter className="px-10">
            <div className="flex w-full justify-between">
              <CustomButton
                variant="secondary"
                leftIconName="la:times"
                onClick={() => onClose()}
                children={t("general.cancel")}
              />
              <CustomButton
                variant="primary"
                leftIconName="la:save"
                onClick={() =>
                  isEdit
                    ? doUpdate(formData, onClose)
                    : doSave(formData, onClose)
                }
                children={
                  isEdit
                    ? t("notificationDetail.form.title.edit")
                    : t("device.form.buttonSave")
                }
                disabled={!isEdit && saveDisabled}
              />
            </div>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default CreateNotification;
