import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";

export interface ISearchFilter {
  name: string;
  value: any;
}

export interface ISearchColumn {
  name: string;
  sortable: boolean;
  type: string;
}

export interface ISearchParams {
  term: string;
  skip:number,
  size: number;
  page?: number;
  sortColumn: string;
  sortAsc: boolean;
  filters?: Array<ISearchFilter>;
}

export class UIList<T> {
  items: Array<T> = [];
  count: number = 0;

  constructor(public getItems: (params: ISearchParams , models:SearchParamsEnum) => Promise<any>) {}
}
export interface ISearchAction {
  icon: string;
  color: string;
  routerLink: IRouterLinkDef;
  action: any;
  permission: string;
  condition?: any;
}

export interface IRouterLinkDef {
  link: string;
  params: Array<IRouterParamDef>;
}

export interface IRouterParamDef {
  name: string;
  property: string;
}
