import React, { useEffect, useRef, useState } from "react";
import { ActionItemType } from "@/utils/models";
import CustomDropdown from "@/components/molecules/CustomDropdown";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomText from "@/components/atoms/CustomText";
import CustomChip from "@/components/atoms/CustomChip";
import { CustomOptionType, CustomSelectType } from "./CustomChipsInputType";

const CustomSelectChips = ({
  defaultValue = { label: "", value: "" },
  defaultValues = [],
  onChange = () => { },
  onSearch = () => { },
  emptyValue = false,
  hasSearch = false,
  multiple = false,
  disabled = false,
  required = false,
  helperText = "",
  className = "",
  label = "",
  options,
  name,
}: CustomSelectType) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [dropdownItems, setDropdownItems] = useState<ActionItemType[]>([]);
  const [selectedValues, setSelectedValues] = useState<CustomOptionType[]>(
    multiple ? [] : [defaultValue]
  );
  const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValues.length > 0) {
      const initialSelectedValues = defaultValues.map(
        (el: CustomOptionType) => {
          return (
            options.find(
              (option: CustomOptionType) => option.value === el.value
            ) || el
          );
        }
      );
      setSelectedValues(initialSelectedValues);
      onChange(initialSelectedValues);
    }
  }, [defaultValues]);

  const setArrayActionItems = (): ActionItemType[] => {
    const DropdownItems: ActionItemType[] = [];
    const filteredOptions = getFilteredOptions();

    if (emptyValue) {
      DropdownItems.push({
        action: () => { },
        text: "-",
        value: "",
      });
    }

    filteredOptions.forEach((el: CustomOptionType) => {
      DropdownItems.push({
        action: () => { },
        text: el.label?.trim() || el.value.trim(),
        value: el.value,
      });
    });

    return DropdownItems;
  };

  const getFilteredOptions = () => {
    return options.filter(
      (option) =>
        !selectedValues.some((selected) => selected.value === option.value)
    );
  };

  const setDropdownPosition = () => {
    const rect = dropdownRef?.current?.getBoundingClientRect();
    const h = window.innerHeight - (rect?.bottom || 0);
    rect && setPosition({ x: rect.left, y: rect.bottom, w: rect.width, h: h });
  };

  const handleButtonClick = () => {
    setDropdownPosition();
    setIsOpen(!isOpen);
  };

  const onActionClick = (data: any) => {
    if (data !== undefined) {
      if (multiple) {
        setSelectedValues((prevValues) => {
          const newValues = prevValues.some(
            (value) => value.value === data.value
          )
            ? prevValues.filter((value) => value.value !== data.value)
            : [...prevValues, { label: data.text, value: data.value }];
          onChange(newValues);
          return newValues;
        });
      } else {
        const newValue = { label: data.text, value: data.value };
        setSelectedValues([newValue]);
        onChange(newValue);
      }
      setIsOpen(false);
    }
  };

  const removeChip = (value: string) => {
    setSelectedValues((prevValues) => {
      const newValues = prevValues.filter((val) => val.value !== value);
      onChange(newValues);
      return newValues;
    });
  };

  useEffect(() => {
    setDropdownItems(setArrayActionItems());

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const scrollHandler = () => {
      setDropdownPosition();
    };

    document.addEventListener("scroll", scrollHandler, true);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, position, options, selectedValues]);

  return (
    <div
      ref={dropdownRef}
      className={`relative text-gray-600 select-none w-full
      ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
      ${className}`}
    >
      <label className="absolute text-xs scale-100 text-gray-500 top-3 -translate-y-6 origin-[0]">
        {required ? label + " *" : label}
      </label>

      <div
        className={`font-manropeSemiBold text-black h-[42px] text-sm w-full border-0 border-b-2 ${isOpen ? "border-black" : "border-gray-300"
          } relative`}
        onClick={handleButtonClick}
      >
        <div className="flex items-center h-full w-full my-3">
          {multiple ? (
            <div className="flex items-center w-full pr-8 overflow-hidden">
              {selectedValues.map((val, index) => (
                <CustomChip
                  deleteIcon={true}
                  key={index}
                  value={val.label || ""}
                  onDelete={() => removeChip(val.value)}
                />
              ))}
            </div>
          ) : (
            <span className="text-ellipsis overflow-hidden whitespace-nowrap pr-8">
              {selectedValues[0]?.label}
            </span>
          )}
          <button
            className="absolute right-1 top-3 hover:drop-shadow"
            onClick={handleButtonClick}
            disabled={disabled}
          >
            {disabled ? (
              <CustomIcon icon="nimbus:chevron-up" />
            ) : (
              <CustomIcon
                icon={isOpen ? "nimbus:chevron-up" : "nimbus:chevron-down"}
              />
            )}
          </button>
        </div>
      </div>

      {!disabled && isOpen && (
        <CustomDropdown
          className="fixed left-0 bg-white z-30 text-sm focus:outline-none w-full shadow-xl mt-0"
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: position.h,
          }}
          dropdownItems={dropdownItems}
          onActionClick={onActionClick}
          hasSearch={hasSearch}
          onSearch={onSearch}
        />
      )}

      <select
        multiple={multiple}
        className="hidden"
        ref={selectRef}
        name={name}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <CustomText variant="secondary" size="xs" className="mt-2">
        {helperText && helperText}
      </CustomText>
    </div>
  );
};

export default CustomSelectChips;
