import { ChangeEvent } from "react";
import { ActionItemType, ISearchParams, UIList } from "../models";
import { floatingLabelTheme } from "../theme/Themes";
import Cookies from "js-cookie";
import useSearchParams from "../hooks/useSearchParams";
import { SearchParamsEnum } from "../enums/SearchParamsType";
import deviceService from "@/services/device.service";
import { userService } from "@/services/user.service";
import { notificationService } from "@/services/notifications.service";
import { CustomOptionType } from "@/components/molecules/CustomSelect";
import { unitMeasureSensorArray } from "@/pages/Device/Measures/d";
import { useNavigate } from "react-router-dom";

const PhoneNumber = require('awesome-phonenumber');
export const ERROR_NOT_VERIFIED = "error.login.account.not.verified";
export const ERROR_MAINTENANCE = "error.app_maintenance";
export const ERROR_PASSWORD_NOT_MATCH = "error.login.email_pwd.notfound";
export const reloadTime = 2000;

export const setCountryCodeMap = () => {
  const optionsArrayOptions = countryCodes.map((el, i) => {
    return {
      value: el.dialCode,
      label: `${el.name}(${el.dialCode})`,
    };
  });

  return optionsArrayOptions;
};

export const removeLocalStorage = () => {
  localStorage.removeItem('SAL-nimbus-token');
}

export const handleFormChange = (
  e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  setFormData: any,
  formData: any
) => {
  const el = e.currentTarget ? e.currentTarget : e.target;
  if (el) {
    setFormData({
      ...formData,
      [el.name]: el.value,
    });
  }
};
export const getFloatingLabelPositionTheme = (label: string) => {
  let theme = floatingLabelTheme;
  let labelPosition: string = "top-3";
  if (label.length > 40) {
    labelPosition = "top-0  md:top-3";
  }
  theme.label.default.standard.md += " " + labelPosition;
  return theme;
};

export const reloadPage = (): any => {
  const intervalId = setInterval(() => {
    window.location.reload();
    clearInterval(intervalId);
  }, reloadTime);
};


export const createISearchParams = (
  term: string = "",
  skip: number = 0,
  size: number = 10,
  page: number = 1,
  sortColumn: string = "iotId",
  sortAsc: boolean = true,
  filter: any[] = []
): ISearchParams => {
  let newParams: ISearchParams = {
    term: term,
    skip: skip,
    size: size,
    page: page,
    sortColumn: sortColumn,
    sortAsc: sortAsc,
    filters: filter,
  };

  return newParams;
};

export const searchList = new UIList<any>(
  async (
    params: ISearchParams,
    searchType: SearchParamsEnum,
    OrderColumnName: string = "name"
  ) => {
    if (params) {
      if (!params.sortColumn) {
        params.sortColumn = OrderColumnName;
        params.sortAsc = true;
      }

      const { getStringifiedSearchParams } = useSearchParams(
        params,
        searchType
      );

      const stringifiedParams = getStringifiedSearchParams();

      if (searchType === SearchParamsEnum.device) {
        return deviceService.getDeviceSearchParams(stringifiedParams);
      } else if (searchType === SearchParamsEnum.user) {
        return userService.searchUsers(stringifiedParams);
      } else if (searchType === SearchParamsEnum.notification) {
        const currentUser = await userService.getCurrentUserMe();
        if (currentUser) {
          return await notificationService.getNotificationByUserId(
            currentUser.id, stringifiedParams
          );
        }
      }
    }
    return [];
  }
);

export function getEmailCurrentUser() {
  const userDataString = Cookies.get("SAL-nimbus-userData");
  if (userDataString) {
    const userData = JSON.parse(userDataString);
    return userData.email;
  } else {
    return null;
  }
}

export function isValidPassword(password: string): boolean {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!<>";}=¿_€¥£¢¡,~:@#%&.^$*+?()[\]{|])(?=.{8,})/;
  return passwordRegex.test(password);
}
export function isValidPhone(countryCode: any, number: any): boolean {
  const regionCode = PhoneNumber.getRegionCodeForCountryCode(countryCode.replace('+', ''));
  const pn = new PhoneNumber(number, { regionCode });
  return pn.isValid();
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export const dropDownItems: ActionItemType[] = [
  {
    icon: "la:sign-out-alt",
    text: "Alex",
    disabled: true,
    action: () => {
      alert("Hello World");
    },
  },
  {
    icon: "la:sign-out-alt",
    text: "Prova",
    disabled: true,
    action: () => {
      alert("Hello World");
    },
  },
];

export const unixTimeToDate = (ms?: any): string => {
  
  if (ms === undefined || ms === null) {
    return '-';
  }

  if (typeof ms === 'number') {
    if (ms === undefined || ms === 0) {
      return '-';
    }
    const date = new Date(ms);
    const pad = (num: number): string => ('0' + num).slice(-2);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
  } else {
    const updateDate = ms.toString();
    const formattedUpdateDate = (updateDate?.slice(0, 10) ?? '-') + ' ' + (updateDate?.slice(11, 19) ?? '');
    return formattedUpdateDate;
  }
};

export const countryCodes = [
  { countryCode: "AX", dialCode: "+358", name: "Aaland Islands" },
  { countryCode: "AF", dialCode: "+93", name: "Afghanistan" },
  { countryCode: "AL", dialCode: "+355", name: "Albania" },
  { countryCode: "DZ", dialCode: "+213", name: "Algeria" },
  { countryCode: "AS", dialCode: "+1684", name: "American Samoa" },
  { countryCode: "AD", dialCode: "+376", name: "Andorra" },
  { countryCode: "AO", dialCode: "+244", name: "Angola" },
  { countryCode: "AI", dialCode: "+1264", name: "Anguilla" },
  { countryCode: "AG", dialCode: "+1268", name: "Antigua And Barbuda" },
  { countryCode: "AR", dialCode: "+54", name: "Argentina" },
  { countryCode: "AM", dialCode: "+374", name: "Armenia" },
  { countryCode: "AW", dialCode: "+297", name: "Aruba" },
  { countryCode: "AU", dialCode: "+61", name: "Australia" },
  { countryCode: "AT", dialCode: "+43", name: "Austria" },
  { countryCode: "AZ", dialCode: "+994", name: "Azerbaijan" },
  { countryCode: "BS", dialCode: "+1242", name: "Bahamas" },
  { countryCode: "BH", dialCode: "+973", name: "Bahrain" },
  { countryCode: "BD", dialCode: "+880", name: "Bangladesh" },
  { countryCode: "BB", dialCode: "+1246", name: "Barbados" },
  { countryCode: "BY", dialCode: "+375", name: "Belarus" },
  { countryCode: "BE", dialCode: "+32", name: "Belgium" },
  { countryCode: "BZ", dialCode: "+501", name: "Belize" },
  { countryCode: "BJ", dialCode: "+229", name: "Benin" },
  { countryCode: "BM", dialCode: "+1441", name: "Bermuda" },
  { countryCode: "BT", dialCode: "+975", name: "Bhutan" },
  { countryCode: "BO", dialCode: "+591", name: "Bolivia" },
  {
    countryCode: "BA",
    dialCode: "+387",
    name: "Bosnia And Herzegowina",
  },
  { countryCode: "BW", dialCode: "+267", name: "Botswana" },
  { countryCode: "BR", dialCode: "+55", name: "Brazil" },
  {
    countryCode: "IO",
    dialCode: "+246",
    name: "British Indian Ocean Territory",
  },
  { countryCode: "BN", dialCode: "+673", name: "Brunei Darussalam" },
  { countryCode: "BG", dialCode: "+359", name: "Bulgaria" },
  { countryCode: "BF", dialCode: "+226", name: "Burkina Faso" },
  { countryCode: "BI", dialCode: "+257", name: "Burundi" },
  { countryCode: "KH", dialCode: "+855", name: "Cambodia" },
  { countryCode: "CM", dialCode: "+237", name: "Cameroon" },
  { countryCode: "CA", dialCode: "+1", name: "Canada" },
  { countryCode: "CV", dialCode: "+238", name: "Cape Verde" },
  {
    countryCode: "BQ",
    dialCode: "+599",
    name: "Caribbean Netherlands",
  },
  { countryCode: "KY", dialCode: "+1345", name: "Cayman Islands" },
  {
    countryCode: "CF",
    dialCode: "+236",
    name: "Central African Republic",
  },
  { countryCode: "TD", dialCode: "+235", name: "Chad" },
  { countryCode: "CL", dialCode: "+56", name: "Chile" },
  { countryCode: "CN", dialCode: "+86", name: "China" },
  { countryCode: "CO", dialCode: "+57", name: "Colombia" },
  { countryCode: "KM", dialCode: "+269", name: "Comoros" },
  { countryCode: "CD", dialCode: "+243", name: "Congo" },
  { countryCode: "CG", dialCode: "+242", name: "Congo Republic" },
  { countryCode: "CK", dialCode: "+682", name: "Cook Islands" },
  { countryCode: "CR", dialCode: "+506", name: "Costa Rica" },
  { countryCode: "CI", dialCode: "+225", name: "Cote Divoire" },
  { countryCode: "HR", dialCode: "+385", name: "Croatia" },
  { countryCode: "CU", dialCode: "+53", name: "Cuba" },
  { countryCode: "CW", dialCode: "+599", name: "Curacao" },
  { countryCode: "CY", dialCode: "+357", name: "Cyprus" },
  { countryCode: "CZ", dialCode: "+420", name: "Czech Republic" },
  { countryCode: "DK", dialCode: "+45", name: "Denmark" },
  { countryCode: "DJ", dialCode: "+253", name: "Djibouti" },
  { countryCode: "DM", dialCode: "+1767", name: "Dominica" },
  { countryCode: "DO", dialCode: "+1", name: "Dominican Republic" },
  { countryCode: "EC", dialCode: "+593", name: "Ecuador" },
  { countryCode: "EG", dialCode: "+20", name: "Egypt" },
  { countryCode: "SV", dialCode: "+503", name: "El Salvador" },
  { countryCode: "GQ", dialCode: "+240", name: "Equatorial Guinea" },
  { countryCode: "ER", dialCode: "+291", name: "Eritrea" },
  { countryCode: "EE", dialCode: "+372", name: "Estonia" },
  { countryCode: "ET", dialCode: "+251", name: "Ethiopia" },
  {
    countryCode: "FK",
    dialCode: "+500",
    name: "Falkland Islands (malvinas)",
  },
  { countryCode: "FO", dialCode: "+298", name: "Faroe Islands" },
  { countryCode: "FJ", dialCode: "+679", name: "Fiji" },
  { countryCode: "FI", dialCode: "+358", name: "Finland" },
  { countryCode: "FR", dialCode: "+33", name: "France" },
  { countryCode: "GF", dialCode: "+594", name: "French Guiana" },
  { countryCode: "PF", dialCode: "+689", name: "French Polynesia" },
  { countryCode: "GA", dialCode: "+241", name: "Gabon" },
  { countryCode: "GM", dialCode: "+220", name: "Gambia" },
  { countryCode: "GE", dialCode: "+995", name: "Georgia" },
  { countryCode: "DE", dialCode: "+49", name: "Germany" },
  { countryCode: "GH", dialCode: "+233", name: "Ghana" },
  { countryCode: "GI", dialCode: "+350", name: "Gibraltar" },
  { countryCode: "GR", dialCode: "+30", name: "Greece" },
  { countryCode: "GL", dialCode: "+299", name: "Greenland" },
  { countryCode: "GD", dialCode: "+1473", name: "Grenada" },
  { countryCode: "GP", dialCode: "+590", name: "Guadeloupe" },
  { countryCode: "GU", dialCode: "+1671", name: "Guam" },
  { countryCode: "GT", dialCode: "+502", name: "Guatemala" },
  { countryCode: "GG", dialCode: "+44", name: "Guernsey" },
  { countryCode: "GN", dialCode: "+224", name: "Guinea" },
  { countryCode: "GW", dialCode: "+245", name: "Guinea-bissau" },
  { countryCode: "GY", dialCode: "+592", name: "Guyana" },
  { countryCode: "HT", dialCode: "+509", name: "Haiti" },
  { countryCode: "HN", dialCode: "+504", name: "Honduras" },
  { countryCode: "HK", dialCode: "+852", name: "Hong Kong" },
  { countryCode: "HU", dialCode: "+36", name: "Hungary" },
  { countryCode: "IS", dialCode: "+354", name: "Iceland" },
  { countryCode: "IN", dialCode: "+91", name: "India" },
  { countryCode: "ID", dialCode: "+62", name: "Indonesia" },
  { countryCode: "IR", dialCode: "+98", name: "Iran" },
  { countryCode: "IQ", dialCode: "+964", name: "Iraq" },
  { countryCode: "IE", dialCode: "+353", name: "Ireland" },
  { countryCode: "IM", dialCode: "+44", name: "Isla De Man" },
  { countryCode: "IL", dialCode: "+972", name: "Israel" },
  { countryCode: "IT", dialCode: "+39", name: "Italy" },
  { countryCode: "JM", dialCode: "+1876", name: "Jamaica" },
  { countryCode: "JP", dialCode: "+81", name: "Japan" },
  { countryCode: "JE", dialCode: "+44", name: "Jersey" },
  { countryCode: "JO", dialCode: "+962", name: "Jordan" },
  { countryCode: "KZ", dialCode: "+7", name: "Kazakhstan" },
  { countryCode: "KE", dialCode: "+254", name: "Kenya" },
  { countryCode: "KI", dialCode: "+686", name: "Kiribati" },
  { countryCode: "KP", dialCode: "+850", name: "Korea North" },
  { countryCode: "KR", dialCode: "+82", name: "Korea South" },
  { countryCode: "XK", dialCode: "+383", name: "Kosovo" },
  { countryCode: "KW", dialCode: "+965", name: "Kuwait" },
  { countryCode: "KG", dialCode: "+996", name: "Kyrgyzstan" },
  { countryCode: "LA", dialCode: "+856", name: "Lao" },
  { countryCode: "LV", dialCode: "+371", name: "Latvia" },
  { countryCode: "LB", dialCode: "+961", name: "Lebanon" },
  { countryCode: "LS", dialCode: "+266", name: "Lesotho" },
  { countryCode: "LR", dialCode: "+231", name: "Liberia" },
  {
    countryCode: "LY",
    dialCode: "+218",
    name: "Libyan Arab Jamahiriya",
  },
  { countryCode: "LI", dialCode: "+423", name: "Liechtenstein" },
  { countryCode: "LT", dialCode: "+370", name: "Lithuania" },
  { countryCode: "LU", dialCode: "+352", name: "Luxembourg" },
  { countryCode: "MO", dialCode: "+853", name: "Macau" },
  { countryCode: "MK", dialCode: "+389", name: "Macedonia" },
  { countryCode: "MG", dialCode: "+261", name: "Madagascar" },
  { countryCode: "MW", dialCode: "+265", name: "Malawi" },
  { countryCode: "MY", dialCode: "+60", name: "Malaysia" },
  { countryCode: "MV", dialCode: "+960", name: "Maldives" },
  { countryCode: "ML", dialCode: "+223", name: "Mali" },
  { countryCode: "MT", dialCode: "+356", name: "Malta" },
  { countryCode: "MH", dialCode: "+692", name: "Marshall Islands" },
  { countryCode: "MQ", dialCode: "+596", name: "Martinique" },
  { countryCode: "MR", dialCode: "+222", name: "Mauritania" },
  { countryCode: "MU", dialCode: "+230", name: "Mauritius" },
  { countryCode: "YT", dialCode: "+262", name: "Mayotte" },
  { countryCode: "MX", dialCode: "+52", name: "Mexico" },
  { countryCode: "FM", dialCode: "+691", name: "Micronesia" },
  { countryCode: "MD", dialCode: "+373", name: "Moldova" },
  { countryCode: "MC", dialCode: "+377", name: "Monaco" },
  { countryCode: "MN", dialCode: "+976", name: "Mongolia" },
  { countryCode: "ME", dialCode: "+382", name: "Montenegro" },
  { countryCode: "MS", dialCode: "+1664", name: "Montserrat" },
  { countryCode: "MA", dialCode: "+212", name: "Morocco" },
  { countryCode: "MZ", dialCode: "+258", name: "Mozambique" },
  { countryCode: "MM", dialCode: "+95", name: "Myanmar" },
  { countryCode: "NA", dialCode: "+264", name: "Namibia" },
  { countryCode: "NR", dialCode: "+674", name: "Nauru" },
  { countryCode: "NP", dialCode: "+977", name: "Nepal" },
  { countryCode: "NL", dialCode: "+31", name: "Netherlands" },
  { countryCode: "NC", dialCode: "+687", name: "New Caledonia" },
  { countryCode: "NZ", dialCode: "+64", name: "New Zealand" },
  { countryCode: "NI", dialCode: "+505", name: "Nicaragua" },
  { countryCode: "NE", dialCode: "+227", name: "Niger" },
  { countryCode: "NG", dialCode: "+234", name: "Nigeria" },
  { countryCode: "NU", dialCode: "+683", name: "Niue" },
  { countryCode: "NF", dialCode: "+672", name: "Norfolk Island" },
  {
    countryCode: "MP",
    dialCode: "+1670",
    name: "Northern Mariana Islands",
  },
  { countryCode: "NO", dialCode: "+47", name: "Norway" },
  { countryCode: "OM", dialCode: "+968", name: "Oman" },
  { countryCode: "PK", dialCode: "+92", name: "Pakistan" },
  { countryCode: "PW", dialCode: "+680", name: "Palau" },
  { countryCode: "PS", dialCode: "+970", name: "Palestina" },
  { countryCode: "PA", dialCode: "+507", name: "Panama" },
  { countryCode: "PG", dialCode: "+675", name: "Papua New Guinea" },
  { countryCode: "PY", dialCode: "+595", name: "Paraguay" },
  { countryCode: "PE", dialCode: "+51", name: "Peru" },
  { countryCode: "PH", dialCode: "+63", name: "Philippines" },
  { countryCode: "PL", dialCode: "+48", name: "Poland" },
  { countryCode: "PT", dialCode: "+351", name: "Portugal" },
  { countryCode: "PR", dialCode: "+1", name: "Puerto Rico" },
  { countryCode: "QA", dialCode: "+974", name: "Qatar" },
  { countryCode: "RE", dialCode: "+262", name: "Reunion" },
  { countryCode: "RO", dialCode: "+40", name: "Romania" },
  { countryCode: "RU", dialCode: "+7", name: "Russian Federation" },
  { countryCode: "RW", dialCode: "+250", name: "Rwanda" },
  { countryCode: "SH", dialCode: "+290", name: "Saint Helena" },
  {
    countryCode: "KN",
    dialCode: "+1869",
    name: "Saint Kitts And Nevis",
  },
  { countryCode: "LC", dialCode: "+1758", name: "Saint Lucia" },
  {
    countryCode: "PM",
    dialCode: "+508",
    name: "Saint Pierre And Miquelon",
  },
  {
    countryCode: "VC",
    dialCode: "+1784",
    name: "Saint Vincent And The Grenadines",
  },
  { countryCode: "WS", dialCode: "+685", name: "Samoa" },
  { countryCode: "BL", dialCode: "+590", name: "San Bartolomé" },
  { countryCode: "SM", dialCode: "+378", name: "San Marino" },
  {
    countryCode: "ST",
    dialCode: "+239",
    name: "Sao Tome And Principe",
  },
  { countryCode: "SA", dialCode: "+966", name: "Saudi Arabia" },
  { countryCode: "SN", dialCode: "+221", name: "Senegal" },
  { countryCode: "RS", dialCode: "+381", name: "Serbia" },
  { countryCode: "SC", dialCode: "+248", name: "Seychelles" },
  { countryCode: "SL", dialCode: "+232", name: "Sierra Leone" },
  { countryCode: "SG", dialCode: "+65", name: "Singapore" },
  { countryCode: "SX", dialCode: "+1721", name: "Sint Maarten" },
  { countryCode: "SK", dialCode: "+421", name: "Slovakia" },
  { countryCode: "SI", dialCode: "+386", name: "Slovenia" },
  { countryCode: "SB", dialCode: "+677", name: "Solomon Islands" },
  { countryCode: "SO", dialCode: "+252", name: "Somalia" },
  { countryCode: "ZA", dialCode: "+27", name: "South Africa" },
  { countryCode: "SS", dialCode: "+211", name: "South Sudan" },
  { countryCode: "ES", dialCode: "+34", name: "Spain" },
  { countryCode: "LK", dialCode: "+94", name: "Sri Lanka" },
  { countryCode: "SD", dialCode: "+249", name: "Sudan" },
  { countryCode: "SR", dialCode: "+597", name: "Suriname" },
  {
    countryCode: "SJ",
    dialCode: "+47",
    name: "Svalbard And Jan Mayen Islands",
  },
  { countryCode: "SZ", dialCode: "+268", name: "Swaziland" },
  { countryCode: "SE", dialCode: "+46", name: "Sweden" },
  { countryCode: "CH", dialCode: "+41", name: "Switzerland" },
  { countryCode: "SY", dialCode: "+963", name: "Syrian Arab Republic" },
  { countryCode: "TW", dialCode: "+886", name: "Taiwan" },
  { countryCode: "TJ", dialCode: "+992", name: "Tajikistan" },
  { countryCode: "TZ", dialCode: "+255", name: "Tanzania" },
  { countryCode: "TH", dialCode: "+66", name: "Thailand" },
  { countryCode: "TL", dialCode: "+670", name: "Timor-leste" },
  { countryCode: "TG", dialCode: "+228", name: "Togo" },
  { countryCode: "TK", dialCode: "+690", name: "Tokelau" },
  { countryCode: "TO", dialCode: "+676", name: "Tonga" },
  { countryCode: "TT", dialCode: "+1868", name: "Trinidad And Tobago" },
  { countryCode: "TN", dialCode: "+216", name: "Tunisia" },
  { countryCode: "TR", dialCode: "+90", name: "Turkey" },
  { countryCode: "TM", dialCode: "+993", name: "Turkmenistan" },
  {
    countryCode: "TC",
    dialCode: "+1649",
    name: "Turks And Caicos Islands",
  },
  { countryCode: "TV", dialCode: "+688", name: "Tuvalu" },
  { countryCode: "UG", dialCode: "+256", name: "Uganda" },
  { countryCode: "UA", dialCode: "+380", name: "Ukraine" },
  { countryCode: "AE", dialCode: "+971", name: "United Arab Emirates" },
  { countryCode: "GB", dialCode: "+44", name: "United Kingdom" },
  { countryCode: "US", dialCode: "+1", name: "United States" },
  { countryCode: "UY", dialCode: "+598", name: "Uruguay" },
  { countryCode: "UZ", dialCode: "+998", name: "Uzbekistan" },
  { countryCode: "VU", dialCode: "+678", name: "Vanuatu" },
  { countryCode: "VA", dialCode: "+39", name: "Vatican City State" },
  { countryCode: "VE", dialCode: "+58", name: "Venezuela" },
  { countryCode: "VN", dialCode: "+84", name: "Viet Nam" },
  {
    countryCode: "VG",
    dialCode: "+1284",
    name: "Virgin Islands (british)",
  },
  {
    countryCode: "VI",
    dialCode: "+1340",
    name: "Virgin Islands (u.s.)",
  },
  {
    countryCode: "WF",
    dialCode: "+681",
    name: "Wallis And Futuna Islands",
  },
  { countryCode: "EH", dialCode: "+212", name: "Western Sahara" },
  { countryCode: "YE", dialCode: "+967", name: "Yemen" },
  { countryCode: "ZM", dialCode: "+260", name: "Zambia" },
  { countryCode: "ZW", dialCode: "+263", name: "Zimbabwe" },
];

export function getTimezones() {
  return [
    "Pacific/Midway",
    "Pacific/Honolulu",
    "Pacific/Marquesas",
    "America/Anchorage",
    "America/Los_Angeles",
    "America/Denver",
    "America/Monterrey",
    "America/New_York",
    "America/Santiago",
    "America/St_Johns",
    "America/Argentina/Buenos_Aires",
    "America/Noronha",
    "Atlantic/Azores",
    "Europe/London",
    "Europe/Berlin",
    "Europe/Athens",
    "Asia/Qatar",
    "Asia/Tehran",
    "Asia/Dubai",
    "Asia/Kabul",
    "Indian/Maldives",
    "Asia/Colombo",
    "Asia/Kathmandu",
    "Asia/Urumqi",
    "Asia/Yangon",
    "Asia/Bangkok",
    "Asia/Hong_Kong",
    "Australia/Eucla",
    "Asia/Tokyo",
    "Australia/Adelaide",
    "Australia/Sydney",
    "Australia/Lord_Howe",
    "Asia/Sakhalin",
    "Asia/Kamchatka",
    "Pacific/Chatham",
    "Pacific/Fakaofo",
    "Pacific/Kiritimati",
  ];
}

export const modelsMapDevicesOptions: CustomOptionType[] = [
  { value: "XPERT", label: "SLC X-PERT" },
  { value: "XTRA", label: "SLC X-TRA" },
  { value: "ADAPT2", label: "SLC ADAPT2" },
  { value: "ADAPTX", label: "SLC ADAPT" },
  { value: "CUBE3", label: "SLC CUBE3+" },
  { value: "CUBE43080", label: "SLC CUBE4 30-80 kVA" },
  { value: "CUBE40720", label: "SLC CUBE4 7,5-20 kVA" },
  { value: "DCS", label: "DC POWER-S" },
  { value: "DCL", label: "DC POWER-L" },
  { value: "EMI3", label: "EMi3" },
  { value: "TWINRT20003", label: "SLC TWIN RT2 700 VA-3 kVA" },
  { value: "TWINRT20410", label: "SLC TWIN RT2 4-10 kVA" },
  { value: "TWINPRO20003", label: "SLC TWIN PRO2 700 VA-3 kVA" },
  { value: "TWINPRO20410", label: "SLC TWIN PRO2 4-10 kVA" },
  { value: "TWINPRO20820", label: "SLC TWIN PRO2 8-20 kVA" },
  { value: "TWINPRO3", label: "SLC TWIN PRO3" },
  { value: "TWINRT3", label: "SLC TWIN RT3" },
];
export const modelsMapDevices = {
  XPERT: "SLC X-PERT",
  XTRA: "SLC X-TRA",
  ADAPT2: "SLC ADAPT2",
  ADAPTX: "SLC ADAPT",
  CUBE3: "SLC CUBE3+",
  CUBE43080: "SLC CUBE4 30-80 kVA",
  CUBE40720: "SLC CUBE4 7,5-20 kVA",
  DCS: "DC POWER-S",
  DCL: "DC POWER-L",
  EMI3: "EMi3",
  TWINRT20003: "SLC TWIN RT2 700 VA-3 kVA",
  TWINRT20410: "SLC TWIN RT2 4-10 kVA",
  TWINPRO20003: "SLC TWIN PRO2 700 VA-3 kVA",
  TWINPRO20410: "SLC TWIN PRO2 4-10 kVA",
  TWINPRO20820: "SLC TWIN PRO2 8-20 kVA",
  TWINPRO3: "SLC TWIN PRO3",
  TWINRT3: "SLC TWIN RT3",
};

export type ModelKey = keyof typeof modelsMapDevices;
export type ModelValue = (typeof modelsMapDevices)[ModelKey];

export const accountUse = [
  { value: 'Professional', label: 'Professional' },
  { value: 'Personal', label: 'Personal' },
];


export const accountRole = {
  Administrator: "admin",
  User: "user",
};

export const accountSelectRole = [
  { value: 'administrator', label: 'administrator' },
  { value: 'user', label: 'user' },
];

export const getFindSelectModel = (
  options: CustomOptionType[],
  labelToFind: string
): CustomOptionType | undefined => {
  if (!labelToFind) {
    return undefined;
  }
  const normalizedLabelToFind = labelToFind.toLowerCase();

  const selectedOption = options.find(
    (option) => option.value.toLowerCase() === normalizedLabelToFind
  );

  return selectedOption;
};


export interface ErrorResponse {
  error: {
    statusCode: number;
    name: string;
    message: string;
  };
}
export interface ServiceData {
  id: string;
  deviceIotId: string;
  name: string;
  status: string;
  additionalInfo: string;
  currentVersion: string;
  firstRemoteUpdate: number;
  lastRemoteUpdate: number;
  type: any;
}

export interface AuthError {
  error: {
    statusCode: number;
    name: string;
    message: string;
  };
}

export function getChartColors() {
  let chartColors = {
    green: "#7ce7ac",
    red: "#ff808b",
    blue: "#5e81f4",
    darkGreen: "#4A8765",
    yellow: "#F1F45E",
    orange: "#FFA500",
    darkOrange: "#b84214",
    darkYellow: "#FFD700",
    brown: "#996633",
    darkRed: "#ca2821",
  };
  return chartColors;
}



export const langOptions: CustomOptionType[] = [
  {
    value: "es",
    label: "Español",
    selected: false,
  },
  {
    value: "ca",
    label: "Català",
    selected: false,
  },
  {
    value: "en",
    label: "English",
    selected: false,
  },
];