import { CloudHistoryMeasuresSearchParams } from "@/utils/models/entities/History";
import { authService } from "./auth.service";
import { apiService } from "./api.service";
import { devicesAlarms } from '@/utils/constants/alarms';



export const historyService = {
    searchHistoryAlarms: async (params?: CloudHistoryMeasuresSearchParams) => {
        const url = `${process.env.REACT_APP_BASE_URL}/historyAlarms/search`; 
        let response = await apiService.post(url, params,authService.getToken());
        return response; 
    },

     getAlarmsByModel(model: string): any {
        if (devicesAlarms.hasOwnProperty(model)) {
          return devicesAlarms[model];
        } else {
          return null;
        }
      },
      

    getAlarmDescriptionByAddressAndBitFormat(addressAndBit: string, model: string): string {
        let alarmDesc: string = addressAndBit;
        model = model.toUpperCase().replace(/\-/gi, '');
        let alarms = this.getAlarmsByModel(model);

        let splittedAlarm: Array<string> = addressAndBit.replace('ALARM', '').split('b');

        if (splittedAlarm.length === 2) {
          let address: string = splittedAlarm[0];
          let bit: string = splittedAlarm[1];
      
          // Verificar si existe la dirección y el bit en el objeto alarms
          if (alarms[address] && alarms[address][bit]) {
            alarmDesc = alarms[address][bit];
          }
        }
        return alarmDesc;
      }
    
};