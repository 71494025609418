import Cookies from "js-cookie";
import { useUser } from "../context/userContext";
import { authService } from "@/services/auth.service";
import { userService } from "@/services/user.service";
import { IUser } from "../models";
import toast from "react-hot-toast";
import { t } from "i18next";
import i18n from "@/i18n";

const useAuth = () => {
  const { user, setUser } = useUser();

  const getToken = () => {
    return Cookies.get("SAL-nimbus-token") || "";
  };

  const putToken = (token: any) => {
    Cookies.set("SAL-nimbus-token", token);
  };

  const clearToken = () => {
    Cookies.remove("SAL-nimbus-token");
    Cookies.remove("SAL-nimbus-language");
    Cookies.remove("SAL-nimbus-userData");
  };

  const isLoggedIn = () => {
    return !!Cookies.get("SAL-nimbus-token");
  };
  const putUserData = async () => {
    const userResponse = await userService.getCurrentUserMe();
    setUser(userResponse);
  };
  const getCurrentUserHook = () => {
    return user!;
  };
  const getCurrentUserServer = () => {
    return userService
      .getCurrentUserMe()
      .then((res) => {
        setUser(res);
        return res;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const isDevicePinned = (deviceId: string): boolean => {
    const currentUser: IUser | null = getCurrentUserHook();
    return currentUser?.pinnedDevices?.includes(deviceId) ?? false;
  };

  const pinDevice = (deviceId: string) => {
    let currentUser: IUser = getCurrentUserHook();

    if (!currentUser.pinnedDevices) {
      currentUser.pinnedDevices = [deviceId];
    } else if (!currentUser.pinnedDevices.includes(deviceId)) {
      currentUser.pinnedDevices.push(deviceId);
    }
    const editUser = (({ id, ...rest }) => rest)(currentUser);

    userService
      .update(editUser, currentUser.id)
      .then((response: any) => {
        userService.getCurrentUserMe().then((user) => {
          toast.success(t("device.form.pinDeviceFixedPinned"));
          setUser(user);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const unpinDevice = (deviceId: string) => {
    let currentUser: IUser = getCurrentUserHook();

    if (currentUser.pinnedDevices) {
      currentUser.pinnedDevices = currentUser.pinnedDevices.filter(
        (id) => id !== deviceId
      );
    }
    const editUser = (({ id, ...rest }) => rest)(currentUser);

    userService
      .update(editUser, currentUser.id)
      .then((response: any) => {
        userService.getCurrentUserMe().then((user) => {
          toast.success(t("device.form.pinDeviceUnPinned"));
          setUser(user);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const login = async (payload: any) => {
    try {
      const response = await authService.login(payload);
      putToken(response.token);
      const userResponse = await userService.getCurrentUserMe();
      if (userResponse !== null) {
        setUser(userResponse);
        Cookies.set("SAL-nimbus-language", userResponse.languageSettings);
        i18n.changeLanguage(userResponse.languageSettings);
        Cookies.set("SAL-nimbus-userData", userResponse);
      } else {
        logout();
      }
    } catch (error) {
      throw error;
    }
  };

  const getTokenLanguage = () => {
    if (user?.languageSettings) {
      return user.languageSettings;
    } else {
      const cookie = Cookies.get("SAL-nimbus-language");
      return cookie || "en";
    }
  };

  const setTokenLanguage = (lang: string) => {
    const updatedUser = { ...user };
    updatedUser.languageSettings = lang;
    const editUser = (({ id, ...rest }) => rest)(updatedUser);
    
    userService
      .update(editUser, updatedUser.id)
      .then((response) => {
        userService.getCurrentUserMe().then((user) => {
          setUser(user);
          Cookies.set("SAL-nimbus-language", lang);
          Cookies.set("SAL-nimbus-userData", user);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const logout = () => {
    clearToken();
    setUser(null);
  };
  const hasRole = (role: string) => {
    return user?.roles ? user.roles.includes(role) : false;
  };

  return {
    isLoggedIn,
    isDevicePinned,
    getCurrentUserServer,
    setTokenLanguage,
    getTokenLanguage,
    putUserData,
    pinDevice,
    unpinDevice,
    login,
    logout,
    getToken,
    getCurrentUserHook,
    hasRole,
  };
};

export default useAuth;
