
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

import './index.css';
import { UserProvider } from './utils/context/userContext';
import Cookies from 'js-cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const language = Cookies.get("SAL-nimbus-language") || 'en';
i18n.changeLanguage(language);

root.render (
  // <React.StrictMode>
    <UserProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <App  />
        </Router>
      </I18nextProvider>
    </UserProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
