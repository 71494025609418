export const navigationRoutes = [
    {
      name: 'menu.device',
      href: '/devices',
      icon: 'la:microchip',
      roles: ['admin', 'user'],
    },
    {
      name: 'menu.user',
      href: '/users',
      icon: 'la:user-friends',
      roles: ['admin'],
    },
    {
      name: 'menu.notification',
      href: '/notifications',
      icon: 'la:bell',
      roles: ['admin', 'user'],
    },
  ];