import { useRef, useState, useEffect } from "react";
import { CustomIconDropdownType } from "./CustomIconDropdownType";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import CustomDropdown from "@/components/molecules/CustomDropdown";

const CustomIconDropdown = ({
  borderShadow = true,
  variant = "primary",
  disabled = false,
  className = "",
  dropdownItems,
  fixed = false,
  tooltip = "",
  icon = "",
  onClick,
}: CustomIconDropdownType) => {
  const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const setChangePropClickDropDown = (data: boolean) => {
    setIsOpen(data);
  };

  const setDropdownPosition = () => {
    const rect = dropdownRef?.current?.getBoundingClientRect();
    const h = window.innerHeight - (rect?.bottom || 0);
    rect && setPosition({ x: rect.left, y: rect.bottom, w: rect.width, h: h });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const scrollHandler = () => {
      setIsOpen(false);
    };

    setDropdownPosition();

    document.addEventListener("scroll", scrollHandler, true);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, isOpen, dropdownItems]);

  return (
    <div className={`relative ${className}`} ref={dropdownRef} onClick={onClick}>
      <CustomIconButton
        borderShadow={borderShadow}
        onClick={handleToggleOpen}
        className={className}
        disabled={disabled}
        variant={variant}
        tooltip={tooltip}
        icon={icon}
      />

      {isOpen && (
        <div className="fixed z-10">
          <CustomDropdown
            onActionClick={setChangePropClickDropDown}
            dropdownItems={dropdownItems}
            scroll={false}
            fixed={true}
            style={{
              top: position.y,
              left: position.x,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomIconDropdown;