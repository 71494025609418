import React from "react";
import LabeledText from "@/components/molecules/LabeledText";
import { t } from "i18next";
import { NotificationCollapsedType } from "./NotificationCollapsedType";
import { useNavigate } from "react-router-dom";

const NotificationCollapsed = ({
  notificationInfo,
}: NotificationCollapsedType) => {
  const navigate = useNavigate();

  const redirectToNotificationDetails = () => {
    navigate(`/notifications/${notificationInfo.id}/detail`);
  };

  return (
    <div onClick={redirectToNotificationDetails} className="grid grid-cols-2 lg:grid-cols-4 w-full pl-6">
      <LabeledText
        label={t("menu.notification")}
        text={notificationInfo.iotId}
        className={"col-span-1"}
      />
    </div>
  );
};
export default NotificationCollapsed;