import { useState } from "react";
import { t } from "i18next";
import { accountRole, getTimezones, modelsMapDevicesOptions, reloadPage, } from "@/utils/constants";
import { CreateDevice, Device, DeviceData, IUser } from "@/utils/models";
import { userService } from "@/services/user.service";
import { CustomOptionType } from "../CustomSelectRounded/CustomSelectRoundedType";
import deviceService from "@/services/device.service";
import toast from "react-hot-toast";

export const DeviceFormComponent = ({
  device,
  user,
}: {
  device?: any;
  user: IUser;
}) => {
  const [devices, setDevices] = useState<CreateDevice>({
    iotId: "",
    description: "",
    type: "",
    uuid: "",
    latitude: "",
    longitude: "",
    localIp: "",
    remoteIp: "",
    serialNumber: "",
    maintenanceContract: "",
    company: "",
    address: "",
    timezone: "",
  });
  const [selectedUsers, setSelectedUsers] = useState<IUser | null>(null);
  const [associateEmail, setAssociateEmail] = useState<CustomOptionType[]>([]);
  const isAdmin: boolean = user?.roles === accountRole.User ? false : true;
  const [errors, setErrors] = useState<Record<string, string>>({});

  const translationContractMaintenance = (options: any) => {
    return options.map((option: { value: any; label: any }) => ({
      value: option.value,
      label: t(`device.detail.contract.${option.label}`),
    }));
  };

  const setUsersOptionsMap = async (user: any) => {
    try {
      if (user) {
        const userPromises = user.map((user: any) =>
          userService.getUserById(user)
        );
        const responses = await Promise.all(userPromises);
        const newUserList: CustomOptionType[] = responses.map(
          (response: IUser) => ({
            label: response.email,
            value: response.id,
          })
        );
        return newUserList;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const setTimeZoneOptionsMap = (): CustomOptionType[] => {
    return getTimezones().map((zone) => ({
      value: zone,
      label: zone,
    }));
  };

  const setAssociateEmailUpdate = (OptionsSelectedUser: any) => {
    setAssociateEmail(OptionsSelectedUser);
  };
  const initializeDefaultValuesForSelectors = (
    options: CustomOptionType[],
    defaultValue: string
  ): CustomOptionType | undefined => {
    if (!defaultValue) {
      return undefined;
    }
    const normalizedDefaultValue = defaultValue.toLowerCase();

    const selectedOption = options.find(
      (option) => option.value.toLowerCase() === normalizedDefaultValue
    );

    return selectedOption;
  };

  const getFindDeviceModel = (
    options: CustomOptionType[],
    labelToFind: string
  ): CustomOptionType | undefined => {
    if (!labelToFind) {
      return undefined;
    }
    const normalizedLabelToFind = labelToFind.toLowerCase();

    const selectedOption = options.find(
      (option) => option.value.toLowerCase() === normalizedLabelToFind
    );

    return selectedOption;
  };

  const initializeEditForm = (
    editDevice: Device,
    isEdit: boolean,
    userEmail: CustomOptionType[]
  ) => {
    if (isEdit) {
      validationForm(editDevice);
      setDevices({
        iotId: editDevice?.iotId || "",
        description: editDevice.description || "",
        type: editDevice?.type || "",
        uuid: editDevice?.uuid || "",
        latitude: editDevice?.latitude || "",
        longitude: editDevice?.longitude || "",
        localIp: editDevice?.localIp || "",
        remoteIp: editDevice?.remoteIp || "",
        serialNumber: editDevice?.serialNumber || "",
        maintenanceContract: editDevice?.maintenanceContract || "",
        associateEmail: userEmail,
        company: editDevice?.company || "",
        address: editDevice?.address || "",
        timezone: editDevice?.timezone || "",
      });
      return device;
    } else {
      return devices;
    }
  };

  const isSaveDisabled = (formaData: any) => {
    const isUuidRequired = formaData.role === accountRole.User;

    return formaData.serialNumber &&
      (isUuidRequired ? formaData.uuid : true) &&
      formaData.type &&
      formaData.timezone &&
      formaData.address &&
      formaData.latitude &&
      formaData.longitude
      ? false
      : true;
  };

  const validationForm = (formData: any, isEdit?: boolean) => {
    const alphanumericRegex = /^[a-z0-9]+$/i;
    const newErrors: Record<string, string> = {};

    if (!formData.serialNumber) {
      newErrors.serialNumber = t(
        "device.form.formValidationError.serialNumberMandatory"
      );
    } else if (!alphanumericRegex.test(formData.serialNumber)) {
      newErrors.serialNumber = t(
        "device.form.formValidationError.serialNumberFormat"
      );
    }

    if (!formData.timezone) {
      newErrors.timezone = t(
        "device.form.formValidationError.timeZoneMandatory"
      );
    }
    if (!formData.type) {
      newErrors.type = t("device.form.formValidationError.modelMandatory");
    }

    if (!formData.maintenanceContract) {
      newErrors.maintenanceContract = t(
        "device.form.formValidationError.modelContractMandatory"
      );
    }

    if (user.roles === accountRole.User) {
      if (!isEdit) {
        if (!formData.uuid) {
          newErrors.uuid = t(
            "device.form.formValidationError.deviceUuidMandatory"
          );
        }
      }
    }

    if (
      !formData.latitude ||
      !formData.longitude ||
      formData.latitude.trim() === "" ||
      formData.longitude.trim() === ""
    ) {
      newErrors.latitude = t("device.form.formValidationError.coordMandatory");
      newErrors.longitude = t("device.form.formValidationError.coordMandatory");
    }
    if (!formData.address) {
      newErrors.address = t("device.form.formValidationError.addressMandatory");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getModelLabel = (value: string): string | undefined => {
    const model = modelsMapDevicesOptions.find(
      (option) => option.value === value
    );
    return model ? model.label : undefined;
  };

  const updateDevices = (formData: any): DeviceData => {
    const selectedUsers: Array<any> = [];
    let modelFullName: any = "";

    let {
      iotId,
      description,
      type,
      uuid,
      latitude,
      longitude,
      localIp,
      remoteIp,
      serialNumber,
      maintenanceContract,
      company,
      address,
      timezone,
    } = formData;

    iotId = `${type}_${serialNumber}`;
    modelFullName = getModelLabel(type);

    if (isAdmin) {
      formData.uuid = "";
      if (associateEmail) {
        associateEmail.map((el: CustomOptionType) => {
          selectedUsers.push(el.value);
        });
      }
    } else {
      if (user) {
        selectedUsers.push(user.id);
      }
    }

    const deviceData: DeviceData = {
      iotId: iotId || "",
      type: modelFullName || "",
      description: description || "",
      uuid: uuid || "",
      latitude: latitude.toString() || "",
      longitude: longitude.toString() || "",
      localIp: localIp || "",
      remoteIp: remoteIp || "",
      serialNumber: serialNumber || "",
      maintenanceContract: maintenanceContract || "",
      company: company || "",
      address: address || "",
      timezone: timezone || "",
      users: selectedUsers,
    };
    return deviceData;
  };

  const handleError = (err: any) => {
    const errorResponse = err?.error || {};
    const { statusCode, message } = errorResponse;

    if (statusCode === 422 || message === "deviceRegistration.invalidDeviceCode") {
      toast.error(t("device.form.toastSave.serialNumberNotValid"));
    } else if (statusCode === 409 || message === "error.device.exists") {
      toast.error(t("device.form.toastSave.errorDeviceExists"));
    } else {
      toast.error(t("device.form.toastSave.error"));
    }
  };

  const handleSuccess = (onClose: () => void) => {
    toast.success(t("device.form.toastSave.success"));
    onClose();
    const interval = reloadPage();
  };

  const doSave = (formData: any, onClose: () => void) => {
    if (!validationForm(formData)) {
      return;
    }

    formData = updateDevices(formData);
    const saveDevice = () => deviceService.save(formData, true);
    if (!isAdmin) {
      deviceService
        .validateDeviceCode(formData.iotId, formData.uuid)
        .then((res) => {
          if (res.isValid === true) {
            saveDevice()
              .then(() => handleSuccess(onClose))
              .catch(handleError);
          } else {
            toast.error(t("device.form.toastSave.invalidSerialNumberAndUUID"));
          }
        })
        .catch(() => {
          toast.error(t("device.form.toastSave.error"));
        });
    } else {
      saveDevice()
        .then(() => handleSuccess(onClose))
        .catch(handleError);
    }
  };
  const doPut = (formData: any, onClose: () => void, isEdit?: boolean) => {
    if (validationForm(formData, isEdit)) {
      formData = updateDevices(formData);

      deviceService
        .update(formData, device.id)
        .then((res) => {
          toast.success(t("device.form.toastSave.successEdit"));
          onClose();
          const interval = reloadPage();
        })
        .catch((err) => {
          toast.error(t("device.form.toastSave.error"));
        });
    }
  };

  return {
    errors,
    translationContractMaintenance,
    setTimeZoneOptionsMap,
    initializeDefaultValuesForSelectors,
    setUsersOptionsMap,
    initializeEditForm,
    setSelectedUsers,
    setAssociateEmailUpdate,
    getFindDeviceModel,
    isSaveDisabled,
    validationForm,
    doSave,
    doPut,
  };
};
