import { CustomFlowbiteTheme } from "flowbite-react";

export const toasterTheme = {
  style: {
    fontSize: "16px",
    color: "white",
    fontWeight: "bold",
  },
  duration: 6000,
  error: {
    style: {
      background: "rgb(239 68 68)",
    },
  },
  success: {
    style: {
      background: "rgb(34 197 94)",
    },
  },
};

export const tooltipTheme = {
  target: "w-fit",
  animation: "transition-opacity",
  arrow: {
    base: "absolute z-10 h-2 w-2 rotate-45",
    style: {
      dark: "bg-gray-900 dark:bg-gray-700",
      light: "bg-white",
      auto: "bg-white dark:bg-gray-700",
    },
    placement: "-4px",
  },
  base: "text-center absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm",
  hidden: "invisible opacity-0",
  style: {
    dark: "bg-gray-900 text-white dark:bg-gray-700",
    light: "border border-gray-200 bg-white text-gray-900",
    auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
  },
  content: "relative z-20",
};

export const floatingLabelTheme = {
  input: {
    default: {
      standard: {
        sm: "pl-0 pr-10 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] block py-2.5 w-full text-xs font-manropeSemiBold text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer disabled:cursor-not-allowed",
        md: "pl-0 pr-10 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] block py-2.5 w-full text-sm font-manropeSemiBold text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer disabled:cursor-not-allowed",
      },
    },
  },
  label: {
    default: {
      standard: {
        sm: "font-manropeRegular !z-10 absolute text-xs text-gray-500 dark:text-gray-400 transition-transform -translate-y-6 !scale-100 top-3 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-focus:text-blueGrey200 peer-focus:dark:text-blueGrey200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6",
        md: `font-manropeRegular !z-10 absolute text-xs text-gray-500 dark:text-gray-400 transition-transform -translate-y-6 !scale-100 -z-10 origin-[0] duration-300 peer-focus:left-0 peer-focus:text-blueGrey200 peer-focus:dark:text-blueGrey200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`,
      },
    },
  },
};

export const customSelectTheme = {
  field: {
    base: "relative w-full",
    select: {
      base: "!bg-transparent autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] block py-2.5 w-full text-sm font-manropeSemiBold text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer disabled:cursor-not-allowed !rounded-none !pl-0 !pr-10 ",
      sizes: {
        sm: "text-sm",
        md: "text-sm",
        lg: "text-sm",
      },
    },
  },
};

export const customSideNav: CustomFlowbiteTheme["sidebar"] = {
  root: {
    inner:
      "hidden md:block h-full overflow-y-auto overflow-x-hidden bg-neutralGrey200 py-4 px-3",
  },
};

export const textAreaTheme = 'pl-0 pr-2 autofill:shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] outline-none block py-2.5 w-full text-sm font-manropeSemiBold text-black bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-black focus:outline-none focus:ring-0 focus:border-black peer disabled:cursor-not-allowed';
