import {  useState } from "react";
import { isValidEmail, isValidPassword } from "@/utils/constants";
import { t } from "i18next";


export interface resetPasswordFormType {
    email: string;
    password: string;
    confirmPassword: string;
  }
export const ResetPasswordData = () => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateStep1 = (formaData: any) => {
    const newErrors: Record<string, string> = {};
    if (!formaData.email) {
      newErrors.email = t("user.formUser.formValidationError.emailMandatory");
    } else if (!isValidEmail(formaData.email)) {
      newErrors.email = t("user.formUser.formValidationError.emailFormat");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = (formaData: any) => {
    const newErrors: Record<string, string> = {};
    if (!formaData.password) {
      newErrors.password = t(
        "user.formUser.formValidationError.passwordMandatory"
      );
    } else if (!formaData.confirmPassword) {
      newErrors.confirmPassword = t(
        "user.formUser.formValidationError.passwordMandatory"
      );
    } else if (formaData.password !== formaData.confirmPassword) {
      newErrors.confirmPassword = t(
        "user.formUser.formValidationError.confirmPasswordMismatch"
      );
    } else if (!isValidPassword(formaData.password)) {
      newErrors.password = t(
        "user.formUser.formValidationError.passwordFormat"
      );
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  return {
    errors,
    
    validateStep1,
    validateStep2,
  };
};
