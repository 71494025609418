import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "@/components/organisms/CustomCard/CustomCard";
import CustomModal from "@/components/organisms/CustomModal";
import CustomLoader from "@/components/atoms/CustomLoader";
import ServiceModal from "@/components/organisms/ServiceModal";
import { ActionItemType } from "@/utils/models";
import { modeServiceFunction } from "@/components/organisms/ServiceModal/d";
import { useDeviceContext } from "@/utils/context/deviceContext";
import CustomInfoBox from "@/components/organisms/CustomInfoBox";
import CustomLayout from "@/components/atoms/CustomLayout";
import DeviceHeader from "@/components/organisms/DeviceHeader/DeviceHeader";
import { ServiceStatus } from "@/components/organisms/ServiceItem/d";
import { useDeviceStatus } from "@/utils/hooks/useDeviceStatus";
import ServiceList from "@/components/organisms/ServiceList/ServiceList";
import {  LogDisplay } from "./d";
const Service: React.FC = () => {
  const { t } = useTranslation();
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceOptions, setServiceOptions] = useState<string>(
    modeServiceFunction?.installService || ""
  );
  const { deviceInfo } = useDeviceContext();
  const onCloseServiceModal = () => setShowServiceModal(false);

  const {
    services,
    logs,
    loading,
    enabled,
    isRequiredService,
    getRefreshServiceStatus,
    isModbusService,
    updateService,
    uninstallService,
    getLogs,
  } = useDeviceStatus();
  const isAnyDeviceInProgress = () => {
    return services.some(
      (service) => service.status === ServiceStatus.IN_PROGRESS
    );
  };

  const deviceServices: ActionItemType[] = [
    {
      action: () => {
        setShowServiceModal(true);
        if (modeServiceFunction?.installService !== serviceOptions) {
          setServiceOptions(modeServiceFunction?.installService || "");
        }
      },
      text: t("device.generalData.installNewService"),
      disabled: isAnyDeviceInProgress() || deviceInfo?.status === "offline",
      icon: "la:plus",
    },
    {
      action: () => {
        setShowServiceModal(true);
        if (modeServiceFunction?.restartService !== serviceOptions) {
          setServiceOptions(modeServiceFunction?.restartService || "");
        }
      },
      text: t("device.generalData.restartService"),
      disabled: isAnyDeviceInProgress() || deviceInfo?.status === "offline",
      icon: "la:history",
    },
    {
      action: () => {
        setShowServiceModal(true);
        if (modeServiceFunction?.actionsDevice !== serviceOptions) {
          setServiceOptions(modeServiceFunction?.actionsDevice || "");
        }
      },
      text: t("device.generalData.actionsOnTheDevice"),
      disabled: isAnyDeviceInProgress() || deviceInfo?.status === "offline",
      icon: "la:edit",
    },
  ];

  return (
    <>
      <CustomLayout className="relative">
        <DeviceHeader title={t("device.detail.service")}   buttonIcon="la:refresh" buttonTitle={t('services.refreshPage')} onClickButton={() => getRefreshServiceStatus()}/>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-6">
          <CustomCard
            title={t("device.generalData.installedServices")}
            icon={"la:info-circle"}
            className="col-span-2"
            expandable={false}
            customDropdown={deviceServices}
          >
            {deviceInfo?.status === "offline" ? (
              <CustomInfoBox
                icon="la:info-circle"
                className="flex items-center "
                type="danger"
              >
                {" "}
                {t("services.status.disabled")}
              </CustomInfoBox>
            ) : (
              <>
                {loading ? (
                  <CustomLoader visible={loading} />
                ) : (
                  deviceInfo &&
                  services && (
                    <ServiceList
                      services={services}
                      deviceInfo={deviceInfo}
                      enabled={enabled}
                      isRequiredService={isRequiredService}
                      isModbusService={isModbusService}
                      isAnyDeviceInProgress={isAnyDeviceInProgress}
                      uninstallService={uninstallService}
                      updateService={updateService}
                    />
                  )
                )}
              </>
            )}
          </CustomCard>
          <CustomCard
            title={t("device.generalData.mqttLogs")}
            icon={"la:info-circle"}
            className="col-span-2"
            expandable={false}
            customIconButtonIcon="la:sync"
            customIconButtonOnClick={getLogs}
            graphicCardOptions={true}
          >
            {loading ? (
              <CustomLoader visible={loading} />
            ) : (
              <div className="flex flex-col w-full ">
                <LogDisplay log={logs} />
              </div>
            )}
          </CustomCard>
        </div>

        <CustomModal
          isOpen={showServiceModal}
          size="4xl"
          dismissible={true}
          onClose={onCloseServiceModal}
          title={t("device.generalData." + serviceOptions)}
          body={
            <ServiceModal
              onClose={onCloseServiceModal}
              serviceOptions={serviceOptions}
            ></ServiceModal>
          }
        />
      </CustomLayout>
    </>
  );
};

export default Service;
