import { CustomOptionType } from "@/components/molecules/CustomSelect";
import DeleteModal from "@/components/organisms/DeleteModal";
import { userService } from "@/services/user.service";
import { reloadPage } from "@/utils/constants/consts";
import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";
import { IUser } from "@/utils/models";
import { t } from "i18next";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

export const UserLogicComponent = ({ isEditing }: { isEditing: boolean }) => {
  const getSelectOptions = (userData: IUser[]) => {
    let optionsArrayOptions: CustomOptionType[] = [];
    userData.forEach((userData) => {
      optionsArrayOptions.push({
        label: userData.name,
        value: userData.name,
      });
    });
    return optionsArrayOptions;
  };
  const modalDeleteUser = (
    deleteModalElement: any,
    onCloseDeleteModal: () => void,
    refreshListData: () => Promise<void>
  ) => {
    userService
      .delete(deleteModalElement)
      .then(() => {
        toast.success(t("user.formUser.deleteModal.toastDelete.success"));
        onCloseDeleteModal();
        const interval = reloadPage();
      })
      .catch((err) => {
        toast.error(t("user.formUser.deleteModal.toastDelete.error"));
      });
  };

  const titleModalEdit = (editCurrentUser: any) => {
    let title = editCurrentUser
      ? t("user.formUser.editUser")
      : t("user.formUser.newUser");
    return title;
  };
  const titleModalDelete = (editCurrentUser: any) => {
    const confirmationText = t("user.formUser.deleteModal.confirmationText");
    const confirmationTextWarning = t(
      "user.formUser.deleteModal.confirmationTextWarning"
    );
    const userName = editCurrentUser?.name || "";

    return `${confirmationText} ${userName} ${confirmationTextWarning}`;
  };

  return {
    getSelectOptions,
    modalDeleteUser,
    titleModalEdit,
    titleModalDelete,
  };
};
