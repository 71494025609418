import React, { useState, useEffect, ChangeEvent } from "react";
import CustomInput from "@/components/molecules/CustomInput";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomButton from "@/components/atoms/CustomButton";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import useAuth from "@/utils/hooks/useAuth";
import { t } from "i18next";
import { getFindSelectModel, setCountryCodeMap } from "@/utils/constants";
import CustomSelect from "@/components/molecules/CustomSelect";
import { DynamicFieldPhoneType } from "./DynamicFieldPhoneType";
import { FieldValuePhoneNumber } from "./d";

const DynamicFieldPhone = ({
  maxFieldsCount = 5,
  name = "",
  additionalPhone = [],
  isEdit = false,
  statusCheck,
  onChange,
  className = "",
}: DynamicFieldPhoneType) => {
  const { getCurrentUserHook } = useAuth();
  const currentUser = getCurrentUserHook();
  const [showFields, setShowFields] = useState(false);
  const [formData, setFormData] = useState<FieldValuePhoneNumber[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (isEdit && additionalPhone.length > 0) {
      setShowFields(true);
      const initialData: FieldValuePhoneNumber[] = additionalPhone.map(
        (phone) => ({
          mobilePhone: phone.number || "",
          countryCode: phone.countryCode || "",
        })
      );
      setFormData(initialData);
    }
  }, []);

  const handleFieldChange =
    (index: number) =>
      (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
          const updatedData = prevData.map((field, i) =>
            i === index ? { ...field, [name]: value } : field
          );
          validateField(name, value, index);
          onChange?.(updatedData);
          return updatedData;
        });
      };

  const addAdditionalField = () => {
    if (formData.length < maxFieldsCount) {
      setFormData((prevData) => [
        ...prevData,
        { mobilePhone: "", countryCode: currentUser.countryCode || "" },
      ]);
    }
  };

  const removeAdditionalField = (index: number) => {
    setFormData((prevData) => {
      const updatedData = prevData.filter((_, i) => i !== index);
      setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
      onChange?.(updatedData);
      return updatedData;
    });
  };

  const validateField = (name: string, value: string, index: number) => {
    let error = "";
    if (!value) {
      error = t("user.formUser.formValidationError.nationalPhoneMandatory");
    } else if (name === "mobilePhone") {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(value)) {
        error = t("user.formUser.formValidationError.nationalPhoneFormat");
      }
    }
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = error;
      return newErrors;
    });
  };

  return (
    <div className={`my-6 mx-2 ${className}`}>
      <div className="flex justify-between gap-6 my-4">
        <CustomCheckbox
          label={name}
          name={name}
          checked={showFields}
          onChange={(e) => {
            statusCheck(e.target.checked);
            setShowFields(!showFields);
          }}
        />
        {showFields && (
          <CustomButton onClick={addAdditionalField}>{t("general.addField")}</CustomButton>
        )}
      </div>

      {showFields && (
        <form>
          <div className="pt-5">
            {formData.map((field, index) => (
              <div
                className="flex flex-col lg:flex-row items-center gap-2 my-4"
                key={index}
              >
                <div className="w-2/4">
                  <CustomSelect
                    hasSearch={true}
                    label={t("user.formUser.countryCode")}
                    options={setCountryCodeMap()}
                    name="countryCode"
                    defaultValue={getFindSelectModel(
                      setCountryCodeMap(),
                      field.countryCode || ""
                    )}
                    onChange={handleFieldChange(index)}
                  />
                </div>
                <div className="w-full flex-col">
                  <CustomInput
                    className={index === 0 ? "" : className}
                    label={`${name}${index}`}
                    type="number"
                    name="mobilePhone"
                    value={field.mobilePhone}
                    onChange={handleFieldChange(index)}
                    helperText={errors[index]}
                    danger={errors[index] ? true : false}
                  />
                </div>
                {index > 0 && (
                  <div
                    className="cursor-pointer flex justify-center"
                    onClick={() => removeAdditionalField(index)}
                  >
                    <CustomIcon icon="la:trash" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </form>
      )}
    </div>
  );
};

export default DynamicFieldPhone;
