import { Icon } from "@iconify/react/dist/iconify.js"
import { Tooltip } from "flowbite-react"
import { CustomIconType } from "./CustomIconType";
import { tooltipTheme } from "@/utils/theme";

const CustomIcon = ({
    onClick = () => { },
    tooltip = '',
    size = 'lg',
    className,
    icon
}: CustomIconType) => {
    return (
        <div className={`text-${size}`} onClick={(e) => onClick(e)}>
            {tooltip ? (
                <Tooltip content={tooltip} theme={tooltipTheme}>
                    <Icon
                        className={`cursor-pointer ${className}`}
                        icon={icon}
                    />
                </Tooltip>
            ) : (
                <Icon
                    className={className}
                    icon={icon}
                />
            )}
        </div>
    )
}

export default CustomIcon;