import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import CustomText from "@/components/atoms/CustomText";
import LabeledText from "@/components/molecules/LabeledText";
import CustomCard from "@/components/organisms/CustomCard";
import { DeviceCollapsedType } from "../DeviceCollapsed/DeviceCollapsedType";
import { userService } from "@/services/user.service";
import { IUser } from "@/utils/models";
import CustomLoader from "@/components/atoms/CustomLoader";
import { unixTimeToDate } from "@/utils/constants/consts";

interface UserAssociate {
  id?: String;
  email?: String;
}
const DeviceExpanded = ({ deviceInfo }: DeviceCollapsedType) => {
  const [userList, setUserList] = useState<UserAssociate[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (deviceInfo?.users) {
          const userPromises = deviceInfo.users.map((user: any) =>
            userService.getUserById(user)
          );
          const responses = await Promise.all(userPromises);
          const newUserList = responses.map((response: IUser) => ({
            id: response.id,
            email: response.email,
          }));
          setUserList(newUserList);
        } else {
          setUserList([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [deviceInfo.users]);

  return (
    <>
      {loading ? (
        <CustomLoader visible={loading} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 gap-4 mt-6 space-y-6 mb-6 w-full">
          <CustomCard
            title={t("device.generalData.generalData")}
            expandable={false}
            icon="la:network-wired"
            className="col-span-1 md:col-span-2  lg:col-span-10"
          >
            <div className="w-full pt-5 px-8 m-auto">
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6">
                <LabeledText
                  label={t("device.generalData.serialNumber")}
                  text={deviceInfo.iotId}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.model")}
                  text={deviceInfo.type}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.maintenanceContract")}
                  text={t(`device.detail.contract.${deviceInfo?.maintenanceContract?.toLowerCase()}`) || "-"}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.company")}
                  text={deviceInfo.company}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.description")}
                  text={deviceInfo.description}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.lastMeasurementDate")}
                  text={unixTimeToDate(deviceInfo?.lastPing)}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.lastUpdateDate")}
                  text={unixTimeToDate(deviceInfo?.updatedAt)}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.generalData.upsFirmware")}
                  text={deviceInfo.UPSrelease}
                  className={"col-span-1"}
                />

                <LabeledText
                  label={t("device.generalData.iotDeviceFirmware")}
                  text={deviceInfo.release}
                  className={"col-span-2"}
                />
              </div>
              <div className="flex justify-end">
                <Link to={`/device/${deviceInfo.id}/measures`}>
                  <div
                    className=" flex gap-2"
                  >
                    <div>
                      <CustomText
                        size="sm"
                        variant="secondary"
                        className="text-blueGrey200"
                      >
                        {t("menu.device")}
                      </CustomText>
                    </div>
                    <div className=" flex items-center justify-center">
                      <Icon icon="la:arrow-right" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </CustomCard>

          <CustomCard
            title={t("device.generalData.location.location")}
            expandable={false}
            icon="la:network-wired"
            className="col-span-1 md:col-span-1 lg:col-span-5"
          >
            <div className="w-full pt-5 px-8 m-auto">
              <div className="flex flex-col lg:grid lg:grid-cols-2 gap-6">
                <LabeledText
                  label={t("device.form.locationAddress")}
                  text={deviceInfo.address}
                  className={"col-span-2"}
                />
                <LabeledText
                  label={t("device.form.formLocation.longitude")}
                  text={deviceInfo.longitude}
                  className={"col-span-1"}
                />
                <LabeledText
                  label={t("device.form.formLocation.latitude")}
                  text={deviceInfo.latitude}
                  className={"col-span-1"}
                />
              </div>
            </div>
          </CustomCard>

          <CustomCard
            title={t("device.form.users")}
            expandable={false}
            icon="la:network-wired"
            className="col-span-1 md:col-span-1 lg:col-span-5"
          >
            <div className="w-full pt-5 px-8 m-auto">
              <div className="flex flex-col gap-2">
                <CustomText variant="secondary">
                  {t("device.form.users")}
                </CustomText>
                {userList &&
                  userList.length > 0 &&
                  userList.map((user: UserAssociate, index: any) => (
                    <div key={index}>{user.email}</div>
                  ))}
              </div>
            </div>
          </CustomCard>
        </div>
      )}
    </>
  );
};

export default DeviceExpanded;