import { useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { ModalBody, ModalFooter } from "flowbite-react";
import CustomButton from "@/components/atoms/CustomButton";
import CustomText from "@/components/atoms/CustomText";
import { ModalCreateDevicesType } from "./DeviceModalFormType";
import { DeviceFormComponent } from "./d";
import { CreateDevice, IUser, MaintenanceContractKey } from "@/utils/models";
import CustomInput from "@/components/molecules/CustomInput";
import CustomSelect, { CustomOptionType, } from "@/components/molecules/CustomSelect";
import CustomGoogleInput from "../CustomGoogleInput";
import { accountRole, handleFormChange, modelsMapDevicesOptions, } from "@/utils/constants";
import CustomSelectChips from "@/components/organisms/CustomChipsInput";
import useDynamicDataLoader from "@/utils/hooks/useDynamicDataLoader";
import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";
import CustomLoader from "@/components/atoms/CustomLoader";
import useAuth from "@/utils/hooks/useAuth";

const ModalCreateDevices = ({
  onClose,
  isEdit,
  device,
}: ModalCreateDevicesType) => {
  const [addressDisableField, setAddressDisableField] = useState<boolean>(false);
  const { getCurrentUserHook } = useAuth();
  const { data, fetchInitialData } = useDynamicDataLoader();
  const CurrentUser: IUser = getCurrentUserHook();
  const [editLoader, setEditLoader] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [emailAssociate, setEmailAssociate] = useState<CustomOptionType[]>([]);
  const [formData, setFormData] = useState<CreateDevice>({
    iotId: "",
    description: "",
    type: "",
    uuid: "",
    latitude: "",
    longitude: "",
    localIp: "",
    remoteIp: "",
    serialNumber: "",
    maintenanceContract: "None",
    company: "",
    address: "",
    timezone: "",
  });

  const {
    errors,
    setTimeZoneOptionsMap,
    translationContractMaintenance,
    setUsersOptionsMap,
    initializeDefaultValuesForSelectors,
    getFindDeviceModel,
    initializeEditForm,
    setAssociateEmailUpdate,
    isSaveDisabled,
    doSave,
    doPut,
  } = DeviceFormComponent({ device: device, user: CurrentUser });

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit && device) {
        setEditLoader(true);
        const editAssociateEmail = await setUsersOptionsMap(device?.users);
        if (editAssociateEmail) {
          setEmailAssociate(editAssociateEmail);
        } else {
          setEmailAssociate([]);
        }
        const editFormData = initializeEditForm(device, isEdit, emailAssociate);
        if (editFormData) {
          setFormData(editFormData);
        }
        setEditLoader(false);
      } else {
        setFormData(formData);
      }
    };

    fetchData();
  }, [isEdit, device]);

  const handleSearch = useCallback(
    (searchTerm: string) => {
      fetchInitialData(searchTerm, SearchParamsEnum.user);
    },
    [fetchInitialData]
  );

  const getAddressObject = (place: google.maps.places.PlaceResult) => {
    setFormData((prevState) => ({
      ...prevState,
      address: place.formatted_address || "",
      latitude: place.geometry?.location?.lat().toString() || "",
      longitude: place.geometry?.location?.lng().toString() || "",
    }));
    setAddressDisableField(true);
  };

  useEffect(() => {
    setSaveDisabled(isSaveDisabled(formData));
  }, [formData, isSaveDisabled]);

  return (
    <>
      {editLoader ? (
        <CustomLoader visible={editLoader} />
      ) : (
        <>
          <ModalBody className="px-10">
            <div className="flex justify-between">
              <CustomText
                size="lg"
                weight="bold"
                className="mb-7"
                children={t("device.form.generalData")}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-10">
              <CustomInput
                label={t("device.form.serial")}
                name={`serialNumber`}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.serialNumber ? errors.serialNumber : ""}
                danger={errors.serialNumber ? true : false}
                value={formData?.serialNumber}
                disabled={isEdit ? true : false}
                className={isEdit ? "col-span-3 " : "col-span-1"}
                required
              />

              {!isEdit && CurrentUser.roles === accountRole.User && (
                <CustomInput
                  label={t("device.form.uuid")}
                  name={"uuid"}
                  onChange={(e) => handleFormChange(e, setFormData, formData)}
                  helperText={errors.uuid ? errors.uuid : ""}
                  danger={errors.uuid ? true : false}
                  value={formData?.uuid}
                  className="col-span-1"
                  required
                />
              )}

              <CustomSelect
                hasSearch={true}
                label={t("device.form.model")}
                name={"type"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.type ? errors.type : ""}
                danger={errors.type ? true : false}
                defaultValue={getFindDeviceModel(
                  modelsMapDevicesOptions,
                  formData?.type
                )}
                options={modelsMapDevicesOptions}
                disabled={isEdit ? true : false}
                className={`${CurrentUser.roles === accountRole.User
                  ? "col-span-1"
                  : "col-span-2"
                  }`}
                required
              />
              <CustomInput
                label={t("device.form.description")}
                name={"description"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                value={formData?.description}
                className="col-span-1"
              />
              <CustomInput
                label={t("device.form.company")}
                name={"company"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                value={formData?.company}
                className="col-span-1"
              />
              <CustomSelect
                label={t("device.generalData.maintenanceContract")}
                name={"maintenanceContract"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                options={translationContractMaintenance(MaintenanceContractKey)}
                defaultValue={isEdit ?
                  initializeDefaultValuesForSelectors(
                    translationContractMaintenance(MaintenanceContractKey),
                    formData?.maintenanceContract)
                  :
                  initializeDefaultValuesForSelectors(
                    translationContractMaintenance(MaintenanceContractKey),
                    formData.maintenanceContract === "-" ? "-" : "None"
                  )}
                helperText={errors.maintenanceContract ? errors.maintenanceContract : ""}
                danger={errors.maintenanceContract ? true : false}
                className="col-span-1"
                required
              />
              <CustomSelect
                hasSearch={true}
                label={t("device.form.timezone")}
                name={"timezone"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.timezone ? errors.timezone : ""}
                danger={errors.timezone ? true : false}
                defaultValue={initializeDefaultValuesForSelectors(
                  setTimeZoneOptionsMap(),
                  formData?.timezone
                )}
                options={setTimeZoneOptionsMap()}
                className="col-span-2"
                required
              />
            </div>
            <div className="flex justify-between items-center">
              <CustomText
                size="lg"
                weight="bold"
                className="mb-5"
                children={t("device.form.searchLocation")}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10 py-2">
              <CustomGoogleInput
                label={t("device.form.locationAddress")}
                name={"address"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.address ? errors.address : ""}
                danger={errors.address ? true : false}
                className="col-span-2"
                onPlaceChanged={(place) => getAddressObject(place)}
                defaultValue={formData?.address}
                required
              />
              <CustomInput
                label={t("device.form.formLocation.latitude")}
                name={"latitude"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.latitude ? errors.latitude : ""}
                danger={errors.latitude ? true : false}
                type="number"
                className="col-span-1"
                value={formData?.latitude}
                disabled={addressDisableField}
                required
              />
              <CustomInput
                label={t("device.form.formLocation.longitude")}
                name={"longitude"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.longitude ? errors.longitude : ""}
                danger={errors.longitude ? true : false}
                type="number"
                className="col-span-1"
                value={formData?.longitude}
                disabled={addressDisableField}
                required
              />
            </div>

            {CurrentUser.roles === accountRole.Administrator && (
              <>
                <div className="flex justify-between">
                  <CustomText
                    size="lg"
                    weight="bold"
                    className="mb-7"
                    children={t("device.form.users")}
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10 py-2">
                  <CustomSelectChips
                    defaultValues={isEdit ? emailAssociate : []}
                    className={"col-span-2"}
                    hasSearch={true}
                    onSearch={handleSearch}
                    onChange={setAssociateEmailUpdate}
                    multiple={true}
                    options={data.map((e: IUser) => ({
                      value: e.id,
                      label: e.email,
                    }))}
                    label={t("device.form.users")}
                    name={"associateEmail"}
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="px-10">
            <div className="flex w-full justify-between">
              <CustomButton
                variant="secondary"
                leftIconName="la:times"
                onClick={() => onClose()}
                children={t("device.form.buttonCancel")}
              />
              <CustomButton
                variant="primary"
                leftIconName="la:save"
                onClick={() => {
                  isEdit ? doPut(formData, onClose, isEdit) : doSave(formData, onClose);
                }}
                children={
                  isEdit
                    ? t("device.form.titleEdit")
                    : t("device.form.buttonSave")
                }
                disabled={isEdit ? false : saveDisabled}
              />
            </div>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default ModalCreateDevices;
