import { useCallback, useEffect, useState } from "react";
import { IUser, Device } from "../models";
import { INotification } from "../models/entities/Notification";
import { CustomOptionType } from "@/components/molecules/CustomSelect";
import deviceService from "@/services/device.service";
import { notificationService } from "@/services/notifications.service";
import useAuth from "./useAuth";

const useNotificationList = () => {
  const [deviceOptions, setDeviceOptions] = useState<CustomOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const { getCurrentUserHook } = useAuth();
  const currentUser: IUser = getCurrentUserHook();

  const fetchDeviceOptions = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const [devicesResponse, notificationsResponse] = await Promise.all([
        deviceService.getDeviceSearchParams(),
        notificationService.getNotificationByUserId(currentUser.id)
      ]);

      const devices = devicesResponse.items || [];
      const notifications = notificationsResponse?.items || [];

      const notificationIotIds = new Set(
        notifications.map((notification: INotification) => notification.iotId)
      );

      const filteredDevices = devices.filter(
        (device: Device) => !notificationIotIds.has(device.iotId)
      );

      const options = filteredDevices.map((device: Device) => ({
        label: device.iotId,
        value: device.iotId,
      }));

      setDeviceOptions(options);
      setDisabled(options.length === 0);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch device options");
      setDisabled(true);
    } finally {
      setLoading(false);
    }
  }, [currentUser.id]);

  useEffect(() => {
    fetchDeviceOptions();
  }, [fetchDeviceOptions]);

  return { deviceOptions, loading, error, disabled };
};

export default useNotificationList;
