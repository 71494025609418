import React from "react";
import { CustomImageType } from "./CustomImageType";

const CustomImage = ({
  className = '',
  src,
  alt,
}: CustomImageType) => {
  return <img src={src} className={className} alt={alt} />;
};

export default CustomImage;