import { CustomTitleType } from "./CustomTitleType";

const CustomTitle = ({
  variant = 'primary',
  heading = 'h1',
  className = '',
  children,
}: CustomTitleType) => {

  const TitleTag = `${heading}` as keyof JSX.IntrinsicElements;

  const getStyle = () => {
    if (variant === 'secondary') return 'text-blueGrey200'
    return ''
  }

  const getBold = () => {
    if (heading === 'h1') return 'font-manropeExtraBold'
    else if (heading === 'h2') return 'font-manropeBold'
    return 'font-manropeSemiBold'
  }

  const getSize = () => {
    if (heading === 'h3') return 'text-2xl'
    return 'text-3xl'
  }

  return (
    <TitleTag className={`${getBold()} ${getStyle()} ${getSize()} ${className}`}>
      {children}
    </TitleTag>
  )
}

export default CustomTitle;