import { Device } from "@/utils/models";
import { t } from "i18next";

export const filters = {
  groups: {
    bypass: false,
    input: false,
    output: false,
  },
  types: {
    voltage: false,
    current: false,
    power: false,
  },
  phases: {
    L1: false,
    L2: false,
    L3: false,
  },
};
export interface DataSetSensor {
  title: string;
  sensor: string;
  data: {
    name: string;
    series: {
      value: number;
      name: number;
      tooltipText: string;
      type: "line" ; 
    }[];
  }[];
}
export type UseMeasureDataState = {
  measures: Measures[];
  groupSensors: GroupSensors;
  loading: boolean;
  error: string | null;
  searchHistoryAlarms: (
    deviceId: string,
    startDate: number,
    endDate: number,
    filters: any
  ) => void;
};
export interface Measures {
  title: string;
  sensor: string;
  data: ApexChartSeries[]; 
}
export interface DataSetSensors {
  title: string;
  sensor: string;
  data: SensorData[];
}
export interface SensorData {
  name: string;
  series: Series[];
}
export interface GroupSensors {
  bypass: Sensor[];
  input: Sensor[];
  output: Sensor[];
}

export interface Sensor {
  sensor: string;
  description: string;
  unit: string;
  type: string;
}


export interface Series {
  value: number;
  name: number;
  tooltipText: string;
}

export interface GetDate {
  iotId: string;
  fromDate: number;
  toDate: number;
}
export interface SensorDescriptor {
  title: string;
  icon: string;
  value: string;
}

export type DeviceContextType = {
  deviceId: string | undefined;
  deviceInfo?: Device | null;
  updateDeviceInfo?: (deviceId: string) => void;
};

export interface DataPoint {
  x: number | string;
  y: number;
}

export interface ApexChartSeries {
  name: string;
  data: DataPoint[];
}

export interface PowerChartProps {
  measureData: ApexChartSeries[];
  measureNameSeriesData: string[];
  collapsed: boolean;
  expanded?: boolean;
  loading: boolean;
}


export type UnitMeasureSensor = {
  sensor: string;
  description: string;
  unit: string;
  type: string;
};

export type UnitMeasureSensorArray = {
  [key: string]: Sensor[];
};


export const unitMeasureSensorArray: UnitMeasureSensorArray = {
  "Bypass": [
      {
          sensor: "desc.byp_volt",
          description: "desc.byp_volt_r, desc.byp_volt_s, desc.byp_volt_t",
          unit: "V",
          type: 'measure.units.voltage'
      }
  ],
  "Input": [
      {
          sensor: "desc.in_volt",
          description: "desc.in_volt_r, desc.in_volt_s, desc.in_volt_t",
          unit: "V",
          type: 'measure.units.voltage'
      },
      {
          sensor: "desc.in_cur",
          description: "desc.in_cur_r, desc.in_cur_s, desc.in_cur_t",
          unit: "A",
          type: 'measure.units.current'
      }
  ],
  "Output": [
      {
          sensor: "desc.out_volt",
          description: "desc.out_volt_r, desc.out_volt_s, desc.out_volt_t",
          unit: "V",
          type: 'measure.units.voltage'
      },
      {
          sensor: "desc.out_cur",
          description: "desc.out_cur_r, desc.out_cur_s, desc.out_cur_t",
          unit: "A",
          type: 'measure.units.current'
      },
      {
          sensor: "desc.out_acp",
          description: "desc.out_acp_r, desc.out_acp_s, desc.out_acp_t",
          unit: "kW",
          type: 'measure.units.power'
      },
      {
          sensor: "desc.out_app",
          description: "desc.out_app_r, desc.out_app_s, desc.out_app_t",
          unit: "kVA",
          type: 'measure.units.power'
      }
  ]
};