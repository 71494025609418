import React, { useEffect, useState } from "react";
import CustomTabMenu from "@/components/molecules/CustomTabMenu";
import { t } from "i18next";
import { ExpandedMeasureCardType } from "./ExpandedMeasureCardType";
import CustomLoader from "@/components/atoms/CustomLoader";
import PowerChart from "@/components/molecules/PowerCurveChart/PowerCurveChart";

const ExpandedMeasureCard = ({
  measures,
  expanded,
  loading,
  selectedTabItem,
}: ExpandedMeasureCardType) => {
  const [selectedTab, setSelectedTab] = useState(selectedTabItem);
  const [tabOptions, setTabOptions] = useState<string[]>([]);
  const [measureSeries, setMeasureSeries] = useState<string[]>([]);

  useEffect(() => {
    if (measures) {
      const options = measures.map((measure: any) => {
        const translatedName = t(`alarm.${measure.sensor}`);
        setMeasureSeries((prevSeries) => [...prevSeries, translatedName]);
        return translatedName;
      });

      setTabOptions(options);
    }
  }, [measures]);

  function handleTabChange(index: number) {
    setSelectedTab(index);
  }

  return (
    <>
      {loading ? (
        <CustomLoader visible={loading} />
      ) : (
        <div className="h-[50rem]">
          <div className="py-4 lg:px-10 md:px-10 px-4">
            {measures.length > 1 && (
              <CustomTabMenu
                tabs={measures.length}
                currentTab={selectedTab}
                onChange={handleTabChange}
                options={tabOptions}
              />
            )}
          </div>
          <div className="mx-6">
            {measures.length > 0 &&
              measures.map((measure, index) =>
                selectedTab === index ? (
                  <PowerChart
                    key={index}
                    collapsed={false}
                    measureData={measure.data}
                    measureNameSeriesData={measureSeries}
                    expanded={expanded}
                    loading={loading}
                  />
                ) : null
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpandedMeasureCard;
