import CustomButton from "@/components/atoms/CustomButton";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomLoader from "@/components/atoms/CustomLoader";
import GoBackHeader from "@/components/molecules/CustomGoBackHeader";
import HeaderTitle from "@/components/molecules/HeaderTitle/HeaderTitle";
import LabeledText from "@/components/molecules/LabeledText";
import CustomCard from "@/components/organisms/CustomCard";
import CustomModal from "@/components/organisms/CustomModal";
import ModalUserForm from "@/components/organisms/ModaUserForm";
import NotificationListTable from "@/components/organisms/NotificationListTable";
import useDeviceListNotification from "@/utils/hooks/useDeviceListNotification";
import { Device } from "@/utils/models";
import { INotification } from "@/utils/models/entities/Notification";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  let userId: string = "";
  if (id) {
    userId = id;
  }

  const { currentUser, devices, notificationList, loading, error } =
    useDeviceListNotification(userId);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const processNotifications = (
    devices: Device[],
    notifications: INotification[]
  ): INotification[] => {
    let notificationList: INotification[] = [...notifications];

    const notificationIotIds = new Set(
      notifications.map((notification) => notification.iotId)
    );

    devices.forEach((device) => {
      if (!notificationIotIds.has(device.iotId)) {
        notificationList.push({
          id: device.id,
          userId: userId,
          iotId: device.iotId,
          locale: "ca",
          sms: false,
          mail: false,
          additionalPhone: [],
          additionalMail: [],
        });
      }
    });

    return notificationList;
  };

  const processedNotifications =
    !loading && !error ? processNotifications(devices, notificationList) : [];

  return (
    <>
      {loading ? (
        <CustomLoader visible={loading} />
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="flex h-full w-full">
          <div className="w-full bg-neutralGrey100">
            <GoBackHeader goBackText={t("user.goBackUserList")} href="/users" />
            <div>
              <CustomLayout className="relative pb-0">
                <div className="flex gap-2 mb-6">
                  <HeaderTitle
                    title={
                      t("user.notificationTable.detailUser") +
                      " " +
                      currentUser?.name
                    }
                    className="flex items-center w-full my-0 py-0"
                  />
                  <CustomButton
                    variant="primary"
                    leftIconName="la:edit"
                    onClick={() => setShowCreateModal(true)}
                  >
                    {t("user.notificationTable.edit")}
                  </CustomButton>
                </div>
                <div className="my-5">
                  <CustomCard
                    padding="lg"
                    expandable={false}
                    title={t("user.notificationTable.userInformation")}
                    icon={"la:info-circle"}
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                      <LabeledText
                        label={t("user.nameAndSurname")}
                        text={currentUser?.name + " " + currentUser?.surname}
                        className={"col-span-1"}
                      />
                      <LabeledText
                        label={t("user.email")}
                        text={currentUser?.email}
                        className={"col-span-1"}
                      />
                      <LabeledText
                        label={t("user.formUser.phone")}
                        text={
                          (currentUser?.countryCode ?? "-") +
                          " " +
                          (currentUser?.nationalPhone ?? "-")
                        }
                        className={"col-span-1"}
                      />
                      <LabeledText
                        label={t("user.rol")}
                        text={currentUser?.roles}
                        className={"col-span-1"}
                      />
                      <LabeledText
                        label={t("user.formUser.accountUsage.field")}
                        text={
                          currentUser?.professional
                            ? t("user.formUser.accountUsage.professional")
                            : t("user.formUser.accountUsage.personal")
                        }
                        className={"col-span-1"}
                      />
                      <LabeledText
                        label={t("user.formUser.languagePreference")}
                        text={currentUser?.languageSettings}
                        className={"col-span-1"}
                      />
                    </div>
                  </CustomCard>
                </div>
                <div className="my-5">
                  <CustomCard
                    padding="lg"
                    expandable={false}
                    title={t("user.notificationTable.detailNotification")}
                    icon={"la:info-circle"}
                  >
                    <NotificationListTable
                      NotificationInfo={processedNotifications}
                    ></NotificationListTable>
                  </CustomCard>
                </div>
              </CustomLayout>
            </div>
          </div>
        </div>
      )}

      <CustomModal
        isOpen={showCreateModal}
        size="4xl"
        icon={"la:edit"}
        onClose={onCloseCreateModal}
        title={t("user.formUser.editUser")}
        body={
          <ModalUserForm
            onClose={onCloseCreateModal}
            isEdit={true}
            user={currentUser}
          />
        }
      />
    </>
  );
};

export default UserDetails;
