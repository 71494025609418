import { authService } from "./auth.service";
import { apiService } from "./api.service";
import Cookies from "js-cookie";
export const userService = {

  create: async (user: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/`;
    let response = await apiService.post(url, user, authService.getToken());
    return response;
  },
  delete: async (userId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
    let response = await apiService.delete(url, authService.getToken());
    return response;
  },
  update: async (user: any, userId: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/${userId}`;
    let response = await apiService.put(url, user, authService.getToken());
    return response;
  },

  getCurrentUserId: () => {
    const user = JSON.parse(Cookies.get("SAL-nimbus-userData") as string);
    return user.id || "";
  },

  getUserData: () => {
    const user = JSON.parse(Cookies.get("SAL-nimbus-userData") as string);
    console.log(user);
    return user.id || "";
  },

  getCurrentUserMe: async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/me`;
    return await apiService.get(url, authService.getToken());
  },

  changeUserPassword: async (payload: any) => {
    const url = `${
      process.env.REACT_APP_BASE_URL
    }/user/${userService.getCurrentUserId()}/password`;
    console.log(userService.getCurrentUserId());
    return await apiService.put(url, payload, authService.getToken());
  },

  getUserById: async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/user/${id}`;
    let response = await apiService.get(url, authService.getToken());
    return response;
  },

  sendVerifyRequest: async (email: string, password: string) => {
    const payload = {
      'email': email,
      'password': password
    };
    const url = `${process.env.REACT_APP_BASE_URL}/account-verification/request`;
    let response = await apiService.post(url, payload, authService.getToken());
    return response;
  },

  searchUsers: async (params?: string) => {
    if (!params) {
      params = "";
    }
    const url = `${process.env.REACT_APP_BASE_URL}/user/search`;
    let response = await apiService.get(url, authService.getToken(), params);
    return response;
  },
};

