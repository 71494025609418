import React, { useEffect, useState } from "react";
import { useDeviceContext } from "@/utils/context/deviceContext";
import { useAlarmHistory } from "@/utils/hooks/useAlarmHistory";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomEmptyList from "@/components/molecules/CustomEmptyList";
import CustomCard from "@/components/organisms/CustomCard/CustomCard";
import HistoryAlarmsTable from "@/components/organisms/HistoryAlarmTable/HistoryAlarmTable";
import { t } from "i18next";
import CustomLayout from "@/components/atoms/CustomLayout"
import DeviceHeader from "@/components/organisms/DeviceHeader/DeviceHeader";

interface GetDate {
  iotId: string;
  limit: number;
  skip: number;
  fromDate: number;
  toDate: number;
  order: string[];
}

const Alarms: React.FC = () => {
  const { deviceInfo } = useDeviceContext();
  const { alarms, loading, searchHistoryAlarms } = useAlarmHistory();
  const today = new Date();
  const toDateUnix = Math.floor(today.getTime() / 1000);

  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(yesterday.getDay() - 40);
  const fromDateUnix = Math.floor(yesterday.getTime() / 1000);

  const [formData, setFormData] = useState<GetDate>({
    iotId: deviceInfo?.iotId || "",
    limit: 15,
    skip: 0,
    fromDate: fromDateUnix,
    toDate: toDateUnix,
    order: [""],
  });

  useEffect(() => {
    searchHistoryAlarms(formData);
  }, []);

  const onDayChange = (e: any) => {
    const { start, end } = e;
    const fromDayChange = new Date(start).getTime() / 1000;
    const toDayChange = new Date(end).getTime() / 1000;
    setFormData((prevData) => ({
      ...prevData,
      fromDate: fromDayChange,
      toDate: toDayChange,
      skip: 0,
    }));
  };

  const onSearch = () => {
    searchHistoryAlarms(formData);
  };

  return (
    <>
      <CustomLayout className="relative ">
        <DeviceHeader
          title={t("device.detail.alarms")}
          onChangeDayRange={onDayChange}
          onClickIconButton={onSearch}
          buttonIcon="la:search"
          buttonTitle="alarm.Search"
        />
        {loading ? (
          <CustomLoader visible={loading} fixed />
        ) : (
          <div className="my-6">
            <CustomCard
              padding="lg"
              expandable={false}
              title={t("device.detail.activeAlarmsCount")}
              icon={"la:info-circle"}
            >
              {alarms.length > 0 ? (
                <HistoryAlarmsTable HistoryAlarmInfo={alarms} />
              ) : (
                <CustomEmptyList title={t("alarm.emptyListText")} />
              )}
            </CustomCard>
          </div>
        )}
      </CustomLayout>
    </>
  );
};

export default Alarms;
