import React, { useState } from "react";
import clsx from "clsx";
import { SideNavProfileType } from "./SideNavProfileType";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomDropdown from "@/components/molecules/CustomDropdown";
import useAuth from "@/utils/hooks/useAuth";

const SideNavProfile = ({ dropdownItems }: SideNavProfileType) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { getCurrentUserHook } = useAuth();
  const CurrentUser: any = getCurrentUserHook();

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleToggleOpen}
      onBlur={() => setIsOpen(false)}
      tabIndex={0}
      className={clsx(
        "relative cursor-pointer w-full inline-block gap-4 p-3 rounded-3xl items-center justify-center text-blueGrey100 border-transparent mb-4 border text-sm font-medium",
        {
          "text-blueGrey100 !border-neutralGrey200 bg-white":
            !isOpen && isHovered,
          "text-salicru !border-neutralGrey200 bg-white": isOpen,
        }
      )}
    >
      <div className="flex items-center">
        <CustomIcon icon="la:user-circle" className="w-7 h-7" />
        {(isOpen || isHovered) && CurrentUser && CurrentUser.name && (
          <div className="ml-3 font-manropeSemiBold">
            <span className="">{CurrentUser.name}</span>
          </div>
        )}
      </div>

      {isOpen && (
        <div className="">
          <CustomDropdown
            variant="secondary"
            dropdownItems={dropdownItems}
          ></CustomDropdown>
        </div>
      )}
    </div>
  );
};

export default SideNavProfile;