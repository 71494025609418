import { CustomLoaderType } from "./CustomLoaderType";
import { Icon } from "@iconify/react";
import "./CustomLoader.scss";
import CustomTitle from "../CustomTitle/CustomTitle";
import CustomText from "../CustomText/CustomText";
import { t } from "i18next";

const CustomLoader = ({
  showIcon = true,
  rounded = false,
  className = '',
  size = "5xl",
  subtitle,
  visible,
  title = t("general.loading"),
  fixed,
}: CustomLoaderType) => {
  return (
    <div className={`loader flex ${visible ? "flex" : "hidden"} ${fixed ? 'fixed' : 'absolute'} ${rounded ? "rounded-3xl" : ""} ${className}`}>
      <div className="loader__container">
        <div className={`loader__wrapper ${fixed ? 'loader__wrapper--fixed' : ''}`}>
          {showIcon &&
            <Icon className={`text-${size} mb-5`} icon={"eos-icons:bubble-loading"} />
          }
          {title &&
            <CustomTitle variant="primary" heading="h3">{title}</CustomTitle>
          }
          {subtitle &&
            <CustomText className="mt-4">{subtitle}</CustomText>
          }
        </div>
      </div>
    </div>
  );
}

export default CustomLoader;