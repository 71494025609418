import CustomIcon from "@/components/atoms/CustomIcon/CustomIcon";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomText from "@/components/atoms/CustomText/CustomText";
import CustomCard from "@/components/organisms/CustomCard";
import DeviceHeader from "@/components/organisms/DeviceHeader/DeviceHeader";
import { t } from "i18next";

const Synoptic: React.FC = () => {

  return (
    <>
      <CustomLayout className="relative">
      <DeviceHeader
              title={t("device.detail.synoptic")}
            />
        <div className="my-6">
          <CustomCard
            padding="lg"
            expandable={false}
            title={t("device.detail.synoptic")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-3 gap-6 mt-6">
                <CustomText weight="bold">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="bold">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="regular"> </CustomText>
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="regular"> </CustomText>
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
              </div>
              <div className="grid gap-6 mt-6">
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
              </div>
            </>
          </CustomCard>
        </div>
        <div className="my-6">
          <CustomCard
            padding="lg"
            expandable={false}
            title={t("device.generalData.selectStatus")}
            icon={"la:info-circle"}
          >
            <>
            </>
          </CustomCard>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <CustomCard
            title={t("device.generalData.alarmsBlock1")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-2 gap-6 mt-6">
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
              </div>
            </>
          </CustomCard>
          <CustomCard
            title={t("device.generalData.alarmsBlock2")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-2 gap-6 mt-6">
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
              </div>
            </>
          </CustomCard>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
          <CustomCard
            title={t("device.generalData.alarmsBlock3")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-2 gap-6 mt-6">
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
              </div>
            </>
          </CustomCard>
          <CustomCard
            title={t("device.generalData.alarmsBlock4")}
            icon={"la:info-circle"}
          >
            <>
              <div className="grid grid-cols-2 gap-6 mt-6">
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
                <CustomText weight="regular">
                  Lorem ipsum dolor sit amet
                </CustomText>
                <CustomIcon icon="la:check" size="xl" />
              </div>
            </>
          </CustomCard>
        </div>
      </CustomLayout>
    </>
  );
};

export default Synoptic;