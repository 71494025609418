import HeaderTitle from "@/components/molecules/HeaderTitle/HeaderTitle";
import { DeviceHeaderType } from "./DeviceHeaderType";
import CustomDateRounded from "../CustomDateRounded/CustomDateRounded";
import { t } from "i18next";
import CustomButton from "@/components/atoms/CustomButton/CustomButton";
import CustomIconButton from "@/components/atoms/CustomIconButton/CustomIconButton";

const DeviceHeader = ({
  title,
  onChangeDayRange,
  onClickButton,
  onClickIconButton,
  buttonTitle = "",
  buttonIcon = "",
  range = true
}: DeviceHeaderType) => {
  return (
    <div className="w-full flex justify-between">
      <div>
        <HeaderTitle
          title={title}
          className="flex"
        />
      </div>
      <div className="flex gap-x-5 items-center">
        <div>
          {onChangeDayRange && (
            <CustomDateRounded
              maxDayRange={40}
              range={range}
              onChange={onChangeDayRange}
              name="date"
            />
          )}
        </div>
        <div>
          {onClickButton && (
            <CustomButton onClick={onClickButton} leftIconName={buttonIcon}>
              {t(buttonTitle)}
            </CustomButton>            
          )}
          {onClickIconButton && (
            <CustomIconButton icon={buttonIcon} onClick={onClickIconButton}></CustomIconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceHeader;