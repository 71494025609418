import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "flowbite-react";
import { CustomButtonType } from "./CustomButtonType";

const CustomButton = ({
  variant = 'primary',
  disabled = false,
  className = '',
  pill = true,
  rightIconName,
  leftIconName,
  children,
  onClick,
  type,
}: CustomButtonType) => {

  const getStyleClasses = () => {
    return (variant === 'primary') ? 
      `focus:outline-none text-white bg-gray-800 border border-transparent enabled:hover:bg-gray-900 focus:ring-gray-300 dark:bg-gray-800 dark:enabled:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700 rounded-full focus:ring-2 ${className}`
    : (variant === 'white') ?
      `bg-white shadow-secondaryButton text-gray-700 enabled:hover:bg-gray-100 focus:ring-0 focus:border-0 ${className}`
    : 
      `bg-neutralGrey100 shadow-secondaryButton text-blueGrey200 enabled:hover:bg-neutralGrey300 focus:ring-0 focus:border-0 ${className}`;
  };


  return (
    <Button
      onClick={!disabled && onClick ? onClick : undefined}
      className={`px-4 py-2 ${getStyleClasses()}`}
      disabled={disabled}
      color="dark"
      type={type}
      pill={pill}
    >
      {leftIconName && <Icon icon={leftIconName} className="w-6 h-6 mr-2" />}
      <p className="w-max font-bold">
        {children}
      </p>
      {rightIconName && <Icon icon={rightIconName} className="w-6 h-6 ml-2" />}
    </Button>
  );

}

export default CustomButton;