import CustomInput from "@/components/molecules/CustomInput/CustomInput";
import { ServiceModalType } from "./ServiceModalType";
import React, { ChangeEvent, useState } from "react";
import { ModalBody, ModalFooter } from "flowbite-react";
import CustomText from "@/components/atoms/CustomText";
import CustomButton from "@/components/atoms/CustomButton";
import { t } from "i18next";
import { ServiceFormData } from "./d";
import { useDeviceStatus } from "@/utils/hooks/useDeviceStatus";

const ServiceModal: React.FC<ServiceModalType> = ({
  serviceOptions,
  onClose,
}) => {
    const {
      installService,
      restartService,
      launchBatteryTest,
    } = useDeviceStatus();

  const [formData, setFormData] = useState<ServiceFormData>({
    serviceName: "",
    serviceUrl: "",
    battType: "",
    battValue: "",
  });

  const [formErrors, setFormErrors] = useState<ServiceFormData>({
    serviceName: "",
    serviceUrl: "",
    battType: "",
    battValue: "",
  });

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const el = e.currentTarget ? e.currentTarget : e.target;
    if (el) {
      setFormData({
        ...formData,
        [el.name]: el.value,
      });

      setFormErrors({
        ...formErrors,
        [el.name]: undefined,
      });
    }
  };

  const validateInstallService = () => {
    const errors: any = {};
    if (!formData.serviceName) {
      errors.serviceName = t('device.deleteModal.error.emptyField');
    }
    if (!formData.serviceUrl) {
      errors.serviceUrl = t('device.deleteModal.error.emptyField');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateRestartService = () => {
    return true;
  };

  const validateBatteryTest = () => {
    const errors: any = {};
    if (!formData.battType) {
      errors.battType = t('device.deleteModal.error.emptyField');
    }
    if (!formData.battValue) {
      errors.battValue = t('device.deleteModal.error.emptyField');
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleServiceAction = () => {
    let isValid = false;

    switch (serviceOptions) {
      case "installNewService":
        isValid = validateInstallService();
        break;
      case "restartService":
        isValid = validateRestartService();
        break;
      default:
        isValid = validateBatteryTest();
        break;
    }

    if (isValid) {
      switch (serviceOptions) {
        case "installNewService":
          installService(formData,onClose);
          break;
        case "restartService":
          restartService('device-mqtt',onClose)
          break;
        default:
           launchBatteryTest(formData,onClose);
          break;
      }
    }
  };

  let modalContent: JSX.Element;

  switch (serviceOptions) {
    case "installNewService":
      modalContent = (
        <div className="p-4">
          <CustomInput
            label={t("device.generalData.services.name")}
            name="serviceName"
            onChange={handleFormChange}
            className="pb-3"
            helperText={formErrors.serviceName}
            danger={formErrors.serviceName ? true : false}
          />
          <CustomInput
            label={t("device.generalData.services.packageURL")}
            name="serviceUrl"
            onChange={handleFormChange}
            helperText={formErrors.serviceUrl}
            danger={formErrors.serviceUrl ? true : false}
          />
        </div>
      );
      break;
    case "restartService":
      modalContent = (
        <div className="p-4">
          <CustomText>{t("device.generalData.restartServiceText")}</CustomText>
        </div>
      );
      break;
    default:
      modalContent = (
        <div className="p-4">
          <CustomInput
            label={t("device.generalData.services.batteryTestType")}
            name="battType"
            onChange={handleFormChange}
            className="pb-3"
            helperText={formErrors.battType}
            danger={formErrors.battType ? true : false}
          />
          <CustomInput
            label={t("device.generalData.services.batteryTestValue")}
            name="battValue"
            onChange={handleFormChange}
            type="number"
            helperText={formErrors.battValue}
            danger={formErrors.battValue ? true : false}
          />
        </div>
      );
      break;
  }
  
  return (
    <>
      <ModalBody className="px-10">
        <div className="grid grid-cols-1 gap-6 mb-4">{modalContent}</div>
      </ModalBody>
      <ModalFooter className="px-10">
        <div className="flex w-full justify-between">
          <CustomButton
            variant="secondary"
            leftIconName="la:times"
            onClick={onClose}

            children={t("general.cancel")}
          />
          <CustomButton
            variant="primary"
            onClick={handleServiceAction}
            children={t("device.generalData." + serviceOptions)}
          />
        </div>
      </ModalFooter>
    </>
  );
};

export default ServiceModal;