import { useState, useEffect } from 'react';

const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isLargeDesktop, setIsLargeDesktop] = useState(false);
    const [isPortrait, setIsPortrait] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth, innerHeight } = window; 
            setIsMobile(innerWidth <= 767);
            setIsTablet(innerWidth > 767 && innerWidth <= 1024);
            setIsDesktop(innerWidth > 1024);
            setIsLargeDesktop(innerWidth >= 1440);
            if (isMobile || isTablet) {
                setIsPortrait(innerHeight > innerWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobile, isTablet]);

    return {isMobile, isTablet, isDesktop, isLargeDesktop, isPortrait };
};

export default useDeviceType;