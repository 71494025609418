import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { CustomCardType } from "./CustomCardType";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import CardContext from "@/utils/context/CardContext";
import CustomIconDropdown from "../CustomIconDropdown";
const CustomCard = ({
  onExpand,
  expandable = true,
  customIconButtonIcon,
  customDropdown,
  customIconButtonOnClick,
  graphicCardOptions = false,
  padding = "none",
  background = "",
  className = "",
  description,
  children,
  title,
  icon,
}: CustomCardType) => {
  const [open, setOpen] = useState(false);
  const widgetCardHeaderRef = useRef<HTMLDivElement>(null);
  const widgetCardRef = useRef<HTMLDivElement>(null);
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onExpand) onExpand(open);
  }, [open, onExpand]);

  useEffect(() => {
    window.addEventListener("error", function (e) {
      if (!widgetCardRef.current || !widgetRef.current) return;

      const handleResize = () => {
        if (
          !widgetCardRef.current ||
          !widgetCardHeaderRef.current ||
          !widgetRef.current
        )
          return;

        const widgetCardHeight = widgetCardRef.current.offsetHeight;
        const widgetCardHeaderHeight = widgetCardHeaderRef.current.offsetHeight;
        let widgetHeight = widgetCardHeight - widgetCardHeaderHeight;

        if (widgetHeight > 20) widgetHeight -= 20;

        if (widgetRef.current.style.height !== `${widgetHeight}px`) {
          widgetRef.current.style.height = `${widgetHeight}px`;
        }
      };

      const resizeObserver = new ResizeObserver(() => {
        requestAnimationFrame(handleResize);
      });
      if (open) {
        document.body.classList.add("overflow-hidden");
        resizeObserver.observe(widgetCardRef.current);
      } else {
        document.body.classList.remove("overflow-hidden");
        if (widgetRef.current) {
          widgetRef.current.style.height = children ? "auto" : "224px";
        }
        resizeObserver.disconnect();
      }

      return () => {
        document.body.classList.remove("overflow-hidden");
        resizeObserver.disconnect();
      };
    });
  }, [open, children]);

  return (
    <>
      {open && (
        <div className="fixed top-0 left-0 h-full w-full z-50 bg-widgetBgTransparentBlack"></div>
      )}
      <motion.div
        layout
        className={clsx(`w-full ${className}`, {
          "fixed top-0 left-0 h-full p-7 z-50": open,
        })}
      >
        <div
          ref={widgetCardRef}
          className={clsx(`bg-white rounded-2xl h-full ${background}`, {
            "h-full border border-neutralGrey200": open,
          })}
        >
          <div className="h-full w-full">
            <div
              ref={widgetCardHeaderRef}
              className={`${
                children ? "border-b border-neutralGrey200" : ""
              } pt-3.5 pb-3.5 xl:px-12 px-6  relative`}
            >
              <div className="flex justify-between">
                <div>
                  <div className="flex items-center">
                    {icon ? (
                      <Icon
                        icon={icon}
                        className="text-2xl mr-4 min-h-10"
                      ></Icon>
                    ) : (
                      <span></span>
                    )}
                    <h2 className="font-manropeBold text-lg leading-6">
                      {title}
                    </h2>
                  </div>
                  <div className="mt-1">
                    <p className="font-manropeRegular text-sm text-blueGrey200">
                      {description}
                    </p>
                  </div>
                </div>
                <div className="mr-4">
                  {customDropdown ? (
                    <CustomIconDropdown
                      icon="la:cog"
                      dropdownItems={customDropdown}
                    />
                  ) : (
                    expandable && (
                      <div>
                        {!open ? (
                          <CustomIconButton
                            icon="eva:expand-fill"
                            onClick={() => setOpen(!open)}
                          ></CustomIconButton>
                        ) : (
                          <CustomIconButton
                            icon="eva:collapse-fill"
                            onClick={() => setOpen(!open)}
                          ></CustomIconButton>
                        )}
                      </div>
                    )
                  )}

                  {customIconButtonOnClick && (
                    <div>
                      <CustomIconButton
                        icon={
                          customIconButtonIcon ? customIconButtonIcon : "la:cog"
                        }
                        onClick={customIconButtonOnClick}
                      ></CustomIconButton>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {children && (
              <div
                className={clsx("border-t border-neutralGrey200", {
                  "overflow-y-auto": open,
                })}
              >
                <div
                  className={clsx("", {
                    "": padding === "none",
                    "p-2": padding === "sm",
                    "py-2 px-4 pb-5": padding === "lg",
                  })}
                  ref={widgetRef}
                >
                  <CardContext.Provider value={{ expanded: open }}>
                    <div
                      className={`${graphicCardOptions ? "xl:px-2 xl:py-2" : "pt-3.5 pb-3.5 xl:px-12 px-6"}
                                  ${padding === "none" ? "p-0" : ""}`}
                    >
                      {children}
                    </div>
                  </CardContext.Provider>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default CustomCard;
