import React, { ChangeEvent, useEffect, useState } from "react";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomButton from "@/components/atoms/CustomButton";
import CustomTitle from "@/components/atoms/CustomTitle";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomText from "@/components/atoms/CustomText";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import CustomSelect, { CustomOptionType } from "@/components/molecules/CustomSelect";
import HeaderTitle from "@/components/molecules/HeaderTitle";
import CustomInput from "@/components/molecules/CustomInput";
import LabeledText from "@/components/molecules/LabeledText";
import CustomCard from "@/components/organisms/CustomCard/CustomCard";
import {
  changePasswordForm,
  changePasswordHandler,
  generalInfo,
  getLanguageName,
  saveEditUserProfile,
  setLangOptionsMap,
  validateFormData,
} from "./d";
import { t } from "i18next";
import { ProfileResponse } from "@/utils/models/Profile";
import useAuth from "@/utils/hooks/useAuth";
import HeaderAction from "@/components/organisms/HeaderAction";
import { useNavigate } from "react-router-dom";
import { getFindSelectModel, handleFormChange, setCountryCodeMap } from "@/utils/constants";
import i18n from "@/i18n";
import toast from "react-hot-toast";

type ProfileType = { isEditing?: boolean };

const Profile: React.FC<ProfileType> = ({ isEditing = false }) => {
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [professional, setProfessional] = useState<boolean>(false);
  const [user, setUser] = useState<ProfileResponse>({});
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { getCurrentUserServer, getCurrentUserHook } = useAuth();
  let currentUser = getCurrentUserHook();
  const navigate = useNavigate();
  const headerButton = [
    {
      variant: (isEditing ? "secondary" : "primary") as "primary" | "secondary",
      route: isEditing ? "/profile" : "/profile/edit",
      text: isEditing ? t("general.back") : t("general.edit"),
      icon: isEditing ? "la:arrow-left" : "la:user-edit",
    },
  ];

  useEffect(() => {
    getCurrentUserServer()
      .then((res) => {
        setUser(res);
        setFormData(res);
        setProfessional(res.professional);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSaveProfile = () => {
    getCurrentUserServer()
      .then((res) => {
        setUser(res);
        setFormData(res);
        i18n.changeLanguage(res.languageSettings);
        toast.success(t("user.formUser.toastMessage.userEdit"));
        navigate("/profile");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSavePassword = () => {
    setIsChangingPassword(false);
  };

  const handleValiditySaveProfile = () => {
    const validationResult = validateFormData(formData, isEditing);
    if (Object.keys(validationResult).length === 0) {
      saveEditUserProfile(formData, currentUser.id, handleSaveProfile);
    } else {
      setErrors(validationResult);
    }
  };
  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-col bg-white p-8 lg:px-12 lg:pt-6 lg:pb-8 gap-4 transition-all duration-300">
        <HeaderTitle title=" " buttons={headerButton} className="justify-end" />
        <HeaderAction actionItems={[]} dropdownItems={[]}>
          <div className="w-2/4">
            <div className="flex">
              <CustomIcon
                icon="ph:user-circle-light"
                className="w-28 h-28 mr-2"
              />
              <div className="flex flex-col justify-center">
                <CustomTitle heading="h1">{`${user["name"]} ${user["surname"]}`}</CustomTitle>
                <CustomText weight="bold">{t("profile.welcome")}</CustomText>
              </div>
            </div>
          </div>
        </HeaderAction>
      </div>
      <CustomLayout>
        <CustomCard
          title={t("profile.information")}
          icon={"la:info-circle"}
          expandable={false}
          className="mb-12"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 lg:px-12 md:px-6 px-0">
            {generalInfo.map((el, i) => {
              return el.type === "text" ? (
                isEditing ? (
                  <div className={el.className} key={i}>
                    {el.name === "companyName" && !professional ? (
                      <></>
                    ) : el.name === "countryCode" ? (
                      <CustomSelect
                        hasSearch={true}
                        defaultValue={getFindSelectModel(setCountryCodeMap(), formData[el.name])}
                        onChange={(e) => handleFormChange(e, setFormData, formData)}
                        label={t("user.formUser.countryCode")}
                        name={t(`${el.name}`)}
                        options={setCountryCodeMap()}
                        danger={errors[el.name] ? true : false}
                        helperText={errors[el.name] || ""}
                      />
                    ) : (
                      <CustomInput
                        value={formData[el.name] || ""}
                        onChange={(e) => handleFormChange(e, setFormData, formData)}
                        label={t(`${el.text}`)}
                        name={el.name}
                        disabled={el.name === "email" ? true : false}
                        danger={errors[el.name] ? true : false}
                        helperText={errors[el.name] || ""}
                        type={el.name === "nationalPhone" ? "number" : "text"}
                      />
                    )}
                  </div>
                ) : el.name === "companyName" && !professional ? (
                  <></>
                ) : (
                  el.name === "companyName" && professional && (!user[el.name] || el.name.trim() === "") ? (
                    <></>
                  ) : (
                    <LabeledText
                      text={user[el.name] as unknown as number}
                      className={el.className}
                      label={el.name === "countryCode" ? t("user.formUser.countryCode") : t(`${el.text}`)}
                      key={i}
                    />
                  )

                )
              ) : (
                <CustomCheckbox
                  checked={formData[el.name] || false}
                  className={el.className}
                  label={t(`${el.text}`)}
                  disabled={!isEditing}
                  name={el.name}
                  key={i}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      [el.name]: e.target.checked,
                    });
                    el.name === "professional" && setProfessional(!professional);
                  }}

                />
              );
            })}
          </div>
        </CustomCard>

        <CustomCard
          title={t("profile.configuration")}
          expandable={false}
          icon={"la:cog"}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 lg:px-12 md:px-6 px-0">
            <div className="py-4">
              {isChangingPassword && !isEditing ? (
                <div className="md:w-4/5">
                  {changePasswordForm.map((el, i) => {
                    return (
                      <CustomInput
                        onChange={(e) => handleFormChange(e, setFormData, formData)}
                        label={t(el.label)}
                        className="mb-8"
                        showText={true}
                        name={el.name}
                        key={i}
                      />
                    );
                  })}
                  <div className="flex justify-between">
                    <CustomButton
                      onClick={() => setIsChangingPassword(!isChangingPassword)}
                      children={t("general.cancel")}
                      variant="secondary"
                    />
                    <CustomButton
                      onClick={() => changePasswordHandler(formData, handleSavePassword)}
                      children={t("general.accept")}
                    />
                  </div>
                </div>
              ) : (
                <CustomButton
                  onClick={() => setIsChangingPassword(!isChangingPassword)}
                  children={t("profile.pwd.change")}
                  disabled={isEditing}
                />
              )}
            </div>
          </div>
        </CustomCard>

        {isEditing && (
          <div className="flex justify-end gap-5 w-full py-4 col-span-4 my-5">
            <CustomButton
              variant="white"
              onClick={() => {
                setFormData(user);
                navigate("/profile");
              }}
              children={t("general.cancel")}
              disabled={!isEditing}
            />
            <CustomButton
              variant="primary"
              onClick={handleValiditySaveProfile}
              leftIconName="la:save"
              children={t("user.formUser.saveUser")}
              disabled={!isEditing}
            />
          </div>
        )}
      </CustomLayout>
    </div>
  );
};

export default Profile;
