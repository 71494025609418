import { authService } from "./auth.service";
import { apiService } from "./api.service";
import { CommandRequest } from "@/utils/models";


export const deviceLogService = {
    
    //Service
    getServicesStatus: async (id: string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/device/${id}/getServicesStatus`;
        let response = await apiService.get(url, authService.getToken());
        let servicesStatus: any = {};
        response.forEach((serviceData: any) => {
            servicesStatus[serviceData.name] = serviceData;
        });
        return servicesStatus;
    },

    updateService: async (id: string, service: string, packageUrl: string = '', subfolder?: any) => {
        const url = `${process.env.REACT_APP_BASE_URL}/service/update`;
        let payload: CommandRequest;
        if (packageUrl) {
          payload = {
            iotId: id,
            service: service,
            packageUrl: packageUrl,
            subfolder: subfolder ? subfolder : null
          }
        } else {
          payload = {
            iotId: id,
            service: service
          }
        }
        let response = await apiService.put(url,payload,authService.getToken());
        return response
    },
    uninstallService: async (id: string, service: string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/service/uninstall`;
        const payload = {
            'iotId': id,
            'service': service
          }
        let response = await apiService.put(url,payload,authService.getToken());
        return response
    },
    restartService: async (id: string, service: string) => {
      const url = `${process.env.REACT_APP_BASE_URL}/service/restart`;
      const payload = {
            'iotId': id,
            'service': service
          }
        let response = await apiService.put(url,payload,authService.getToken());
        return response
    },
    launchBatteryTest: async (id: string, battType: string, battValue: number) => {
        const url = `${process.env.REACT_APP_BASE_URL}/service/batteryTest`;
        const payload = {
            'iotId': id,
            'battType': battType,
            'battValue': battValue,
            'subfolder': "batteryTest"
          }
        let response = await apiService.put(url,payload,authService.getToken());
        return response
    },
    
    sendReport: async (id: string, battType: string, battValue: number) => {
        const url = `${process.env.REACT_APP_BASE_URL}/service/batteryTest`;
        const payload = {
            'iotId': id,
            'battType': battType,
            'battValue': battValue,
            'subfolder': "batteryTest"
          }
        let response = await apiService.put(url,payload,authService.getToken());
        return response
    },

    getLogs: async (iotId: string) => {
        const url = `${process.env.REACT_APP_BASE_URL}/logs/${iotId}`;
        let response = await apiService.get(url, authService.getToken());
        return response
    },
    refreshLogs: async (iotId: string) => {
      const url = `${process.env.REACT_APP_BASE_URL}/logs/${iotId}/refresh`;
      let response = await apiService.put(url, {}, authService.getToken());
        return response;
    },
     
};

