import { HeaderTitleType } from "./HeaderTitleType";
import { Link } from "react-router-dom";
import { t } from "i18next";
import CustomButton from "@/components/atoms/CustomButton";
import CustomTitle from "@/components/atoms/CustomTitle";

const HeaderTitle = ({
  className = "",
  children,
  buttons,
  title,
}: HeaderTitleType) => {
  return (
    <div
      className={`flex flex-row justify-between h-[60px] items-center  ${className}`}
    >
      {title && <CustomTitle heading={"h1"}>{title}</CustomTitle>}
      {children && children}

      {buttons && (
        <div className="flex gap-4">
          {buttons.map((b, i) =>
            b?.route ? (
              b?.disabled ? (
                <CustomButton
                  variant={b.variant ? b.variant : "primary"}
                  leftIconName={b.icon}
                  disabled={b?.disabled}
                >
                  {t(b.text)}
                </CustomButton>
              ) : (
                <Link to={b.route} key={i}>
                  <CustomButton
                    variant={b.variant ? b.variant : "primary"}
                    leftIconName={b.icon}
                  >
                    {t(b.text)}
                  </CustomButton>
                </Link>
              )
            ) : b?.type === "file" ? (
              <></>
            ) : (
              <CustomButton
                variant={b?.variant ? b.variant : "primary"}
                leftIconName={b?.icon}
                disabled={b?.disabled}
                onClick={b?.onClick}
                key={i}
              >
                {t(b?.text)}
              </CustomButton>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderTitle;