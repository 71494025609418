import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { useGetMeasure } from "@/utils/hooks/useGetMeasure";
import { useDeviceContext } from "@/utils/context/deviceContext";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomLoader from "@/components/atoms/CustomLoader";
import { filters, GetDate, SensorDescriptor } from "./d";
import CustomGraphicCard from "@/components/organisms/CustomGraphicCard";
import DeviceHeader from "@/components/organisms/DeviceHeader/DeviceHeader";
import { setHours } from "react-datepicker/dist/date_utils";

const Measures: React.FC = () => {
  const sensorDescriptors: SensorDescriptor[] = [
    { title: t("alarm.titleBypass"), icon: "la:info-circle", value: "desc.byp" },
    { title: t("alarm.titleInput"), icon: "la:info-circle", value: "desc.in" },
    { title: t("alarm.titleOutput"), icon: "la:info-circle", value: "desc.out" },
    { title: t("alarm.titleBattery"), icon: "la:info-circle", value: "desc.discharge" },
  ];
  const { deviceInfo } = useDeviceContext();
  const today = new Date();
  const toDateUnix = Math.floor(today.getTime() / 1000);

  const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
  yesterday.setHours(0, 0, 0, 0);
  const fromDateUnix = Math.floor(yesterday.getTime() / 1000);

  const [formData, setFormData] = useState<GetDate>({
    iotId: deviceInfo?.id || "",
    fromDate: fromDateUnix,
    toDate: toDateUnix,
  });
  const { measures, loading, searchHistoryAlarms } = useGetMeasure();

  useEffect(() => {
    if (deviceInfo?.id) {
      searchHistoryAlarms(
        deviceInfo.id,
        formData.fromDate,
        formData.toDate,
        filters
      );
    }
  }, [deviceInfo?.id]);

  const onDayChange = (e: any) => {
    const { start, end } = e;
    const fromDate = new Date(start).getTime() / 1000;
    const toDate = new Date(end).getTime() / 1000;
    setFormData((prevData) => ({
      ...prevData,
      fromDate,
      toDate,
    }));
  };

  const onSearch = () => {
    if (deviceInfo?.id) {
      searchHistoryAlarms(
        deviceInfo.id,
        formData.fromDate,
        formData.toDate,
        filters
      );
    }
  };

  return (
    <>
      <CustomLayout className="relative">
        <DeviceHeader
          title={t("device.detail.measures")}
          onChangeDayRange={onDayChange}
          onClickIconButton={onSearch}
          buttonIcon="la:search"
          buttonTitle="alarm.Search"
        />
        {loading ? (
          <CustomLoader visible={loading} fixed />
        ) : (
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 my-6">
            {sensorDescriptors.map((sensor, index) => (
              <CustomGraphicCard
                key={index}
                titleCard={sensor.title}
                iconCard={sensor.icon}
                sensors={sensor.value}
                dataMeasures={measures}
                loading={loading}
              ></CustomGraphicCard>
            ))}
          </div>
        )}
      </CustomLayout>
    </>
  );
};

export default Measures;