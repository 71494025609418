import React, { ChangeEvent, useState } from "react";
import CustomInput from "@/components/molecules/CustomInput";
import CustomButton from "@/components/atoms/CustomButton";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomTitle from "@/components/atoms/CustomTitle";
import CustomText from "@/components/atoms/CustomText";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ReactComponent as LogoImage } from "@/assets/images/nimbus-logo.svg";
import BackImage from "@/assets/images/login-background.png";

import useAuth from "@/utils/hooks/useAuth";
import { AuthError, ERROR_MAINTENANCE, ERROR_NOT_VERIFIED, ERROR_PASSWORD_NOT_MATCH } from "@/utils/constants";
import CustomModal from "@/components/organisms/CustomModal/CustomModal";
import { ModalBody, ModalFooter } from "flowbite-react";
import { userService } from "@/services/user.service";
import toast from "react-hot-toast";
const Login: React.FC = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [maintenance, setMaintenance] = useState(false);


  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const onCloseDeleteModal = () => setShowVerificationModal(false);

  const { isLoggedIn, login } = useAuth();
  const navigate = useNavigate();

  const customStyles: React.CSSProperties = {
    backgroundImage: `url('${BackImage}')`,
  };


  const handleLoginError = (err: any) => {
    const errorResponse = err?.error || {};
    const { statusCode, name, message } = errorResponse;

    switch (message) {
      case ERROR_NOT_VERIFIED:
        setShowVerificationModal(true);
        break;
      case ERROR_MAINTENANCE:
        setMaintenance(true);
        break;
      case ERROR_PASSWORD_NOT_MATCH:
        setInvalidLogin(true);
        break;
      default:
        toast.error(t('login.serverInternalError'));
    }
  };

  
  const submitLogin = async () => {
    setLoading(true);
  
    try {
      await login(formData);
      if (isLoggedIn()) {
        navigate("/devices");
      } else {
        setInvalidLogin(true);
      }
    } catch (err: any) {
      handleLoginError(err);
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const resendVerificationMail = () => {
    userService
      .sendVerifyRequest(formData.email, formData.password)
      .then(() => {
        toast.success(t("alarm.email-verification-text"));
        onCloseDeleteModal();
      })
      .catch((err: any) => {
        toast.error(t("alarm.email-verification.fail.text"));
      });
  };

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const el = e.currentTarget ? e.currentTarget : e.target;
    if (el) {
      setFormData({
        ...formData,
        [el.name]: el.value,
      });
    }
  };

  return (
    <>
      <CustomLoader visible={loading}></CustomLoader>
      <div className="flex flex-col-reverse md:flex-row w-full h-screen md:h-[25vh] mb-[5rem]">
        <div className="py-8 px-8 w-full flex flex-col md:justify-center items-center h-3/5 md:h-screen md:w-1/2 rounded-t-3xl md:rounded-r-3xl bg-white relative md:mt-0 -mt-5 md:-mr-4 ">
          <div className="mb-8 w-full max-w-md">
            <div>
              <CustomTitle heading="h1">{t("login.title")}</CustomTitle>
              <CustomText variant="secondary" weight="semi">
                {t("login.subtitle")}
              </CustomText>
              {invalidLogin && (
                <CustomText className="text-red-600" weight="bold">
                  {t("login.error")} {t("login.please")}
                </CustomText>
              )}
              {maintenance && (
                <CustomText className="text-red-600" weight="bold">
                  {t("user.formUser.toastMessage.desc.maintenance")}
                </CustomText>
              )}
            </div>
          </div>
          <form
            data-test-id="login-form"
            onSubmit={onSubmit}
            className="w-full max-w-md bg-white rounded-lg"
          >
            <div className="mb-12">
              <CustomInput
                name="email"
                icon="ci:mail-open"
                label={t("login.email")}
                onChange={handleFormChange}
              />
            </div>
            <div className="mb-12">
              <CustomInput
                name="password"
                type="password"
                showText={true}
                label={t("login.password")}
                onChange={handleFormChange}
              />
            </div>
            <div className="mb-6">
              <CustomButton
                className="mt-6 w-full"
                onClick={submitLogin}
                type="submit"
              >
                {t("login.submit")}
              </CustomButton>
            </div>
            <div className="flex flex-col items-center">
              <Link to="/reset-password">
                <div className="mt-[1rem] mb-[2rem]">
                  <CustomText variant="secondary" weight="semi" size="sm">
                    {t("login.forgotten")}
                  </CustomText>
                </div>
              </Link>
            </div>
            <div className="flex gap-8 mb-12">
              <Link to="/create-account" className="w-full">
                <CustomButton
                  className="mt-6 w-full"
                  variant="secondary"
                  type="submit"
                >
                  {t("login.create")}
                </CustomButton>
              </Link>
            </div>
          </form>
        </div>

        <div
          className="flex h-3/5 md:h-screen md:w-4/5 items-center justify-center bg-cover bg-no-repeat"
          style={customStyles}
        >
          <LogoImage className="h-[13rem] md:h-[25rem] w-80 self-center mx-auto flex-shrink-0 flex-grow-0" />
        </div>
      </div>

      <CustomModal
        isOpen={showVerificationModal}
        size="4xl"
        icon={"clarity:contract-line"}
        dismissible={false}
        onClose={onCloseDeleteModal}
        title={t("user.formUser.toastMessage.userNotVerifiedTitle")}
        body={
          <>
            <ModalBody className="px-10">
              <div className="grid grid-cols-1 gap-4">
                <p className="font-manropeRegular text-center">
                  {t("user.formUser.toastMessage.userNotVerifiedDescription")}
                </p>
              </div>
            </ModalBody>
            <ModalFooter className="flex justify-center">
              <CustomButton onClick={resendVerificationMail}>
                {t("user.formUser.toastMessage.resendButton")}
              </CustomButton>
            </ModalFooter>
          </>
        }
      />
    </>
  );
};

export default Login;
