import { langOptions } from "@/pages/Profile/d";
import { IUser } from "@/utils/models";
import { t } from "i18next";
import { useState } from "react";
import { notificationService } from "@/services/notifications.service";
import { INotification } from "@/utils/models/entities/Notification";
import toast from "react-hot-toast";
import { isValidEmail, isValidPhone, reloadPage } from "@/utils/constants/consts";
import { CustomOptionType } from "../CustomSelectRounded/CustomSelectRoundedType";

export interface FieldData {
  name: string;
  value: string;
}
export type FieldValue = {
  name: string;
  countryCode?: string;
  value: string;
};

export enum CustomDynamicFieldEnumMode {
  DynamicFieldEmail = "Email",
  DynamicFieldPhone = "Phone",
}

interface IFormData {
  sms: boolean;
  mail: boolean;
  locale: string;
  iotId: string;
  enabledAdditionalMail?: boolean;
  enabledAdditionalPhone?: boolean;
  additionalMail: { value: string }[];
  additionalPhone: { countryCode: string; value: string }[];
}

export const setLangOptionsMap = (languageSettings: any): CustomOptionType[] =>
  langOptions.map(({ value, label }) => ({ value, label }));
export const NotificationFormComponent = ({
  isEditing,
  CurrentUser,
}: {
  isEditing: boolean;
  CurrentUser: IUser;
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const toggleField = (
    setShowField: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setShowField((showField) => !showField);
  };

  const initializeEditForm = (
    notification: INotification,
    isEdit: boolean
  ): INotification => {
    if (notification) {
      const editNotification: INotification = {
        mail: notification?.mail || false,
        sms: notification?.sms || false,
        locale: notification?.locale || "",
        additionalMail: notification?.additionalMail || [],
        additionalPhone: notification?.additionalPhone || [],
        enabledAdditionalPhone: notification?.additionalPhone ? true : false,
        enabledAdditionalMail: notification?.additionalMail ? true : false,
        userId: notification?.userId || "",
        iotId: notification?.iotId || "",
      };
      return editNotification;
    } else {
      return {
        id: "",
        userId: "",
        sms: false,
        mail: false,
        additionalPhone: [],
        additionalMail: [],
        enabledAdditionalPhone: false,
        enabledAdditionalMail: false,
        locale: "",
        iotId: "",
      };
    }
  };
  const isSaveDisabled = (formaData: any) => {
    return formaData.iotId && formaData.locale ? false : true;
  };

  const isEmailCorrect = (formData: any): boolean => {
    if (!formData.enabledAdditionalMail) {
      return true;
    } else {
      for (const email of formData.additionalMail) {
        if (!isValidEmail(email.value)) {
          return false;
        }
      }
      return true;
    }
  };

  const isPhoneCorrect = (formData: any): boolean => {
    if (!formData.enabledAdditionalMail) {
      return true;
    } else {
      for (const phone of formData.additionalPhone) {
        if (!isValidPhone(phone.countryCode, phone.number)) {
          return false;
        }
      }
      return true;
    }
  };

  const isCorrect = (formData: any, onClose?: () => void) => {
    const userNotification = validateAndSaveNotification(formData);
    notificationService
      .saveNotifications(userNotification)
      .then(() => {
        if (onClose) onClose();
        toast.success(t("notificationDetail.saveNotifications"));
        const interval = reloadPage();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const doUpdate = async (formData: any, onClose: () => void) => {
    let validCount = 0;

    if (formData.enabledAdditionalMail) {
      if (isEmailCorrect(formData)) {
        validCount++;
      } else {
        toast.error(t("user.formUser.formValidationError.emailFormat"));
      }
    } else {
      validCount++;
    }
    if (formData.enabledAdditionalPhone) {
      if (isPhoneCorrect(formData)) {
        validCount++;
      } else {
        toast.error(t("user.formUser.formValidationError.nationalPhoneFormat"));
      }
    } else {
      validCount++;
    }
    if (validCount === 2) {
      isCorrect(formData, onClose);
    }
  };
  const convertEmailData = (emails: { value: string }[]) => {
    return emails.map((email) => email.value);
  };
  const convertPhoneData = (phones: any) => {
    return phones.map((phone: any) => ({
      countryCode: phone.countryCode,
      number: phone.number,
    }));
  };
  const validateAndSaveNotification = (formData: IFormData): INotification => {
    const {
      locale,
      iotId,
      mail,
      sms,
      enabledAdditionalPhone,
      enabledAdditionalMail,
      additionalMail,
      additionalPhone,
    } = formData;

    let additionalMailData: string[] = additionalMail
      ? convertEmailData(additionalMail)
      : [];
    let additionalPhoneData: any[] = additionalPhone
      ? convertPhoneData(additionalPhone)
      : [];

    const userNotification: INotification = {
      mail: mail,
      sms: sms,
      additionalMail: enabledAdditionalMail ? additionalMailData : [],
      additionalPhone: enabledAdditionalPhone ? additionalPhoneData : [],
      userId: CurrentUser?.id || "",
      locale: locale || CurrentUser?.languageSettings,
      iotId: iotId || "",
    };

    if (isEditing) {
      userNotification.iotId = formData.iotId || "";
    }
    
    return userNotification;
  };
  const doSave = async (formData: any, onClose: () => void) => {
    let validFieldsCount = 0;

    if (formData.enabledAdditionalMail) {
      if (isEmailCorrect(formData)) {
        validFieldsCount++;
      } else {
        toast.error(t("user.formUser.formValidationError.emailFormat"));
      }
    } else {
      validFieldsCount++;
    }
    if (formData.enabledAdditionalPhone) {
      if (isPhoneCorrect(formData)) {
        validFieldsCount++;
      } else {
        toast.error(t("user.formUser.formValidationError.nationalPhoneFormat"));
      }
    } else {
      validFieldsCount++;
    }
    if (validFieldsCount === 2) {
      isCorrect(formData, onClose);
    }
  };

  return {
    errors,
    initializeEditForm,
    toggleField,
    setLangOptionsMap,
    isSaveDisabled,
    doSave,
    doUpdate,
  };
};
