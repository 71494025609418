import { getFloatingLabelPositionTheme } from "@/utils/constants";
import { FloatingLabel } from "flowbite-react";
import { CustomInputType } from "./CustomInputType";
import CustomIcon from "@/components/atoms/CustomIcon";
import { useState } from "react";

const CustomInput = ({
    onChange = () => { },
    autocomplete = true,
    disabled = false,
    required = false,
    readOnly = false,
    showText = false,
    helperText = '',
    className = '',
    type = 'text',
    label = '',
    icon = '',
    maxLength,
    danger,
    value,
    name,
    max,
    min,
}: CustomInputType) => {
    const [view, setView] = useState(false);
    const theme = getFloatingLabelPositionTheme(label);
    const showPassword = () => {
        setView(!view);
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const regex = /[0-9]|\./;
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        }
    };

    return (
        <div className={`relative w-full ${className} ${disabled ? 'opacity-50' : ''}`}>
            <FloatingLabel
                type={(type !== 'password' && !showText) ? type : (view ? 'text' : 'password')}
                autoComplete={autocomplete ? 'new-password' : ''}
                helperText={helperText ? helperText : undefined}
                onWheel={(e) => e.currentTarget.blur()}
                label={required ? label + " *" : label}
                color={danger ? 'error' : undefined}
                onKeyPress={handleKeyPress}
                className={`${className}`}
                maxLength={maxLength}
                onChange={onChange}
                disabled={disabled}
                readOnly={readOnly}
                variant="standard"
                theme={theme}
                value={value}
                name={name}
                max={max}
                min={min}
            />
            {!showText ?
                icon &&
                <CustomIcon
                    className="absolute right-1 top-2 text-xl text-black"
                    icon={icon}
                />
                :
                <div className="cursor-pointer" onClick={showPassword}>
                    <CustomIcon
                        className="absolute right-1 top-2 text-xl text-black"
                        icon={view ? "la:eye" : "la:eye-slash"}
                    />
                </div>
            }
        </div>
    )
}

export default CustomInput;