import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/assets/i18n/en.json';
import es from '@/assets/i18n/es.json';
import ca from '@/assets/i18n/ca.json';

const language = navigator.language.split(/[-_]/)[0];

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: {
      default:[language]
    },
    debug: false,
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      ca: {
        translation: ca,
      }
    },
  });

export default i18n;