import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ActionItemType, CustomOptionType, CustomSelectType } from "./CustomSelectRoundedType";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomDropdown from "@/components/molecules/CustomDropdown";
import { t } from "i18next";

const CustomSelectRounded = ({
  defaultValue = { label: "", value: "" },
  dropdownHelpText = "",
  placeholderText = "",
  emptyValue = false,
  hasSearch = false,
  hasLoader = false,
  className = "",
  onSearch,
  onChange,
  deleteOnChange,
  options,
  name,
}: CustomSelectType) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [dropdownItems, setDropdownItems] = useState<ActionItemType[]>([]);
  const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<CustomOptionType>({
    value: "",
    label: ""
  });

  const setArrayActionItems = (): ActionItemType[] => {
    const DropdownItems: ActionItemType[] = [];
    if (emptyValue) {
      DropdownItems.push({
        action: () => { },
        text: "-",
        value: "",
        selected: true
      });
    }
    options.map((el: any) => {
      DropdownItems.push({
        action: () => { },
        text: t(el.label.trim()) || t(el.value.trim()),
        value: el.value,
        selected: el.selected
      });
    });

    return DropdownItems;
  };

  const setDropdownPosition = () => {
    const rect = dropdownRef?.current?.getBoundingClientRect();
    const h = window.innerHeight - (rect?.bottom || 0);
    rect && setPosition({ x: rect.left, y: rect.bottom, w: rect.width, h: h });
  };

  const handleButtonClick = () => {
    setDropdownPosition();
    hasLoader ? setIsOpen(true) : setIsOpen(!isOpen);
  };

  const onActionClick = (data: any) => {
    const selectElement = selectRef.current;

    if (selectElement && data !== undefined) {
      const selectedOption = Array.from(selectElement.options).find(
        (option) => option.value === data.value
      );
      selectElement.value = data.value;
      const event = new Event("change", { bubbles: true });
      selectElement.dispatchEvent(event);
      setSelectedValue({
        value: selectedOption?.value || "",
        label: selectedOption?.text || ""
      });
      setIsOpen(false);
    }
  };

  const getIconName = () => {
    let icon;
    isOpen && dropdownItems.length > 0
      ? (icon = "nimbus:chevron-up")
      : dropdownItems.length > 0
        ? (icon = "nimbus:chevron-down")
        : (icon = "la:search");
    return icon;
  };

  const removeValue = () => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const event = new Event("change", { bubbles: true });
      inputElement.dispatchEvent(event);
    }

    setInputValue('');
    setDropdownItems([]);
    setSelectedValue({
      value: "",
      label: ""
    });

    deleteOnChange?.({
      target: { value: '' }
    } as React.ChangeEvent<HTMLSelectElement>);
    
    setIsOpen(false) 
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value)
    setIsOpen(true);

    if (onSearch) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      const timeout = setTimeout(() => {
        onSearch && onSearch(e);
      }, 1000);

      setSearchTimeout(timeout);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        hasLoader ? setIsOpen(true) : setIsOpen(false);
      }
    }

    const scrollHandler = () => {
      setDropdownPosition();
    };

    document.addEventListener("scroll", scrollHandler, true);
    document.addEventListener("mousedown", handleClickOutside);

    setDropdownItems(setArrayActionItems());

    return () => {
      document.removeEventListener("scroll", scrollHandler);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, options]);

  return (
    <div
      className={`relative cursor-pointer text-gray-600 w-full ${isOpen ? 'ring ring-2 ring-salicru rounded-full' : ''} ${className}`}
      ref={dropdownRef}
    >
      <div
        className={`font-manropeMedium border-2 border-gray-300 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none w-full focus:shadow-md blur-none bg-transparent relative ${isOpen && 'border-transparent h-10'}`}
        onClick={handleButtonClick}
      >
        <div className="flex items-center h-full w-full">
          <span className="text-ellipsis overflow-hidden whitespace-nowrap">
            {selectedValue.label ? t(selectedValue.label) : defaultValue.label && defaultValue.label}
          </span>
          <input
            className={`h-full w-full ${(selectedValue.label || defaultValue.label) && "hidden"}`}
            placeholder={placeholderText}
            onChange={onInputChange}
            value={inputValue}
            ref={inputRef}
            style={{ outline: 'none' }}
          />
          <button
            className="absolute right-0 top-0 mt-2.5 mr-4 hover:drop-shadow flex"
            onClick={handleButtonClick}
          >
            {(selectedValue.label && !defaultValue.label) && (
              <CustomIcon
                onClick={removeValue}
                icon={"la:times"}
                className="mr-2"
                size="2xl"
              />
            )}
            {hasLoader && (
              <CustomIcon
                icon={"eos-icons:bubble-loading"}
                className="mr-2"
                size="2xl"
              />
            )}
            <CustomIcon size="2xl" icon={getIconName()} />
          </button>
        </div>
      </div>

      {isOpen && options.length > 0 && (
        <CustomDropdown
          className="fixed top-full left-0 bg-white z-30 text-sm focus:outline-none w-full focus:shadow-md blur-none"
          dropdownItems={dropdownItems}
          onActionClick={onActionClick}
          helpText={dropdownHelpText}
          style={{
            top: position.y,
            left: position.x,
            width: position.w,
            maxHeight: position.h,
          }}
          hasSearch={hasSearch}
        />
      )}

      <select
        defaultValue={selectedValue.value ? selectedValue.value : defaultValue.value && defaultValue.value}
        onChange={onChange}
        className="hidden"
        ref={selectRef}
        name={name}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value && option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomSelectRounded;