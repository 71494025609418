import { useEffect, useRef, useState } from "react";
import { CollapsedMeasureCardType } from "./CollapsedMeasureCardType";
import CustomTabLines from "@/components/molecules/CustomTabLines";
import { t } from "i18next";
import PowerChart from "@/components/molecules/PowerCurveChart/PowerCurveChart";

const CollapsedMeasureCard = ({
  measures,
  expanded,
  selectedTab,
  setSelectedTab,
}: CollapsedMeasureCardType) => {
  const intervalId = useRef<NodeJS.Timeout>();
  const [measureSeries, setMeasureSeries] = useState<string[]>([]);

  useEffect(() => {
    if (measures) {
      const options = measures.map((measure: any) => {
        const translatedName = t("alarm." + measure.sensor);
        setMeasureSeries((prevSeries) => [...prevSeries, translatedName]);
        return translatedName;
      });
    }
  }, [measures]);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setSelectedTab((prevTab) =>
        prevTab === measures.length - 1 ? 0 : prevTab + 1
      );
    }, 10000);

    return () => clearInterval(intervalId.current);
  }, [measures.length, setSelectedTab]);

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  function handleTabChange(index: number) {
    setSelectedTab(index);
    clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setSelectedTab((prevTab) =>
        prevTab === measures.length - 1 ? 0 : prevTab + 1
      );
    }, 8000);
  }

  return (
    <div className="">
      {measures.length > 0 &&
        measures.map(
          (measure, index) =>
            selectedTab === index && (
              <PowerChart
                key={index + "a"}
                collapsed={true}
                measureData={measure.data}
                measureNameSeriesData={measureSeries}
                expanded={expanded}
                loading={false}
              />
            )
        )}
      {measures.length > 1 && (
        <CustomTabLines
          tabs={measures.length}
          currentTab={selectedTab}
          onChange={handleTabChange}
        />
      )}
    </div>
  );
};

export default CollapsedMeasureCard;
