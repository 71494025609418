import React, { useEffect, useRef, useState } from "react";
import { CustomDropdownType } from "./CustomDropdownType";
import { t } from "i18next";
import CustomDropdownItem from "@/components/molecules/CustomDropdownItem";
import CustomInput from "@/components/molecules/CustomInput";
import CustomText from "@/components/atoms/CustomText";
import CustomLoader from "@/components/atoms/CustomLoader";

const CustomDropdown = ({
  variant = "primary",
  selectedValues = [],
  hasSearch = false,
  className = "",
  scroll = true,
  fixed = false,
  dropdownItems,
  onActionClick,
  multiselect,
  helpText,
  onSearch,
  style,
}: CustomDropdownType) => {

  const [filteredItems, setFilteredItems] = useState(dropdownItems)
  const [actionsHeight, setActionsHeight] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [position, setPosition] = useState(style)

  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const searchContainer = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getActionsHeight = () => {
    if (style) {
      const dropdownHeight = style.maxHeight;
      const searchHeight = searchContainer?.current?.clientHeight || 0;
      setActionsHeight(Math.abs(dropdownHeight - searchHeight - 52));
    }
  }

  const setScreenEdges = () => {
    if (!scroll) {
      const dropdownHeight = dropdownRef?.current?.clientHeight || 0;
      const heightToSubs = (style.top + dropdownHeight) > window.innerHeight ? ((style.top + dropdownHeight) - window.innerHeight) + 24 : 0;
      setPosition({
        left: style.left - 8,
        top: parseInt(style.top) - heightToSubs
      });
    }
  }

  useEffect(() => {
    onSearch && setFilteredItems(dropdownItems)
    getActionsHeight()
    setScreenEdges()
  }, [filteredItems])

  useEffect(() => {
    if (!onSearch) {
      const filtered = dropdownItems.filter(item =>
        item.text.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredItems(filtered)
    }
    onSearch && isLoading && setFilteredItems(dropdownItems)
    setIsLoading(false)
  }, [dropdownItems, searchText])

  useEffect(() => {
    if (onSearch) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      searchTimeoutRef.current = setTimeout(() => {
        if (searchText.length > 0) {
          onSearch(searchText)
          setIsLoading(true)
        }
      }, 1000);
    }
  }, [searchText]);

  return (
    <>
      <div
        style={scroll ? style : position}
        ref={dropdownRef}
        className={`min-w-fit py-4 z-20 border rounded-2xl shadow-md border-gray-300
          ${variant === "primary" ? "bg-neutralGrey300 mt-2" : "absolute mb-5 left-0 text-blueGrey100 bg-gray-100 bottom-9"}
          ${className && className}
          ${fixed && 'fixed'}`}
      >
        <div className="flex flex-col">
          {hasSearch && (
            <div ref={searchContainer} className="px-4 py-2">
              <CustomInput
                onChange={(e) => setSearchText(e.target.value)}
                label={t("general.search")}
                name={"searchText"}
                value={searchText}
              />
            </div>
          )}

          <div className={isLoading ? `overflow-hidden relative` : `overflow-y-auto relative`} style={{ maxHeight: `${style && actionsHeight ? actionsHeight + `px` : `auto`}` }}>
            {onSearch && <CustomLoader visible={isLoading} />}
            {helpText &&
              <CustomText variant={'secondary'} size="sm" children={t(helpText)} className="px-4 pb-1" />
            }
            {filteredItems.map((item, index) => {
              return (
                <CustomDropdownItem
                  className={multiselect && selectedValues.find(i => i.value === item.value) ? 'opacity-35' : ''}
                  onActionClick={onActionClick}
                  disabled={item.disabled}
                  action={item.action}
                  image={item.image}
                  value={item.value}
                  variant={variant}
                  icon={item.icon}
                  text={item.text}
                  name={item.name}
                  key={index}
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDropdown;