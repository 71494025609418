import { useState } from "react";
import CollapsedMeasureCard from "../CollapsedMeasureCard";
import CustomCard from "../CustomCard";
import ExpandedMeasureCard from "../ExpandedMeasureCard";
import { CustomChartCardType } from "./CustomChartCardType";

const CustomGraphicCard = ({
  titleCard,
  iconCard,
  sensors,
  dataMeasures,
  loading,
}: CustomChartCardType) => {
  const [showExpandedByPassCard, setShowExpandedByPassCard] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const filterDataBySensor = (
    data: any[],
    includeSensor: string,
    excludeSensor?: string
  ) => {
    const filteredData = data.filter(
      (item) =>
        item.sensor.includes(includeSensor) &&
        (!excludeSensor || !item.sensor.includes(excludeSensor))
    );
    return filteredData;
  };

  return (
    <>
      {filterDataBySensor(dataMeasures, sensors).length > 0 ? (
        <>
          <CustomCard
            title={titleCard}
            icon={iconCard}
            expandable={true}
            graphicCardOptions={true}
            onExpand={(e) => setShowExpandedByPassCard(e)}
          >
            {showExpandedByPassCard ? (
              <ExpandedMeasureCard
                measures={filterDataBySensor(dataMeasures, sensors)}
                expanded={showExpandedByPassCard}
                loading={loading}
                selectedTabItem={selectedTab}
              ></ExpandedMeasureCard>
            ) : (
              <CollapsedMeasureCard
                measures={filterDataBySensor(dataMeasures, sensors)}
                expanded={showExpandedByPassCard}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              ></CollapsedMeasureCard>
            )}
          </CustomCard>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomGraphicCard;
