import { HeaderActionType } from "./HeaderActionsType";
import CustomIconDropdown from "../CustomIconDropdown";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import { ActionItemType } from "@/utils/models";
import React from "react";
import useDeviceType from "@/utils/hooks/useDevicResponsiveType";

const HeaderActions = ({
  actionItems,
  dropdownItems,
  children,
}: HeaderActionType) => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const combinedItems: ActionItemType[] = [
    ...(actionItems || []),
    ...(dropdownItems || []),
  ];

  return (
    <>
      <div>
        <div className="flex flex-col gap-4 lg:flex-row lg:w-2/4">
          {children}
        </div>

        <div className="flex items-center justify-end lg:hidden">
          <>
            <CustomIconDropdown
              icon={isMobile || isTablet ? "la:ellipsis-h" : "la:cog"}
              dropdownItems={combinedItems}
              variant={"primary"}
            />
          </>
        </div>

        <div className="hidden lg:flex lg:justify-self-end lg:gap-4">
          {actionItems &&
            actionItems.map((item, i) => (
              <CustomIconButton
                icon={item.icon || ""}
                onClick={item.action}
                tooltip={item.text}
                variant={"primary"}
                key={i}
              />
            ))}

          {dropdownItems && dropdownItems.length > 0 && (
            <CustomIconDropdown
              icon={isMobile || isTablet ? "la:ellipsis-h" : "la:cog"}
              dropdownItems={dropdownItems}
              variant={"primary"}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderActions;