import { useState} from 'react';
import { CloudHistoryMeasuresSearchParams, CloudAlarmHistory } from "@/utils/models/entities/History";
import { historyService } from "@/services/history.service";

interface UseAlarmHistoryResult {
  alarms: CloudAlarmHistory[];
  loading: boolean;
  error: string | null;
  getAlarmDescription: (addressAndBit: string, model: string) => string;
  searchHistoryAlarms: (params?: CloudHistoryMeasuresSearchParams) => Promise<void>;
}

export const useAlarmHistory = (): UseAlarmHistoryResult => {
  const [alarms, setAlarms] = useState<CloudAlarmHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  

  const fetchAlarms = async (params?: CloudHistoryMeasuresSearchParams) => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await historyService.searchHistoryAlarms(params);
      const now = Date.now();

      response.items.sort((a: { timestamp: number; }, b: { timestamp: number; }) => {
        const diffA = Math.abs(a.timestamp - now);
        const diffB = Math.abs(b.timestamp - now);
        return diffA - diffB;
      });  
      response.items.forEach((element: CloudAlarmHistory) => {
        element.sensor = element.sensor.includes("ALARM")
          ? historyService.getAlarmDescriptionByAddressAndBitFormat(
              element.sensor,
              element.iotId.split("_")[0]
            )
          : element.sensor;
        element.value = element.value === 1 ? "alarm.activated" : "alarm.deactivated";
      });
  
      setAlarms(response.items);
    } catch (err: any) {
      setError(err.message || "An error occurred while fetching alarms.");
    } finally {
      setLoading(false);
    }
  };
  

 

  const getAlarmDescription = (addressAndBit: string, model: string): string => {
    return historyService.getAlarmDescriptionByAddressAndBitFormat(addressAndBit, model);
  };

  const searchHistoryAlarms = async (params?: CloudHistoryMeasuresSearchParams) => {
    await fetchAlarms(params);
  };

  return { alarms, loading, error, getAlarmDescription, searchHistoryAlarms };
};
