import { useState } from "react";
import { t } from "i18next";
import { HistoryAlarmTableType } from "./HistoryAlarmTableType";
import { unixTimeToDate } from "@/utils/constants/consts";

const HistoryAlarmsTable = ({ HistoryAlarmInfo }: HistoryAlarmTableType) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const concatTranslateLiteral = (param: string) => {
    const translationKey = `alarm.${param}`;
    return t(translationKey);
  };

  const totalPages = Math.ceil(HistoryAlarmInfo.length / itemsPerPage);
  const renderItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return HistoryAlarmInfo.slice(startIndex, endIndex).map((item, index) => (
      <div
        key={index}
        className="grid grid-cols-12 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
      >
        <div className="px-4 py-3 col-span-4 flex items-center justify-center">
          {unixTimeToDate(item.timestamp)}
        </div>
        <div className="px-4 py-3 col-span-4 flex items-center justify-center">
          {concatTranslateLiteral(item.sensor)}
        </div>
        <div className="px-4 py-3 col-span-4 flex items-center justify-center gap-2">
          <div
            className={`h-3 w-3 rounded-full item ${item.value === "alarm.activated" ? "bg-green-500" : "bg-red-500"
              }`}
          ></div>
          {concatTranslateLiteral(item.value)}
        </div>
      </div>
    ));
  };

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full">
      <div className="hidden md:flex flex-col">
        <div className="flex flex-col border rounded-lg">
          <div className="grid grid-cols-12 h-10 bg-gray-100 dark:bg-gray-800">
            <div className="flex items-center justify-center col-span-4 text-center text-gray-700 dark:text-gray-300">
              {t("alarm.search.column.undefined.timestamp")}
            </div>
            <div className="flex items-center justify-center col-span-4 text-center text-gray-700 dark:text-gray-300">
              {t("alarm.search.column.undefined.sensor")}
            </div>
            <div className="flex items-center justify-center col-span-4 text-center text-gray-700 dark:text-gray-300">
              {t("alarm.search.column.undefined.value")}
            </div>
          </div>
          {renderItems()}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`mx-1 px-3 py-1 rounded-md ${currentPage === index + 1 ? "bg-red-500 text-white" : "bg-gray-200"
              }`}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HistoryAlarmsTable;