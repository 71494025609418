import { useCallback, useEffect, useState } from "react";

export default function useListState<T>(items: T[], identifier: keyof T) {
  const [itemStates, setItemStates] = useState(new Map<any, boolean>());

  useEffect(() => {
    if(items.length > 0) {
      setItemStates((prevStates) => {
        const newStates = new Map(prevStates);
        items.forEach((item) => {
          const itemId = item[identifier];
          if (!newStates.has(itemId)) newStates.set(itemId, false);
        });
        return newStates;
      });
    }
  }, [items, identifier]);

  const getItemState = useCallback((itemId: any) => {
    let state = itemStates.get(itemId);
    if(state === undefined) state = false;
    return state;
  }, [itemStates]);

  const toggleItem = (itemId: any) => {
    setItemStates((prevStates) => {
      const newStates = new Map(prevStates);
      if (newStates.has(itemId)) newStates.set(itemId, !newStates.get(itemId));
      return newStates;
    });
  };

  const toggleAll = (expand: boolean) => {
    setItemStates((prevStates) => {
      const newStates = new Map(prevStates);
      newStates.forEach((currentState, itemId) => {
        if (currentState !== expand) {
          newStates.set(itemId, expand);
        }
      });
      return newStates;
    });
  };

  return { getItemState, toggleItem, toggleAll };
}
