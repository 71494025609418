import React, { useEffect, useState } from "react";
import { CustomItemCardType } from "./CustomItemCardType";
import CustomIconDropdown from "@/components/organisms/CustomIconDropdown";
import CustomIconButton from "@/components/atoms/CustomIconButton";
import "./CustomItemCard.scss";

const CustomItemCard = ({
  onClick = (e: React.MouseEvent<HTMLDivElement>) => { },
  expandedByDefault = false,
  dropDownDisabled = false,
  showIfExpanded = false,
  collapsedChildren,
  expandedChildren,
  border = false,
  containerClass,
  dropDownHidden,
  leftLineColor,
  fixed = false,
  hoverTabText,
  leftMargin,
  className,
  dropDown,
  leftLine,
}: CustomItemCardType) => {
  const [isExpandedCard, setExpandedCard] = useState(expandedByDefault);
  const [oldChildren, setOldChildren] = useState(collapsedChildren)

  const setExpandedCarditems = (e: any) => {
    setExpandedCard(!isExpandedCard);
    e.stopPropagation()
  };

  const dismissCardClick = (e: any) => {
    e.stopPropagation()
  }

  useEffect(() => {
    collapsedChildren !== oldChildren && setExpandedCard(false);
  }, [collapsedChildren])

  return (
    <div
      className={`custom-item-card w-full transition-shadow duration-300 rounded-xl
      ${isExpandedCard ? '' : "hover:shadow-lg cursor-pointer"}
      ${containerClass ? containerClass : ''}
      ${leftMargin ? "pl-6" : ''}`}
      onClick={onClick}
    >
      {hoverTabText && <div className="custom-item-card__hover-text">{hoverTabText}</div>}
      <div
        className={`bg-white p-4 gap-4 rounded-xl transition-shadow duration-300 
        ${leftLineColor ? `custom-item-card--left-line-${leftLineColor}` : ''}
        ${leftLine ? "custom-item-card--left-line" : ''}
        ${border ? "border border-salicru border-2" : ''}
        ${className ? className : ''}`}
      >
        <div className="flex gap-4">
          {(!isExpandedCard || showIfExpanded) && collapsedChildren}

          <div className="flex items-center justify-center">
            <div className="flex justify-center items-end">
              {expandedChildren && (
                <CustomIconButton
                  icon={isExpandedCard ? "la:angle-up" : "la:angle-down"}
                  onClick={setExpandedCarditems}
                  borderShadow={false}
                  variant="secondary"
                />
              )}

              {dropDown && dropDown.length > 0 ? (
                <CustomIconDropdown
                  disabled={dropDownDisabled}
                  onClick={dismissCardClick}
                  dropdownItems={dropDown}
                  fixed={fixed && fixed}
                  borderShadow={false}
                  icon="la:ellipsis-h"
                  variant="secondary"
                />
              ) : dropDownHidden && <div className="w-11"></div>}
            </div>
          </div>
        </div>
        {isExpandedCard && <div className="mt-4">{expandedChildren}</div>}
      </div>
    </div>
  );
};

export default CustomItemCard;