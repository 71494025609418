import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { toast } from "react-hot-toast";

import HeaderTitle from "@/components/molecules/HeaderTitle";
import CustomItemCard from "@/components/organisms/CustomItemCard";
import HeaderAction from "@/components/organisms/HeaderAction";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomList from "@/components/molecules/CustomList";
import CustomEmptyList from "@/components/molecules/CustomEmptyList";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomModal from "@/components/organisms/CustomModal";
import DeleteModal from "@/components/organisms/DeleteModal";
import CustomSelectRounded from "@/components/organisms/CustomSelectRounded";
import NotificationCollapsed from "@/components/organisms/NotificationCollapsed";
import ModalNotification from "@/components/organisms/ModalNotification";

import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";
import useListState from "@/utils/hooks/useListState";
import { useRender } from "@/utils/hooks/useRender";
import useScrollDetection from "@/utils/hooks/useScrollDetection";
import useDynamicDataLoader from "@/utils/hooks/useDynamicDataLoader";
import useNotificationList from "@/utils/hooks/useNotificationList";

import { ActionItemType } from "@/utils/models";
import { IListCard } from "@/utils/models/interface/IListCard";
import { INotification } from "@/utils/models/entities/Notification";

import { getSelectOptions, notificationLogicComponent } from "./d";

const Notifications: React.FC = () => {
  const isAdmin = true;

  const [isEditNotification, setIsEditNotification] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [noMoreData, setMoreData] = useState(false);

  const [currentNotification, setCurrentNotification] = useState<
    INotification | any
  >(null);

  const onCloseDeleteModal = () => setShowDeleteModal(false);
  const onCloseCreateModal = () => {
    setIsEditNotification(false);
    setShowCreateModal(false);
  };

  const { loading, data, fetchInitialData, fetchMoreData } =
    useDynamicDataLoader();

  const { loading: notificationsLoading, disabled } = useNotificationList();

  useEffect(() => {
    fetchInitialData("", SearchParamsEnum.notification);
  }, []);

  const handleLoadMore = async () => {
    data.length === 0
      ? setMoreData(true)
      : fetchMoreData("", SearchParamsEnum.notification);
  };

  const { modalDeleteNotification, titleModalEdit, titleModalDelete } =
    notificationLogicComponent({ isEditing: isEditNotification });

  const { loaderRef, activateScrollDetection, deactivateScrollDetection } =
    useScrollDetection(handleLoadMore, { rootMargin: "0px", threshold: 0.5 });

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      const searchValue = e.target.value;
      setSearchTerm(searchValue);
      if (searchValue === "") {
        activateScrollDetection();
      } else {
        deactivateScrollDetection();
      }
      fetchInitialData(searchValue, SearchParamsEnum.notification);
    },
    [activateScrollDetection, deactivateScrollDetection, fetchInitialData]
  );

  const { getItemState: notificationState, toggleItem: toggleNotification } =
    useListState<INotification>(data, "userId");

  const notificationOptionDropDown = (
    notificationId: string,
    notification: INotification
  ): ActionItemType[] => [
      {
        action: () => {
          setCurrentNotification(notification);
          setShowCreateModal(true);
          setIsEditNotification(true);
        },
        icon: "la:edit",
        text: t("notificationDetail.form.title.edit"),
      },
      {
        action: () => {
          setCurrentNotification(notification);
          setShowDeleteModal(true);
        },
        icon: "la:trash",
        text: t("notificationDetail.delete.title"),
      },
    ];

  const renderNotificationCard = (
    notification: INotification,
    index: number
  ) => (
    <CustomItemCard
      key={index}
      collapsedChildren={
        <NotificationCollapsed notificationInfo={notification} />
      }
      expandedChildren={undefined}
      dropDown={notificationOptionDropDown(notification.iotId, notification)}
    />
  );

  const renderedNotificationCards = useRender(
    data,
    toggleNotification,
    notificationState,
    (props: IListCard<INotification>) => {
      const { model: notification, key } = props;
      return renderNotificationCard(notification, key);
    },
    "userId",
    { isAdmin: isAdmin }
  );

  const headerButton = [
    {
      onClick: () => setShowCreateModal(true),
      text: t("general.create"),
      disabled: disabled,
      icon: "la:plus",
    },
  ];

  const bodyModalNotification = (isEditNotification: any) => (
    <ModalNotification
      onClose={onCloseCreateModal}
      isEdit={isEditNotification}
      {...(isEditNotification && { notification: currentNotification })}
    />
  );

  const deleteModal = () => {
    modalDeleteNotification(currentNotification, onCloseDeleteModal, async () =>
      refreshList(searchTerm, SearchParamsEnum.notification)
    );
  };
  const refreshList = (
    searchParams?: string,
    dataSearch?: SearchParamsEnum
  ) => {
    const searchValue = searchParams || "";
    const dataNameField = dataSearch || SearchParamsEnum.notification;
    fetchInitialData(searchValue, dataNameField);
  };

  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex flex-col bg-white p-8 lg:px-12 lg:pt-6 lg:pb-8 gap-4 transition-all duration-300">
          <HeaderTitle title={t("menu.notification")} buttons={headerButton} />
          <HeaderAction actionItems={[]} dropdownItems={[]}>
            <div className="w-2/4">
              <CustomSelectRounded
                options={getSelectOptions(data)}
                onSearch={handleSearch}
                onChange={handleSearch}
                name={t("notificationDetail.search")}
                placeholderText={t("notificationDetail.search")}
                deleteOnChange={handleSearch}
              />
            </div>
          </HeaderAction>
        </div>

        <CustomLayout>
          {data.length > 0 ? (
            <CustomList
              elements={renderedNotificationCards}
              showGoTop={false}
            />
          ) : (
            <CustomEmptyList title={t("notificationDetail.detail.emptyList")} />
          )}
          <div ref={loaderRef}>
            {notificationsLoading && <CustomLoader visible={loading} />}
          </div>
        </CustomLayout>
        <CustomModal
          isOpen={showCreateModal}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={onCloseCreateModal}
          title={titleModalEdit(isEditNotification)}
          body={bodyModalNotification(isEditNotification)}
        />
        <CustomModal
          isOpen={showDeleteModal}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={onCloseDeleteModal}
          title={t("notificationDetail.delete.title")}
          body={
            <DeleteModal
              onClose={onCloseDeleteModal}
              title={titleModalDelete(currentNotification)}
              deleteFunction={deleteModal}
              button={t("notificationDetail.delete.title")}
            />
          }
        />
      </div>
    </>
  );
};

export default Notifications;
