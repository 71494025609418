import React, { useState, useEffect, ChangeEvent } from "react";
import CustomInput from "@/components/molecules/CustomInput";
import CustomIcon from "@/components/atoms/CustomIcon";
import CustomButton from "@/components/atoms/CustomButton";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import useAuth from "@/utils/hooks/useAuth";
import { FieldMailValue } from "./d";
import { t } from "i18next";
import { isValidEmail } from "@/utils/constants";
import { CustomDynamicFieldMailType } from "./DynamicFieldMailType";
import { CustomDynamicFieldEnumMode } from "../ModalNotification/d";

const DynamicFieldMail = ({
  maxFieldsCount = 5,
  name = "",
  additionalMail = [],
  isEdit = false,
  statusCheck,
  onChange,
  className = "",
}: CustomDynamicFieldMailType) => {
  const { getCurrentUserHook } = useAuth();
  const currentUser = getCurrentUserHook();
  const [showFields, setShowFields] = useState(false);
  const [formData, setFormData] = useState<FieldMailValue[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (isEdit) {
      if (additionalMail.length > 0) {
        setShowFields(true);
        const initialData: FieldMailValue[] = [];

        additionalMail.forEach((email, index) => {
          initialData.push({
            name: `${name}${index}`,
            value: email,
          });
        });
        setFormData(initialData);
      }
    }
  }, []);

  const handleFieldChange =
    (index: number) =>
      (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | any) => {
        const { name, value } = e.target;
        setFormData((prevData) =>
          prevData.map((field, i) =>
            i === index
              ? {
                ...field,
                [name.includes("countryCode") ? "countryCode" : "value"]: value,
              }
              : field
          )
        );
        validateField(name, value, index);
      };

  const addAdditionalField = () => {
    if (formData.length < maxFieldsCount) {
      const newName = `${name}${formData.length}`;
      setFormData((prevData) => [
        ...prevData,
        { name: newName, value: "" },
      ]);
    }
  };

  const removeAdditionalField = (index: number) => {
    setFormData((prevData) => {
      const newData = prevData.filter((_, i) => i !== index);
      return newData;
    });
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (showFields) {
      onChange?.(formData);
    }
  }, [formData]);

  const validateField = (name: string, value: string, index: number) => {
    let error = "";
    if (!value) {
      error = t("user.formUser.formValidationError.emailMandatory");
    } else {
      if (!isValidEmail(value)) {
        error = t("user.formUser.formValidationError.emailFormat");
      }
    }
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = error;
      return newErrors;
    });
  };

  return (
    <div className={`my-6 mx-2 ${className}`}>
      <div className="flex justify-between gap-6 my-4">
        <CustomCheckbox
          label={name}
          name={name}
          checked={showFields}
          onChange={(e) => {
            statusCheck(e.target.checked);
            setShowFields(!showFields);
          }}
        />
        {showFields && (
          <CustomButton onClick={addAdditionalField}>
            {t("general.addField")}
          </CustomButton>
        )}
      </div>

      {showFields && (
        <form>
          <div className="pt-5">
            {formData.map((field, index) => (
              <div
                className="flex flex-col lg:flex-row items-center gap-2 my-4"
                key={index}
              >
                <div className="w-full flex-col">
                  <CustomInput
                    className={index === 0 ? "" : className}
                    label={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={handleFieldChange(index)}
                    helperText={errors[index]}
                    danger={errors[index] ? true : false}
                  />
                </div>
                {index > 0 && (
                  <div
                    className="cursor-pointer flex justify-center"
                    onClick={() => removeAdditionalField(index)}
                  >
                    <CustomIcon icon="la:trash" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </form>
      )}
    </div>
  );
};

export default DynamicFieldMail;