import { useCallback, useState } from "react";
import { createISearchParams, searchList } from "@/utils/constants";
import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";

const useDynamicDataLoader = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const fetchLimit = 14;

  const fetchData = useCallback(
    (
      pageIndex: number,
      searchTerm: string = "",
      models: SearchParamsEnum
    ) => {
      setLoading(true);
      const startIndex = pageIndex * fetchLimit;
      const searchParams = createISearchParams(
        searchTerm,
        startIndex,
        fetchLimit
      );

      searchList
        .getItems(searchParams, models)
        .then((res) => {
          if (res && Array.isArray(res.items) && res.items.length > 0 && res.count > 0) {
            if (pageIndex === 0) {
              setData(res.items);
            } else {
              setData((prevData) => [...prevData, ...res.items]);
            }
            setPageIndex((prevPageIndex) => prevPageIndex + 1);

            if (totalCount === 0) {
              setTotalCount(res.count);
            }
          } else {
            // Si no hay items o count es 0, detener la carga adicional
            setLoading(false);
            return;
          }
        })
        .catch((error: any) => {
          console.error("Error obtaining data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [fetchLimit, totalCount]
  );

  const fetchMoreData = useCallback(
    (searchTerm = "", models: SearchParamsEnum) => {
      if (
        !loading && (totalCount === 0 || pageIndex * fetchLimit < totalCount)
      ) {
        fetchData(pageIndex, searchTerm, models);
      }
    },
    [fetchData, pageIndex, loading, totalCount]
  );

  const fetchInitialData = useCallback(
    (searchTerm = "", models: SearchParamsEnum) => {
      setPageIndex(0);
      setTotalCount(0);
      fetchData(0, searchTerm, models);
    },
    [fetchData]
  );

  return {
    loading,
    data,
    pageIndex,
    totalCount,
    fetchInitialData,
    fetchMoreData,
  };
};

export default useDynamicDataLoader;
