import React from "react";
import { DeviceCollapsedType } from "./DeviceCollapsedType";
import LabeledText from "@/components/molecules/LabeledText";
import { t } from "i18next";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const DeviceCollapsed = ({
  deviceInfo,
  onActionClick,
}: DeviceCollapsedType) => {
  const navigate = useNavigate();
  const redirectToDeviceDetails = () => {
    navigate(`/device/${deviceInfo.id}/measures`);
  };

  return (
    <div onClick={redirectToDeviceDetails} className="grid grid-cols-2 lg:grid-cols-4 w-full pl-6">
      <LabeledText
        label={t("device.generalData.serialNumber")}
        text={deviceInfo.iotId}
        className={"col-span-1 text-ellipsis overflow-hidden"}
      />

      <LabeledText
        label={t("device.generalData.description")}
        text={deviceInfo.description}
        className={"col-span-1 text-ellipsis overflow-hidden"}
        ellipsis
      />

      <LabeledText
        label={t("device.generalData.company")}
        text={deviceInfo.company}
        className={"col-span-1 text-ellipsis overflow-hidden"}
        ellipsis
      />

      <LabeledText
        label={t("device.generalData.status")}
        text={
          deviceInfo.status == "offline" ? (
            <Icon className="text-red-600" icon="fa:times" />
          ) : (
            <Icon className="text-green-400" icon="fa:check" />
          )
        }
        className={"col-span-1 flex flex-col items-center"}
      />
    </div>
  );
};

export default DeviceCollapsed;