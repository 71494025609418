import { useEffect, useRef, useState } from "react";

const useScrollDetection = (onScrollEnd:any, options:any) => {
  const [isScrollDetectionActive, setIsScrollDetectionActive] = useState(true);
  const loaderRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && isScrollDetectionActive) {
          onScrollEnd();
        }
      },
      options
    );

    if (loaderRef.current && isScrollDetectionActive) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isScrollDetectionActive, onScrollEnd, options]);

  const activateScrollDetection = () => {
    setIsScrollDetectionActive(true);
  };

  const deactivateScrollDetection = () => {
    setIsScrollDetectionActive(false);
  };

  return { loaderRef, activateScrollDetection, deactivateScrollDetection };
};

export default useScrollDetection;