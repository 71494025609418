import { ISearchParams } from "../models";
import { SearchParamsEnum } from "../enums/SearchParamsType";
import { getEmailCurrentUser } from "../constants";

const useSearchParams = (
  params: ISearchParams,
  searchType: SearchParamsEnum
) => {
  const getStringifiedSearchParams = () => {
    let orderContent =
      params.sortColumn + " " + (params.sortAsc ? "ASC" : "DESC");
    let offsetContent = params.skip;
    let whereQuery: any = {};

    if (searchType === SearchParamsEnum.user) {
      whereQuery = {
        and: [{ email: { neq: getEmailCurrentUser() } }],
      };
    }

    if (searchType === SearchParamsEnum.device) {
      if (params.term) {
        whereQuery = {
          or: [
            { iotId: { like: params.term, options: "i" } },
            { description: { like: params.term, options: "i" } },
            { company: { like: params.term, options: "i" } },
          ],
        };
      }
    } else if (searchType === SearchParamsEnum.user) {
      if (params.term) {
        whereQuery = {
          or: [
            { email: { like: ".*" + params.term + ".*" } },
            { name: { like: ".*" + params.term + ".*" } },
            { surname: { like: ".*" + params.term + ".*" } },
          ],
        };
      }
    } else if (searchType === SearchParamsEnum.notification) {
      if (params.term) {
        whereQuery = {
          iotId: { like: `${params.term}` },
        };
      }
    }

    let newParams: any = {
      where: whereQuery,
      offset: 0,
      skip: offsetContent,
      order: [orderContent],
      fields: {},
    };

    if (params.size) {
      newParams.limit = params.size;
    }
    return JSON.stringify(newParams);
  };

  return { getStringifiedSearchParams };
};

export default useSearchParams;
