import { CustomTextType } from "./CustomTextType";

const CustomText = ({
  variant = "primary",
  weight = "regular",
  size = "base",
  className,
  children,
}: CustomTextType) => {
  const getStyle = () => {
    return variant === "secondary"
      ? "text-blueGrey200"
      : variant === "gray"
        ? "text-blueGrey100"
        : variant === "danger"
          ? "text-salicru"
          : "";
  };

  const getBold = () => {
    return weight === "regular"
      ? "font-manropeRegular"
      : weight === "semi"
        ? "font-manropeSemiBold"
        : "font-manropeBold";
  };

  return (
    <p className={`${getBold()} ${getStyle()} text-${size} ${className ? className : ''}`}>
      {children}
    </p>
  );
};

export default CustomText;
