import React, { useEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import CustomLoader from "@/components/atoms/CustomLoader";
import { PowerCurveChartType } from "./PowerCurveChartType";
import { PowerCurvedChartFormComponent } from "./d";

const PowerChart: React.FC<PowerCurveChartType> = ({
  measureData,
  collapsed,
  expanded,
  loading,
}) => {
  const { chartSeries, chartOptions, heightScreen } =
    PowerCurvedChartFormComponent({
      measureData: measureData,
      collapsed: collapsed,
      expanded: expanded,
      loading: loading,
    });
   

  return (
    <>
      <CustomLoader visible={loading} />
      <div
        className={`${
          expanded
            ? "my-4  mx-2  px-2 py-4 border border-neutralGrey200 rounded-2xl lg:mx-10 md:mx-10 mx-6 h-[37rem]"
            : "w-full h-full"
        }`}
      >
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={heightScreen}
          width="100%"
        />
      </div>
    </>
  );
};

export default PowerChart;
