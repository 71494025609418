import CustomText from "@/components/atoms/CustomText";
import { LabeledTextType } from "./LabeledTextType";

const LabeledText = ({
  ellipsis = false,
  className = "",
  label,
  text
}: LabeledTextType) => {
  return (
    <div className={`${className}`}>
      <CustomText
        size="sm"
        variant="secondary"
        className={`flex
            ${ellipsis ? "overflow-hidden overflow-ellipsis whitespace-nowrap" : ''}
          `.trim()}
      >
        {label}
      </CustomText>
      <CustomText
        size="base"
        weight="bold"
        className={`flex
          ${ellipsis ? "!block overflow-hidden overflow-ellipsis whitespace-nowrap" : ''}
          `.trim()}
      >
        {text}
      </CustomText>
    </div>
  )
}

export default LabeledText;