import CustomButton from "@/components/atoms/CustomButton";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomText from "@/components/atoms/CustomText";
import GoBackHeader from "@/components/molecules/CustomGoBackHeader";
import HeaderTitle from "@/components/molecules/HeaderTitle";
import LabeledText from "@/components/molecules/LabeledText";
import CustomCard from "@/components/organisms/CustomCard";
import CustomModal from "@/components/organisms/CustomModal";
import ModalNotification from "@/components/organisms/ModalNotification";
import { langOptions } from "@/utils/constants";
import useAuth from "@/utils/hooks/useAuth";
import useDeviceListNotification from "@/utils/hooks/useDeviceListNotification";
import { INotification } from "@/utils/models/entities/Notification";
import { t } from "i18next";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
const NotificationDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  let notificationID: string = "";

  if (id) {
    notificationID = id;
  }

  const { getCurrentUserHook } = useAuth();
  const user = getCurrentUserHook();

  const { currentUser, notificationList, loading, error } =
    useDeviceListNotification(user.id);

  const getNotificationById = (notifications: any, id: any) => {
    return notifications.find(
      (notification: INotification) => notification.id === id
    );
  };

  const notification: INotification = getNotificationById(
    notificationList,
    notificationID
  );

  function getLanguage(valueToCompare?: string): string | undefined {
    if (valueToCompare) {
      const matchedOption = langOptions.find(
        (el) => el.value === valueToCompare
      );
      if (matchedOption) {
        return matchedOption.label;
      }
    }
    return valueToCompare;
  }
  const [showCreateModal, setShowCreateModal] = useState(false);

  const onOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };
  return (
    <>
      {loading ? (
        <CustomLoader visible={loading} />
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="flex h-full w-full">
          <div className="w-full bg-neutralGrey100">
            <GoBackHeader goBackText={t("notificationDetail.goBackNotificationList")} href="/notifications" />
            <div>
              <CustomLayout className="relative">
                <div className="flex gap-2 mb-6">
                  <HeaderTitle
                    title={
                      t("notificationDetail.form.detailNotification") +
                      " " +
                      notification?.iotId
                    }
                    className="flex items-center w-full my-0 py-0"
                  />
                  <CustomButton
                    variant="primary"
                    leftIconName="la:edit"
                    onClick={onOpenCreateModal}
                  >
                    {t("user.notificationTable.edit")}
                  </CustomButton>
                </div>
                <div className="my-5">
                  <CustomCard
                    padding="lg"
                    expandable={false}
                    title={t("notificationDetail.form.userNotification")}
                    icon={"la:info-circle"}
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-7">
                      <CustomText className="col-span-2" variant="secondary">
                        {t("profile.language")}
                      </CustomText>
                      <div className="border-b  col-span-2"></div>
                      <LabeledText
                        label={t("")}
                        text={getLanguage(notification.locale)}
                        className={"col-span-2"}
                      />

                      <CustomText
                        variant="secondary"
                        className="col-span-1 mt-4"
                      >
                        {t("notificationDetail.form.notifications")}
                      </CustomText>

                      <div className="border-b  col-span-2"></div>
                      <CustomText variant="primary" className="col-span-1">
                        {t("notificationDetail.detail.notifyByEmail")}
                      </CustomText>
                      <CustomText className="col-span-1" variant="primary">
                        {t("notificationDetail.detail.notifyBySMS")}
                      </CustomText>

                      <LabeledText
                        label={t("user.email")}
                        text={currentUser?.email}
                        className={"col-span-1"}
                      />

                      <LabeledText
                        label={t("notificationDetail.detail.ownerPhone")}
                        text={
                          currentUser?.countryCode +
                          " " +
                          currentUser?.nationalPhone
                        }
                        className={"col-span-1"}
                      />

                      <div className="flex flex-col gap2 col-span-1">
                        <CustomText variant="secondary" className="col-span-1">
                          {t("notificationDetail.detail.additionalMail")}
                        </CustomText>
                        {notification &&
                          notification.additionalMail?.map((mail: string) => (
                            <LabeledText
                              key={mail.charAt(1)}
                              label={""}
                              text={mail}
                              className={"col-span-1"}
                            />
                          ))}
                      </div>

                      <div className="flex flex-col gap2 col-span-1">
                        <CustomText variant="secondary" className="col-span-1">
                          {t("notificationDetail.detail.additionalPhone")}
                        </CustomText>
                        {notification &&
                          notification.additionalPhone?.map(
                            (
                              phone: { countryCode: string; number: string },
                              index: number
                            ) => (
                              <LabeledText
                                key={index}
                                label=""
                                text={phone.countryCode + " " + phone.number}
                                className="col-span-1"
                              />
                            )
                          )}
                      </div>
                    </div>
                  </CustomCard>
                </div>
              </CustomLayout>
            </div>
          </div>
        </div>
      )}

      <CustomModal
        isOpen={showCreateModal}
        size="4xl"
        icon={"clarity:contract-line"}
        onClose={onCloseCreateModal}
        title={t("notificationDetail.form.title.edit")}
        body={
          <ModalNotification
            onClose={onCloseCreateModal}
            isEdit={true}
            notification={notification}
          />
        }
      />
    </>
  );
};

export default NotificationDetail;
