import { useState } from "react";
import { Icon } from "@iconify/react";
import { CustomSearchBarType } from "./CustomInputRoundedType";

const CustomSearchBar = ({
  customClass = '',
  defaultText = '',
  placeholderText,
  onSearch,
}: CustomSearchBarType) => {
  const [searchText, setSearchText] = useState(defaultText || '');
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(searchText);
    }
  };

  const handleButtonClick = () => {
    onSearch(searchText);
  };

  return (
    <div className={`relative text-gray-600 w-full ${customClass}`}>
      <input
        className="font-manropeMedium border-2 border-gray-300 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none w-full focus:shadow-md blur-none !autofill:bg-transparent"
        placeholder={placeholderText}
        onKeyDown={handleKeyDown}
        value={searchText || ''}
        name="search"
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <button
        className="absolute right-0 top-0 mt-3 mr-4 hover:drop-shadow"
        onClick={handleButtonClick}
      >
        <Icon
          icon="la:search"
          className="w-6 h-6 hover:scale-105   transition-all duration-300"
        />
      </button>
    </div>
  );
};

export default CustomSearchBar;