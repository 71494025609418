import { CustomOptionType } from "@/components/molecules/CustomSelect";
import { notificationService } from "@/services/notifications.service";
import { reloadPage } from "@/utils/constants/consts";
import { t } from "i18next";
import toast from "react-hot-toast";

export const getSelectOptions = (notifications: any[]) => {
  let optionsArrayOptions: CustomOptionType[] = [];
  notifications.forEach((notification) => {
    optionsArrayOptions.push({
      label: notification.iotId,
      value: notification.iotId,
    });
  });
  return optionsArrayOptions;
};
export const notificationLogicComponent = ({ isEditing }: { isEditing: boolean }) => {
  const titleModalEdit = (currentNotification: any) => {
    let title = !currentNotification
      ? t("notificationDetail.form.title.new")
      : t("notificationDetail.form.title.edit")
    return title;
  };
  const titleModalDelete = (currentNotification: any) => {
    const confirmationText = t("device.deleteModal.confirmationText");
    const confirmationTextWarning = t(
      t("device.deleteModal.confirmationTextWarning")
    );
    const userName = currentNotification?.iotId || "";

    return `${confirmationText} ${userName} ${confirmationTextWarning}`;
  };

  const modalDeleteNotification = (
    deleteModalElement: any,
    onCloseDeleteModal: () => void,
    refreshListData: () => Promise<void>
  ) => {
    notificationService
      .delete(deleteModalElement?.id)
      .then(() => {
        toast.success(t("notificationDetail.deleteNotifications"));
        onCloseDeleteModal();
        const interval = reloadPage();
      })
      .catch((err) => {
        toast.error(t("device.deleteModal.toastDelete.error"))
      });
  };

  return {
    titleModalEdit,
    titleModalDelete,
    modalDeleteNotification,
  };
};
