import React, { ChangeEvent, useState } from "react";

import CustomIconDropdown from "@/components/organisms/CustomIconDropdown";
import CustomCard from "@/components/organisms/CustomCard";

import CustomSelect, {
} from "@/components/molecules/CustomSelect";
import CustomSearchBar from "@/components/molecules/CustomInputRounded";
import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import CustomInput from "@/components/molecules/CustomInput";

import CustomIconButton from "@/components/atoms/CustomIconButton";
import CustomButton from "@/components/atoms/CustomButton";
import CustomTitle from "@/components/atoms/CustomTitle";
import CustomText from "@/components/atoms/CustomText";
import CustomIcon from "@/components/atoms/CustomIcon";


import { dropDownItems } from "../Devices/d";

const Test: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const el = e.currentTarget ? e.currentTarget : e.target;
    if (el) {
      setFormData({
        ...formData,
        [el.name]: el.value,
      });
    }
  };

  return (
    <div className="bg-neutralGrey100 ">
      <nav className="bg-white">
      
      </nav>

      <div className="px-5 pt-5 flex flex-col  lg:flex-row	 gap-4">
        <CustomButton>Lorem ipsum dolor</CustomButton>
        <CustomButton pill={false}>Lorem ipsum dolor</CustomButton>
        <CustomButton disabled={true}>Lorem ipsum dolor</CustomButton>
        <CustomButton leftIconName="la:circle">Lorem ipsum dolor</CustomButton>
        <CustomButton rightIconName="la:square">Lorem ipsum dolor</CustomButton>
        <CustomButton leftIconName="la:circle" rightIconName="la:square">
          Lorem ipsum dolor
        </CustomButton>
        n
      </div>
      <div className="px-5 pt-5 flex  flex-col lg:flex-row gap-4">
        <CustomButton variant="secondary">Lorem ipsum dolor</CustomButton>

        <CustomButton variant="secondary" pill={false}>
          Lorem ipsum dolor
        </CustomButton>

        <CustomButton variant="secondary" disabled={true}>
          Lorem ipsum dolor
        </CustomButton>

        <CustomButton variant="secondary" leftIconName="la:circle">
          Lorem ipsum dolor
        </CustomButton>

        <CustomButton variant="secondary" rightIconName="la:square">
          Lorem ipsum dolor
        </CustomButton>

        <CustomButton
          variant="secondary"
          leftIconName="la:circle"
          rightIconName="la:square"
        >
          Lorem ipsum dolor
        </CustomButton>
      </div>
      <div className="px-5 pt-5 flex flex-col lg:flex-row gap-4">
        <CustomIcon icon="la:circle" size="md" />
        <CustomIcon icon="la:square" tooltip="this is a square" />
        <CustomIcon icon="la:circle" size="xl" />
        <CustomIcon
          icon="la:square"
          size="2xl"
          tooltip="this is a square"
          className="text-pink"
        />
        <CustomIcon icon="la:circle" size="3xl" />

        <CustomIconButton
          onClick={() => {
            alert("hola");
          }}
          variant="secondary"
          icon={"la:lock"}
        ></CustomIconButton>
        <CustomIconButton
          tooltip="Secundary No Onclick"
          variant="secondary"
          icon={"la:lock"}
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            alert("hola");
          }}
          tooltip="Dissabled"
          disabled={true}
          variant="secondary"
          icon={"la:lock"}
        ></CustomIconButton>

        <CustomIconButton
          onClick={() => {
            alert("hola");
          }}
          tooltip=" Onclick"
          variant="primary"
          icon={"la:lock"}
        ></CustomIconButton>
        <CustomIconButton
          tooltip="primary No Onclick"
          variant="primary"
          icon={"la:lock"}
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            alert("hola");
          }}
          tooltip="Dissabled"
          disabled={true}
          variant="primary"
          icon={"la:lock"}
        ></CustomIconButton>

        <CustomIconDropdown
          dropdownItems={dropDownItems}
          variant="primary"
          disabled={false}
          icon="material-symbols-light:10k-sharp"
        />
        {/* <CustomIconDropdown
          dropdownItems={dropDownItems}
          variant="primary"
          disabled={true}
          icon="material-symbols-light:10k-sharp"
        />
        <CustomIconDropdown
          dropdownItems={dropDownItems}
          variant="secondary"
          disabled={false}
          icon="material-symbols-light:10k-sharp"
        />
        <CustomIconDropdown
          dropdownItems={dropDownItems}
          variant="secondary"
          disabled={true}
          icon="material-symbols-light:10k-sharp"
        />

        <CustomIconDropdown
          dropdownItems={DropDownItemDissabled}
          variant="primary"
          disabled={false}
          icon="material-symbols-light:10k-sharp"
        />
        <CustomIconDropdown
          dropdownItems={DropDownItemDissabled}
          variant="secondary"
          disabled={false}
          icon="material-symbols-light:10k-sharp"
        /> */}
      </div>

      <div className="px-5 pt-5 flex flex-col lg:flex-row gap-4">
        <CustomTitle heading="h1">Lorem ipsum dolor sit amet</CustomTitle>
        <CustomTitle heading="h2">Lorem ipsum dolor sit amet</CustomTitle>
        <CustomTitle heading="h3">Lorem ipsum dolor sit amet</CustomTitle>
      </div>
      <div className="px-5 pt-5 flex flex-col lg:flex-row gap-4">
        <CustomTitle variant="secondary" heading="h1">
          Lorem ipsum dolor sit amet
        </CustomTitle>
        <CustomTitle variant="secondary" heading="h2">
          Lorem ipsum dolor sit amet
        </CustomTitle>
        <CustomTitle variant="secondary" heading="h3">
          Lorem ipsum dolor sit amet
        </CustomTitle>
      </div>
      <div className="px-5 pt-5 flex flex-col  lg:flex-row gap-4">
        <CustomText>Lorem ipsum dolor sit amet</CustomText>
        <CustomText weight="semi">Lorem ipsum dolor sit amet</CustomText>
        <CustomText weight="bold">Lorem ipsum dolor sit amet</CustomText>
        <CustomText variant="secondary">Lorem ipsum dolor sit amet</CustomText>
        <CustomText variant="secondary" weight="semi">
          Lorem ipsum dolor sit amet
        </CustomText>
        <CustomText variant="secondary" weight="bold">
          Lorem ipsum dolor sit amet
        </CustomText>
      </div>
      <div className="px-5 pt-5 flex flex-col lg:flex-row gap-4">
        <CustomText size="xs">Lorem ipsum dolor sit amet</CustomText>
        <CustomText size="sm">Lorem ipsum dolor sit amet</CustomText>
        <CustomText size="base">Lorem ipsum dolor sit amet</CustomText>
        <CustomText size="lg">Lorem ipsum dolor sit amet</CustomText>
      </div>
      <div className="px-5 pt-5 flex flex-col  lg:flex-row gap-4">
        <CustomInput
          label="Lorem ipsum"
          name="input1"
          onChange={handleFormChange}
        />
        <CustomInput
          label="Lorem ipsum"
          name="input2"
          defaultValue="Hola"
          readOnly
          onChange={handleFormChange}
        />
        <CustomInput
          label="Lorem ipsum"
          name="input3"
          disabled
          onChange={handleFormChange}
        />
        <CustomInput
          label="Lorem ipsum"
          name="input4"
          icon="la:lock"
          defaultValue="loremipsum"
          type="password"
          onChange={handleFormChange}
        />
        <CustomInput
          label="Lorem ipsum"
          name="input5"
          icon="la:phone"
          helperText="Lorem ipsum door sit amet"
          defaultValue="354"
          type="number"
          onChange={handleFormChange}
        />
      </div>
      <div className="px-5 pt-5 flex  flex-col lg:flex-row gap-4">
        <CustomSelect
          label="lorem ipsum dolor sit amet"
          onChange={handleFormChange}
          name="select1"
          options={[]}
        />
        <CustomSelect
          label="lorem ipsum dolor sit amet"
          onChange={handleFormChange}
          disabled
          name="select1"
          options={[]}
        />
        <CustomSelect
          label="lorem ipsum dolor sit amet"
          onChange={handleFormChange}
          helperText="Lorem ipsum door sit amet"
          name="select1"
          options={[]}
        />
      </div>
      <div className="px-5 pt-5 flex flex-col  lg:flex-row gap-4">
        <CustomCheckbox
          label="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          onChange={handleFormChange}
          name="checkbox1"
        />
        <CustomCheckbox
          label="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          onChange={handleFormChange}
          name="checkbox1"
          disabled
        />
        <CustomCheckbox
          label="Lorem ipsum dolor sit amet consectetur adipiscing elit"
          onChange={handleFormChange}
          name="checkbox1"
          checked={true}
        />
      </div>

      <CustomCard
        title="Custom Card"
        icon={"la:lock"}
        description="Es una prova"
      >
        <>Prueba Prueba Prueba</>
      </CustomCard>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6 m-2">
        <CustomCard
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          icon={"la:lock"}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        >
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Id donec
            ultrices tincidunt arcu non sodales neque sodales. Varius vel
            pharetra vel turpis nunc eget lorem dolor. Duis tristique
            sollicitudin nibh sit amet commodo nulla. Vel facilisis volutpat est
            velit egestas dui id ornare. Fusce ut placerat orci nulla
            pellentesque dignissim. Nunc sed velit dignissim sodales ut.
            Scelerisque purus semper eget duis at tellus at urna. Vestibulum
            mattis ullamcorper velit sed ullamcorper morbi tincidunt. Viverra
            suspendisse potenti nullam ac tortor vitae purus faucibus. Amet
            massa vitae tortor condimentum lacinia quis vel eros. Diam volutpat
            commodo sed egestas egestas fringilla phasellus faucibus. Urna
            porttitor rhoncus dolor purus non enim. At augue eget arcu dictum
            varius. Orci a scelerisque purus semper. Faucibus a pellentesque sit
            amet porttitor eget dolor morbi non. Auctor elit sed vulputate mi
            sit. Pellentesque id nibh tortor id aliquet lectus proin nibh.
            Elementum facilisis leo vel fringilla est ullamcorper. Fermentum dui
            faucibus in ornare. Tellus mauris a diam maecenas sed. Sed vulputate
            odio ut enim blandit volutpat maecenas volutpat blandit. Id volutpat
            lacus laoreet non curabitur gravida. Augue ut lectus arcu bibendum
            at varius vel pharetra. Interdum velit laoreet id donec ultrices
            tincidunt. Proin libero nunc consequat interdum varius sit amet
            mattis. Rhoncus aenean vel elit scelerisque mauris pellentesque
            pulvinar. Dictum sit amet justo donec enim diam vulputate. Commodo
            ullamcorper a lacus vestibulum sed arcu non odio. Morbi non arcu
            risus quis varius quam quisque. Vitae proin sagittis nisl rhoncus
            mattis rhoncus urna neque viverra. Vel pretium lectus quam id. Eget
            duis at tellus at urna condimentum mattis pellentesque. Quam
          </>
        </CustomCard>
        <CustomCard
          title="Custom Card"
          icon={"la:lock"}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        >
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod
            in pellentesque massa placerat duis ultricies lacus. Sed pulvinar
            proin gravida hendrerit. Eget felis eget nunc lobortis mattis.
            Mauris a diam maecenas sed enim ut sem viverra. In vitae turpis
            massa sed elementum tempus egestas sed sed. Dolor purus non enim
            praesent elementum facilisis leo vel fringilla. Elementum curabitur
            vitae nunc sed velit dignissim sodales ut eu. Quam vulputate
            dignissim suspendisse in. At quis risus sed vulputate odio. Lorem
            mollis aliquam ut porttitor leo a diam. Interdum posuere lorem ipsum
            dolor sit amet consectetur adipiscing. Donec adipiscing tristique
            risus nec feugiat in. Viverra suspendisse potenti nullam ac tortor
            vitae purus. Amet luctus venenatis lectus magna fringilla urna.
            Pulvinar neque laoreet suspendisse interdum. Lobortis scelerisque
            fermentum dui faucibus in ornare quam viverra. Libero volutpat sed
            cras ornare arcu dui. Mauris ultrices eros in cursus turpis massa.
            Cursus metus aliquam eleifend mi in nulla posuere. Aliquet risus
            feugiat in ante metus dictum at tempor. Risus feugiat in ante metus
            dictum at tempor commodo. Nisi porta lorem mollis aliquam. Vitae
            elementum curabitur vitae nunc sed velit dignissim sodales ut.
            Mauris ultrices eros in cursus turpis massa. Eget dolor morbi non
            arcu risus quis. Vulputate mi sit amet mauris commodo. Ut tellus
            elementum sagittis vitae et leo duis ut diam. Vitae proin sagittis
            nisl rhoncus mattis. Ut ornare lectus sit amet est placerat in
            egestas. Porttitor leo a diam sollicitudin tempor id eu. Augue
            mauris augue neque gravida. Condimentum id venenatis a condimentum
            vitae sapien pellentesque habitant morbi. Et magnis dis parturient
            montes nascetur ridiculus mus. Volutpat consequat mauris nunc congue
            nisi vitae suscipit tellus. Amet mauris commodo quis imperdiet
          </>
        </CustomCard>
        <CustomCard
          padding="lg"
          expandable={false}
          title="Custom Card"
          icon={"la:lock"}
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        >
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod
            in pellentesque massa placerat duis ultricies lacus. Sed pulvinar
            proin gravida hendrerit. Eget felis eget nunc lobortis mattis.
            Mauris a diam maecenas sed enim ut sem viverra. In vitae turpis
            massa sed elementum tempus egestas sed sed. Dolor purus non enim
            praesent elementum facilisis leo vel fringilla. Elementum curabitur
            vitae nunc sed velit dignissim sodales ut eu. Quam vulputate
            dignissim suspendisse in. At quis risus sed vulputate odio. Lorem
            mollis aliquam ut porttitor leo a diam. Interdum posuere lorem ipsum
            dolor sit amet consectetur adipiscing. Donec adipiscing tristique
            risus nec feugiat in. Viverra suspendisse potenti nullam ac tortor
            vitae purus. Amet luctus venenatis lectus magna fringilla urna.
            Pulvinar neque laoreet suspendisse interdum. Lobortis scelerisque
            fermentum dui faucibus in ornare quam viverra. Libero volutpat sed
            cras ornare arcu dui. Mauris ultrices eros in cursus turpis massa.
            Cursus metus aliquam eleifend mi in nulla posuere. Aliquet risus
            feugiat in ante metus dictum at tempor. Risus feugiat in ante metus
            dictum at tempor commodo. Nisi porta lorem mollis aliquam. Vitae
            elementum curabitur vitae nunc sed velit dignissim sodales ut.
            Mauris ultrices eros in cursus turpis massa. Eget dolor morbi non
            arcu risus quis. Vulputate mi sit amet mauris commodo. Ut tellus
            elementum sagittis vitae et leo duis ut diam. Vitae proin sagittis
            nisl rhoncus mattis. Ut ornare lectus sit amet est placerat in
            egestas. Porttitor leo a diam sollicitudin tempor id eu. Augue
            mauris augue neque gravida. Condimentum id venenatis a condimentum
            vitae sapien pellentesque habitant morbi. Et magnis dis parturient
            montes nascetur ridiculus mus. Volutpat consequat mauris nunc congue
            nisi vitae suscipit tellus. Amet mauris commodo quis imperdiet
            massa. In ante metus dictum at. Mauris in aliquam sem fringilla ut
            morbi. Magna etiam tempor orci eu lobortis elementum nibh tellus. In
            iaculis nunc sed augue lacus viverra vitae. Libero volutpat sed cras
            ornare arcu. Ultricies tristique nulla aliquet enim tortor at auctor
            urna nunc. Leo vel fringilla est ullamcorper eget nulla. Facilisis
          </>
        </CustomCard>
        <CustomSearchBar
          onSearch={(text) => {
            alert(text);
          }}
          placeholderText={"Buscador"}
        ></CustomSearchBar>
      </div>
    </div>
  );
};

export default Test;
