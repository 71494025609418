import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

interface ProtectedRouteProps {
  element: React.ReactElement;
  roles: string[];
  path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, roles, path }) => {
  const { isLoggedIn, hasRole } = useAuth();
  const { logout} =
    useAuth();

  if (!isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  const  unauthorized = () => {
    logout();
    return <Navigate to="/login" />;   
  }

  const userHasRequiredRole = roles.some(role => hasRole(role));
  if (!userHasRequiredRole) {
    unauthorized();
  }

  return element;
};

export default ProtectedRoute;
