import HeaderTitle from "@/components/molecules/HeaderTitle";
import CustomItemCard from "@/components/organisms/CustomItemCard";
import HeaderAction from "@/components/organisms/HeaderAction";
import UserCollapsed from "@/components/organisms/UserCollapsed";
import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";
import useListState from "@/utils/hooks/useListState";
import { useRender } from "@/utils/hooks/useRender";
import useScrollDetection from "@/utils/hooks/useScrollDetection";
import { ActionItemType, IUser } from "@/utils/models";
import { IListCard } from "@/utils/models/interface/IListCard";
import { t } from "i18next";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomList from "@/components/molecules/CustomList";
import CustomEmptyList from "@/components/molecules/CustomEmptyList";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomModal from "@/components/organisms/CustomModal";
import DeleteModal from "@/components/organisms/DeleteModal";
import useDynamicDataLoader from "@/utils/hooks/useDynamicDataLoader";
import ModalUserForm from "@/components/organisms/ModaUserForm";
import CustomSelectRounded from "@/components/organisms/CustomSelectRounded";
import { UserLogicComponent } from "./d";

const Users: React.FC = () => {
  const isAdmin = true;
  const headerButton = [
    {
      onClick: () => setShowCreateModal(true),
      text: t("general.create"),
      icon: "la:plus",
    },
  ];
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalElement, setDeleteModalElement] = useState("");
  const [editCurrentUser, setEditCurrentUser] = useState<IUser | null>(null);
  const onCloseDeleteModal = () => setShowDeleteModal(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditingUser, setIsEditingUser] = useState<boolean>(false);
  const [noMoreData, setMoreData] = useState(false);

  const {
    getSelectOptions,
    modalDeleteUser,
    titleModalEdit,
    titleModalDelete,
  } = UserLogicComponent({ isEditing: isEditingUser });

  const onCloseCreateModal = () => {
    setIsEditingUser(false);
    setShowCreateModal(false);
  };
  const handleLoadMore = async () => {
    data.length === 0
      ? setMoreData(true)
      : fetchMoreData("", SearchParamsEnum.user);
  };

  const { loading, data, fetchInitialData, fetchMoreData } =
    useDynamicDataLoader();
  const { loaderRef, activateScrollDetection, deactivateScrollDetection } =
    useScrollDetection(handleLoadMore, { rootMargin: "0px", threshold: 0.5 });
  const userRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    fetchInitialData("", SearchParamsEnum.user);
  }, []);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      const searchValue = e.target.value;
      setSearchTerm(searchValue);
      if (searchValue === "") {
        activateScrollDetection();
      } else {
        deactivateScrollDetection();
      }
      fetchInitialData(searchValue, SearchParamsEnum.user);
    },
    [activateScrollDetection, deactivateScrollDetection]
  );

  const {
    getItemState: UserState,
    toggleItem: toggleUser,
    toggleAll: toggleAllUser,
  } = useListState<IUser>(data, "name");

  const userOptionDropDawn = (
    userID: string,
    user: IUser
  ): ActionItemType[] => [
      {
        action: () => {
          setEditCurrentUser(user);
          setShowCreateModal(true);
          setIsEditingUser(true);
        },
        icon: "la:edit",
        text: t("user.formUser.editUser"),
      },
      {
        action: () => {
          setEditCurrentUser(user);
          setDeleteModalElement(userID);
          setShowDeleteModal(true);
        },
        icon: "la:trash",
        text: t("user.formUser.deleteUser"),
      },
    ];

  const renderUserCard = (user: IUser, index: number) => (
    <CustomItemCard
      key={index}
      collapsedChildren={<UserCollapsed UserInfo={user} />}
      dropDown={userOptionDropDawn(user.id, user)}
    />
  );

  const renderedUserCards = useRender(
    data,
    toggleUser,
    UserState,
    (props: IListCard<IUser>) => {
      const { model: User, key } = props;
      return renderUserCard(User, key);
    },
    "name",
    { isAdmin: isAdmin }
  );

  const refreshList = (
    searchParams?: string,
    dataSearch?: SearchParamsEnum
  ) => {
    const searchValue = searchParams || "";
    const dataNameField = dataSearch || SearchParamsEnum.user;
    fetchInitialData(searchValue, dataNameField);
  };

  const bodyEditModal = (editCurrentUser: any) => (
    <ModalUserForm
      onClose={onCloseCreateModal}
      isEdit={isEditingUser}
      {...(editCurrentUser && { user: editCurrentUser })}
    />
  );

  const deleteModal = () => {
    modalDeleteUser(deleteModalElement, onCloseDeleteModal, async () =>
      refreshList(searchTerm, SearchParamsEnum.user)
    );
  };

  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex flex-col bg-white p-8 lg:px-12 lg:pt-6 lg:pb-8 gap-4 transition-all duration-300">
          <HeaderTitle title={t("menu.user")} buttons={headerButton} />
          <HeaderAction actionItems={[]} dropdownItems={[]}>
            <div className="w-2/4">
              <CustomSelectRounded
                options={getSelectOptions(data)}
                onSearch={handleSearch}
                onChange={handleSearch}
                name={"searchUser"}
                placeholderText={t("user.search")}
                deleteOnChange={handleSearch}
              />
            </div>
          </HeaderAction>
        </div>
        <CustomLayout>
          <div className="flex flex-col ">
            {data.length > 0 ? (
              <CustomList elements={renderedUserCards} showGoTop={false} />
            ) : (
              <CustomEmptyList title={t("user.detail.emptyList")} />
            )}
            <div ref={loaderRef}>
              {loading && <CustomLoader visible={loading} />}
            </div>
          </div>
        </CustomLayout>

        <CustomModal
          isOpen={showCreateModal}
          dismissible={true}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={onCloseCreateModal}
          title={titleModalEdit(isEditingUser)}
          body={bodyEditModal(editCurrentUser)}
        />

        <CustomModal
          isOpen={showDeleteModal}
          size="4xl"
          icon={"clarity:contract-line"}
          dismissible={true}
          onClose={onCloseDeleteModal}
          title={t("user.formUser.deleteUser")}
          body={
            <DeleteModal
              onClose={onCloseDeleteModal}
              title={titleModalDelete(editCurrentUser)}
              deleteFunction={deleteModal}
              button={t("user.formUser.deleteUser")}
            />
          }
        />
      </div>
    </>
  );
};

export default Users;
