import React, { useState } from "react";
import { CustomLeftMenuType } from "./CustomLeftMenuType";
import { Link } from "react-router-dom";
import CustomListMenu from "@/components/molecules/CustomListMenu";
import { DetailNavigationRoutes } from "@/pages/Device";
import CustomIcon from "@/components/atoms/CustomIcon";

const CustomLeftMenu = ({ isMobile, title }: CustomLeftMenuType) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setSelectedItem(index);
  };

  return (
    <>
      {!isMobile ? (
        <div className="px-7 bg-white shrink-0">
          <div className="mt-11">
            {DetailNavigationRoutes.map((link, i) => (
              <CustomListMenu
                key={i}
                index={i}
                icon={link.icon}
                title={link.name}
                subtitle={link.description}
                href={link.href}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="w-full mb-4 bg-white flex gap-4 flex-col justify-center items-center h-32">
          <div className="flex justify-center items-center   h-10 w-[70vw] border border-neutralGrey300 rounded-full content-top-menu mx-auto">
            {DetailNavigationRoutes.map((link, index) => (
              <Link
                className="cursor-auto py-1 px-2 flex-1 "
                to={""}
                key={index}
              >
                <div
                  className={`flex h-full flex-1 justify-center items-center cursor-pointer ${index === 0
                      ? "rounded-tl-full rounded-bl-full"
                      : index === DetailNavigationRoutes.length - 1
                        ? "rounded-tr-full rounded-br-full"
                        : ""
                    } ${selectedItem !== index
                      ? "border-neutralGrey300 text-blueGrey100"
                      : "bg-blueGrey200 text-white rounded-full border-blueGrey200"
                    }`}
                >
                  <div>
                    <div className="flex">
                      <CustomIcon icon={link.icon} className="text-lg h-5" />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CustomLeftMenu;