export enum modeServiceFunction {
  installService = "installNewService",
  restartService = "restartService",
  actionsDevice = "actionsOnTheDevice",
};

export interface ServiceFormData {
  serviceName: string
  serviceUrl: string
  battType: string
  battValue: string
}