import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { t } from "i18next";
import { DeviceLogicComponent, getSelectOptions } from "./d";
import toast from "react-hot-toast";

import CustomLoader from "@/components/atoms/CustomLoader";
import CustomLayout from "@/components/atoms/CustomLayout";
import CustomEmptyList from "@/components/molecules/CustomEmptyList";
import HeaderTitle from "@/components/molecules/HeaderTitle";
import CustomList from "@/components/molecules/CustomList";

import HeaderAction from "@/components/organisms/HeaderAction";
import CustomSelectRounded from "@/components/organisms/CustomSelectRounded";
import CustomModal from "@/components/organisms/CustomModal";
import ModalCreateDevices from "@/components/organisms/DeviceModalForm";
import DeleteModal from "@/components/organisms/DeleteModal";
import DeviceExpanded from "@/components/organisms/DeviceExpanded";
import DeviceCollapsed from "@/components/organisms/DeviceCollapsed";
import CustomItemCard from "@/components/organisms/CustomItemCard";

import deviceService from "@/services/device.service";
import useScrollDetection from "@/utils/hooks/useScrollDetection";
import useListState from "@/utils/hooks/useListState";
import { useRender } from "@/utils/hooks/useRender";
import { SearchParamsEnum } from "@/utils/enums/SearchParamsType";
import { IListCard } from "@/utils/models/interface/IListCard";
import { ActionItemType, Device, IUser } from "@/utils/models";
import useDynamicDataLoader from "@/utils/hooks/useDynamicDataLoader";
import useAuth from "@/utils/hooks/useAuth";
import { UserLogicComponent } from "../Users/d";

const Devices = () => {
  const isAdmin = true;
  const headerButton = [
    {
      onClick: () => setShowCreateModal(true),
      text: t("general.create"),
      icon: "la:plus",
    },
  ];

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalElement, setDeleteModalElement] = useState("");
  const [currentDevice, setCurrentDevice] = useState<Device | null>(null);
  const [importantDevices, setImportantDevices] = useState<Set<string>>(
    new Set()
  );
  const [searchTerm, setSearchTerm] = useState("");
  const onCloseDeleteModal = () => setShowDeleteModal(false);
  const [isEditingDevice, setIsEditingDevice] = useState<boolean>(false);
  const [noMoreData, setMoreData] = useState(false);
  const { isDevicePinned, pinDevice, unpinDevice, getCurrentUserHook } =
    useAuth();

  const onCloseCreateModal = () => {
    setIsEditingDevice(false);
    setShowCreateModal(false);
  };

  const handleLoadMore = async () => {
    data.length === 0
      ? setMoreData(true)
      : fetchMoreData("", SearchParamsEnum.device);
  };

  const {
    loading: dynamicLoading,
    data,
    fetchInitialData,
    fetchMoreData,
  } = useDynamicDataLoader();
  const { loaderRef, activateScrollDetection, deactivateScrollDetection } =
    useScrollDetection(handleLoadMore, { rootMargin: "0px", threshold: 0.5 });
  let currentUser: IUser = getCurrentUserHook();

  useEffect(() => {
    fetchInitialData("", SearchParamsEnum.device);
  }, []);

  const { titleModalEdit, titleModalDelete, modalDeleteUser } =
    DeviceLogicComponent({ isEditing: isEditingDevice });

  useEffect(() => {
    localStorage.setItem(
      "importantDevices",
      JSON.stringify(Array.from(importantDevices))
    );
  }, [importantDevices]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      const searchValue = e.target.value;
      setSearchTerm(searchValue);
      if (searchValue === "") {
        activateScrollDetection();
      } else {
        deactivateScrollDetection();
      }
      fetchInitialData(searchValue, SearchParamsEnum.device);
    },
    [activateScrollDetection, deactivateScrollDetection, fetchInitialData]
  );

  const deviceOptionDropDawn = (
    deviceID: string,
    device: Device
  ): ActionItemType[] => [
      {
        action: () => {
          setCurrentDevice(device);
          setShowCreateModal(true);
          setIsEditingDevice(true);
        },
        icon: "la:edit",
        text: t("device.form.titleEdit"),
      },
      {
        action: () => {
          setCurrentDevice(device);
          setDeleteModalElement(deviceID);
          setShowDeleteModal(true);
        },
        icon: "la:trash",
        text: t("device.form.deleteDevice"),
      },
      {
        action: () => {
          isDevicePinned(deviceID) ? unpinDevice(deviceID) : pinDevice(deviceID);
        },
        icon: isDevicePinned(deviceID)
          ? "mdi:pin-off-outline"
          : "mdi:pin-outline",
        text: isDevicePinned(deviceID)
          ? t("device.form.unpinDevice")
          : t("device.form.pinDevice"),
      },
    ];

  const { getItemState: deviceState, toggleItem: toggleDevice } =
    useListState<Device>(data, "iotId");

  const renderDeviceCard = (device: Device, index: number) => (
    <CustomItemCard
      border={isDevicePinned(device.id)}
      leftLineColor={"danger"}
      leftLine={true}
      key={index}
      collapsedChildren={<DeviceCollapsed deviceInfo={device} />}
      expandedChildren={<DeviceExpanded deviceInfo={device} />}
      dropDown={deviceOptionDropDawn(device.id, device)}
    />
  );

  const renderedDeviceCards = useRender(
    data,
    toggleDevice,
    deviceState,
    (props: IListCard<Device>) => {
      const { model: device, key } = props;
      return renderDeviceCard(device, key);
    },
    "iotId",
    { isAdmin: isAdmin }
  );

  const bodyEditModal = () => (
    <ModalCreateDevices
      onClose={onCloseCreateModal}
      isEdit={isEditingDevice}
      {...(currentDevice && { device: currentDevice })}
    />
  );

  const refreshList = (
    searchParams?: string,
    dataSearch?: SearchParamsEnum
  ) => {
    const searchValue = searchParams || "";
    const dataNameField = dataSearch || SearchParamsEnum.device;
    fetchInitialData("", SearchParamsEnum.device);
  };

  const deleteModal = () => {
    modalDeleteUser(deleteModalElement, onCloseDeleteModal, async () =>
      refreshList(searchTerm, SearchParamsEnum.device)
    );
  };

  return (
    <>

      <div className="flex flex-col h-screen">
        <div className="flex flex-col bg-white p-8 lg:px-12 lg:pt-6 lg:pb-8 gap-4 transition-all duration-300">
          <HeaderTitle title={t("menu.device")} buttons={headerButton} />
          <HeaderAction actionItems={[]} dropdownItems={[]}>
            <div className="w-2/4">
              <CustomSelectRounded
                options={getSelectOptions(data)}
                onSearch={handleSearch}
                onChange={handleSearch}
                name={"searchDevice"}
                placeholderText={t("device.detail.search")}
                deleteOnChange={handleSearch}
              />
            </div>
          </HeaderAction>
        </div>
        <CustomLayout>
          {data.length > 0 ? (
            <CustomList elements={renderedDeviceCards} showGoTop={false} />
          ) : (
            <CustomEmptyList title={t("device.detail.emptyList")} />
          )}
          <div ref={loaderRef}>
            {dynamicLoading && <CustomLoader visible={dynamicLoading} />}
          </div>
        </CustomLayout>

        <CustomModal
          isOpen={showCreateModal}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={() => {
            onCloseCreateModal();
          }}
          title={titleModalEdit(isEditingDevice)}
          body={bodyEditModal()}
        />
        <CustomModal
          isOpen={showDeleteModal}
          size="4xl"
          icon={"clarity:contract-line"}
          onClose={onCloseDeleteModal}
          title={t("device.form.deleteDevice")}
          body={
            <DeleteModal
              onClose={onCloseDeleteModal}
              title={titleModalDelete(currentDevice?.id)}
              deleteFunction={deleteModal}
              button={t("device.form.deleteDevice")}
            />
          }
        />
      </div>
    </>
  );
};

export default Devices;
