export const DetailNavigationRoutes = [
  {
    name: "device.detail.synoptic",
    description: "",
    href: "synoptic",
    icon: "la:tachometer-alt",
  },
  {
    name: "device.detail.measures",
    href: "measures",
    icon: "la:tachometer-alt",
  },
  {
    name: "device.detail.alarms",
    href: "alarms",
    icon: "ant-design:bell-outlined",
  },
  {
    name: "device.detail.service",
    description: "",
    href: "service",
    icon: "la:list",
  },
  {
    name: "device.detail.device",
    href: "deviceInfo",
    icon: "la:list",
  },
];
