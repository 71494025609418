import React, { useState } from "react";

import { t } from "i18next";
import { Link } from "react-router-dom";
import { Modal } from "flowbite-react";
import terms from "@/assets/policies/terms-conditions.json";
import privacy from "@/assets/policies/privacy-policies.json";

import { ReactComponent as LogoImage } from "@/assets/images/nimbus-logo.svg";
import BackImage from "@/assets/images/login-background.png";

import CustomCheckbox from "@/components/molecules/CustomCheckbox";
import CustomSelect, {
} from "@/components/molecules/CustomSelect";
import CustomInput from "@/components/molecules/CustomInput";

import CustomIconButton from "@/components/atoms/CustomIconButton";
import CustomLoader from "@/components/atoms/CustomLoader";
import CustomButton from "@/components/atoms/CustomButton";
import CustomTitle from "@/components/atoms/CustomTitle";
import CustomText from "@/components/atoms/CustomText";
import { CreateUser } from "@/utils/models";
import {
  accountRole,
  handleFormChange,
  setCountryCodeMap,
} from "@/utils/constants";
import { UserFormComponent } from "@/components/organisms/ModaUserForm/d";

const CreateAccount: React.FC = () => {
  const [professional, setProfessional] = useState<boolean>(false);
  const [termsOk, setTermsOk] = useState<boolean>(true);
  const changeSetTermsOk = (e: any) => setTermsOk(!e);

  const [formData, setFormData] = useState<CreateUser>({
    id: "",
    name: "",
    surname: "",
    password: "",
    confirmPassword: "",
    email: "",
    nationalPhone: "",
    countryCode: "",
    roles: accountRole.User,
    companyName: "",
    country: "",
    professional: false,
    commercialOk: false,
    termsOk: false,
  });
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState<"privacy" | "terms" | false>(
    false
  );

  const customStyles: React.CSSProperties = {
    backgroundImage: `url('${BackImage}')`,
  };

  const privacy_policies = privacy["en"];
  const terms_conditions = terms["en"];

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSave(formData, () => { }, true);
  };

  const { doSave, errors } = UserFormComponent({ isEditing: false, user: {} });

  return (
    <>
      <CustomLoader visible={loading}></CustomLoader>
      <div className="flex flex-col-reverse lg:flex-row w-full h-screen lg:h-[25vh] mb-[5rem]">
        <div className="py-8 lg:px-24 md:px-18 px-12 w-full flex flex-col lg:justify-center items-center h-3/5 lg:h-screen lg:w-4/5 rounded-t-3xl lg:rounded-r-3xl bg-white relative lg:mt-0 -mt-5 lg:-mr-4 ">
          <form
            className="w-full bg-white rounded-lg"
            data-test-id="create-form"
            onSubmit={onSubmit}
          >
            <div className="mb-12 w-full max-w-md">
              <div className="mb-4">
                <div className="flex gap-3 mb-6">
                  <Link to="/login" className="flex">
                    <div>
                      <CustomIconButton
                        icon={"la:arrow-left"}
                      ></CustomIconButton>
                    </div>
                    <div className="pt-3 pl-5">
                      <CustomText variant="secondary">
                        {t("general.back")}
                      </CustomText>
                    </div>
                  </Link>
                </div>
              </div>
              <div>
                <CustomTitle heading="h1">{t("account.title")}</CustomTitle>
                <CustomText variant="secondary" weight="semi">
                  {t("login.subtitle")}
                </CustomText>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:gap-12 md:grid-cols-2 md:gap-y-12">
              <CustomInput
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                label={t("account.name")}
                icon="la:user"
                name={"name"}
                helperText={errors.name ? errors.name : ""}
                danger={errors.name ? true : false}
                required
              />
              <CustomInput
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                label={t("account.surname")}
                name={"surname"}
                icon="la:user"
                helperText={errors.surname ? errors.surname : ""}
                danger={errors.surname ? true : false}
                required
              />
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:gap-12 md:grid-cols-2 md:gap-y-12">
              <CustomInput
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                label={t("account.email")}
                icon="la:envelope-open"
                autocomplete={false}
                name={"email"}
                helperText={errors.email ? errors.email : ""}
                danger={errors.email ? true : false}
                required
              />
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:gap-12 md:grid-cols-2 md:gap-y-12">
              <CustomInput
                label={t("account.password")}
                type="password"
                name={"password"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={errors.password ? errors.password : ""}
                danger={errors.password ? true : false}
                showText
                required
              />
              <CustomInput
                label={t("account.confirm")}
                type="password"
                name={"confirmPassword"}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword : ""
                }
                danger={errors.confirmPassword ? true : false}
                showText
                required
              />
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:gap-12 md:grid-cols-2 md:gap-y-12">
              <CustomSelect
                hasSearch={true}
                label={t("account.country")}
                name={"countryCode"}
                helperText={errors.countryCode ? errors.countryCode : ""}
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                danger={errors.countryCode ? true : false}
                options={setCountryCodeMap()}
                required
              />
              <CustomInput
                onChange={(e) => handleFormChange(e, setFormData, formData)}
                label={t("account.phone")}
                icon="la:phone"
                type="number"
                helperText={errors.nationalPhone ? errors.nationalPhone : "" || errors.isValidPhone ? errors.isValidPhone : ""}
                danger={errors.nationalPhone ? true : false || errors.isValidPhone ? true : false}
                name={"nationalPhone"}
                required
              />
            </div>

            <div className="grid grid-cols-1 gap-6 mt-6 md:gap-12 md:grid-cols-2 md:gap-y-12">
              <div className="flex flex-col">
                <div className="mb-6">
                  <CustomCheckbox
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        professional: e.target.checked,
                      });
                      setProfessional(!professional);
                    }}
                    checked={professional}
                    name={"professional"}
                    label={t("account.professional")}
                  />
                </div>

                <div>
                  {professional && (
                    <CustomInput
                      onChange={(e) =>
                        handleFormChange(e, setFormData, formData)
                      }
                      label={t("account.company")}
                      icon="la:briefcase"
                      name={"companyName"}
                      required
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="mb-6">
                  <CustomCheckbox
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        termsOk: e.target.checked,
                      });
                      changeSetTermsOk(e.target.checked);
                    }}
                    name={"termsOk"}
                    label={
                      <>
                        {t("account.info")}
                        <b
                          className="hover:underline text-blue-500"
                          onClick={() => setModalOpen("terms")}
                        >
                          &nbsp;{`${t("account.terms")}`}
                        </b>
                        ,
                        <b
                          className="hover:underline text-blue-500"
                          onClick={() => setModalOpen("privacy")}
                        >
                          &nbsp;{`${t("account.privacy")}`}
                        </b>
                      </>
                    }
                  />
                </div>

                <div>
                  <CustomCheckbox
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        commercialOk: e.target.checked,
                      });
                    }}
                    label={t("account.commercial")}
                    name={"commercialOk"}
                    checked={formData.commercialOk}
                    className="col-span-1"
                  />
                </div>
              </div>
            </div>

            <div className="my-10 flex justify-center">
              <CustomButton disabled={termsOk} type="submit">
                {t("account.title")}
              </CustomButton>
            </div>
          </form>
        </div>

        <Modal show={modalOpen === "terms"} onClose={() => setModalOpen(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: terms_conditions }}></div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton onClick={() => setModalOpen(false)}>
              {t("close")}
            </CustomButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalOpen === "privacy"}
          onClose={() => setModalOpen(false)}
        >
          <Modal.Header></Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: privacy_policies }}></div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton onClick={() => setModalOpen(false)}>
              {t("close")}
            </CustomButton>
          </Modal.Footer>
        </Modal>

        <div
          className="flex h-3/5 lg:h-screen lg:w-1/2 items-center justify-center bg-cover bg-no-repeat"
          style={customStyles}
        >
          <LogoImage className="h-[13rem] lg:h-[25rem] w-80 self-center mx-auto flex-shrink-0 flex-grow-0" />
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
