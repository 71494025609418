import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import SideNav from "@/components/organisms/SideNav";

import Notifications from "@/pages/Notifications";
import ResetPassword from "@/pages/ResetPassword";
import CreateAccount from "@/pages/CreateAccount";
import Devices from "@/pages/Devices";
import Device from "@/pages/Device";
import Profile from "@/pages/Profile";
import Users from "@/pages/Users";
import Login from "@/pages/Login";
import Test from "@/pages/Test";
import Measures from "@/pages/Device/Measures";
import Alarms from "@/pages/Device/Alarms";
import Service from "@/pages/Device/Service";
import DeviceInfo from "@/pages/Device/DeviceInfo";
import UserDetails from "@/pages/Users/UserDetail/UserDetails";

import { toasterTheme } from "@/utils/theme";
import NotificationDetail from "./pages/Notifications/notificationDetail";
import ProtectedRoute from "./utils/Provider/ProtectedRouteProps";
import useAuth from "./utils/hooks/useAuth";
import Synoptic from "./pages/Device/Synoptic/Synoptic";

const App: React.FC = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const { isLoggedIn } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    const publicPaths = ["/login", "/", "/reset-password", "/create-account"];
    const isPublicPath = publicPaths.some(
      (path) =>
        currentPath === path || currentPath.startsWith("/reset-password/")
    );

    if (!isLoggedIn() && !isPublicPath) {
      navigate("/login");
    }
  }, [currentPath, isLoggedIn, navigate]);

  const hasMenu = (path: string) => {
    const noMenuPaths = ["/login", "/reset-password", "/create-account"];
    return !noMenuPaths.some(
      (p) => path === p || path.startsWith("/reset-password/")
    );
  };

  const sideMap = [
    {
      element: <Navigate to="/login" />,
      path: "/",
    },
    {
      element: (
        <ProtectedRoute
          element={<Devices />}
          roles={["user", "admin"]}
          path="/devices"
        />
      ),
      path: "/devices",
    },
    {
      element: (
        <ProtectedRoute
          element={<Device element={<Synoptic />} />}
          roles={["user", "admin"]}
          path="/device/:id/synoptic/*"
        />
      ),
      path: "/device/:id/synoptic/*",
    },
    {
      element: (
        <ProtectedRoute
          element={<Device element={<Measures />} />}
          roles={["user", "admin"]}
          path="/device/:id/measures/*"
        />
      ),
      path: "/device/:id/measures/*",
    },
    {
      element: (
        <ProtectedRoute
          element={<Device element={<Alarms />} />}
          roles={["user", "admin"]}
          path="/device/:id/alarms/*"
        />
      ),
      path: "/device/:id/alarms/*",
    },
    {
      element: (
        <ProtectedRoute
          element={<Device element={<Service />} />}
          roles={["user", "admin"]}
          path="/device/:id/service/*"
        />
      ),
      path: "/device/:id/service/*",
    },
    {
      element: (
        <ProtectedRoute
          element={<Device element={<DeviceInfo />} />}
          roles={["user", "admin"]}
          path="/device/:id/deviceInfo/*"
        />
      ),
      path: "/device/:id/deviceInfo/*",
    },

    {
      element: (
        <ProtectedRoute element={<Users />} roles={["admin"]} path="/users" />
      ),
      path: "/users",
    },
    {
      element: (
        <ProtectedRoute
          element={<UserDetails />}
          roles={["admin"]}
          path="/users/:id/userDetails/*"
        />
      ),
      path: "/users/:id/userDetails/*",
    },
    {
      element: (
        <ProtectedRoute
          element={<Notifications />}
          roles={["user", "admin"]}
          path="/notifications"
        />
      ),
      path: "/notifications",
    },
    {
      element: (
        <ProtectedRoute
          element={<NotificationDetail />}
          roles={["user", "admin"]}
          path="/notifications/:id/detail"
        />
      ),
      path: "/notifications/:id/detail",
    },
    {
      element: <Test />,
      path: "/test",
    },
    {
      element: !isLoggedIn() ? <Login /> : <Navigate to="/devices" />,
      path: "/login",
    },
    {
      element: (
        <ProtectedRoute
          element={<Profile />}
          roles={["user", "admin"]}
          path="/profile"
        />
      ),
      path: "/profile",
    },
    {
      element: (
        <ProtectedRoute
          element={<Profile isEditing={true} />}
          roles={["user", "admin"]}
          path="/profile/edit"
        />
      ),
      path: "/profile/edit",
    },
    {
      element: <ResetPassword />,
      path: "/reset-password",
    },
    {
      element: <ResetPassword />,
      path: "/reset-password/:token/:code/:lang/*",
    },
    {
      element: <CreateAccount />,
      path: "/create-account",
    },
  ];

  return (
    <>
      {hasMenu(currentPath) && <SideNav isAdmin={true} />}
      <main
        className={`${
          hasMenu(currentPath) &&
          " overflow-auto md:w-[calc(100%-5rem)] h-screen md:ml-20"
        }`}
      >
        <Toaster toastOptions={toasterTheme} />
        <Routes>
          {sideMap.map((el, i) => {
            return <Route key={i} element={el.element} path={el.path} />;
          })}
        </Routes>
      </main>
    </>
  );
};

export default App;
