import { useState } from "react";
import {
  accountRole,
  accountUse,
  countryCodes,
  isValidEmail,
  isValidPassword,
  isValidPhone,
  reloadPage,
} from "@/utils/constants";
import { CreateUser, EditUser, IUser } from "@/utils/models";
import toast from "react-hot-toast";
import { t } from "i18next";
import { userService } from "@/services/user.service";
import useAuth from "@/utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const UserFormComponent = ({
  isEditing,
  user,
}: {
  isEditing: boolean;
  user?: any;
}) => {
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { putUserData } = useAuth();
  const navigate = useNavigate();

  const AccountUseType = {
    Professional: "Professional",
    Personal: "Personal",
  };

  function setAccountUseOption(
    valueToCompare?: string
  ): { value: string; label: string }[] {
    return accountUse.map((el) => ({
      value: el.value,
      label: t(el.label),
    }));
  }

  const setAccountRoleOption = () => {
    return Object.entries(accountRole).map(([key, value]) => ({
      value: value,
      label: t(
        `user.formUser.role.${value === accountRole.Administrator ? "administrator" : value
        }`
      ),
    }));
  };

  function getCountryCodeFromDialCode(dialCode: string) {
    const countryCode = countryCodes.find(
      (countryCode) => countryCode.dialCode === dialCode
    );
    return countryCode ? countryCode.countryCode : "";
  }

  const initializeEditForm = (editDevice: IUser, isEdit: boolean) => {
    if (isEdit) {
      const editUserData = {
        id: editDevice?.id || "",
        name: editDevice?.name || "",
        surname: editDevice?.surname || "",
        password: editDevice?.password || "",
        ConfirmPassword: editDevice?.ConfirmPassword || "",
        email: editDevice?.email || "",
        nationalPhone: editDevice?.nationalPhone || "",
        dialCode: editDevice?.dialCode || "",
        roles: editDevice?.roles || "",
        professional: editDevice?.professional || false,
        companyName: editDevice?.companyName || "",
        countryCode: editDevice?.countryCode || "",
        commercialOk: editDevice?.commercialOk || false,
      };

      return editUserData;
    } else {
      return user;
    }
  };

  const validateFormData = (formaData: any, updateUser?: boolean) => {
    const newErrors: Record<string, string> = {};
    const validationName = /^[a-zA-ZÀ-ÿ\s]+$/;

    if (!formaData.name) {
      newErrors.name = t("user.formUser.formValidationError.nameMandatory");
    } else if (!validationName.test(formaData.name)) {
      newErrors.name = t("user.formUser.formValidationError.nameFormat");
    }

    if (!formaData.surname) {
      newErrors.surname = t("user.formUser.formValidationError.surnameMandatory");
    } else if (!validationName.test(formaData.surname)) {
      newErrors.surname = t("user.formUser.formValidationError.surnameFormat");
    }

    if (!formaData.email)
      newErrors.email = t("user.formUser.formValidationError.emailMandatory");
    else if (!isValidEmail(formaData.email))
      newErrors.email = t("user.formUser.formValidationError.emailFormat");

    if (!formaData.nationalPhone)
      newErrors.nationalPhone = t(
        "user.formUser.formValidationError.nationalPhoneEmpty"
      );
    else if (!isValidPhone(formaData.countryCode, formaData.nationalPhone)) {
      newErrors.nationalPhone = t(
        "user.formUser.formValidationError.nationalPhoneFormat"
      );
    }

    if (!updateUser) {
      if (!formaData.password) {
        newErrors.password = t(
          "user.formUser.formValidationError.passwordMandatory"
        );
      }
      if (!formaData.confirmPassword) {
        newErrors.confirmPassword = t(
          "user.formUser.formValidationError.passwordMandatory"
        );
      }

      if (formaData.password !== formaData.confirmPassword) {
        newErrors.confirmPassword = t(
          "user.formUser.formValidationError.confirmPasswordMismatch"
        );
        newErrors.password = t(
          "user.formUser.formValidationError.confirmPasswordMismatch"
        );
      } else if (!isValidPassword(formaData.password)) {
        newErrors.password = t(
          "user.formUser.formValidationError.passwordFormat"
        );
      }
    }

    if (!formaData.countryCode)
      newErrors.countryCode = t(
        "user.formUser.formValidationError.countryCodeMandatory"
      );

    if (!formaData.roles) {
      newErrors.roles = t("user.formUser.formValidationError.roleMandatory");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const isSaveDisabled = (formaData: any) => {
    const requiredFieldsFilled =
      formaData.name &&
      formaData.surname &&
      formaData.password &&
      formaData.confirmPassword &&
      formaData.email &&
      formaData.countryCode;

    const professionalFieldValid =
      formaData.professional !== AccountUseType.Professional ||
      (formaData.professional === AccountUseType.Professional &&
        formaData.companyName);

    return !(requiredFieldsFilled && professionalFieldValid);
  };

  const doUpdate = async (formData: any, onClose: () => void) => {
    let EditUserData: EditUser;
    if (validateFormData(formData, true)) {
      EditUserData = {
        name: formData.name || "",
        surname: formData.surname || "",
        email: formData.email || "",
        nationalPhone: formData.nationalPhone || "",
        countryCode: formData.countryCode || "",
        roles: formData.roles || "",
        professional:
          formData.professional === AccountUseType.Professional ||
            formData.professional === true
            ? true
            : false,
        commercialOk: formData.commercialOk || false,
        pinnedDevices: formData.pinnedDevices || [],
        languageSettings: formData.languageSettings || "es",
        companyName: formData.companyName || "",
        country: getCountryCodeFromDialCode(formData.dialCode) || "",
      };
      userService
        .update(EditUserData, user.id)
        .then(() => {
          toast.success(t("user.formUser.toastMessage.userEdit"));
          putUserData();
          onClose();
          const interval = reloadPage();
        })
        .catch((error) => {
          toast.error(t("user.formUser.toastMessage.userError") + error);
        });
    }
  };

  const doSave = async (
    formData: any,
    onClose?: () => void,
    userCreateAccount: boolean = false
  ) => {
    if (validateFormData(formData)) {
      const createUserData: CreateUser = {
        name: formData.name || "",
        surname: formData.surname || "",
        password: formData.password || "",
        email: formData.email || "",
        countryCode: formData.countryCode || "",
        nationalPhone: formData.nationalPhone || "",
        roles: formData.roles || "",
        professional: formData.professional === AccountUseType.Professional ? true : false,
        languageSettings: formData.languageSettings || "es",
        pinnedDevices: [],
        companyName: formData.companyName || "",
        country: getCountryCodeFromDialCode(formData.dialCode) || "",
        commercialOk: formData.commercialOk || false,
      };
      userService
        .create(createUserData)
        .then(() => {
          if (userCreateAccount) {
            userService
              .sendVerifyRequest(createUserData.email, createUserData.password)
              .then(() => {
                toast.success(
                  t("user.formUser.toastMessage.userSuccess") +
                  " " +
                  t("user.formUser.toastMessage.emailVerificationText")
                );
                navigate("/login");
              })
              .catch(() => {
                toast.success(t("user.formUser.toastMessage.userSuccess"));
              });
          } else {
            toast.success(t("user.formUser.toastMessage.userSuccess"));
            const interval = reloadPage();
            if (onClose) {
              onClose();
            }
          }
        })
        .catch((error: any) => {
          const errorResponse = error?.error || {};
          const { statusCode, name, message } = errorResponse;

          if (statusCode === 400 || message === "error.user_exists") {
            const newErrors: Record<string, string> = {};
            newErrors.email = t(
              "user.formUser.formValidationError.errorUserExists"
            );
            setErrors(newErrors);
            toast.error(t("user.formUser.formValidationError.errorUserExists"));
          }
        });
    }
  };

  return {
    errors,
    validateFormData,
    initializeEditForm,
    isSaveDisabled,
    setAccountUseOption,
    setAccountRoleOption,
    doSave,
    doUpdate,
  };
};
