import React from "react";
import CustomText from "@/components/atoms/CustomText";
import CustomIcon from "@/components/atoms/CustomIcon";
import { CustomDropdownItemType } from "./CustomDropdownItemType";
import { ActionItemType } from "@/utils/models";
import { t } from "i18next";

const CustomDropdownItem = ({
  variant = "primary",
  disabled = false,
  className = "",
  onActionClick,
  image = "",
  value = "",
  icon = "",
  text = "",
  name = "",
  children,
  action,
}: CustomDropdownItemType) => {

  const DropdownItemClick = () => {
    if (!disabled) {
      if (action) {
        action();
      }
      if (onActionClick) {
        if (text || value) {
          const actionSelectitem: ActionItemType = {
            name: name,
            text: text,
            value: value,
          };
          onActionClick(actionSelectitem);
        } else {
          onActionClick(text);
        }
      }
    }
  };

  const itemStyles = {
    primary: {
      base: "flex transition-all duration-300 text-gray-700",
      hover: "hover:bg-gray-100",
    },
    secondary: {
      base: "flex text-blueGrey100 transition-all duration-300",
      hover: "hover:bg-gray-300",
    },
  };

  const itemStyle =
    variant === "primary" ? itemStyles.primary : itemStyles.secondary;

  const containerClasses = `py-2 px-4 w-full ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
    } ${itemStyle.base} ${!disabled ? itemStyle.hover : ""}`;

  return (
    <>
      <div onClick={DropdownItemClick} className={`${containerClasses} ${className}`}>
        {children ?
          children
          : (<>
            {icon && <CustomIcon icon={icon} className="w-6 h-6 mr-2" />}
            {image && <img src={image} width={24} height={24} alt="" />}
            <CustomText weight="semi">{t(text)}</CustomText>
            <div className="border-b-2 border-gray-500"></div>
          </>)}
      </div>
    </>
  );
};

export default CustomDropdownItem;