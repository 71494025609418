import { t } from "i18next";
import { NotificationListType } from "./NotificationListTableType";
import CustomChip from "@/components/atoms/CustomChip";

const NotificationListTable = ({ NotificationInfo }: NotificationListType) => {
  return (
    <div className="w-full">
      {/* Mobile */}
      <div className="block md:hidden">
        <div className="space-y-4">
          {NotificationInfo.map((item, index) => (
            <div
              key={index}
              className={`bg-white dark:bg-gray-800 rounded-lg shadow-sm`}
            >
              <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
                <div className="flex items-center">
                  <span className="font-medium">{item.iotId}</span>
                </div>
                <div>
                  <div className="p-4 space-y-2">
                    <div className="flex items-center flex-col">
                      {item.additionalPhone.map((phone, index) => (
                        <div key={index}>
                          {phone.countryCode} {phone.number}
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center flex-col">
                      {item.additionalMail.map((mail, index) => (
                        <div key={index}>{mail}</div>
                      ))}
                    </div>
                    <div className="flex items-center">
                      {item.mail && <CustomChip value={"Mail"} />}

                      {item.sms && <CustomChip value={"Sms"} />}
                    </div>
                    <div className="flex items-center space-x-2"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Computer */}
      <div className="hidden md:flex flex-col ">
        <div className="flex flex-col border rounded-lg">
          <div className="grid grid-cols-12 h-10 bg-gray-100 dark:bg-gray-800">
            <div className="flex items-center justify-center col-span-3 text-center text-gray-700 dark:text-gray-300">
              {t("user.notificationTable.device")}
            </div>
            <div className="flex items-center justify-center col-span-4 text-center text-gray-700 dark:text-gray-300">
              {t("user.notificationTable.email")}
            </div>
            <div className="flex items-center justify-center col-span-3 text-center text-gray-700 dark:text-gray-300">
              {t("user.notificationTable.phone")}
            </div>
            <div className="flex items-center justify-center col-span-2 text-center text-gray-700 dark:text-gray-300">
              {t("user.notificationTable.notification")}
            </div>
          </div>
          {NotificationInfo.map((item, index) => (
            <div
              key={index}
              className="grid grid-cols-12 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
            >
              <div className="px-4 py-3 col-span-3 flex items-center justify-center">
                {item.iotId}
              </div>
              <div className="px-4 py-3 col-span-4 flex items-center justify-center flex-col">
                {item.additionalMail.map((mail, index) => (
                  <div key={index}>{mail}</div>
                ))}
              </div>
              <div className="px-4 py-3 col-span-3 flex items-center justify-center flex-col">
                {item.additionalPhone.map((phone, index) => (
                  <div key={index}>
                    {phone.countryCode} {phone.number}
                  </div>
                ))}
              </div>
              <div className="px-4 py-3 col-span-2 flex items-center justify-center flex-col">
                {item.mail && <CustomChip value={t('user.notificationTable.notificationState.email')} />}

                {item.sms && <CustomChip value={t('user.notificationTable.notificationState.sms')} />}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationListTable;