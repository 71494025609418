import CustomText from "@/components/atoms/CustomText";
import CustomIcon from "@/components/atoms/CustomIcon";
import { CustomInfoboxType } from "./CustomInfoBoxType";

const CustomInfobox = ({
  type = "default",
  className = '',
  center = false,
  children,
  message,
  title,
  icon,
}: CustomInfoboxType) => {
  const cln = `rounded-xl p-4
    ${type === "default" ? "border border-gray-400" : ""}
    ${type === "warning" ? "border border-yellow-400 !bg-yellow-100" : ""}
    ${type === "success" ? "border border-lime-400 !bg-lime-100" : ""}
    ${type === "danger" ? "border border-red-400 !bg-red-100" : ""}
    ${type === "info" ? "border border-blue-400 !bg-blue-100" : ""}
    ${className}
  `;

  return (
    <div className={cln}>
      <div className={`flex items-center gap-4 ${center && 'w-fit m-auto'}`}>
        {icon && <CustomIcon icon={icon} className="w-[2rem] h-[2rem]" />}
        <div>
          {title && <CustomText weight="bold" children={title} size="xl" />}
          <CustomText size="sm" children={message} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default CustomInfobox;