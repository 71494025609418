import { useState, useEffect } from "react";
import { Device, IUser } from "../models";
import { INotification } from "../models/entities/Notification";
import { userService } from "@/services/user.service";
import { deviceService } from "@/services/device.service";
import { notificationService } from "@/services/notifications.service";

const useDeviceListNotification = (userId: string) => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [devices, setDevices] = useState<Device[]>([]);
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      setError("");
      try {
        const [user, devices, notifications] = await Promise.all([
          userService.getUserById(userId),
          deviceService.getDevicesByUserId(userId),
          notificationService.getNotificationByUserId(userId),
        ]);

        if (!Array.isArray(devices)) {
        }
        if (!Array.isArray(notifications)) {
        }

        setCurrentUser(user);
        setDevices(devices);
        setNotificationList(notifications.items);
      } catch (err) {
        setError("Failed to fetch data: " + err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]); 

  return { currentUser, devices, notificationList, loading, error };
};

export default useDeviceListNotification;
