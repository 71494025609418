import { CustomOptionType } from "@/components/molecules/CustomSelect";
import deviceService from "@/services/device.service";
import { reloadPage } from "@/utils/constants/consts";
import { ActionItemType } from "@/utils/models";
import { t } from "i18next";
import toast from "react-hot-toast";

export const dropDownItems: ActionItemType[] = [
  {
    icon: "la:sign-out-alt",
    text: "Alex",
    disabled: false,
    action: () => {
      alert("Hello World");
    },
  },
];

export const getSelectOptions = (devices: any[]) => {
  let optionsArrayOptions: CustomOptionType[] = [];
  devices.forEach((device) => {
    optionsArrayOptions.push({
      label: device.iotId,
      value: device.iotId,
    });
  });
  return optionsArrayOptions;
};

export const DeviceLogicComponent = ({ isEditing }: { isEditing: boolean }) => {
  const titleModalEdit = (currentDevice: any) => {
    let title = currentDevice
      ? t("device.form.titleEdit")
      : t("device.form.titleNew")
    return title;
  };
  const titleModalDelete = (currentDevice: any) => {
    const confirmationText = t("device.deleteModal.confirmationText");
    const confirmationTextWarning = t(
      t("device.deleteModal.confirmationTextWarning")
    );
    const userName = currentDevice?.iotId || "";

    return `${confirmationText} ${userName} ${confirmationTextWarning}`;
  };

  const modalDeleteUser = (
    deleteModalElement: any,
    onCloseDeleteModal: () => void,
    refreshListData: () => Promise<void>
  ) => {
    deviceService
      .delete(deleteModalElement)
      .then(() => {
        toast.success(t("device.deleteModal.toastDelete.success"));
        onCloseDeleteModal();
        const interval = reloadPage();
      })
      .catch((err) => {
        toast.error(t("device.deleteModal.toastDelete.error"));
      });
  };

  return {
    titleModalEdit,
    titleModalDelete,
    modalDeleteUser,
  };
};
