import CustomIcon from "../CustomIcon";
import { CustomChipType } from "./CustomChipType";

const CustomChip = ({
  value,
  className = "",
  icon = "material-symbols:cancel",
  onDelete = () => { },
  deleteIcon = false,
}: CustomChipType) => {
  return (
    <div
      className={`m-1 py-1.5 pl-3 pr-2 bg-neutralGrey100 rounded-2xl z-0 ${className}`}
    >
      <div className="flex items-center justify-between">
        <p className="text-sm text-strongTransparentBlack cursor-default">
          {value}
        </p>

        {deleteIcon && (
          <div className="ml-3 w-4 h-4 relative">
            <CustomIcon
              icon={icon}
              className="text-strongGrey text-2xl cursor-pointer relative -top-1 -left-1.5"
              onClick={() => onDelete(value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomChip;