import React, { useEffect, useState } from "react";
import { CustomListType } from "./CustomListType";
import CustomIconButton from "@/components/atoms/CustomIconButton";

const CustomList = ({
  elements,
  showGoTop = false
}: CustomListType) => {
  const [displayButton, setDisplayButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (showGoTop) {
      const handleScroll = () => {
        setDisplayButton(window.scrollY > 300);
      };
      window.addEventListener('scroll', handleScroll);
      return () => { window.removeEventListener('scroll', handleScroll); };
    }
  }, [showGoTop]);

  return (
    <div className="bg-neutralGrey100 flex-1 flex flex-col justify-center items-center space-y-4 py-10">
      {elements}
      {showGoTop && displayButton &&
        <CustomIconButton
          icon={"la:angle-up"}
          className="fixed bottom-4 right-4"
          variant="primary"
          onClick={scrollToTop}
        />
      }
    </div>
  )
};

export default CustomList;
