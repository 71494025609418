import { t } from "i18next";
import { CustomLeftPanelType } from "./CustomLeftPanelType";
import CustomText from "@/components/atoms/CustomText";
import GoBackHeader from "@/components/molecules/CustomGoBackHeader";

const CustomLeftPanel = ({
  title = "",
  children,
  className = "",
  isMobile = false,
}: CustomLeftPanelType) => {
  return isMobile ? (
    <>
      <div className={`flex w-full  h-32 flex-col lg:pt-32 ${className}`}>
        {title && (
          <CustomText
            variant="primary"
            size="lg"
            weight="bold"
            className="px-7 flex item-center justify-center my-2"
          >
            {title}
          </CustomText>
        )}
        {children}
      </div>
    </>
  ) : (
    <>
      <div className={`flex w-96 flex-col lg:pt-32 ${className}`}>
        {title && (
          <CustomText
            variant="primary"
            size="lg"
            weight="bold"
            className="px-7 "
          >
            {title}
          </CustomText>
        )}
        {children}
      </div>
    </>
  );
};

export default CustomLeftPanel;
