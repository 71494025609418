import { useState } from "react";
import deviceService from "@/services/device.service";
import {
  ApexChartSeries,
  DataSetSensor,
  GroupSensors,
  Measures,
  UseMeasureDataState,
} from "@/pages/Device/Measures/d";

export const useGetMeasure = (): UseMeasureDataState => {
  const [measures, setMeasures] = useState<Measures[]>([]);
  const [groupSensors, setGroupSensors] = useState<GroupSensors>({
    bypass: [],
    input: [],
    output: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchAlarms = async (
    deviceId: string,
    startDate: number,
    endDate: number,
    filters?: any
  ) => {
    setLoading(true);
    setError(null);

    try {
      const response = await deviceService.getGraphicMeasures(
        deviceId,
        startDate,
        endDate,
        filters
      );

      transformToMixedChartData(response.datasets);
      setGroupSensors(response.groupSensors);
    } catch (err: any) {
      setError(err.message || "An error occurred while fetching alarms.");
    } finally {
      setLoading(false);
    }
  };
  const transformToMixedChartData = (dataSets: {
    [key: string]: DataSetSensor;
  }) => {
    const result: { [key: string]: Measures } = {};

    Object.keys(dataSets).forEach((key) => {
      const dataSet = dataSets[key];

      if (!result[dataSet.sensor]) {
        result[dataSet.sensor] = {
          title: dataSet.title,
          sensor: dataSet.sensor,
          data: [],
        };
      }

      const seriesMap: { [key: string]: ApexChartSeries } = {};

      dataSet.data.forEach((dataEntry) => {
        dataEntry.series.forEach((seriesData) => {
          if (!seriesMap[seriesData.tooltipText]) {
            seriesMap[seriesData.tooltipText] = {
              name: seriesData.tooltipText,
              data: [],
            };
          }
          seriesMap[seriesData.tooltipText].data.push({
            x: seriesData.name,
            y: seriesData.value,
          });
        });
      });
      result[dataSet.sensor].data = Object.values(seriesMap);
    });
    setMeasures(Object.values(result));
  };
  const searchHistoryAlarms = async (
    deviceId: string,
    startDate: number,
    endDate: number,
    filters: any
  ) => {
    await fetchAlarms(deviceId, startDate, endDate, filters);
  };

  return { measures, groupSensors, loading, error, searchHistoryAlarms };
};
