import clsx from "clsx";
import CustomText from "@/components/atoms/CustomText";
import { CustomTabMenuType } from "./CustomTabMenuType";
import { t } from "i18next";

const CustomTabMenu = ({
  tabs,
  currentTab,
  options,
  onChange,
}: CustomTabMenuType) => {
  const tabArray = Array.from({ length: tabs }, (_, index) => index);
  return (
    <div className={`grid grid-cols-${tabs} gap-x-3 rounded-3xl border border-transparentBlack p-1 cursor-pointer`}>
      {tabArray.map((tabIndex) => (
        <div
          key={tabIndex}
          className={clsx("w-full rounded-3xl px-4 py-2", {
            "bg-blueGrey200 transition ease-in delay-250":
            tabIndex === currentTab,
            "bg-white hover:bg-gray-100 transition ease-out delay-250":
            tabIndex !== currentTab,
          })}
          onClick={() => onChange(tabIndex)}
        >
          <CustomText weight="bold" variant="secondary" className={clsx("text-center", {
              "text-white": tabIndex === currentTab,
              "text-black": tabIndex !== currentTab,
            })}>
            {options[tabIndex]}
          </CustomText>
        </div>
      ))}
    </div>
  );
};

export default CustomTabMenu;
