import { CustomLayoutType } from "./CustomLayoutType";

const CustomLayout = ({
  className = '',
  children,
}: CustomLayoutType) => {
  return (
    <div className="flex-1 bg-neutralGrey100">
      <div className="flex">
        <div className={`max-w-screen-xl w-full pt-10 pb-20 m-auto ${className}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default CustomLayout;