import CustomTitle from "@/components/atoms/CustomTitle";
import { CustomEmptyListType } from "./CustomEmptyListType";
import { t } from "i18next";
import CustomText from "@/components/atoms/CustomText";
import CustomImage from "@/components/atoms/CustomImage";
import emptyImage from "@/assets/images/graph.gif";

const CustomEmptyList = ({
  title = "",
  subtitle = "",
}: CustomEmptyListType) => {
  return (
    <div className="flex flex-col items-center justify-center px-12">
      <CustomImage
        src={emptyImage}
        className="w-[20rem]"
        alt={t("plant.no_plants")}
      />
      <div className="text-center">
        <CustomTitle variant="primary" heading="h3">
          {" "}
          {title}
        </CustomTitle>
        {subtitle && <CustomText className="mt-4">{subtitle}</CustomText>}
      </div>
    </div>
  );
};

export default CustomEmptyList;