export enum ServiceStatus {
    OK = "OK",
    ERROR = "ERROR",
    IN_PROGRESS = "IN_PROGRESS",
    UNINSTALLED = "UNINSTALLED",
    NOT_INSTALLED = "NOT_INSTALLED",
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
  }

export enum DeviceStatus {
    online = "online",
    offline = "offline",
}