import { CustomOptionType } from "@/components/molecules/CustomSelect";

export interface Device {
  id: string;
  iotId: string;
  type: string;
  createdAt?: Date;
  updatedAt?: Date;
  description: string;
  uuid?: string;
  status: string;
  latitude?: string;
  longitude?: string;
  address?: string;
  timezone?: string;
  timezoneOffset?: number;
  serialNumber?: string;
  localIp?: string;
  remoteIp?: string;
  mac?: string;
  release?: string;
  UPSrelease?: string;
  company: string;
  lastPing?: number;
  upsMode?: UpsMode;
  users?: string[];
  maintenanceContract?: string;
}
export interface DeviceData {
  iotId: string;
  description: string;
  type: string;
  uuid: string;
  latitude: string;
  longitude: string;
  localIp: string;
  remoteIp: string;
  serialNumber: string;
  maintenanceContract: string;
  company: string;
  address: string;
  timezone: string;
  users: string[];
}

export interface CreateDevice {
  iotId: string;
  description: string;
  type: string;
  uuid: string;
  latitude: string;
  longitude: string;
  localIp: string;
  remoteIp: string;
  serialNumber: string;
  maintenanceContract: string;
  associateEmail?: CustomOptionType[];
  company: string;
  timezone: string;
  address: string;
}

export const MaintenanceContractKey: CustomOptionType[] = [
  { value: "None", label: "none" },
  { value: "Basic", label: "basic" },
  { value: "Advanced", label: "advanced" },
  { value: "Premium", label: "premium" },
];

export interface UpsMode {
  input: DeviceConfig;
  output: DeviceConfig;
  bypass: DeviceConfig;
}

export enum DeviceConfig {
  Mono = "mono",
  Tri = "tri",
}

export interface ISensor {
  sensor: string;
  description?: string;
  type: string;
  unit: string;
  component?: string;
  additionalInfo?: ISensorAdditionalInfo;
}

export interface ISensorAdditionalInfo {
  Group: string;
}

export interface IAlarm {
  id: any;
  name: string;
  ownerType: string;
  ownerId: string;
  components: Array<IAlarmComponent>;
}

export interface IAlarmComponent {
  _id: any;
  deviceName: string;
  sensors: Array<IAlarmSensor>;
}

export interface IAlarmSensor {
  _id: any;
  sensorId: string;
  sensorName: string;
  selected?: boolean;
}

export interface Log {
  iotId: string;
  content: string;
  timestamp: number;
}

export interface CommandRequest {
  iotId: string;
  service: string;
  packageUrl?: string;
  subfolder?: string;
}
