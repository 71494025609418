import { apiService } from "./api.service";

import Cookies from "js-cookie";
export const authService = {
  getToken: () => {
    return Cookies.get("SAL-nimbus-token") || "";
  },
  clearToken() {
    Cookies.remove("SAL-nimbus-token");
  },

  resetPasswordRequest: async (email: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/reset_password_request`;
    const payload = { 'email': email };

    return await apiService.post(url, payload);
  },


  validateResetPasswordToken: async (token: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}/reset_password/validate/${token}`;
    return await apiService.get(url);
  },

  resetPassword: async (rawToken: string, hashedToken: string, password: string, confirmPassword: string) => {
    const payload = {
      'rawToken': rawToken,
      'hashToken': hashedToken,
      'password': password,
      'confirmPassword': confirmPassword
    };

    const url = `${process.env.REACT_APP_BASE_URL}/reset_password`;
    return await apiService.put(url, payload);
  },

  putToken(token: string) {
    return Cookies.set("SAL-nimbus-token", token);
  },
  putUserData(userData: any) {
    const userDataJSON = JSON.stringify(userData);
    Cookies.set("SAL-nimbus-userData", userDataJSON);
  },
  login: async (payload: any) => {
    const url = `${process.env.REACT_APP_BASE_URL}/login`;
    return await apiService.post(url, payload, undefined, true);
  },
};
