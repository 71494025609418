import { useEffect, useState, useCallback } from "react";
import { useDeviceContext } from "../context/deviceContext";
import {
  LogsData,
  requiredServices,
  serialDevices,
  ServiceData,
} from "@/pages/Device/Service/d";
import { deviceLogService } from "@/services/device-logs.service";
import toast from "react-hot-toast";
import { t } from "i18next";
import { useConfirmationModalContext } from "../context/ConfirmationModalContextProvider";
export const useDeviceStatus = () => {
  const { deviceInfo } = useDeviceContext();
  const { showConfirmation } = useConfirmationModalContext();
  const modbusServices = ["device-modbus-api-rest"];
  const [services, setServices] = useState<ServiceData[]>([]);
  const [logs, setLogs] = useState<LogsData | null>(null);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [servicesStatus, setServicesStatus] = useState<any>(null);
  const [deviceStatus, setDeviceStatus] = useState(deviceInfo?.status || "");
  const [inProgress, setInProgress] = useState(false);
  const [iotId, setIotId] = useState(deviceInfo?.iotId || "");
  
  useEffect(() => {
    if (deviceInfo) {
      getRefreshServiceStatus();
    }
  }, []);

  const getRefreshServiceStatus = useCallback(() => {
    if (deviceInfo) {
      setLoading(true);
      setDeviceStatus(deviceInfo.status);
      setIotId(deviceInfo.iotId);
      getServicesAndDeviceStatus();
      getLogs();
      setLoading(false);
    }
  }, [deviceInfo]);


  const getServicesAndDeviceStatus = useCallback(() => {
    if (!deviceInfo) return;
    setEnabled(false);
    setServicesStatus(deviceInfo.status);
    setIotId(deviceInfo.iotId);
    const model = deviceInfo.type;

    deviceLogService
      .getServicesStatus(deviceInfo.id)
      .then((newServicesStatus) => {
        setInProgress(false);
        let updatedServicesStatus = newServicesStatus;
        updatedServicesStatus = filterServicesByModel(newServicesStatus, model);
        
        if (!servicesStatus) {
          setServicesStatus(newServicesStatus);
        } else {
          checkForDeviceStatusChange(newServicesStatus);
        }

        Object.keys(newServicesStatus).forEach((index) => {
          const newServiceStatus = newServicesStatus[index];
          if (newServiceStatus.status === "IN_PROGRESS") {
            setInProgress(true)
            setEnabled(true)
            updatedServicesStatus[index] = newServiceStatus;
          } else if (
            !newServiceStatus.status ||
            newServiceStatus.status === "NOT_INSTALLED" ||
            newServiceStatus.status === "UNINSTALLED"
          ) {
            delete updatedServicesStatus[index];
          } else {
            updatedServicesStatus[index] = newServiceStatus;
          }
        });

        if (!inProgress) {
          setEnabled(deviceStatus !== 'offline');
        }
        
        setServices(serviceList(updatedServicesStatus));
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("services.error.updatingStatus"));
      });
  }, [deviceInfo, servicesStatus, deviceStatus]);
  const filterServicesByModel = (newServicesStatus: any, model: string) => {
    if (model.includes("EMI3") && Object.keys(newServicesStatus).length !== 0) {
      delete newServicesStatus["device-rccmd"];
    }

    if (
      (model.includes("TWINPRO3") || model.includes("TWINRT3")) &&
      newServicesStatus
    ) {
      if (newServicesStatus["device-firmware"])
        delete newServicesStatus["device-firmware"];
      if (newServicesStatus["device-webpage"])
        delete newServicesStatus["device-webpage"];
    }

    if (
      serialDevices.includes(model.split("_")[0]) &&
      Object.keys(newServicesStatus).length !== 0
    ) {
      delete newServicesStatus["device-modbus"];
    } else if (Object.keys(newServicesStatus).length !== 0) {
      delete newServicesStatus["device-serial"];
    }

    return newServicesStatus;
  };

  const serviceList = (serviceStatus: Record<string, any>) => {
    return Object.values(serviceStatus).map((res: any) => ({
      id: res.id || "",
      deviceIotId: res.deviceIotId || "",
      name: res.name || "",
      status: res.status || "",
      currentVersion: res.currentVersion || "",
      additionalInfo: res.additionalInfo || "",
      firstRemoteUpdate: res.firstRemoteUpdate || "",
      lastRemoteUpdate: res.lastRemoteUpdate || "",
    }));
  };
  const checkForDeviceStatusChange = (newServicesStatus: Array<any>) => {
    for (const serviceName in newServicesStatus) {
      const service = newServicesStatus[serviceName];
      const currentService = servicesStatus[serviceName];
      const currentServiceStatus = currentService ? currentService.status : "";
      const newServiceStatus = service.status;

      if (currentServiceStatus === "IN_PROGRESS") {
        if (newServiceStatus === "OK") {
          if (currentService.additionalInfo === "Restarting..") {
            toast.success(t("services.restartedSuccessfully"));
          } else {
            toast.success(t("services.installedSuccessfully"));
          }
        } else if (
          newServiceStatus === "UNINSTALLED" ||
          newServiceStatus === "NOT_INSTALLED"
        ) {
          toast.success(t("services.uninstalledSuccessfully"));
        } else if (newServiceStatus === "ERROR") {
          toast.error(t("services.error_installing"));
        }
      }
    }
  };
  const installService = (formData?: any, onClose?: () => void) => {
    const serviceUrlRegex: RegExp = /^(https?):\/\/[^\s$.?#].[^\s]*\.deb$/;
    const serviceNameRegex: RegExp = /^[-_A-Za-z0-9]+$/;
    const errorMessage: string = t("error.update_service");
    const successMessage: string = t("services.command.installService");

    if (!formData.serviceName || !serviceNameRegex.test(formData.serviceName)) {
      toast.error(t("services.required.name"));
      return;
    }
    if (!formData.serviceUrl || !serviceUrlRegex.test(formData.serviceUrl)) {
      toast.error(t("services.required.url"));
      return;
    }

    if (deviceInfo) {
      setEnabled(false);
      deviceLogService
        .updateService(
          deviceInfo.iotId,
          formData.serviceName,
          formData.serviceUrl
        )
        .then((res) => {
          if (res) {
            toast.success(
              t(successMessage, {
                deviceName: deviceInfo.iotId,
                service: formData.serviceName,
              })
            );
            onClose && onClose();
          } else {
            toast.error(errorMessage);
          }
          getRefreshServiceStatus();
        })
        .catch((err) => {
          console.error(err);
          toast.error(errorMessage);
        });
    }
  };
  const updateService = async (service: string) => {
    let dialogTitle: string = t("services.update.dialogTitle");
    let dialogMsg: string = t("services.update.dialogMsg");

    if (isModbusService(service)) {
      dialogTitle = t("services.add.dialogTitle");
      dialogMsg = t("services.add.dialogMsg");
    }

    const updateConfirmAlarms = await showConfirmation(
      t(dialogTitle),
      t(dialogMsg)
    );

    if (updateConfirmAlarms && deviceInfo) {
      const errorMessage: string = t("services.error.updateService");
      const successMessage: string = t("services.command.updateService");

      let packageUrl: string = "";
      setEnabled(false);
      deviceLogService
        .updateService(deviceInfo.iotId, service, packageUrl)
        .then((res) => {
          if (res) {
            toast.success(
              t(successMessage, {
                deviceName: deviceInfo.iotId,
                service: service,
              })
            );
          } else {
            toast.error(errorMessage);
          }
          getRefreshServiceStatus();
        })
        .catch((err) => {
          console.error(err);
          toast.error(errorMessage + " " + err);
        });
    }
    return getServicesAndDeviceStatus();
  };
  const restartService = (service: string, onClose?: () => void) => {
    if (deviceInfo) {
      const errorMessage: string = t("services.error.restartService");
      const successMessage: string = t("services.command.restartService");

      deviceLogService
        .restartService(deviceInfo.iotId, service)
        .then((res) => {
          if (res) {
            toast.success(
              t(successMessage, {
                deviceName: deviceInfo.iotId,
                service: service,
              })
            );
            onClose && onClose();
          } else {
            toast.error(errorMessage);
            onClose && onClose();
          }
          getRefreshServiceStatus();
        })
        .catch((err) => {
          toast.error(errorMessage + " " + err);
        });
    }
  };
  const launchBatteryTest = (formData?: any, onClose?: () => void) => {
    const battTypeRegex: RegExp = /^[-_A-Za-z]+$/;
    const errorMessage: string = t("service.error.batt_test");
    const successMessage: string = t("command_sended.batt_test");

    if (!formData.battType || !battTypeRegex.test(formData.battType)) {
      toast.error(t("services.required.battType"));
      return;
    }
    if (!formData.battValue) {
      toast.error(t("services.required.battValue"));
      return;
    }

    if (deviceInfo) {
      deviceLogService
        .launchBatteryTest(
          deviceInfo.iotId,
          formData.battType,
          parseInt(formData.battValue)
        )
        .then((res) => {
          if (res) {
            toast.success(successMessage);
            onClose && onClose();
          } else {
            toast.error(errorMessage);
            onClose && onClose();
          }
          getRefreshServiceStatus();
        })
        .catch((err) => {
          toast.error(errorMessage + " " + err);
        });
    }
  };
  const uninstallService = async (service: string) => {
    let dialogTitle: string = t("services.uninstall.dialogTitle");
    let dialogMsg: string = t("services.uninstall.dialogMsg");

    if (isModbusService(service)) {
      dialogTitle = t("services.remove.dialogTitle");
      dialogMsg = t("services.remove.dialogMsg");
    }
    const uninstallServiceConfirmed = await showConfirmation(
      t(dialogTitle),
      t(dialogMsg)
    );

    if (uninstallServiceConfirmed && deviceInfo) {
      deviceLogService
        .uninstallService(deviceInfo.iotId, service)
        .then((res) => {
          if (res) {
            toast.success(t('device.generalData.commandSended.uninstallService', {deviceName: deviceInfo.iotId, serviceName: service}));
          } else {
            toast.error(dialogTitle);
          }

          getServicesAndDeviceStatus();
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  function isRequiredService(serviceName: string): boolean {
    return requiredServices.includes(serviceName);
  }

  function isModbusService(serviceName: string): boolean {
    return modbusServices.includes(serviceName);
  }
  const getLogs = async () => {
    if (deviceInfo) {
      deviceLogService
        .getLogs(deviceInfo.iotId)
        .then((logs) => {
          if (logs) {
            const dataLogs = {
              logsDate: new Date(logs.timestamp).toLocaleString(),
              logsContent: logs.content,
            };
            setLogs(dataLogs);
          }
        })
        .catch((err) => {
          console.error("Error obtaining data:", err);
        });
    }
    return null;
  };

  return {
    services,
    logs,
    loading,
    enabled,
    getRefreshServiceStatus,
    checkForDeviceStatusChange,
    updateService,
    restartService,
    installService,
    uninstallService,
    isRequiredService,
    isModbusService,
    launchBatteryTest,
    getLogs,
  };
};
