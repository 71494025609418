import { Sidebar } from "flowbite-react";
import { SideNavType } from "./SideNavType";
import Icon from "@/components/atoms/CustomIcon";
import clsx from "clsx";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { navigationRoutes } from "@/utils/navigation";
import { ActionItemType } from "@/utils/models";
import { customSideNav } from "@/utils/theme";
import { useTranslation } from "react-i18next";
import logo from "@/assets/images/nimbus-icon.svg";
import SideNavProfile from "../SideNavProfile/SideNavProfile";
import useAuth from "@/utils/hooks/useAuth";
import LanguageSelector from "../LanguageSelector";

const SideNav = ({ isAdmin }: SideNavType) => {
  const [hoveredItem, setHoveredItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [demoMode, setDemoMode] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const pathname = useLocation();
  const { t } = useTranslation();
  const userIsAdmin = useAuth().hasRole("admin");
  const dropDownItems: ActionItemType[] = [
    ...(demoMode
      ? []
      : [
        {
          icon: "la:edit",
          text: t("menu.profile"),
          action: () => navigate("/profile"),
        },
      ]),
    {
      icon: "la:sign-out-alt",
      text: t("menu.logout"),
      action: () => {
        logout();
      },
    },
  ];


  const handleMouseEnter = (name: string) => {
    setHoveredItem(name);
  };

  const handleMouseLeave = () => {
    setHoveredItem("");
  };

  return (
    <Sidebar theme={customSideNav} className="w-20 h-full fixed z-10">
      <div className="flex items-center justify-center mb-4">
        <img alt="Nimbus logo" width={50} src={logo} />
      </div>
      <Sidebar.Items >
        <Sidebar.ItemGroup className="absolute ml-1">
          {navigationRoutes.map((currentRoute) => {
            const isAllowedForUser =
              currentRoute.roles && currentRoute.roles.includes("user");
            const isAllowedForAdmin =
              currentRoute.roles && currentRoute.roles.includes("admin");

            if (!userIsAdmin && !isAllowedForUser && isAllowedForAdmin) {
              return null;
            }
            return (
              <div key={currentRoute.href} className="block items">
                <Link
                  key={currentRoute.href}
                  to={currentRoute.href}
                  onMouseEnter={() => handleMouseEnter(currentRoute.href)}
                  onMouseLeave={handleMouseLeave}
                  className={clsx(
                    "inline-block h-[48px] gap-4 p-3 rounded-md mb-4 border text-sm font-manropeSemiBold",
                    {
                      "text-salicru bg-white border-neutralGrey200":
                        pathname.pathname === currentRoute.href,
                      "text-blueGrey100 border-neutralGrey200":
                        pathname.pathname !== currentRoute.href,
                      "transition-all duration-100 ease-in grow items-center justify-center bg-gray-50 hover:bg-white border-gray-300":
                        hoveredItem === currentRoute.href,
                    }
                  )}
                >
                  <div className="flex items-center">
                    <Icon icon={currentRoute.icon} className="w-6 h-6" />
                    {(isOpen || hoveredItem) && hoveredItem === currentRoute.href && (
                      <>
                        <div className="ml-3 font-manropeSemiBold">
                          {currentRoute.icon === 'la:microchip' && (
                            <span className="">{t("menu.device")}</span>
                          )}
                          {currentRoute.icon === 'la:user-friends' && (
                            <span className="">{t("menu.user")}</span>
                          )}
                          {currentRoute.icon === 'la:bell' && (
                            <span className="">{t("menu.notification")}</span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
          <div className="fixed flex flex-col gap-2 bottom-20">
            <SideNavProfile dropdownItems={dropDownItems} />
            <LanguageSelector/>
          </div>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
};

export default SideNav;